import { Pipe, PipeTransform } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map, shareReplay, startWith } from 'rxjs/operators';
import { isEqual } from 'lodash';
import { AqlQuery } from '../../aql-module/models/aql/aql-query';
import { FormValueToAqlQueryService } from '../services/form-value-to-aql-query.service';

@Pipe({
  name: 'getQueryFromFormControl',
})
export class GetQueryFromFormControlPipe implements PipeTransform {
  constructor(private formValueToAqlQueryService: FormValueToAqlQueryService) {}

  transform(aqlQueryCtrl: UntypedFormGroup): Observable<AqlQuery | null> {
    return aqlQueryCtrl.valueChanges.pipe(
      startWith({}),
      map(() => {
        if (aqlQueryCtrl.invalid) return null;
        return this.formValueToAqlQueryService.queryFormToAql(aqlQueryCtrl.value);
      }),
      distinctUntilChanged(isEqual),
      shareReplay(1)
    );
  }
}
