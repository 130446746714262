import { Validator, ValidatorType } from '../validator';

export interface CurrencyValidator extends Validator {
  type: ValidatorType.CURRENCY;
  currency?: string;
  currencyKey?: string;
}

export function isCurrencyValidator(validator: Validator): validator is CurrencyValidator {
  return validator.type === ValidatorType.CURRENCY;
}
