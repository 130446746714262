<app-field
  fieldClasses="droplist-field"
  [labelOptions]="{ text: this.label ?? '', classes: this.labelClasses, for: this.uid }"
  [showErrorsAboveInput]="false"
  [control]="control"
  [class.disabled]="control.disabled"
  [class.hide-label]="isSelectedOptionsVisible"
  [class.dropdown-top]="dropdownAltPosition"
>
  <div
    class="multi-droplist-input-container"
    data-test="multi-droplist-input-container"
    [class.is-dropdown]="!control.disabled"
    [class.open]="isAvailableDropdownVisible"
    cdkOverlayOrigin
    #inputContainer
    #dropdownOrigin="cdkOverlayOrigin"
  >
    <input
      [id]="uid"
      [attr.title]="inputElement.value !== '' ? inputElement.value : (this.inputTitle ?? label)"
      type="text"
      class="input"
      data-test="multi-select-input"
      [ngClass]="inputClasses"
      [class.error]="control.invalid"
      [formControl]="inputCtrl"
      [attr.placeholder]="placeholder$ | async"
      (keydown)="handleKeydownInInput($event)"
      (blur)="toggleDropdownList(false)"
      (focus)="handleInputFocusEvent()"
      (click)="toggleDropdownList(true)"
      (paste)="updateFromCsv($event.clipboardData?.getData('text/plain')); $event.preventDefault()"
      autocomplete="false"
      #inputElement
    />
      <button *ngIf="!(control.disabled)" type="button" class="dropdown-toggle-btn" data-test="dropdown-arrow-btn" (click)="showDropdownAndFocusInput()">
        <fa-icon class="icon icon-m icon-primary icon-clickable" [icon]="faChevronDown" [rotate]="(isAvailableDropdownVisible ? 180 : null)!"></fa-icon>
      </button>
  </div>
</app-field>

<ng-template
  #selectedOptionsDropdown="cdkConnectedOverlay"
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="dropdownOrigin"
  [cdkConnectedOverlayOpen]="isSelectedOptionsVisible"
  [cdkConnectedOverlayFlexibleDimensions]="true"
  [cdkConnectedOverlayPositions]="selectedOptionsDropdownPosition"
  [cdkConnectedOverlayViewportMargin]="15"
  [cdkConnectedOverlayWidth]="dropdownOriginRect?.width!"
  [cdkConnectedOverlayScrollStrategy]="scrollStrategyOptions.reposition()"
  [cdkConnectedOverlayDisableClose]="true"
>
  <div class="selected-options" data-test="selected-options" (mousedown)="$event.preventDefault()" [@overlayAnimation]>
    <ul
      *ngIf="((selectedOptions$ | async)?.length ?? 0) > 0; else noSelectedOptions"
      class="options-list"
      role="listbox"
    >
      <li
        *ngFor="let option of selectedOptions$ | async; let i = index"
        [attr.title]="option.label"
      >
        <button type="button" class="pill-button" [class.highlight]="selectedHighlightedIndex === i" (click)="removeOption(option.value)">
          <ng-container>{{ option.label }}</ng-container>
          <fa-icon class="icon icon-clickable icon-xs right" [icon]="cancelIcon"></fa-icon>
        </button>
      </li>
    </ul>

    <ng-template #noSelectedOptions>
      <div class="no-selected-options" i18n="@@multi-select-datalist__no-selected-options">There are no selected options.</div>
    </ng-template>
  </div>
</ng-template>

<ng-template
  #dropDown="cdkConnectedOverlay"
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="dropdownOrigin"
  [cdkConnectedOverlayOpen]="isAvailableDropdownVisible"
  [cdkConnectedOverlayFlexibleDimensions]="true"
  [cdkConnectedOverlayPositions]="dropdownPositions"
  [cdkConnectedOverlayViewportMargin]="15"
  [cdkConnectedOverlayWidth]="dropdownOriginRect?.width!"
  [cdkConnectedOverlayScrollStrategy]="scrollStrategyOptions.close()"
  (detach)="isAvailableDropdownVisible = false"
>
  <div class="dropdown" [class.dropdown-alt]="dropdownAltPosition" data-test="multi-select-dropdown">
    <app-loading-spinner *ngIf="isLoading$ | async; else options"></app-loading-spinner>

    <ng-template #options>
      <ng-container *ngIf="(filteredOptions$ | async); let filteredOptions">
        <ul
          *ngIf="filteredOptions.length > 0; else noOptions"
          class="options"
          data-test="options"
          role="listbox"
        >
          <li
            *ngFor="let option of filteredOptions; let i = index"
            class="option"
            [class.highlight]="availableHighlightedIndex === i"
            [attr.title]="option.label"
            (mousedown)="$event.preventDefault()"
            (click)="toggleOption(option.value)"
          >
            <span>
              <input [id]="uid + i" tabindex="-1" [checked]="isSelected(option.value)" type="checkbox" role="option" />
            </span>
            <label [class.highlight]="availableHighlightedIndex === i" [for]="uid + i" (click)="$event.preventDefault()">{{ option.label }}</label>
          </li>
        </ul>
      </ng-container>

      <ng-template #noOptions>
        <div class="no-options" data-test="multi-select-dropdown-no-options" i18n="@@filter-field__no-matches-text">There are no matches.</div>
      </ng-template>
    </ng-template>
  </div>
</ng-template>
