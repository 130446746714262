import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Inject, OnDestroy, Renderer2, ViewChild } from '@angular/core';
import { animationFrameScheduler, combineLatest, fromEvent, sampleTime, Subject } from 'rxjs';
import { debounceTime, map, startWith, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { SidePanelService } from '../../services/side-panel.service';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';

@Component({
  selector: 'app-side-panel',
  templateUrl: './side-panel.component.html',
  styleUrls: ['./side-panel.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidePanelComponent implements AfterViewInit, OnDestroy {
  private destroy$ = new Subject<null>();
  private latestMinWidth: string | null = null;
  protected readonly faXmark = faXmark;
  protected readonly tooltipCloseText = $localize`:@@button-close:Close`;
  diff = 0;

  @ViewChild('dragBorder', { read: ElementRef }) private dragBorder: ElementRef<HTMLDivElement> | undefined;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private elRef: ElementRef,
    private renderer: Renderer2,
    public sidePanelService: SidePanelService,
  ) {
    // update the min-width and width if the panel is open and the minWidth has changed, otherwise set to auto if the panel closed
    combineLatest([this.sidePanelService.isPanelOpen$, this.sidePanelService.minWidth$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([isOpen, minWidth]) => {
        this.renderer.setStyle(this.elRef.nativeElement, 'width', isOpen && this.latestMinWidth ? this.latestMinWidth : 'auto');
        this.renderer.setStyle(this.elRef.nativeElement, 'min-width', isOpen && minWidth ? minWidth : 'auto');
      });

    // listen for viewport resize events and main content width updates and set panel max-width depending
    combineLatest([
      fromEvent(window, 'resize').pipe(startWith(null),sampleTime(0, animationFrameScheduler)),
      this.sidePanelService.mainMinWidth$
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([_, mainMinWidth]) => {
        const totalWidth = this.document.documentElement.clientWidth;
        const sidebarWidth = Number(window.getComputedStyle(this.document.documentElement).getPropertyValue('--current-sidebar-width')) * 16;
        this.renderer.setStyle(this.elRef.nativeElement, 'max-width', mainMinWidth ? `${totalWidth - sidebarWidth - mainMinWidth}px` : 'auto');
      });
  }

  ngAfterViewInit() {
    // if there is a saved width in the local storage then apply it
    const savedSidePanelConfig = localStorage.getItem('sidePanelConfig');
    if (savedSidePanelConfig) {
      this.latestMinWidth = (JSON.parse(savedSidePanelConfig) as { width: string }).width;
      this.sidePanelService.isPanelOpen && this.renderer.setStyle(this.elRef.nativeElement, 'width', this.latestMinWidth);
    }

    // Listen to mouse down followed by mousemove events on the drag handle to update the minWidth on the component element relative to how far the user dragged
    fromEvent<MouseEvent>(this.dragBorder!.nativeElement, 'mousedown')
      .pipe(
        tap(e => {
          e.preventDefault();
          this.dragBorder!.nativeElement.classList.add('dragging');
        }),
        map(e => ({ xCoord: (e.target as HTMLDivElement).getBoundingClientRect().x, width: this.elRef.nativeElement.clientWidth })),
        switchMap(({ xCoord, width }) =>
          fromEvent<MouseEvent>(this.document, 'mousemove').pipe(
            debounceTime(1, animationFrameScheduler),
            tap(mousemoveEvent => {
              mousemoveEvent.preventDefault();
              document.body.style.cursor = 'col-resize';
              this.latestMinWidth = `${width + (xCoord - mousemoveEvent.x)}px`;
              this.renderer.setStyle(this.elRef.nativeElement, 'width', this.latestMinWidth);
            }),
            switchMap(() =>
              fromEvent<MouseEvent>(this.document, 'mouseup').pipe(
                tap(() => {
                  document.body.style.cursor = 'unset';
                  this.dragBorder!.nativeElement.classList.remove('dragging');
                  window.getSelection()?.removeAllRanges();
                  localStorage.setItem('sidePanelConfig', JSON.stringify({ width: this.latestMinWidth }));
                })
              )
            ),
            take(1)
          )
        )
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
