export const convertToHumanReadableFileSize = (sizeInBytes: number): string => {
  if (sizeInBytes == null) return '';
  const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  let l = 0;

  while (sizeInBytes >= 1024 && ++l) {
    sizeInBytes = sizeInBytes / 1024;
  }

  return sizeInBytes.toFixed(sizeInBytes < 10 && l > 0 ? 1 : 0) + ' ' + units[l];
};

export const FILENAME_REGEX = /^(?!^(PRN|AUX|CLOCK\$|NUL|CON|COM\d|LPT\d|\..*)(\..+)?$)[^\x00-\x1f\\?*:";|/<>]+$/i;
export const FILENAME_INVALID_MESSAGE = $localize`:@@file-info__filename__error--pattern:An asset name can't contain any of the following characters: \\ / : * ? " < > |`;

export function stripFileExtension(filename: string): string {
  const isExt = filename.lastIndexOf('.');
  return isExt === -1 ? filename : filename.substring(0, isExt);
}

export function isFileUploading(rowData: any): boolean {
  return rowData.uploadProgress.loadedBytes !== 0 && !rowData.uploadProgress.completed;
}

export function getFileUploadingPercentage(progress: { loaded: number|null, total: number|null }): number {
  const percentage = Math.floor((100 * (progress.loaded ?? 0)) / (progress.total ?? 0));
  return !isNaN(percentage) ? percentage : 0;
}
