import { Pipe, PipeTransform } from '@angular/core';
import { AuthService } from '../services/auth.service';

@Pipe({
  name: 'hasAnyPermissions',
})
export class HasAnyPermissionsPipe implements PipeTransform {
  constructor(private authService: AuthService) {}

  transform(permissions: string[] | undefined): boolean {
    if (permissions === undefined) return false;
    return this.authService.hasAnyPermissions(...permissions);
  }
}
