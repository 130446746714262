<div class="navbar" role="navigation" aria-label="main navigation">
  <div class="navbar-logo">
    <a [routerLink]="''" id="logoLink" class="image-link">
      <img class="logo" [attr.src]="logoSrc" alt="Logo" #logoImgEl>
    </a>
  </div>

  <div class="navbar-breadcrumbs">
    <app-breadcrumb></app-breadcrumb>
  </div>

  <div class="navbar-actions">
    <ng-template [cdkPortalOutlet]="navbarPortal"></ng-template>
  </div>
</div>
