import { Inject, Injectable } from '@angular/core';
import { Auth0Service } from '../auth0/auth0.service';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { isR36Url } from './api-utils';
import { APP_CONFIG, AppConfig } from '../../app-config';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationInterceptorService implements HttpInterceptor {
  constructor(private auth: Auth0Service, @Inject(APP_CONFIG) private config: AppConfig) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return this.auth.getTokenSilently$().pipe(
      mergeMap(token => {
        const tokenReq = isR36Url(req.url, this.config.apiRoot)
          ? req.clone({
              setHeaders: { Authorization: `Bearer ${token}` },
            })
          : req;
        return next.handle(tokenReq);
      }),
    );
  }
}
