import { Validator, ValidatorType } from '../validator';

export interface AttributeKeyValidator extends Validator {
  type: ValidatorType.ATTRIBUTE_KEY;
  allowInts: boolean;
  strict: boolean;
}

export function isAttributeKeyValidator(validator: Validator): validator is AttributeKeyValidator {
  return validator.type === ValidatorType.ATTRIBUTE_KEY;
}
