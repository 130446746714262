import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { IRestError } from '../../../models/rest-response';
import { OVERLAY_CONTROL, OVERLAY_DATA, OverlayControl } from '../../../services/overlay.service';
import { Task } from '../../models/task';
import { TaskHelperService } from '../../services/task-helper.service';

@Component({
  selector: 'app-task-error-modal',
  templateUrl: './task-error-modal.component.html',
  styleUrls: ['./task-error-modal.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskErrorModalComponent {
  private task: Task;
  subtitle: string;
  errors: IRestError[] | Error | null;

  constructor(@Inject(OVERLAY_DATA) config: TaskErrorModalConfig, @Inject(OVERLAY_CONTROL) private overlayCtrl: OverlayControl, private taskHelperService: TaskHelperService) {
    this.task = config.task;
    this.errors = config.task.errors ? config.task.errors : new Error(); // else generic error - handled by modal-result
    this.subtitle = this.taskHelperService.getDisplayAs(this.task);
  }

  onConfirm() {
    this.overlayCtrl.close();
  }
}

export interface TaskErrorModalConfig {
  task: Task;
}
