import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HasAnyPermissionsPipe } from './pipes/has-any-permissions.pipe';
import { HasAllPermissionsPipe } from './pipes/has-all-permissions.pipe';
import { HasPermissionPipe } from './pipes/has-permission.pipe';

@NgModule({
  declarations: [HasAnyPermissionsPipe, HasAllPermissionsPipe, HasPermissionPipe],
  exports: [HasAnyPermissionsPipe, HasAllPermissionsPipe, HasPermissionPipe],
  imports: [CommonModule],
})
export class AuthModule {}
