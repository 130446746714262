import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { Notification, Subscription } from '../types';
import { icon } from '@fortawesome/fontawesome-svg-core';
import { faBellRing, faCheckCircle, faXmarkCircle } from '@fortawesome/pro-solid-svg-icons';
import { faBell, faBellSlash, faChevronCircleRight } from '@fortawesome/pro-regular-svg-icons';
import { NotificationErrorModalComponent, NotificationErrorModalConfig } from '../components/notification-error-modal/notification-error-modal.component';
import { ActivityId, IRestError } from '../../models/rest-response';
import { OverlayService } from '../../services/overlay.service';
import { NotificationHttpService } from './notification-http.service';
import { IconBtnObj } from '../../shared-module/entities/icon-btn-obj';
import { hasDirectSubscription } from '../helpers/notification-helpers';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private notificationsSub$ = new ReplaySubject<Notification>();
  readonly notifications$ = this.notificationsSub$.asObservable();

  constructor(private overlayService: OverlayService, private notificationHttpService: NotificationHttpService) {}

  showNotification(notification: Notification) {
    this.notificationsSub$.next(notification);
  }

  showNotificationText(text: string, overrides?: Partial<Notification>) {
    const notification: Notification = {
      text,
      type: 'info',
      icon: { icon: icon(faCheckCircle), classes: [] },
      autoDismiss: true,
      ...overrides,
    };
    this.notificationsSub$.next(notification);
  }

  showErrorNotification(text: string, errors: IRestError[] | null | Error, subtitle?: string) {
    this.showNotification({
      text,
      type: 'error',
      icon: { icon: icon(faXmarkCircle), classes: [] },
      autoDismiss: false,
      action: {
        text: $localize`:@@notification-error-modal__run-report--action-text:View Details`,
        icon: { icon: icon(faChevronCircleRight), classes: [] },
        fn: () => { this.overlayService.showOverlay(NotificationErrorModalComponent, { subtitle, errors } as NotificationErrorModalConfig) },
      },
    });
  }

  unsubscribe(subscriptionId: string): Observable<ActivityId> {
    return this.notificationHttpService.unsubscribe(subscriptionId);
  }

  getSubscriptionIconBtnObject(entityId: string, entityType: string, subscriptions: Subscription[], subscribeCallback: () => void, unsubscribeCallback: () => void, onDarkBackground = false): IconBtnObj {
    if (hasDirectSubscription(entityId, entityType, subscriptions)) {
      return { iconObj: { icon: icon(faBellRing), classes: onDarkBackground ? 'icon-white' : 'icon-primary' }, title: this.getDirectSubscriptionTitle(entityType), callback: unsubscribeCallback };
    } else if (subscriptions.length > 0) {
      return { iconObj: { icon: icon(faBell), classes: onDarkBackground ? 'icon-white' : 'icon-primary' }, title: this.getIndirectSubscriptionTitle(subscriptions[0].entityType) };
    } else {
      return { iconObj: { icon: icon(faBellSlash), classes: 'icon-grey-l' }, title: this.getNotSubscribedTitle(entityType), callback: subscribeCallback };
    }
  }

  getDirectSubscriptionTitle(entityType: string): string {
    let title: string | undefined;
    switch (entityType) {
      case 'svc-allocation/job':
        title = $localize`:@@notification-module__subscription-icon--direct-subscribed--job:Unsubscribe from Job`;
        break;
      case 'svc-allocation/element':
        title = $localize`:@@notification-module__subscription-icon--direct-subscribed--element:Unsubscribe from Element`;
        break;
      case 'svc-site-db/site':
        title = $localize`:@@notification-module__subscription-icon--direct-subscribed--site:Unsubscribe from Site`;
        break;
      case 'svc-file/file':
        title = $localize`:@@notification-module__subscription-icon--direct-subscribed--asset:Unsubscribe from Asset`;
        break;
      case 'svc-comment/comment':
        title = $localize`:@@notification-module__subscription-icon--direct-subscribed--comment:Unsubscribe from Comment`;
        break;
      default:
        throw new Error(`Unknown subscription type: ${entityType}`);
    }
    return title as string;
  }

  getNotSubscribedTitle(entityType: string): string {
    let title: string | undefined;
    switch (entityType) {
      case 'svc-allocation/job':
        title = $localize`:@@notification-module__subscription-icon--direct-not-subscribed--job:Subscribe to Job`;
        break;
      case 'svc-allocation/element':
        title = $localize`:@@notification-module__subscription-icon--direct-not-subscribed--element:Subscribe to Element`;
        break;
      case 'svc-site-db/site':
        title = $localize`:@@notification-module__subscription-icon--direct-not-subscribed--site:Subscribe to Site`;
        break;
      case 'svc-file/file':
        title = $localize`:@@notification-module__subscription-icon--direct-not-subscribed--asset:Subscribe to Asset`;
        break;
      case 'svc-comment/comment':
        title = $localize`:@@notification-module__subscription-icon--direct-not-subscribed--comment:Subscribe to Comment`;
        break;
      default:
        throw new Error(`Unknown subscription type: ${entityType}`);
    }
    return title as string;
  }

  getIndirectSubscriptionTitle(entityType: string): string {
    let title: string | undefined;
    switch (entityType) {
      case 'svc-allocation/job':
        title = $localize`:@@notification-module__subscription-icon--indirect-subscribed--job:You are subscribed to the Job`;
        break;
      case 'svc-allocation/element':
        title = $localize`:@@notification-module__subscription-icon--indirect-subscribed--element:You are subscribed to the Element`;
        break;
      case 'svc-site-db/site':
        title = $localize`:@@notification-module__subscription-icon--indirect-subscribed--site:You are subscribed to the Site`;
        break;
      case 'svc-file/file':
        title = $localize`:@@notification-module__subscription-icon--indirect-subscribed--asset:You are subscribed to the Asset`;
        break;
      case 'svc-comment/comment':
        title = $localize`:@@notification-module__subscription-icon--indirect-subscribed--comment:You are subscribed to the Comment`;
        break;
      default:
        throw new Error(`Unknown subscription type: ${entityType}`);
    }
    return title as string;
  }
}
