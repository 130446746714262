import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Task, TaskId } from './models/task';
import { distinctUntilChanged } from 'rxjs/operators';
import { isEqual } from 'lodash';
import { Observable } from 'rxjs';
import { TaskStatus } from './models/task-status';

export interface TasksState {
  tasks: Map<TaskId, Task>;
}

@Injectable({
  providedIn: 'root',
})
export class TasksStore extends ComponentStore<TasksState> {
  constructor() {
    super({ tasks: new Map() });
  }

  readonly taskMap$: Observable<Map<TaskId, Task>> = this.select(state => state.tasks);
  readonly tasks$: Observable<Task[]> = this.select(this.taskMap$, taskMap => Array.from(taskMap.values())).pipe(distinctUntilChanged(isEqual));

  readonly unreadTasksCount$: Observable<number> = this.select(
    this.tasks$,
    tasks => tasks.filter(task => !task.acknowledged && (task.status === TaskStatus.COMPLETE || task.status === TaskStatus.ERROR)).length
  ).pipe(distinctUntilChanged());
  readonly tasksRunning$: Observable<boolean> = this.select(
    this.tasks$,
    tasks => tasks.filter(task => task.status === TaskStatus.RUNNING || task.status === TaskStatus.QUEUED).length > 0
  ).pipe(distinctUntilChanged());

  readonly deleteTasks = this.updater((state, taskIds: TaskId[]) => {
    const tasksMap = new Map(state.tasks);
    taskIds.forEach(id => tasksMap.delete(id));
    return { ...state, tasks: tasksMap };
  });

  readonly updateTasks = this.updater((state, tasks: Task[]) => {
    const tasksMap = new Map(state.tasks);
    tasks.forEach(task => tasksMap.set(task.taskId, task));
    return { ...state, tasks: tasksMap };
  });

  readonly acknowledgeTasks = this.updater((state, ids: TaskId[]) => {
    const tasksMap = new Map(state.tasks);
    ids.forEach(id => {
      const task = tasksMap.get(id);
      tasksMap.set(id, { ...task, acknowledged: true } as Task);
    });
    return { ...state, tasks: tasksMap };
  });
}
