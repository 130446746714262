import { InjectionToken } from '@angular/core';

export const APP_CONFIG = new InjectionToken<AppConfig>('app.config');

export interface AppConfig {
  auth0: {
    clientId: string
    domain: string
  };
  supportEmail: string;
  environment: string;
  apiRoot: string;
  wsRoot: string;
}

export function asWebSocketUrl(url: string): string {
  if (url.startsWith('http')) {
    return `ws${url.substring(4)}`;
  }
  throw new Error(`Unrecognised protocol: '${url}'`);
}
