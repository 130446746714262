import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Inject, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { OVERLAY_DATA } from '../../../services/overlay.service';
import { AbstractControl, FormControl, FormGroup, UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ModalComponent } from '../../../shared-module/components/modal/modal.component';
import { FUNCTION_OPTIONS, MATH_OPTIONS } from '../../utils/form-constants';
import { Schema } from '../../../attribute-module/models/schema';
import { take, takeUntil } from 'rxjs/operators';
import { Element } from '../../../allocation-module/models/element/element';
import { FormValueToAqlQueryService } from '../../services/form-value-to-aql-query.service';
import { positiveNumberOrFieldValidator } from '../../utils/query-builder-form-validators';
import { AqlToFormConversionService } from '../../services/aql-to-form-conversion.service';
import { JobStore } from '../../../allocation-module/pages/job/job.store';
import { KeyVal } from '../../../models/typescript-types';
import { AqlQuery } from '../../../aql-module/models/aql/aql-query';
import { AqlCriteria } from '../../../aql-module/models/aql/aql-criteria';
import { AqlFunctionTypes } from '../../../aql-module/models/aql/aql-value';
import { AUTO_COMPLETE_INTERFACE_IT } from '../../../search-module/interfaces/auto-complete.interface';
import { SiteService } from '../../../allocation-module/services/site.service';
import { FORM_VALUE_SCHEMA_IT, FormValueToCriteriaService } from '../../../aql-module/services/form-value-to-criteria.service';
import { isEqual } from 'lodash';
import { faChevronCircleUp, faChevronDown, faClone, faPlusCircle, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';

@Component({
  selector: 'app-query-builder-modal',
  templateUrl: './query-builder-modal.component.html',
  styleUrls: ['./query-builder-modal.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: AUTO_COMPLETE_INTERFACE_IT, useClass: SiteService },
    { provide: FORM_VALUE_SCHEMA_IT, useFactory: (store: JobStore) => store.mergedSiteSchema$, deps: [JobStore] },
    FormValueToAqlQueryService,
    FormValueToCriteriaService,
  ],
})
export class QueryBuilderModalComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject();
  private elementId: string;
  private queries: AqlQuery[];
  private appendedCriteria: AqlCriteria | null;
  protected readonly showSavedQueries$ = new BehaviorSubject(false);

  readonly warningIcon = faExclamationCircle;
  readonly chevronDownIcon = faChevronDown;
  readonly chevronCircleUpIcon = faChevronCircleUp;
  readonly cloneIcon = faClone;
  readonly deleteIcon = faTrashAlt;
  readonly plusCircleIcon = faPlusCircle;

  readonly mathOptions = MATH_OPTIONS;
  readonly functionOptions = FUNCTION_OPTIONS;
  form: UntypedFormArray | undefined;
  onSave: ((aql: AqlQuery[]) => void);
  element: Element;
  mergedSiteSchema$: Observable<KeyVal<Schema>> | undefined;
  mergedSiteSchemaAttributeKeys$: Observable<string[] | undefined> | undefined;
  mergedSiteSchemaNumericAttributeKeys$: Observable<string[] | undefined> | undefined;
  mergedSiteSchemaNumericBooleanAttributeKeys$: Observable<string[] | undefined> | undefined;
  newQueryAdded$ = new BehaviorSubject<boolean>(false);
  queryRemoved$ = new BehaviorSubject<number | null>(null);
  hasAppendedCriteria = false;
  forecastAllocationTextTranslation = $localize`:@@query-builder-modal__forecast-warning-text:Warning: Your forecast allocation is 0`;

  @ViewChild('modalComponent', { static: false }) modalRef: ModalComponent | undefined;
  @ViewChildren('queryBox') queryBoxChildren: QueryList<ElementRef> | undefined;

  constructor(
    @Inject(OVERLAY_DATA) private config: QueryBuilderModalConfig,
    private jobStore: JobStore,
    private formValueToAqlQueryService: FormValueToAqlQueryService,
    private aqlToFormConversionService: AqlToFormConversionService,
    private cdr: ChangeDetectorRef,
  ) {
    this.elementId = config.element.id;
    this.element = config.element;
    this.queries = config.element.aql ?? [];
    this.appendedCriteria = config.element.appendedCriteria;
    this.hasAppendedCriteria = this.appendedCriteria != null;

    this.onSave = this.config.onSave;
  }

  ngOnInit(): void {
    this.mergedSiteSchema$ = this.jobStore.mergedSiteSchema$;
    this.mergedSiteSchemaAttributeKeys$ = this.jobStore.mergedSiteSchemaAttributeKeys$;
    this.mergedSiteSchemaNumericAttributeKeys$ = this.jobStore.mergedSiteSchemaNumericAttributeKeys$;
    this.mergedSiteSchemaNumericBooleanAttributeKeys$ = this.jobStore.mergedSiteSchemaNumericBooleanAttributeKeys$;
    this.mergedSiteSchema$
      .pipe(take(1))
      .subscribe(siteSchema => this.form = this.aqlToFormConversionService.buildFormControl(siteSchema, this.queries));

    // if appendedCriteria exists prepend it to each query
    if (this.hasAppendedCriteria) {
      this.mergedSiteSchema$
        .pipe(take(1))
        .subscribe(siteSchema => this.form?.controls.forEach((ctrl: any) => this.prependAppendedCriteria(ctrl, siteSchema)));
    }

    // subscribe to the totalPriorities value changes for each query so we can update the checkboxes to match the value
    this.form?.controls.forEach(ctrl => this.subscribeToTotalPrioritiesChanges(ctrl));

    // Mark everything as touched so that error messages always display (not very nice but works)
    this.form?.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(_ => this.form?.markAllAsTouched());
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  get name(): FormControl {
    return this.form?.get('name') as FormControl;
  }

  get where(): FormControl {
    return this.form?.get('where') as FormControl;
  }

  get quantity(): FormControl {
    return this.form?.get('quantity') as FormControl;
  }

  onSaveClicked() {
    if (this.form?.invalid) return;
    const formValueAsAql = this.form?.value.map((it: any) => this.formValueToAqlQueryService.queryFormToAql(it));
    if (!isEqual(this.queries, formValueAsAql)) this.onSave(formValueAsAql);
    this.onClose();
  }

  getBasicQuantity(ctrl: AbstractControl | UntypedFormControl): UntypedFormControl | null {
    if (ctrl instanceof UntypedFormControl) {
      return ctrl;
    }
    const values = ctrl.get('values') as UntypedFormArray;
    if (ctrl.get('fnType')?.value === 'group' && values.length === 1 && values.controls[0] instanceof UntypedFormControl) {
      return values.controls[0] as UntypedFormControl;
    }
    return null;
  }

  isBasicQuantity(ctrl: AbstractControl): boolean {
    return ctrl instanceof UntypedFormControl;
  }

  countQuantityValues(quantityControl: AbstractControl): number {
    // If the quantity has no value then it has 0 values
    if (!quantityControl.value) {
      return 0;
    }
    let c = 0;
    (quantityControl.get('values') as UntypedFormArray).controls.forEach(ctrl => {
      if (ctrl instanceof UntypedFormControl) {
        c++;
      } else {
        c += this.countQuantityValues(ctrl);
      }
    });

    return c;
  }

  addQuantity(parentGroup: AbstractControl) {
    this.mergedSiteSchema$?.pipe(take(1)).subscribe(siteSchema => {
      (parentGroup.get('values') as UntypedFormArray).push(new UntypedFormControl(null, [Validators.required, positiveNumberOrFieldValidator(siteSchema)]));
      (parentGroup.get('operators') as UntypedFormArray).push(new UntypedFormControl('add'));
    });
  }

  addQuantityGroup(parentGroup: AbstractControl) {
    this.mergedSiteSchema$?.pipe(take(1)).subscribe(siteSchema => {
      (parentGroup.get('values') as UntypedFormArray).push(
        this.aqlToFormConversionService.convertQuantityToFormGroup(siteSchema, { type: 'function', function: { type: AqlFunctionTypes.GROUP, value: null } })
      );
      (parentGroup.get('operators') as UntypedFormArray).push(new UntypedFormControl('add'));
    });
  }

  removeQuantity(qtyCtrl: AbstractControl, i: number) {
    const values = qtyCtrl.get('values') as UntypedFormArray;
    values.removeAt(i);
    if (values.length === 0) {
      if (qtyCtrl.parent instanceof UntypedFormGroup) {
        // The parent is a priority (it can't be a quantity because we don't allow the user to delete the last group in a quantity)
        // set the offset to null
        qtyCtrl.parent.setControl('offset', new UntypedFormControl(null));
      } else {
        // The parent is quantity group
        // If the group is empty then we need to delete it
        const parent = qtyCtrl.parent as UntypedFormArray;
        parent.parent && this.removeQuantity(parent.parent, parent.controls.indexOf(qtyCtrl));
      }
    }
  }

  deletePriority(queryCtrl: UntypedFormGroup) {
    queryCtrl.setControl('priority', new UntypedFormControl(null));
  }

  addPriority(queryCtrl: UntypedFormGroup) {
    this.mergedSiteSchema$?.pipe(take(1)).subscribe(siteSchema => {
      queryCtrl.setControl(
        'priority',
        this.aqlToFormConversionService.convertPriorityToFormGroup(siteSchema, {
          offset: null,
          selectedPriorities: [],
          totalPriorities: 2,
        })
      );
      // subscribe to the totalPriorities value changes so we can update the checkboxes to match the value
      this.subscribeToTotalPrioritiesChanges(queryCtrl);
    });
  }

  /**
   * If a query control has priorities then subscribe to the total priorities valueChange in order to update the selected priorities checkboxes to match.
   */
  private subscribeToTotalPrioritiesChanges(queryCtrl: AbstractControl) {
    if (queryCtrl.get('priority.totalPriorities') && queryCtrl.get('priority.selectedPriorities')) {
      queryCtrl
        .get('priority.totalPriorities')
        ?.valueChanges.pipe(takeUntil(this.destroy$))
        .subscribe(total => this.updateSelectedPriorities(queryCtrl.get('priority.selectedPriorities') as UntypedFormArray, total));
    }
  }

  updateSelectedPriorities(selectedPrioritiesCtrl: UntypedFormArray, totalPriorities: number) {
    if (totalPriorities < selectedPrioritiesCtrl.value.length) {
      Array(selectedPrioritiesCtrl.controls.length - totalPriorities)
        .fill(false)
        .forEach(_ => selectedPrioritiesCtrl.removeAt(selectedPrioritiesCtrl.controls.length - 1));
    } else if (totalPriorities > selectedPrioritiesCtrl.value.length) {
      Array(totalPriorities - selectedPrioritiesCtrl.controls.length)
        .fill(false)
        .forEach(_ => selectedPrioritiesCtrl.push(new UntypedFormControl(false)));
    }
  }

  addOffset(priority: UntypedFormGroup) {
    this.mergedSiteSchema$?.pipe(take(1)).subscribe(siteSchema => {
      priority.setControl('offset', this.aqlToFormConversionService.convertPriorityOffsetToFormGroup(siteSchema, { type: 'numeric', value: null }));
    });
  }

  toggleAdvanced(queryCtrl: UntypedFormGroup) {
    const prevVal = queryCtrl.get('showAdvancedQuantity')?.value;
    queryCtrl.patchValue({ showAdvancedQuantity: !prevVal });
    // show the query (i.e change from collapsed) if it is collapsed and we're setting showAdvancedQuantity to true
    if (!prevVal && !queryCtrl.get('show')?.value) queryCtrl.get('show')?.patchValue(true);
  }

  toggleDisplay(event: Event, queryCtrl: UntypedFormGroup) {
    event.stopImmediatePropagation();
    const prevVal = queryCtrl.get('show')?.value;
    queryCtrl.patchValue({ show: !prevVal });
    // if setting the display not to show, hide the advanced
    if (prevVal && queryCtrl.get('showAdvancedQuantity')?.value) queryCtrl.get('showAdvancedQuantity')?.patchValue(false);
  }

  showQueryBox(queryCtrl: UntypedFormGroup) {
    const isShowing = queryCtrl.get('show')?.value;
    if (!isShowing) {
      queryCtrl.patchValue({ show: true });
    }
  }

  addQuery(aql?: AqlQuery) {
    this.mergedSiteSchema$?.pipe(take(1)).subscribe(siteSchema => {
      this.form?.push(this.aqlToFormConversionService.convertQueryToFormGroup(siteSchema, aql ?? this.element.defaultAql ?? { quantity: { type: 'numeric', value: 1 }, where: null, whereFixture: null, isAnyFixtureExists: false, priority: null }, true));
      // if appendedCriteria exists, prepend it
      if (this.hasAppendedCriteria) this.prependAppendedCriteria(this.form?.controls[this.form?.controls.length - 1] as UntypedFormGroup, siteSchema);
      this.showAndAnimateNewQuery();
    });
  }

  private showAndAnimateNewQuery() {
    this.newQueryAdded$.next(true);
    this.cdr.markForCheck();
    setTimeout(() => this.newQueryAdded$.next(false), 2000);
    // we need to wrap in setTimeout to allow for the queryBox to have been added
    setTimeout(() => {
      requestAnimationFrame(() => {
        this.queryBoxChildren?.last?.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      });
    });
  }

  newQueryFromTemplate() {
    this.showSavedQueries$.next(true);
  }

  addQueryFromTemplate(aqlQuery: AqlQuery) {
    this.addQuery(aqlQuery);
    this.showSavedQueries$.next(false);
  }

  deleteQuery(event: Event, queryNo: number) {
    event.stopImmediatePropagation();
    this.queryRemoved$.next(queryNo);
    setTimeout(() => {
      this.form?.removeAt(queryNo);
      this.queryRemoved$.next(null);
      this.newQueryAdded$.next(false);
    }, 150);
  }

  cloneQuery(event: Event, queryNo: number) {
    // to clone the query we need to create the form based on the aql query as the queryToForm logic creates validators that we need for the form to function correctly
    event.stopImmediatePropagation();
    const control = this.form?.at(queryNo);
    if (control == null) throw new Error(`Error: Couldn't find the query`);
    // convert the value to a aql query
    const valueAsQuery = this.formValueToAqlQueryService.queryFormToAql(control.value);
    this.mergedSiteSchema$!
      .pipe(take(1))
      .subscribe(siteSchema => {
        // create a control from the aql query
        const newControl = this.aqlToFormConversionService.convertQueryToFormGroup(siteSchema, valueAsQuery, true);
        if (newControl == null) throw new Error(`Error: Couldn't validate the query`);
        if (control.disabled) newControl.disable();
        this.subscribeToTotalPrioritiesChanges(newControl);
        // push the new cloned control to the initial form
        if (this.form) {
          this.form.push(newControl);
          this.prependAppendedCriteria(this.form.controls[this.form.controls.length - 1] as FormGroup, siteSchema);
          this.showAndAnimateNewQuery();
        }
      });
  }

  onLastCriteriaDeleted(queryNo: number) {
    // Our parent must be the where
    // Set it to null
    (this.form?.at(queryNo) as UntypedFormGroup).setControl('where', new UntypedFormControl(null));
  }

  private prependAppendedCriteria(ctrl: UntypedFormGroup, siteSchema: KeyVal<Schema>) {
    const where: AbstractControl | null = ctrl.get('where');
    const appendedCriteriaAsFormGroup = this.appendedCriteria && this.aqlToFormConversionService.convertCriteriaToFromGroup(siteSchema, this.appendedCriteria) as UntypedFormGroup;

    if (!appendedCriteriaAsFormGroup) return;

    // we add emitEvent false so we don't trigger the status change and re-enable the fields
    appendedCriteriaAsFormGroup.disable({ emitEvent: false });

    // We create an AND criteria that contains the appended criteria and the existing where criteria if it exists
    ctrl.setControl(
      'where',
      new UntypedFormGroup({
        type: new UntypedFormControl({ value: 'and', disabled: true }),
        not: new UntypedFormControl({ value: false, disabled: true }),
        criteria: new UntypedFormArray(where?.value != null ? [appendedCriteriaAsFormGroup, where] : [appendedCriteriaAsFormGroup]),
      })
    );
  }

  onClose() {
    if (this.config.onClose) {
      this.config.onClose();
    }
    this.modalRef?.closeModal();
  }
}

export interface QueryBuilderModalConfig {
  element: Element;
  onSave: (aql: AqlQuery[]) => void;
  onClose?: () => void;
}
