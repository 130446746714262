import { getFirstValidator, Validator, ValidatorType } from '../validator';
import { Schema } from '../schema';

export interface DateValidator extends Validator {
  type: ValidatorType.DATE;
}

export function isDateValidator(validator: Validator): validator is DateValidator {
  return validator.type === ValidatorType.DATE;
}

export function hasDateValidator(schema: Schema) {
  const firstValidator = getFirstValidator(schema);
  return firstValidator && isDateValidator(firstValidator);
}
