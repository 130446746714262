import { Pipe, PipeTransform } from '@angular/core';
import { ConfigService } from '../../services/config.service';

@Pipe({
  name: 'hasFeature'
})
export class HasFeaturePipe implements PipeTransform {

  constructor(private config: ConfigService) {}

  transform(feature: string): unknown {
    return this.config.hasFeature(feature);
  }

}
