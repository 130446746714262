import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { QueryBuilderModalComponent } from '../components/query-builder-modal/query-builder-modal.component';
import { AqlQuery } from '../../aql-module/models/aql/aql-query';
import { AllocationService } from '../services/allocation.service';

@Pipe({
  name: 'forecast',
})
export class ForecastPipe implements PipeTransform {
  constructor(
    private allocationService: AllocationService,
    private queryBuilderModalComponent: QueryBuilderModalComponent,
  ) {}

  transform(query: Observable<AqlQuery|null>): Observable<string> {
    return query.pipe(switchMap(value => {
      if (value == null) return of('~');

      return this.allocationService
        .testAqlQuery(
          this.queryBuilderModalComponent.element.job.id,
          this.queryBuilderModalComponent.element.id,
          value
        )
        .pipe(
          map(count => `${count}`),
          catchError(_ => '~')
        );
    }));

  }
}
