import { Component, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Component({
  selector: 'app-field [control] [labelOptions]',
  templateUrl: './field.component.html',
  styleUrls: ['./field.component.sass'],
  // changeDetection: ChangeDetectionStrategy.OnPush, // We would like this but no can do - https://github.com/angular/angular/issues/10887
})
export class FieldComponent {
  @Input() labelOptions: { text?: string; classes?: string; for: string; } | undefined;
  @Input() showErrorsAboveInput = true;
  @Input() showErrorsTooltip = true;
  @Input() control: NgControl | undefined;
  @Input('fieldClasses') set fieldClasses(classes: any) {
    this.fieldContainerClasses = `field ${classes}`;
  }

  fieldContainerClasses = 'field';

  constructor() { }

}
