import { Injectable, OnDestroy } from '@angular/core';
import { TasksService } from '../../tasks-module/services/tasks.service';
import { NotificationService } from './notification.service';
import { filter, map, takeUntil } from 'rxjs/operators';
import { Notification, NotificationAction } from '../types';
import { TaskStatus } from '../../tasks-module/models/task-status';
import { Task } from '../../tasks-module/models/task';
import { TaskHelperService } from '../../tasks-module/services/task-helper.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NotificationsBridgeService implements OnDestroy {

  private destroy$ = new Subject();
  private isInitialised = false;

  constructor(
    private tasksService: TasksService,
    private notificationsService: NotificationService,
    private taskHelperService: TaskHelperService
  ) {}

  init() {
    if(this.isInitialised) return;
    this.isInitialised = true;

    this.tasksService.tasksStream$
      .pipe(
        takeUntil(this.destroy$),
        filter(it => (it.status === TaskStatus.COMPLETE || it.status === TaskStatus.ERROR) && it.acknowledged === false),
        map(
          task => {
            const notification: Notification = {
              text: this.getNotificationTextFromTask(task),
              type: task.status === TaskStatus.COMPLETE ? 'success' : 'error',
              autoDismiss: false,
              icon: this.taskHelperService.getIconByTaskType(task.type),
            };
            if (task.result || task.status === TaskStatus.ERROR) {
              notification.action = {
                text: this.taskHelperService.getResultActionTextFromTask(task),
                icon: this.taskHelperService.getResultActionIconFromTask(task),
                fn: () => this.tasksService.triggerTaskActionAndAcknowledge(task),
              } as NotificationAction;
            }
            return notification;
          },
        ),
      )
      .subscribe(notification => {
        this.notificationsService.showNotification(notification)
      });
  }

  private getNotificationTextFromTask(task: Task): string {
    switch (task.type) {
      case 'svc-report/execute-report':
        switch (task.status) {
          case 'COMPLETE':
            return $localize`:@@notification-bridge__notification-text--report--complete:Your Report: '${this.taskHelperService.getDisplayAs(task)}:displayAs:' is ready`;
          case 'ERROR':
            return $localize`:@@notification-bridge__notification-text--report--failed:Your Report: '${this.taskHelperService.getDisplayAs(task)}:displayAs:' failed`;
        }
        break
      case 'svc-workflow/execute-workflow':
        switch (task.status) {
          case 'COMPLETE':
            return $localize`:@@notification-bridge__notification-text--workflow--complete:Your Workflow: '${this.taskHelperService.getDisplayAs(task)}:displayAs:' is completed`;
          case 'ERROR':
            return $localize`:@@notification-bridge__notification-text--workflow--failed:Your Workflow: '${this.taskHelperService.getDisplayAs(task)}:displayAs:' failed`;
        }
    }
    return '';
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
