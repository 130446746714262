<button
  type="button"
  class="btn task-btn"
  data-test="task-btn"
  title="{{ task | getTaskDisplayAs }}"
  (click)="triggerTaskAction(task)"
  [disabled]="deletingTask"
>
  <fa-icon [icon]="(task.type | getTaskTypeIcon).icon" class="task-icon icon" [ngClass]="(task.type | getTaskTypeIcon).classes ?? []" data-test="task-icon"></fa-icon>

  <span class="task-name" [class.error]="task.status === taskStatus.ERROR" data-test="task-name" [attr.title]="task | getTaskDisplayAs">{{ task | getTaskDisplayAs }}</span>
  <span class="task-date" data-test="task-date" [attr.title]="task.createdAt | date: 'short'">{{ task.createdAt | toRelativeDateTime }}</span>

  <app-task-status-icon [task]="task"></app-task-status-icon>

  <div *ngIf="task | getResultActionIcon; let resultActionIcon" class="task-action" data-test="task-action">
    <fa-icon [icon]="resultActionIcon.icon" class="icon task-action-icon"></fa-icon>
  </div>
</button>

<button
  *ngIf="!deletingTask; else loadingSpinner"
  type="button"
  class="icon-btn remove-task-btn"
  title="Remove Task"
  i18n-title="@@task__remove-task-button--title"
  data-test="remove-task-btn"
  (click)="removeTask(task.taskId)"
>
  <fa-icon [icon]="removeIcon" class="icon icon-danger remove-task-icon" data-test="remove-task-icon"></fa-icon>
</button>
<ng-template #loadingSpinner>
  <app-loading-spinner iconClasses="icon-warning icon-sm" class="loading-task-icon" [disableLoadingText]="true"></app-loading-spinner>
</ng-template>
