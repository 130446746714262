import { ComponentStore } from '@ngrx/component-store';
import { KeyStoreData } from '../models/key-store-data';
import { Observable } from 'rxjs';
import { KeyVal } from '../../models/typescript-types';

export class KeyStoreDataStore extends ComponentStore<KeyStoreData> {

  readonly updateDataByKey = this.updater((state, { key, data }: { key: string, data: any }) => {
    // check whether the data is of type object and merge the data object, else set the data if array or string
    if (typeof data === 'object' && !Array.isArray(data)) {
      const newState = this.getNewStateWithNullsRemoved(state[key], data);
      return { ...state, [key]: newState };
    } else {
      return { ...state, [key]: data };
    }
  });

  readonly getDataByKey$ = <T>(key: string): Observable<T | null> => this.select(data => {
    return data.hasOwnProperty(key) ? data[key] : null;
  });

  getNewStateWithNullsRemoved(state: any, data: KeyVal<any>): KeyVal<any> {
    // remove null value pairs as they are removed on the server
    const newState = { ...state, ...data };
    const nullValueKeys = Object.entries(newState).filter(([_, v]) => v == null).map(([k, _]) => k);
    nullValueKeys.forEach(k => delete newState[k]);
    return newState;
  }

  getDataByKey<T>(key: string): T | null {
    return this.get((state) => state.hasOwnProperty(key) ? state[key] : null)
  }

}

