import { Injectable } from '@angular/core';
import { AttributeValue } from '../models/attribute';
import { ValidatorType } from '../models/validator';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { PhoneNumberValidator } from '../models/validator/phone-number-validator';

@Injectable({
  providedIn: 'root'
})
export class PhoneNumberValidatorService {

  validate(displayName: string, validator: PhoneNumberValidator, value: AttributeValue): { [p: string]: any } {
    if (!value) return {}; // Short circuit if the value is missing

    if (typeof value !== 'string') {
      return { [ValidatorType.PHONE_NUMBER]: { value, message: $localize`:@@phone-number-validator__invalid--not-a-string:${displayName}:displayName: must be a string.`}};
    }
    if (!isValidPhoneNumber(value)){
      return { [ValidatorType.PHONE_NUMBER]: { value, message: $localize`:@@phone-number-validator__invalid--not-a-phone-number:${displayName}:displayName: must be a valid international phone number.`}};
    }

    return {};
  }
}
