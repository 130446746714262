import { Injectable, OnDestroy } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AqlFieldValue, AqlSimpleValue, AqlValue, isAqlFieldValue } from '../models/aql/aql-value';
import {
  AqlCriteria,
  isAqlArrayContainsCriteria,
  isAqlExistsCriteria,
  isAqlGroupCriteria,
  isAqlInCriteria,
  isAqlNexistsCriteria,
  isAqlNinCriteria
} from '../models/aql/aql-criteria';
import { isAqlOperatorCriteria } from '../models/aql/aql-operator-criteria';
import { AqlOperator, getInverseOfAqlOperator } from '../models/aql/aql-operator';
import { takeUntil } from 'rxjs/operators';
import { AqlTypeConverterService } from './aql-type-converter.service';
import { Schema } from '../../attribute-module/models/schema';
import { merge, Subject } from 'rxjs';
import { KeyVal, Nullable } from '../../models/typescript-types';
import { getAqlOperatorsForSchema } from '../../query-builder-module/utils/form-constants';
import { fieldValidator } from '../utils/form-validators';
import { ValidatorService } from '../../attribute-module/services/validator.service';
import { hasDateValidator } from '../../attribute-module/models/validator/date-validator';
import { ValidatorType } from '../../attribute-module/models/validator';

@Injectable({ providedIn: 'root' })
export class CriteriaToFormConversionService implements OnDestroy {
  private static readonly ARRAY_OPERATORS = new Set([AqlOperator.IN, AqlOperator.NIN, AqlOperator.CONTAINS_ANY, AqlOperator.CONTAINS_ALL, AqlOperator.CONTAINS_NONE])

  private destroy$ = new Subject();

  constructor(private converterSvc: AqlTypeConverterService, private validatorService: ValidatorService) {}

  convertCriteriaToFromGroup(schemaMap: KeyVal<Schema>, criteria: Nullable<AqlCriteria>, isWhere: boolean = false): UntypedFormGroup | UntypedFormControl {
    if (criteria == null) {
      // If there's no criteria then return a null form control
      return new UntypedFormControl(null);
    }

    let not = false;
    let unnestedCriteria: AqlCriteria = criteria;

    // Recurse through any not criteria in the query to flatten/remove them
    while (unnestedCriteria.type === 'not') {
      not = !not;
      unnestedCriteria = unnestedCriteria.criteria;
    }

    // If we're notting an operator then we'll invert it rather than wrap it in a not group
    if (not && !isAqlGroupCriteria(unnestedCriteria)) {
      not = false;
      if (isAqlInCriteria(unnestedCriteria) || isAqlNinCriteria(unnestedCriteria)) {
        unnestedCriteria = {...unnestedCriteria, type: isAqlInCriteria(unnestedCriteria) ? 'nin' : 'in'};
      } else if (isAqlExistsCriteria(unnestedCriteria) || isAqlNexistsCriteria(unnestedCriteria)) {
        unnestedCriteria = {...unnestedCriteria, type: isAqlExistsCriteria(unnestedCriteria) ? 'nexists' : 'exists'};
      } else if (isAqlOperatorCriteria(unnestedCriteria)) {
        unnestedCriteria = {...unnestedCriteria, operator: getInverseOfAqlOperator(unnestedCriteria.operator)};
      } else if (isAqlArrayContainsCriteria(unnestedCriteria)) {
        if(unnestedCriteria.quantifier === 'ALL'){
          // We don't current have an inverse operator for ALL (
          not = true;
        } else {
          unnestedCriteria = {...unnestedCriteria, quantifier: unnestedCriteria.quantifier === 'NONE' ? 'ANY' : 'NONE' };
        }
      } else {
        throw Error(`Unknown AQL Criteria '${JSON.stringify(unnestedCriteria)}' when trying to invert it.`);
      }
    }

    // If this is the root criteria and we're not a group then insert a group so we display better
    if (isWhere && !isAqlGroupCriteria(unnestedCriteria)) {
      unnestedCriteria = { type: 'and', criteria: [unnestedCriteria] };
    }

    if (isAqlGroupCriteria(unnestedCriteria)) {
      const control = new UntypedFormGroup({
        type: new UntypedFormControl(unnestedCriteria.type),
        not: new UntypedFormControl(not),
        criteria: new UntypedFormArray(unnestedCriteria.criteria.map(it => this.convertCriteriaToFromGroup(schemaMap, it))),
      });
      // When the type (and/or) changes re-emit it to make sure all of the pill buttons update
      control
        ?.get('type')
        ?.valueChanges.pipe(takeUntil(this.destroy$))
        .subscribe(value => control.get('type')?.setValue(value, { onlySelf: true, emitEvent: false, emitModelToViewChange: true }));
      return control;
    } else if (
      isAqlOperatorCriteria(unnestedCriteria) ||
      isAqlInCriteria(unnestedCriteria) ||
      isAqlNinCriteria(unnestedCriteria) ||
      isAqlExistsCriteria(unnestedCriteria) ||
      isAqlNexistsCriteria(unnestedCriteria) ||
      isAqlArrayContainsCriteria(unnestedCriteria)
    ) {
      let left: AqlSimpleValue[] | AqlValue | null;
      let right: AqlSimpleValue[] | AqlValue | null;
      if (isAqlExistsCriteria(unnestedCriteria) || isAqlNexistsCriteria(unnestedCriteria)) {
        left = unnestedCriteria.field;
        right = null;
      } else {
        // Make sure that the left is always a field value
        left = unnestedCriteria.left;
        right = unnestedCriteria.right;
        if (!isAqlFieldValue(left) && isAqlFieldValue(right)) {
          [left, right] = [right, left];
        }
      }
      if (left != null && !isAqlFieldValue(left)) {
        throw Error(`Unsupported AQL Criteria '${JSON.stringify(unnestedCriteria)}' (no field value).`);
      }

      // Figure out what the operator is
      let operator;
      if (unnestedCriteria.type === 'in') {
        operator = AqlOperator.IN;
      } else if (unnestedCriteria.type === 'nin') {
        operator = AqlOperator.NIN;
      } else if (unnestedCriteria.type === 'exists') {
        operator = AqlOperator.EXISTS;
      } else if (unnestedCriteria.type === 'nexists') {
        operator = AqlOperator.NEXISTS;
      } else if (unnestedCriteria.type === 'operator') {
        operator = unnestedCriteria.operator;
      } else if (unnestedCriteria.type === 'contains') {
        operator = `CONTAINS_${unnestedCriteria.quantifier}`;
      } else {
        throw Error(`Unknown AQL Operator '${JSON.stringify(unnestedCriteria)}'.`);
      }

      // Coerce the aql value into the right type for the operator and field
      const type = schemaMap && schemaMap[(left as AqlFieldValue)?.field]?.type;
      let rightValue;
      if (type == null) {
        // If the type doesn't exist then remove the right hand value
        rightValue = null;
      } else {
        // Otherwise convert it to make sure it's the correct type
        // This removes situations where the field is a string but the query contains a number or boolean
        if (operator === AqlOperator.EXISTS || operator === AqlOperator.NEXISTS) {
          rightValue = null; // No value for exists
        } else if (operator === AqlOperator.IN || operator === AqlOperator.NIN || operator === AqlOperator.CONTAINS_NONE || operator === AqlOperator.CONTAINS_ANY || operator === AqlOperator.CONTAINS_ALL) {
          rightValue = this.converterSvc.aqlValueToFormValueArray(type, right as AqlSimpleValue[]);
        } else {
          rightValue = this.converterSvc.aqlValueToFormValue(type, right as AqlSimpleValue);
        }
      }

      const rightValidators = this.getRightValidators(schemaMap[(left as AqlFieldValue)?.field]);

      const control = new UntypedFormGroup({
        type: new UntypedFormControl('operator'),
        left: new UntypedFormControl((left as AqlFieldValue | null)?.field, [Validators.required, fieldValidator(schemaMap)]),
        operator: new UntypedFormControl(operator, Validators.required),
        right: new UntypedFormControl(rightValue, rightValidators),
      });

      // Force an update of the disabled states and operator values
      this.updateOperatorDisabledStates(control);
      this.updateOperatorAndValue(schemaMap, control);

      // Then subscribe so they update on change
      control.statusChanges.pipe(takeUntil(this.destroy$)).subscribe(() => this.updateOperatorDisabledStates(control));
      const leftCtrl = control.get('left');
      const operatorCtrl = control.get('operator');

      if (leftCtrl != null && operatorCtrl != null) {
        merge(leftCtrl.valueChanges, operatorCtrl.valueChanges)
          .pipe(takeUntil(this.destroy$))
          .subscribe(() => this.updateOperatorAndValue(schemaMap, control));
      }

      return control;
    } else {
      throw Error(`Unknown AQL Criteria '${JSON.stringify(unnestedCriteria)}'.`);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  /**
   * Disables the operator and right hand side of an operator criteria if the left hand side isn't valid.
   */
  private updateOperatorDisabledStates(operatorCtrl: UntypedFormGroup) {
    if (operatorCtrl.disabled) return;

    const left = operatorCtrl.get('left');
    const operator = operatorCtrl.get('operator');
    const right = operatorCtrl.get('right');

    const status = {
      operator: true,
      right: true,
    };

    if (left && left.status === 'INVALID') {
      status.operator = false;
      status.right = false;
    } else if (operator?.status === 'INVALID' || operator?.value === AqlOperator.EXISTS || operator?.value === AqlOperator.NEXISTS) {
      status.right = false;
    }

    // Toggle the disabled states to match
    if (operator?.enabled !== status.operator) {
      status.operator ? operator?.enable() : operator?.disable();
    }

    if (right && right.enabled !== status.right) {
      status.right ? right.enable() : right.disable();
    }
  }

  /**
   * Makes sure that the operator and value for an operator criteria match the type of the selected field.
   */
  private updateOperatorAndValue(schemaMap: KeyVal<Schema>, operatorCtrl: UntypedFormGroup) {
    const field = operatorCtrl.get('left')?.value;
    const schema = schemaMap && schemaMap[field];
    const type = schema?.type;
    const operator = operatorCtrl.get('operator');
    const right = operatorCtrl.get('right');
    if (type == null) {
      // The user has entered a field which doesn't exist
      // This should mark the field as invalid and disable the operator and right
      return;
    }

    // Change the operator if it doesn't support the type
    const acceptableOperators = getAqlOperatorsForSchema(schema);
    if (!acceptableOperators.includes(operator?.value)) {
      operator?.setValue(acceptableOperators[0]);
    }

    // Make sure the right hand side has the correct type
    let rightValue;
    if (CriteriaToFormConversionService.ARRAY_OPERATORS.has(operator?.value)) {
      rightValue = this.converterSvc.formValueToFormValueArray(type, right?.value);
    } else {
      rightValue = this.converterSvc.formValueToFormValue(type, right?.value);
    }

    const rightValidators = this.getRightValidators(schemaMap[field]);
    right?.setValidators(rightValidators);

    // Only change the value if the new value is different
    if (rightValue !== right?.value) {
      right?.setValue(rightValue);
    }
    right?.updateValueAndValidity();
  }

  /**
   * Required is the default validator we add to the right value, we then check if the schema for the selected field is a validatorSchema
   * and check for validators on the schema to add so that our right value can be validated accordingly
   */
  private getRightValidators(schema: Schema) {
    const rightValidators = [Validators.required];
    if (schema && hasDateValidator(schema)) {
      rightValidators.push(this.validatorService.buildAngularFormValidatorFromValidator(schema, { type: ValidatorType.DATE }));
    }
    return rightValidators;
  }
}
