import { mapValues } from 'lodash';
import { Subscription } from '../types';
import { KeyVal } from '../../models/typescript-types';

export function addSubscriptionToEntities<T extends { id: string, relatedEntities: string[], subscriptions: Subscription[] }>(entityType: string, subscription: Subscription, entityMap: KeyVal<T>) {

  return mapValues(entityMap, (entity) => {
    if (isSubscriptionRelatedToEntity(entityType, subscription, entity)) {
      return { ...entity, subscriptions: [...entity.subscriptions, subscription] };
    } else {
      return entity;
    }
  });

}

export function isSubscriptionRelatedToEntity(entityType: string, subscription: Subscription, entity: { id: string, relatedEntities: string[] }) {
  return (subscription.entityType === entityType && subscription.entityId === entity.id) || (entity.relatedEntities.includes(`${subscription.entityType}/${subscription.entityId}`));
}

export function removeSubscriptionFromEntities<T extends { relatedEntities: string[], subscriptions: Subscription[] }>(subscriptionId: string, entityMap: KeyVal<T>) {
  return mapValues(entityMap, (entity) => ({ ...entity, subscriptions: entity.subscriptions.filter((it) => it.id !== subscriptionId) }));
}

export function hasDirectSubscription(entityId: string, entityType: string, subscriptions: Subscription[]): boolean {
  return subscriptions.some(subscription => subscription.entityId === entityId && subscription.entityType === entityType);
}

export function getDirectSubscription(entityId: string, entityType: string, subscriptions: Subscription[]): Subscription | undefined {
  return subscriptions.find(subscription => subscription.entityId === entityId && subscription.entityType === entityType);
}
