import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { APP_CONFIG, AppConfig } from '../../../app-config';
import { Observable } from 'rxjs';
import { ActivityId, RestResponse } from '../../models/rest-response';
import { extractActivityId } from '../../services/api-utils';

@Injectable({
  providedIn: 'root'
})
export class NotificationHttpService {

  constructor(@Inject(APP_CONFIG) private config: AppConfig, private http: HttpClient) { }

  unsubscribe(subscriptionId: string): Observable<ActivityId> {
    return this.http.delete<RestResponse<void>>(`${this.config.apiRoot}/notification/v1/subscription/${subscriptionId}`).pipe(extractActivityId());
  }
}
