import { AqlValue, aqlValueToString } from './aql-value';
import { AqlOperator, aqlOperatorToString } from './aql-operator';

export interface AqlOperatorCriteria {
  type: 'operator';
  left: AqlValue | null;
  right: AqlValue | null;
  operator: AqlOperator;
}

export function isAqlOperatorCriteria(x: any): x is AqlOperatorCriteria {
  return x && (x as AqlOperatorCriteria).type === 'operator';
}

export function aqlOperatorCriteriaToString(aqlOperatorCriteria: AqlOperatorCriteria): string {
  return `${aqlValueToString(aqlOperatorCriteria.left)} ${aqlOperatorToString(aqlOperatorCriteria.operator)} ${aqlValueToString(aqlOperatorCriteria.right)}`;
}
