/* tslint:disable:member-ordering */
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Renderer2,
  Self,
  ViewChild,
} from '@angular/core';
import { BehaviorSubject, combineLatest, fromEvent, Observable, of, ReplaySubject, Subject, takeWhile } from 'rxjs';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { debounceTime, map, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import {
  CdkConnectedOverlay,
  ConnectedOverlayPositionChange,
  ScrollStrategyOptions,
  ViewportRuler,
} from '@angular/cdk/overlay';
import * as uuid from 'uuid';
import { Option, Options } from '../../../entities/form-entities';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { fieldDropDownPositions, getFirstScrollParent } from '../field-helpers';
import { buildLocalSortAndFilterFunction$ } from '../../../../services/utils';

@Component({
  selector: 'app-filter-field',
  templateUrl: './filter-field.component.html',
  styleUrls: ['./filter-field.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterFieldComponent implements ControlValueAccessor, OnInit, AfterViewInit, OnDestroy {
  faChevronDown = faChevronDown;
  uid = uuid.v4();
  highlightedIndex = -1;
  isLoading$ = new BehaviorSubject(false);
  onDestroy$ = new Subject();
  isDropdownVisible = false;
  dropdownOriginRect: DOMRect | undefined;
  filteredOptions: Option[] = [];
  optionsProvider$ = new ReplaySubject<((search: string) => Observable<Option[]>)>(1);
  dropdownPositions = fieldDropDownPositions(1);
  dropdownAltPosition = false;
  mappedOptions: Option[] = [];

  @Input() inputContainerClasses: string | undefined;
  @Input() inputClasses: string | undefined;
  @Input() label = '';
  @Input() labelClasses: string | undefined;
  @Input() showErrorsAboveInput = true;
  @Input() placeholder = $localize`:@@default-input-placeholder:Please enter a value`;
  @Input() inputTitle: string | undefined;
  @Input() set options (options: string[] | undefined | Options){
    if (options == null) return;
    if (Array.isArray(options)) {
      const mappedOptions = options.map(option => {
        if (typeof option === 'string') {
          return { value: option, label: option ?? '' };
        }
        return option;
      });
      this.mappedOptions = mappedOptions;
      this.optionsProvider$.next(buildLocalSortAndFilterFunction$(mappedOptions));
    } else {
      this.optionsProvider$.next(options);
    }
  }

  @ViewChild('inputElement', { static: true }) inputElement: ElementRef<HTMLInputElement> | undefined;
  @ViewChild('fakeInputElement', { static: false }) fakeInputElement: ElementRef<HTMLButtonElement> | undefined;
  @ViewChild('inputContainer', { static: true }) inputContainer: ElementRef<HTMLDivElement> | undefined;
  @ViewChild('dropDown', { static: true }) dropDown: CdkConnectedOverlay | undefined;

  onChanged: (value: string) => void = () => {};
  onTouched: any = () => {};

  constructor(
    @Self() @Optional() public control: NgControl,
    private viewportRuler: ViewportRuler,
    private cdr: ChangeDetectorRef,
    private renderer: Renderer2,
    public scrollStrategyOptions: ScrollStrategyOptions,
    private elRef: ElementRef,
  ) {
    if (this.control) {
      this.control.valueAccessor = this;
    }
  }

  ngOnInit(): void {
    if (!this.inputElement) return;
    // Validation related maybe?
    this.control.statusChanges?.pipe(takeUntil(this.onDestroy$)).subscribe(() => this.cdr.markForCheck());

    // Handle keyboard events
    this.initKeydownEventListeners(this.inputElement);

    // Keep the filtered options up to date
    combineLatest([
      fromEvent(this.inputElement.nativeElement, 'input').pipe(
        map(evt => (evt.target as HTMLInputElement).value),
        debounceTime(250),
        tap(() => this.toggleDropdownList(true)),
        startWith(''),
      ),
      this.optionsProvider$,
    ]).pipe(
      tap((_: any) => this.isLoading$.next(true)),
      map((optionValues) => [optionValues[0], optionValues[1]] as [value: string, optionsProvider: (str: string) => Observable<Option[]>]),
      switchMap(([value, optionsProvider]) => optionsProvider(value)),
      tap(() => this.isLoading$.next(false)),
      takeUntil(this.onDestroy$),
    ).subscribe((filteredOptions) => {
      this.filteredOptions = filteredOptions;
      this.cdr.markForCheck();
    });

    // If the viewport is resized then recalculate the size of the dropdown
    this.viewportRuler
      .change(250)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        if (this.isDropdownVisible) {
          this.dropdownOriginRect = this.inputContainer?.nativeElement.getBoundingClientRect();
          this.cdr.markForCheck();
        }
      });
  }

  ngAfterViewInit() {
    if (this.fakeInputElement != null) {
      this.initKeydownEventListeners(this.fakeInputElement);
    }
  }

  select(option: Option | number) {
    if (typeof option === 'number') {
      option = this.filteredOptions[option];
    }
    if (this.inputElement) this.inputElement.nativeElement.value = option.label;
    this.onChanged(option.value);
  }

  selectAndCloseDropdown(event: MouseEvent, option: Option) {
    this.select(option);
    this.toggleDropdownList(false);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

  setHighlightedIndex(index: number): void {
    this.highlightedIndex = index;
    this.cdr.markForCheck();
  }

  private updateHighlightedIndex(adjustment: number) {
    this.highlightedIndex += adjustment;
    if (this.highlightedIndex >= this.filteredOptions.length) {
      this.highlightedIndex = 0;
    } else if (this.highlightedIndex < 0) {
      this.highlightedIndex = this.filteredOptions.length - 1;
    }
    this.cdr.markForCheck();
    this.scrollToHighlightedIndex();
  }

  private scrollToHighlightedIndex() {
    setTimeout(() => {
      const el = this.dropDown?.overlayRef?.overlayElement?.querySelector('.highlight');
      el && el.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
    });
  }

  onInputChanged(value: string) {
    const matchingValue = this.filteredOptions.find(option => option.label.toLowerCase() === value.toLowerCase())?.value;
    this.onChanged(matchingValue ?? value);
  }

  writeValue(option: Option|string): void {
    let val;
    if (option == null) {
      val = '';
    } else if (typeof option === 'string') {
      val = this.mappedOptions.find(opt => opt != null && opt.value === option)?.label ?? option;
    } else {
      val = option.label;
    }
    this.renderer.setProperty(this.inputElement?.nativeElement, 'value', val);
  }

  registerOnChange(fn: any): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (this.inputElement) this.inputElement.nativeElement.disabled = isDisabled;
  }

  toggleDropdownList(toggle: boolean) {
    this.dropdownOriginRect = this.inputContainer?.nativeElement.getBoundingClientRect();
    this.highlightedIndex = -1; // When the dropdown is toggled reset the highlighted index (cus it's a better experience)
    this.isDropdownVisible = toggle;

    if (this.isDropdownVisible) {
      const scrollParentElement = getFirstScrollParent(this.elRef.nativeElement);
      if (scrollParentElement == null) return;
      fromEvent(scrollParentElement, 'scroll')
        .pipe(takeWhile(() => this.isDropdownVisible))
        .subscribe(() => {
          this.isDropdownVisible = false;
          this.cdr.markForCheck();
        });
    }

    this.onTouched();
    this.cdr.markForCheck();
  }

  onFocusOut(event: FocusEvent){
    if(event.relatedTarget != null && this.inputContainer?.nativeElement.contains(event.relatedTarget as Node)) return;
    this.toggleDropdownList(false);
  }

  toggleDropdownBtnClicked(event: Event) {
    event.preventDefault();
    if (!this.isDropdownVisible) {
      this.toggleDropdownList(true);
      this.inputElement?.nativeElement.select();
    } else {
      this.toggleDropdownList(false);
    }
  }

  initKeydownEventListeners(ref: ElementRef) {
    fromEvent<KeyboardEvent>(ref.nativeElement, 'keydown').pipe(
      takeUntil(this.onDestroy$),
    ).subscribe((event: KeyboardEvent) => {
      // If the dropdown is visible and there are filtered options allow keyboard inputs
      if (this.isDropdownVisible && this.filteredOptions.length > 0) {
        switch (event.key) {
          case 'ArrowDown':
            this.updateHighlightedIndex(1);
            event.preventDefault();
            break;
          case 'ArrowUp':
            this.updateHighlightedIndex(-1);
            event.preventDefault();
            break;
          case 'Enter':
            if (this.highlightedIndex >= 0) {
              this.select(this.highlightedIndex);
              this.toggleDropdownList(false);
              event.preventDefault();
            } else {
              return;
            }
            break;
          case 'Escape':
            if (this.isDropdownVisible) {
              this.toggleDropdownList(false);
              event.preventDefault();
            } else {
              return;
            }
            break;
          default:
            return;
        }
      }
    });
  }

  positionChanged(positionChange: ConnectedOverlayPositionChange) {
    this.dropdownAltPosition = (positionChange.connectionPair.overlayY === 'bottom');
  }
}
