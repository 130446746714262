<div class="panel" data-test="panel" [class.has-components]="sidePanelService.portalConfigs$ | async">
  <div class="panel-body" data-test="panel-body" [class.hidden]="!(sidePanelService.isPanelOpen$ | async)">
    <div class="panel-drag-border" #dragBorder></div>
    <ng-container *ngIf="sidePanelService.portal$ | async; let portal">
      <ng-template [cdkPortalOutlet]="portal"></ng-template>
    </ng-container>
  </div>
  <div class="panel-buttons" data-test="panel-buttons">
    <ng-container *ngIf="sidePanelService.portalConfigs$ | async; let configEntries">
      <button
        *ngFor="let configEntry of configEntries | keyvalue"
        [attr.data-test]="'panel-button-' + configEntry.key"
        class="icon-btn panel-button"
        [class.active]="(sidePanelService.isPanelOpen$ | async) && (sidePanelService.currentPortalConfigKey$ | async) === configEntry.key"
        (click)="sidePanelService.togglePanelWith(configEntry.key)"
        [appTooltip]="
          (sidePanelService.isPanelOpen$ | async) && (sidePanelService.currentPortalConfigKey$ | async) === configEntry.key ? tooltipCloseText : configEntry.value.displayAs
        "
        [position]="{ direction: 'to-right' }"
      >
        <fa-icon
          class="icon panel-button-icon icon-clickable icon-m panel-icon"
          [icon]="(sidePanelService.isPanelOpen$ | async) && (sidePanelService.currentPortalConfigKey$ | async) === configEntry.key ? faXmark : configEntry.value.icon"
        ></fa-icon>
      </button>
    </ng-container>
  </div>
</div>
