import { Injectable } from '@angular/core';
import { IValidatorService } from './validator.service';
import { AttributeValue } from '../models/attribute';
import { AttributeKeyValidator } from '../models/validator/attribute-key-validator';
import { SanitizeService } from './sanitize.service';
import { ValidatorType } from '../models/validator';

@Injectable({
  providedIn: 'root'
})
export class AttributeKeyValidatorService implements IValidatorService {


  constructor(private sanitizeService: SanitizeService) { }

  validate(displayName: string, validator: AttributeKeyValidator, value: AttributeValue): { [p: string]: any } {
    if(!value) return {}; // Short circuit if the value is missing

    if(typeof value !== 'string'){
      return { [ValidatorType.ATTRIBUTE_KEY]: {value, message: 'Only STRING attributes may contain field values (schema is incorrectly configured - speak to an administrator).'}};
    }

    const n = Math.floor(Number(value));
    if (validator.allowInts && n !== Infinity && String(n) === value){
      return {};
    }

    if((validator.strict && !this.sanitizeService.isAttributeKey(value)) || this.sanitizeService.asAttributeKey(value) == null){
      let message;
      if(validator.allowInts){
        message = $localize`:@@attribute-key-validator__invalid--not-a-valid-attribute-key-or-whole-number:'${value}:value:' is not a valid attribute key or whole number.`;
      } else {
        message = $localize`:@@attribute-key-validator__invalid--not-a-valid-attribute-key:'${value}:value:' is not a valid attribute key.`;
      }
      return { [ValidatorType.ATTRIBUTE_KEY]: {value, message}};
    }

    return {};
  }
}
