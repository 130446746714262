import { Inject, Injectable, NgZone } from '@angular/core';
import { OverlayService } from './overlay.service';
import { ErrorModalComponent, ErrorModalConfig } from '../components/error-modal/error-modal.component';
import { SentryErrorHandler } from '@sentry/angular';
import { APP_CONFIG, AppConfig } from '../../app-config';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService extends SentryErrorHandler {

  constructor(private overlayService: OverlayService, private zone: NgZone, @Inject(APP_CONFIG) private config: AppConfig) {
    super();
  }

  override handleError(error: any): void {
    super.handleError(error);
    if(this.config.environment === "development") {
      console.error(error);
    }
    this.zone.run(() => this.overlayService.showOverlay(ErrorModalComponent, { error } as ErrorModalConfig));
  }
}
