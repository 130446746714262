import { ChangeDetectionStrategy, Component, HostBinding, Inject } from '@angular/core';
import { APP_CONFIG, AppConfig } from '../app-config';
import { ConfigService } from './services/config.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
  envClass: string;

  @HostBinding('class.hasBGImage') hasBgImage;

  constructor(@Inject(APP_CONFIG) config: AppConfig, private configService: ConfigService) {
    this.hasBgImage = this.configService.selectedClient === 'r36';
    this.envClass = config.environment;
  }

}
