import { Inject, Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Option } from '../../shared-module/entities/form-entities';
import { filter, map } from 'rxjs/operators';
import { AUTO_COMPLETE_INTERFACE_IT, AutoCompleteInterface } from '../../search-module/interfaces/auto-complete.interface';

@Pipe({
  name: 'getOptionsFunction'
})
export class GetOptionsFunctionPipe implements PipeTransform {

  constructor(@Inject(AUTO_COMPLETE_INTERFACE_IT) private autoCompleteService: AutoCompleteInterface) {
  }

  transform(field: string, isValid?: boolean): (searchTerm: string) => Observable<Option[]> {
    if (isValid === false) return () => of([]);
    return (searchTerm) => {
      if(field && field.length > 0){
        return this.autoCompleteService.autoComplete(field, searchTerm)
          .pipe(filter(it => it != null), map(results => (results as string[]).map(r => ({ label: r, value: r }))));
      } else {
        return of([]);
      }
    };
  }

}
