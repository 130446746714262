import { Pipe, PipeTransform } from '@angular/core';
import { AuthService } from '../services/auth.service';

@Pipe({
  name: 'hasPermission'
})
export class HasPermissionPipe implements PipeTransform {
  constructor(private authService: AuthService) {}

  transform(permission: string | undefined): boolean {
    if (permission === undefined) return false;
    return this.authService.hasPermission(permission);
  }

}
