import { Component, Inject } from '@angular/core';
import { IRestError } from '../../../models/rest-response';
import { OVERLAY_CONTROL, OVERLAY_DATA, OverlayControl } from '../../../services/overlay.service';

@Component({
  selector: 'app-notification-error-modal',
  templateUrl: './notification-error-modal.component.html',
  styleUrls: ['./notification-error-modal.component.sass']
})
export class NotificationErrorModalComponent {
  subtitle: string;
  errors: IRestError[] | Error | null;

  constructor(@Inject(OVERLAY_CONTROL) private overlayCtrl: OverlayControl, @Inject(OVERLAY_DATA) config: NotificationErrorModalConfig) {
    this.subtitle = config.subtitle;
    this.errors = config.errors;
  }

  onConfirm() {
    this.overlayCtrl.close();
  }
}

export interface NotificationErrorModalConfig {
  subtitle: string;
  errors: IRestError[] | null;
}

