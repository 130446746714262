import { Injectable, OnDestroy } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Schema } from '../../attribute-module/models/schema';
import { Subject } from 'rxjs';
import { KeyVal, Nullable } from '../../models/typescript-types';
import { isOneOfCheckboxesChecked, positiveNumberOrFieldValidator } from '../utils/query-builder-form-validators';
import { AqlQuery } from '../../aql-module/models/aql/aql-query';
import { CriteriaToFormConversionService } from '../../aql-module/services/criteria-to-form-conversion.service';
import { AqlTypeConverterService } from '../../aql-module/services/aql-type-converter.service';
import { AqlQuantity } from '../../aql-module/models/aql/aql-quantity';
import {
  AqlFunctionValue, isAqlBooleanValue,
  isAqlFieldValue,
  isAqlFunctionValue,
  isAqlGroupFunction,
  isAqlMathOperatorFunction,
  isAqlNumericValue,
  isAqlRoundingFunction,
  isAqlRoundingFunctionType,
} from '../../aql-module/models/aql/aql-value';
import { AqlPriority } from '../../aql-module/models/aql/aql-priority';
import { AqlCriteria } from '../../aql-module/models/aql/aql-criteria';

@Injectable( { providedIn: 'root' })
export class AqlToFormConversionService implements OnDestroy {
  private destroy$ = new Subject();

  constructor(private converterSvc: AqlTypeConverterService, private criteriaToForm: CriteriaToFormConversionService) {}

  buildFormControl(schema: KeyVal<Schema>, queries: AqlQuery[]): UntypedFormArray {
    return new UntypedFormArray(queries.map((query, index) => this.convertQueryToFormGroup(schema, query, index === 0)));
  }

  convertQueryToFormGroup(schema: KeyVal<Schema>, query: AqlQuery, show?: boolean): UntypedFormGroup {
    return new UntypedFormGroup({
      quantity: this.convertQuantityToFormGroup(schema, query.quantity),
      where: this.criteriaToForm.convertCriteriaToFromGroup(schema, query.where, true),
      priority: this.convertPriorityToFormGroup(schema, query.priority),
      showAdvancedQuantity: new UntypedFormControl(false),
      showAdvancedOffset: new UntypedFormControl(false),
      show: new UntypedFormControl(show),
      queryTemplateInProgress: new UntypedFormControl(false),
    });
  }

  convertQuantityToFormGroup(schema: KeyVal<Schema>, quantity: AqlQuantity | AqlFunctionValue | null): AbstractControl {
    let fnType = 'group';
    let fnParam1 = 1;
    let values: (AqlQuantity)[] = [];
    let operators: string[] = [];
    let quantityValue: AqlQuantity | null = null;

    // If we're dealing with a function and it's not a math operator then we need to un-nest it
    if (isAqlFunctionValue(quantity) && !isAqlMathOperatorFunction(quantity.function)) {
      fnType = quantity.function.type;
      if (isAqlRoundingFunction(quantity.function)) {
        fnParam1 = quantity.function.roundTo;
      }
      quantityValue = quantity.function.value;
    } else if (quantity != null) {
      quantityValue = quantity;
    }

    if (quantityValue == null) {
      // If the quantity is null we treat it like a number or field
      // @ts-ignore
      values = [quantityValue];
    } else if (isAqlNumericValue(quantityValue) || isAqlBooleanValue(quantityValue)|| isAqlFieldValue(quantityValue)) {
      // If we're dealing with a number or field quantity then we don't need to do anything clever
      values = [quantityValue];
    } else if (isAqlFunctionValue(quantityValue) && (isAqlMathOperatorFunction(quantityValue.function) || isAqlGroupFunction(quantityValue.function))) {
      // If this is a math or group function then we need to extract the operators
      [values, operators] = this.extractFunctionValuesForForm(quantityValue, [], []);
    } else {
      throw Error(`Unknown AQL Quantity '${JSON.stringify(quantityValue)}'.`);
    }

    const fnTypeCtrl = new UntypedFormControl(fnType);
    const fnParam1Ctrl = new UntypedFormControl(fnParam1, control => (isAqlRoundingFunctionType(fnTypeCtrl.value) && control.value == null ? { [`required`]: true } : null));
    fnTypeCtrl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => fnParam1Ctrl.updateValueAndValidity());
    return new UntypedFormGroup({
      fnType: fnTypeCtrl,
      fnParam1: fnParam1Ctrl,
      values: new UntypedFormArray(
        values.map(it => {
          if (it == null) {
            return new UntypedFormControl(null, [Validators.required, positiveNumberOrFieldValidator(schema)]);
          } else if (isAqlNumericValue(it) || isAqlBooleanValue(it)) {
            return new UntypedFormControl(it.value?.toString(), [Validators.required, positiveNumberOrFieldValidator(schema)]);
          }
          if (isAqlFieldValue(it)) {
            return new UntypedFormControl(it.field, [Validators.required, positiveNumberOrFieldValidator(schema)]);
          } else {
            return this.convertQuantityToFormGroup(schema, it);
          }
        })
      ),
      operators: new UntypedFormArray(operators.map(it => new UntypedFormControl(it))),
    });
  }

  convertCriteriaToFromGroup(schema: KeyVal<Schema>, criteria: AqlCriteria, isWhere: boolean = false): AbstractControl {
    return this.criteriaToForm.convertCriteriaToFromGroup(schema, criteria, isWhere);
  }

    convertPriorityToFormGroup(schema: KeyVal<Schema>, priority: Nullable<AqlPriority>): AbstractControl {
    if (priority == null) {
      return new UntypedFormControl(null);
    }

    return new UntypedFormGroup({
      totalPriorities: new UntypedFormControl(priority.totalPriorities),
      selectedPriorities: new UntypedFormArray(
        Array(priority.totalPriorities ?? 0)
          .fill(null)
          .map((nll, i) => new UntypedFormControl(priority.selectedPriorities.includes(i + 1))),
        [isOneOfCheckboxesChecked()]
      ),
      offset: this.convertPriorityOffsetToFormGroup(schema, priority.offset),
    });
  }

  convertPriorityOffsetToFormGroup(schema: KeyVal<Schema>, offset: Nullable<AqlQuantity>): AbstractControl {
    if (offset == null || (offset.type === 'numeric' && offset.value === 0)) {
      // If the offset is null or 0 then treat it as null
      return new UntypedFormControl(null);
    }
    return this.convertQuantityToFormGroup(schema, offset);
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  private extractFunctionValuesForForm(nextValue: AqlQuantity, previousValues: AqlQuantity[] = [], previousOperators: string[] = []): [AqlQuantity[], string[]] {
    if (isAqlFunctionValue(nextValue) && isAqlMathOperatorFunction(nextValue.function)) {
      const [leftValues, leftOperators] = this.extractFunctionValuesForForm(nextValue.function.left);
      const [rightValues, rightOperators] = this.extractFunctionValuesForForm(nextValue.function.right);
      return [
        [...previousValues, ...leftValues, ...rightValues],
        [...previousOperators, ...leftOperators, nextValue.function.type, ...rightOperators],
      ];
    }
    return [[...previousValues, nextValue], previousOperators];
  }
}
