import { Injectable } from '@angular/core';
import { IValidatorService } from './validator.service';
import { RegexValidator } from '../models/validator/regex-validator';
import { AttributeValue } from '../models/attribute';
import { ValidatorType } from '../models/validator';

@Injectable({
  providedIn: 'root',
})
export class RegexValidatorService implements IValidatorService {
  validate(displayName: string, validator: RegexValidator, value: AttributeValue): { [key: string]: any | null } {
    if(!value) return {}; // Short circuit if the value is missing

    if (typeof value === 'string') {
      let flags = '';
      if (!validator.caseSensitive) {
        flags = 'i';
      }

      const regex = new RegExp(validator.regex, flags);
      if (value.match(regex)) {
        return {};
      }
    }

    return { [ValidatorType.REGEX]: { value, message: validator.errorMessage }};
  }
}
