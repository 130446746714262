import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { IValidatorService } from './validator.service';
import { AttributeValue } from '../models/attribute';
import { DateValidator } from '../models/validator/date-validator';
import { ValidatorType } from '../models/validator';
import { AbstractControl, ValidatorFn } from '@angular/forms';
import { HumanDateParserService } from '../../services/human-date-parser.service';
import { DateFormatError, DateTimeParsingService } from '../../shared-module/services/date-time/date-time-parsing.service';

type ValidatorResponse = { [key: string]: any };

@Injectable({
  providedIn: 'root',
})
export class DateValidatorService implements IValidatorService {
  messages = {
    notAString: (value: any): ValidatorResponse => ({ [ValidatorType.DATE]: { value, message: 'Only STRING attributes may contain date values (schema is incorrectly configured - speak to an administrator).' }}),
    notADate: (value: string): ValidatorResponse => ({ [ValidatorType.DATE]: { value, message: $localize`:@@date-validator__invalid--not-a-valid-date:'${value}:value:' is not a valid date.` }}),
  };

  constructor(@Inject(LOCALE_ID) private currentLocale: any, private dateTimeParsingService: DateTimeParsingService, private humanDateParserService: HumanDateParserService) {}

  validate(displayName: string, validator: DateValidator, value: AttributeValue | null): ValidatorResponse {
    if (!value) return {}; // Short circuit if the value is missing
    if (typeof value !== 'string') return this.messages.notAString(value);

    try {
      this.dateTimeParsingService.parseDate(value);
      return {};
    } catch (e) {
      if (!(e instanceof DateFormatError)) throw e;
      // Swallow the exception - we'll return invalid below
    }

    return this.messages.notADate(value);
  }

  /**
   * @Deprecated - refactor to use two inputs for date ranges and validate with the validate method.
   */
  humanDateValidator(): ValidatorFn {
    return ({ value }: AbstractControl): ValidatorResponse => {
      if (!value) return {};
      if (typeof value !== 'string') return this.messages.notAString(value);

      if (this.humanDateParserService.parseHistoricRange(value) == null) return this.messages.notADate(value);
      return {};
    };
  }

  isValidDate(date: string | number | Date): boolean {
    return new Date(date).toString() !== 'Invalid Date';
  }

}
