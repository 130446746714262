import { Schema } from '../../attribute-module/models/schema';
import { AttributeType } from '../../attribute-module/models/attribute';
import { AbstractControl, ValidatorFn } from '@angular/forms';

export function positiveIntOrNull(srumber: string | number): number | null {
  const n = Math.floor(Number(srumber));
  if (n !== Infinity && String(n) === String(srumber) && n > 0) {
    return n;
  }
  return null;
}

export function fieldValidator(siteSchema: { [p: string]: Schema }, types: AttributeType[] | null = null): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const type = siteSchema && siteSchema[control.value]?.type;
    // if the value is empty, we don't need to add any field validation errors
    const value = control.value;
    if (value == null  || (typeof value === 'string' && value.trim() === '')) {
      return {};
    }
    if (type == null) {
      return {
        'query-builder__not-a-field': {
          value: control.value,
          message: $localize`:@@query-builder-modal__validation-error--not-a-field:'${control.value}:value:' is not a recognised field.`,
        },
      };
    }
    if (types != null && !types.includes(type)) {
      return {
        'query-builder__incorrect-field-type': {
          value: control.value,
          message: $localize`:@@query-builder-modal__validation-error--incorrect-field-type:'${control.value}:value:' is a '${type}:type:' field but should be one of '${types.join(
            ', '
          )}:expected:'.`,
        },
      };
    }
    return null;
  };
}
