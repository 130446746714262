import { aqlValueToString } from '../aql/aql-value';
import {
  AqlAndCriteria, AqlArrayContainsCriteria,
  AqlCriteria,
  aqlExistsCriteriaToString,
  AqlInCriteria,
  aqlNexistsCriteriaToString,
  AqlNinCriteria,
  AqlNotCriteria,
  AqlOrCriteria,
  isAqlAndCriteria,
  isAqlArrayContainsCriteria,
  isAqlExistsCriteria,
  isAqlGroupCriteria,
  isAqlInCriteria,
  isAqlNexistsCriteria,
  isAqlNinCriteria,
  isAqlNotCriteria,
  isAqlOrCriteria,
} from '../aql/aql-criteria';
import { aqlOperatorCriteriaToString, isAqlOperatorCriteria } from '../aql/aql-operator-criteria';

export function aqlCriteriaToRqlString(aqlCriteria: AqlCriteria): string {
  if (isAqlAndCriteria(aqlCriteria)) {
    return aqlAndCriteriaToRqlString(aqlCriteria);
  } else if (isAqlOrCriteria(aqlCriteria)) {
    return aqlOrCriteriaToRqlString(aqlCriteria);
  } else if (isAqlOperatorCriteria(aqlCriteria)) {
    return aqlOperatorCriteriaToString(aqlCriteria);
  } else if (isAqlInCriteria(aqlCriteria)) {
    return aqlInCriteriaToRqlString(aqlCriteria);
  } else if (isAqlNinCriteria(aqlCriteria)) {
    return aqlNinCriteriaToRqlString(aqlCriteria);
  } else if (isAqlArrayContainsCriteria(aqlCriteria)) {
    return aqlAinCriteriaToRqlString(aqlCriteria);
  } else if (isAqlNotCriteria(aqlCriteria)) {
    return aqlNotCriteriaToRqlString(aqlCriteria);
  } else if (isAqlExistsCriteria(aqlCriteria)){
    return aqlExistsCriteriaToString(aqlCriteria);
  } else if (isAqlNexistsCriteria(aqlCriteria)){
    return aqlNexistsCriteriaToString(aqlCriteria);
  } else {
    throw Error(`Unknown AQL Criteria '${JSON.stringify(aqlCriteria)}'.`);
  }
}

export function aqlInCriteriaToRqlString(aqlInCriteria: AqlInCriteria) {
  return `${aqlValueToString(aqlInCriteria.left)} IN[${aqlInCriteria.right.map(it => aqlValueToString(it)).join(', ')}]`;
}

export function aqlNinCriteriaToRqlString(aqlInCriteria: AqlNinCriteria) {
  return `${aqlValueToString(aqlInCriteria.left)} NIN[${aqlInCriteria.right.map(it => aqlValueToString(it)).join(', ')}]`;
}

export function aqlAinCriteriaToRqlString(arrayContainsCriteria: AqlArrayContainsCriteria) {
  return `${aqlValueToString(arrayContainsCriteria.left)} CONTAINS ${arrayContainsCriteria.quantifier} [${arrayContainsCriteria.right.map(it => aqlValueToString(it)).join(', ')}]`;
}

export function aqlAndCriteriaToRqlString(aqlAndCriteria: AqlAndCriteria) {
  return '(' + aqlAndCriteria.criteria.map(it => aqlCriteriaToRqlString(it)).join(' AND ') + ')';
}

export function aqlOrCriteriaToRqlString(aqlOrCriteria: AqlOrCriteria) {
  return '(' + aqlOrCriteria.criteria.map(it => aqlCriteriaToRqlString(it)).join(' OR ') + ')';
}

export function aqlNotCriteriaToRqlString(aqlNotCriteria: AqlNotCriteria) {
  if (isAqlGroupCriteria(aqlNotCriteria.criteria)) {
    return `NOT${aqlCriteriaToRqlString(aqlNotCriteria.criteria)}`; // No need for brackets as the AND/OR include them
  }
  return `NOT(${aqlCriteriaToRqlString(aqlNotCriteria.criteria)})`;
}
