import { Component, Input, ChangeDetectionStrategy, TemplateRef, Inject, HostBinding } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { OVERLAY_DATA } from '../../../services/overlay.service';
import { TooltipDirection } from '../../services/tooltip-helpers';

@Component({
  selector: 'app-tooltip',
  styleUrls: ['./tooltip.component.sass'],
  templateUrl: './tooltip.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('tooltip', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate(300, style({ opacity: 1 })),
      ]),
      transition(':leave', [
        animate(300, style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class TooltipComponent {
  @Input() values: string[] | undefined;
  @Input() value: string | undefined;
  @Input() template: TemplateRef<any> | undefined;

  constructor(@Inject(OVERLAY_DATA) public position: TooltipDirection) {}

  @HostBinding('class') get positioningClass() {
    return this.position;
  }
}

