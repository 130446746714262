import { createAction, props } from '@ngrx/store';
import { Config } from '../../models/config/config';

export enum ActionTypes {
  REQUEST_SENT = '[allocation/config] Request Sent',
  REQUEST_SUCCESSFUL = '[allocation/config] Request Successful',
  REQUEST_FAILED = '[allocation/config] Request Failed',

  LOAD_REQUEST = '[allocation/config] Load Request',
  LOAD_SUCCESSFUL = '[allocation/config] Load Successful',
  LOAD_FAILED = '[allocation/config] Load Failed',
}

export const RequestSentAction = createAction(ActionTypes.REQUEST_SENT);
export const RequestSuccessfulAction = createAction(ActionTypes.REQUEST_SUCCESSFUL);
export const RequestFailedAction = createAction(ActionTypes.REQUEST_FAILED, props<{ error: string }>());

export const LoadRequestAction = createAction(ActionTypes.LOAD_REQUEST);
export const LoadSuccessfulAction = createAction(ActionTypes.LOAD_SUCCESSFUL, props<{ config: Config }>());
export const LoadFailedAction = createAction(ActionTypes.LOAD_FAILED, props<{ error: string }>());
