import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG, AppConfig } from '../../../app-config';
import { HttpClient, HttpEvent, HttpEventType, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { EMPTY, firstValueFrom, Observable, of } from 'rxjs';
import { ActivityId, RestResponse } from '../../models/rest-response';
import { extractActivityId, extractRestValue } from '../../services/api-utils';
import { distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';
import { RuleFile } from '../models/rule-file';
import { FileVersion } from '../models/file-version';
import { FileProgress } from '../models/types';
import { getFileUploadingPercentage } from '../helpers/file-helpers';
import { Annotation, Comment } from '../../comment-module/models/comment';
import { Subscription } from '../../notifications-module/types';
import { OverlayService } from '../../services/overlay.service';
import { AllowPopupModalComponent } from '../../allocation-module/components/allow-popup-modal/allow-popup-modal.component';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class FileHttpService {
  private readonly window: Window | null = null;

  constructor(@Inject(APP_CONFIG) private config: AppConfig, private http: HttpClient, private overlayService: OverlayService, @Inject(DOCUMENT) private document: Document) {
    this.window = this.document.defaultView;
  }

  /** Return distinct message for sent, upload progress, & response events */
  private static getUploadProgress(event: HttpEvent<any>): Observable<Partial<FileProgress>> {
    switch (event.type) {
      case HttpEventType.Sent:
        // return 0 to start as this signals that uploading has begun
        return of({ loadedBytes: 0, completed: false, percentage: 0 });

      case HttpEventType.UploadProgress:
        // Compute and show the % done:
        return of({ loadedBytes: event.loaded, percentage: getFileUploadingPercentage({ loaded: event.loaded, total: event.total ?? null }) });

      case HttpEventType.Response:
        // we return null to say that uploading has finished
        return of({ completed: true, percentage: 100 });

      default:
        return EMPTY;
    }
  }

  getFiles(entityId: string): Observable<RuleFile[]> {
    return this.http.get<RestResponse<RuleFile[]>>(`${ this.config.apiRoot }/file/v1/entity/${ entityId }/file`).pipe(extractRestValue());
  }

  getFileVersions(fileId: string): Observable<FileVersion[]> {
    return this.http.get<RestResponse<FileVersion[]>>(`${ this.config.apiRoot }/file/v1/file/${ fileId }/version`).pipe(extractRestValue());
  }

  createFileVersion(fileId: string, file: File, version: { id?: string; filename: string; mimeType: string; fileExtension: string }): Observable<Partial<FileProgress>> {
    return this.http.post<RestResponse<{ id: string; signedUrl: string }>>(`${ this.config.apiRoot }/file/v1/file/${ fileId }/version`, version).pipe(
      extractRestValue(),
      filter(it => it != null),
      switchMap(({ signedUrl }) =>
        this.saveFileVersion(signedUrl).pipe(
          switchMap(response =>
            this.uploadFileVersion(response.headers, file).pipe(
              switchMap(event => FileHttpService.getUploadProgress(event)),
              distinctUntilChanged(),
            ),
          ),
        ),
      ),
    );
  }

  private saveFileVersion(signedUrl: string): Observable<HttpResponse<object>> {
    return this.http.post(signedUrl, undefined, { headers: { 'x-goog-resumable': 'start' }, observe: 'response' });
  }

  private uploadFileVersion(headers: HttpHeaders, file: File): Observable<any> {
    return this.http.put(headers.get('location') as string, file, { observe: 'events', reportProgress: true });
  }

  patchFile(fileId: string, patch: Partial<RuleFile>): Observable<ActivityId> {
    return this.http.patch<RestResponse<ActivityId>>(`${ this.config.apiRoot }/file/v1/file/${ fileId }`, patch).pipe(extractActivityId());
  }

  patchFileVersion(fileId: string, fileVersionId: string, patch: Partial<FileVersion>): Observable<ActivityId> {
    return this.http.patch<RestResponse<any>>(`${ this.config.apiRoot }/file/v1/file/${ fileId }/version/${ fileVersionId }`, patch).pipe(extractActivityId());
  }

  getFileUrl(fileId: string, fileVersionId?: string, format?: string, options?: { thumbnail?: string; client?: string }): Observable<string> {
    const opts: { params?: HttpParams; headers?: HttpHeaders } = {};
    if (options?.thumbnail != null) opts['params'] = new HttpParams().set('thumbnail', options.thumbnail);
    if (options?.client != null) opts['headers'] = new HttpHeaders({ 'r36-client': options.client });

    return this.http
      .get<RestResponse<any>>(`${ this.config.apiRoot }/file/v1/file/${ fileId }${ fileVersionId ? `/version/${ fileVersionId }` : '' }/url${ format ? `/${ format }` : '' }`, opts)
      .pipe(extractRestValue());
  }

  async downloadFile(fileId: string, versionId?: string, client?: string): Promise<boolean> {
    const url = await firstValueFrom(this.getFileUrl(fileId, versionId, undefined, {client}));

    if ((this.window as any)?.chrome) {
      this.window?.location.replace(url);
      return true;
    } else {
      if (this.window?.open(url)) {
        return true;
      } else {
        this.overlayService.showOverlay(AllowPopupModalComponent);
        return false;
      }
    }
  }

  deleteFile(fileId: string, onlyIfEmpty?: boolean): Observable<ActivityId> {
    return this.http.delete<RestResponse<any>>(`${ this.config.apiRoot }/file/v1/file/${ fileId }${ onlyIfEmpty ? '?only-if-empty=true' : '' }`).pipe(extractActivityId());
  }

  createComment(fileVersionId: string, comment: { text: string, annotations?: Annotation[] }): Observable<string> {
    return this.http.post<RestResponse<Pick<Comment, 'id'>>>(`${ this.config.apiRoot }/file/v1/version/${ fileVersionId }/comment`, comment).pipe(
      extractRestValue(),
      map(it => it.id),
    );
  }

  subscribe(fileId: string): Observable<Subscription> {
    return this.http.post<RestResponse<string>>(`${this.config.apiRoot}/file/v1/file/${fileId}/subscription`, {})
      .pipe(
        extractRestValue(),
        map(subscriptionId => ({ id: subscriptionId, entityId: fileId, entityType: 'svc-file/file', relatedEntities: [] })) // map to subscription
      );
  }
}
