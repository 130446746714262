import { AqlFieldValue, aqlFieldValueToString, AqlSimpleValue, AqlValue, aqlValueToString } from './aql-value';
import { AqlOperatorCriteria, aqlOperatorCriteriaToString, isAqlOperatorCriteria } from './aql-operator-criteria';

export type AqlCriteria = AqlOperatorCriteria | AqlAndCriteria | AqlOrCriteria | AqlInCriteria | AqlNinCriteria | AqlNotCriteria | AqlExistsCriteria | AqlNexistsCriteria | AqlArrayContainsCriteria;

export function aqlCriteriaToString(aqlCriteria: AqlCriteria): string {
  if (isAqlAndCriteria(aqlCriteria)) {
    return aqlAndCriteriaToString(aqlCriteria);
  } else if (isAqlOrCriteria(aqlCriteria)) {
    return aqlOrCriteriaToString(aqlCriteria);
  } else if (isAqlOperatorCriteria(aqlCriteria)) {
    return aqlOperatorCriteriaToString(aqlCriteria);
  } else if (isAqlInCriteria(aqlCriteria)) {
    return aqlInCriteriaToString(aqlCriteria);
  } else if (isAqlNinCriteria(aqlCriteria)) {
    return aqlNinCriteriaToString(aqlCriteria);
  } else if (isAqlNotCriteria(aqlCriteria)) {
    return aqlNotCriteriaToString(aqlCriteria);
  } else if (isAqlExistsCriteria(aqlCriteria)){
    return aqlExistsCriteriaToString(aqlCriteria);
  } else if (isAqlNexistsCriteria(aqlCriteria)) {
    return aqlNexistsCriteriaToString(aqlCriteria);
  } else if (isAqlArrayContainsCriteria(aqlCriteria)) {
    return aqlAinCriteriaToString(aqlCriteria);
  } else {
    throw Error(`Unknown AQL Criteria '${JSON.stringify(aqlCriteria)}'.`);
  }
}

export interface AqlAndCriteria {
  type: 'and';
  criteria: AqlCriteria[];
}

export function isAqlAndCriteria(x: any): x is AqlAndCriteria {
  return x && (x as AqlCriteria).type === 'and';
}

export function aqlAndCriteriaToString(aqlAndCriteria: AqlAndCriteria) {
  return '(' + aqlAndCriteria.criteria.map(it => aqlCriteriaToString(it)).join(' AND ') + ')';
}

export interface AqlOrCriteria {
  type: 'or';
  criteria: AqlCriteria[];
}

export function isAqlOrCriteria(x: any): x is AqlOrCriteria {
  return x && (x as AqlCriteria).type === 'or';
}

export function aqlOrCriteriaToString(aqlOrCriteria: AqlOrCriteria) {
  return '(' + aqlOrCriteria.criteria.map(it => aqlCriteriaToString(it)).join(' OR ') + ')';
}

export interface AqlInCriteria {
  type: 'in';
  left: AqlValue;
  right: AqlSimpleValue[];
}

export function isAqlInCriteria(x: any): x is AqlInCriteria {
  return x && (x as AqlCriteria).type === 'in';
}

export function aqlInCriteriaToString(aqlInCriteria: AqlInCriteria) {
  return `${aqlValueToString(aqlInCriteria.left)} IN(${aqlInCriteria.right.map(it => aqlValueToString(it)).join(', ')})`;
}

export interface AqlNinCriteria {
  type: 'nin';
  left: AqlValue;
  right: AqlSimpleValue[];
}

export function isAqlNinCriteria(x: any): x is AqlNinCriteria {
  return x && (x as AqlCriteria).type === 'nin';
}

export function aqlNinCriteriaToString(aqlInCriteria: AqlNinCriteria) {
  return `${aqlValueToString(aqlInCriteria.left)} NIN(${aqlInCriteria.right.map(it => aqlValueToString(it)).join(', ')})`;
}

export interface AqlArrayContainsCriteria {
  type: 'contains';
  left: AqlValue;
  quantifier: 'ALL' | 'ANY' | 'NONE';
  right: AqlSimpleValue[];
}

export function isAqlArrayContainsCriteria(x: any): x is AqlArrayContainsCriteria {
  return x && (x as AqlCriteria).type === 'contains';
}

export function aqlAinCriteriaToString(arrayContainsCriteria: AqlArrayContainsCriteria) {
  return `${aqlValueToString(arrayContainsCriteria.left)} CONTAINS ${arrayContainsCriteria.quantifier} (${arrayContainsCriteria.right.map(it => aqlValueToString(it)).join(', ')})`;
}

export interface AqlNotCriteria {
  type: 'not';
  criteria: AqlCriteria;
}

export function isAqlNotCriteria(x: any): x is AqlNotCriteria {
  return x && (x as AqlNotCriteria).type === 'not';
}

export function aqlNotCriteriaToString(aqlNotCriteria: AqlNotCriteria) {
  if (isAqlGroupCriteria(aqlNotCriteria.criteria)) {
    return `NOT${aqlCriteriaToString(aqlNotCriteria.criteria)}`; // No need for brackets as the AND/OR include them
  }
  return `NOT(${aqlCriteriaToString(aqlNotCriteria.criteria)})`;
}

export type AqlGroupCriteria = AqlAndCriteria | AqlOrCriteria;

export function isAqlGroupCriteria(x: any): x is AqlGroupCriteria {
  return isAqlOrCriteria(x) || isAqlAndCriteria(x);
}

export interface AqlExistsCriteria {
  type: 'exists';
  field: AqlFieldValue;
}

export function isAqlExistsCriteria(x: any): x is AqlExistsCriteria {
  return x && (x as AqlCriteria).type === 'exists';
}

export function aqlExistsCriteriaToString(aqlExistsCriteria: AqlExistsCriteria) {
  return `${aqlFieldValueToString(aqlExistsCriteria.field)} EXISTS`;
}

export interface AqlNexistsCriteria {
  type: 'nexists';
  field: AqlFieldValue;
}

export function isAqlNexistsCriteria(x: any): x is AqlNexistsCriteria {
  return x && (x as AqlCriteria).type === 'nexists';
}

export function aqlNexistsCriteriaToString(aqlNexistsCriteria: AqlNexistsCriteria) {
  return `${aqlFieldValueToString(aqlNexistsCriteria.field)} NEXISTS`;
}
