import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { IValidatorService } from './validator.service';
import { ValidatorType } from '../models/validator';
import { AttributeValue } from '../models/attribute';
import { LocalDateTimeValidator } from '../models/validator/local-date-time-validator';
import { DateFormatError, DateTimeParsingService } from '../../shared-module/services/date-time/date-time-parsing.service';

type ValidatorResponse = { [key: string]: any | null };

@Injectable({
  providedIn: 'root',
})
export class LocalDateTimeValidatorService implements IValidatorService {
  messages = {
    notAString: (value: any): ValidatorResponse => ({ [ValidatorType.LOCAL_DATE_TIME]: { value, message: $localize`:@@datetime-validator__invalid--not-a-string:Only STRING attributes may contain date time values (schema is incorrectly configured - speak to an administrator).` }}),
    notADatetime: (message: string): ValidatorResponse => ({ [ValidatorType.LOCAL_DATE_TIME]: { message }}),
  };

  constructor(@Inject(LOCALE_ID) private currentLocale: any, private dateTimeParsingService: DateTimeParsingService) {}

  validate(displayName: string, validator: LocalDateTimeValidator, value: AttributeValue | null): ValidatorResponse {
    if (!value) return {}; // Short circuit if the value is missing
    if (typeof value !== 'string') return this.messages.notAString(value);

    try {
      this.dateTimeParsingService.parseLocalDateTime(value);
      return {};
    } catch (e) {
      if (!(e instanceof DateFormatError)) throw e;
        return this.messages.notADatetime(e.message);
    }
  }
}
