<div class="list-container">
  <ul *ngIf="tasks.length > 0; else noTasks" class="menu-item-list" data-test="menu-list-item">
    <li *ngFor="let task of tasks" class="menu-item" data-test="menu-item">
      <app-task [task]="task" [class.unread]="!task.acknowledged"></app-task>
    </li>
  </ul>

  <ng-template #noTasks>
    <div class="no-tasks" data-test="no-tasks" i18n="@@current-tasks__no-tasks">There are no recent tasks.</div>
  </ng-template>
</div>
