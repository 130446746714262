<app-field
  fieldClasses="droplist-field"
  [labelOptions]="{ text: label!, classes: labelClasses, for: uid }"
  [showErrorsAboveInput]="showErrorsAboveInput"
  [control]="control"
  [class.dropdown-top]="dropdownAltPosition"
>
  <div class="droplist-input-container" [class.is-dropdown]="!control.disabled" [class.open]="isDropdownVisible" [class.error]="control.invalid && (control.dirty || control.touched)" #droplistContainer>
    <input
      [id]="uid"
      class="input"
      data-test="date-input"
      type="text"
      [attr.title]="inputTitle"
      [placeholder]="placeholder"
      (blur)="onBlur($event)"
      #inputElement
    />

    <button type="button" class="dropdown-toggle-btn" data-test="dropdown-arrow-btn" (click)="toggleDatepicker()" #toggleButtonElement>
      <fa-icon class="icon icon-m icon-primary icon-clickable" [icon]="dropDownIcon" [rotate]="(isDropdownVisible ? 180 : null)!"></fa-icon>
    </button>

    <ng-template
      #datePickerWrapper="cdkConnectedOverlay"
      [cdkConnectedOverlayOrigin]="droplistContainer"
      [cdkConnectedOverlayOpen]="isDropdownVisible"
      [cdkConnectedOverlayFlexibleDimensions]="true"
      [cdkConnectedOverlayPositions]="dropdownPositions"
      [cdkConnectedOverlayWidth]="droplistContainer?.offsetWidth!"
      (overlayKeydown)="onOverlayKeydown($event)"
      [cdkConnectedOverlayScrollStrategy]="scrollStrategyOptions.close()"
      (positionChange)="positionChanged($event)"
      cdkConnectedOverlay
    >
      <div class="date-picker-wrapper" [class.top]="dropdownAltPosition">
        <app-date-picker [formControl]="dateForm" (tabbed)="onTabbedInDatepicker()"></app-date-picker>
      </div>
    </ng-template>
  </div>
</app-field>
