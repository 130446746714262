import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { IValidatorService } from './validator.service';
import { ValidatorType } from '../models/validator';
import { AttributeValue } from '../models/attribute';
import { DateTimeValidator } from '../models/validator/date-time-validator';
import { DateFormatError, DateTimeParsingService } from '../../shared-module/services/date-time/date-time-parsing.service';

type ValidatorResponse = { [key: string]: any };

@Injectable({
  providedIn: 'root',
})
export class DateTimeValidatorService implements IValidatorService {
  readonly messages = {
    notAString: (value: any): ValidatorResponse => ({ [ValidatorType.DATE_TIME]: { value, message: $localize`:@@datetime-validator__invalid--not-a-string:Only STRING attributes may contain date time values (schema is incorrectly configured - speak to an administrator).` }}),
    notADatetime: (value: string): ValidatorResponse => ({ [ValidatorType.DATE_TIME]: { value, message: $localize`:@@datetime-validator__invalid--not-a-valid-datetime:'${value}:value:' is not a valid date time.` }}),
  };

  constructor(@Inject(LOCALE_ID) private currentLocale: any, private dateTimeParsingService: DateTimeParsingService) {}

  validate(displayName: string, validator: DateTimeValidator, value: AttributeValue): ValidatorResponse {
    if (!value) return {}; // Short circuit if the value is missing
    if (typeof value !== 'string') return this.messages.notAString(value);

    try {
      this.dateTimeParsingService.parseDateTime(value);
      return {};
    } catch (e) {
      if (!(e instanceof DateFormatError)) throw e;
      // Swallow the exception - we'll return invalid below
    }
    return this.messages.notADatetime(value);
  }
}
