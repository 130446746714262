import { AqlQuantity, aqlQuantityToString } from './aql-quantity';
import { AqlCriteria, aqlCriteriaToString, isAqlGroupCriteria } from './aql-criteria';
import { AqlPriority, aqlPriorityToString } from './aql-priority';

export interface AqlQuery {
  quantity: AqlQuantity;
  where: AqlCriteria | null;
  whereFixture: AqlCriteria | null;
  priority: AqlPriority | null;
  isAnyFixtureExists: boolean;
}

export function aqlQueryToString(aqlQuery: AqlQuery): string {
  let str = aqlQuantityToString(aqlQuery.quantity);
  if (aqlQuery.whereFixture != null && (!isAqlGroupCriteria(aqlQuery.whereFixture) || aqlQuery.whereFixture.criteria.length > 0)) {
    str += ` WHERE${aqlQuery.isAnyFixtureExists ? " ANY " : " "}FIXTURE ${aqlCriteriaToString(aqlQuery.whereFixture)}`;
  }
  if (aqlQuery.where != null && (!isAqlGroupCriteria(aqlQuery.where) || aqlQuery.where.criteria.length > 0)) {
    str += ` WHERE ${aqlCriteriaToString(aqlQuery.where)}`;
  }
  if (aqlQuery.priority != null) {
    str += ` ${aqlPriorityToString(aqlQuery.priority)}`;
  }

  return str;
}

export function aqlQueriesToString(aqlQueries: AqlQuery[] | string[] | null): string {
  return aqlQueries && aqlQueries.length !== 0 ? aqlQueries.map(query => typeof query === 'string' ? query : aqlQueryToString(query)).join(';\n') + ';' : '';
}
