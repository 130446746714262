import { Injectable, OnDestroy } from '@angular/core';
import { WebSocketService } from '../../websocket-module/services/web-socket.service';
import {  Observable, Subject } from 'rxjs';
import { map, share, takeUntil } from 'rxjs/operators';
import { WsResponse } from '../../websocket-module/models/ws-response';
import { TasksStore } from '../tasks.store';
import { Task, TaskId } from '../models/task';
import { TasksHttpService } from './tasks-http.service';
import { ResultStringService } from '../../shared-module/services/result-string.service';
import { OverlayService } from '../../services/overlay.service';
import { TaskErrorModalComponent } from '../components/task-error-modal/task-error-modal.component';
import { ActivityId } from '../../models/rest-response';
import { TaskStatus } from '../models/task-status';

export interface WsTasksResponse extends WsResponse {
  tasks: Task[];
}

@Injectable({
  providedIn: 'root',
})
export class TasksService implements OnDestroy {
  private resSubChanges$: Observable<WsTasksResponse>;
  private destroy$ = new Subject();
  readonly tasks$ = this.tasksStore.tasks$.pipe(map(tasks => tasks.sort((a, b) => b.createdAt.localeCompare(a.createdAt))));
  private readonly tasksStreamSub$ = new Subject<Task>();
  readonly tasksStream$ = this.tasksStreamSub$.asObservable();
  readonly unreadTasksCount$ = this.tasksStore.unreadTasksCount$;
  readonly tasksRunning$ = this.tasksStore.tasksRunning$;
  private isFirstWSResponse = true;

  constructor(
    private webSocketService: WebSocketService,
    private tasksStore: TasksStore,
    private tasksHttpService: TasksHttpService,
    private overlayService: OverlayService,
    private resultStringService: ResultStringService,
  ) {
    this.resSubChanges$ = this.webSocketService.receive<WsTasksResponse>('SUB_TASKS').pipe(takeUntil(this.destroy$), share());
    this.subscribeToTasksWs();
  }

  subscribeToTasksWs() {
    this.resSubChanges$.subscribe(response => {
      const deletedTasks = response.tasks.filter(it => it.status === TaskStatus.DELETED).map(task => task.taskId);
      const tasks = response.tasks.filter(it => it.status !== TaskStatus.DELETED);
      this.tasksStore.deleteTasks(deletedTasks);
      this.tasksStore.updateTasks(tasks);
      if (!this.isFirstWSResponse) tasks.forEach(task => this.tasksStreamSub$.next(task));
      this.isFirstWSResponse = false;
    });
    this.webSocketService.send({ cmd: 'SUB_TASKS' });
  }

  async triggerTaskActionAndAcknowledge(task: Task) {
    if (await this.triggerTaskAction(task)) {
      this.acknowledgeTasks([task.taskId]);
      return true;
    }
    return false;
  }

  async triggerTaskAction(task: Task): Promise<boolean> {
    if (task.status === TaskStatus.ERROR) {
      this.overlayService.showOverlay(TaskErrorModalComponent, { task });
      return true
    } else if (task.result) {
      return this.resultStringService.handle(task.result, task.client);
    }
    return true
  }

  acknowledgeTasks(ids: TaskId[]) {
    this.tasksStore.acknowledgeTasks(ids);
    this.tasksHttpService.acknowledgeTasks(ids).subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  deleteTask(taskId: TaskId): Observable<ActivityId> {
    return this.tasksHttpService.deleteTask(taskId);
  }
}
