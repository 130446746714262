import { Pipe, PipeTransform } from '@angular/core';
import { AqlOperator } from '../models/aql/aql-operator';

@Pipe({
  name: 'isBlankTypeOperator',
})
export class IsBlankTypeOperatorPipe implements PipeTransform {
  transform(operator: AqlOperator): boolean {
    return operator === AqlOperator.EXISTS || operator === AqlOperator.NEXISTS;
  }
}
