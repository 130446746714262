import { Component } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { NavbarService } from '../../services/navbar.service';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';

interface Breadcrumb {
  label: Observable<string> | null;
  url: string;
}

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.sass'],
})
export class BreadcrumbComponent {
  breadcrumbsSub$ = new BehaviorSubject<Breadcrumb[]>(this.buildBreadcrumb());
  breadcrumbs$ = this.breadcrumbsSub$.asObservable();
  chevronRight = faChevronRight;

  constructor(private router: Router, private navbarService: NavbarService) {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        distinctUntilChanged(),
        map(() => this.buildBreadcrumb())
      )
      .subscribe(breadcrumbs => this.breadcrumbsSub$.next(breadcrumbs));
  }

  private buildBreadcrumb(): Breadcrumb[] {
    let route = this.router.routerState.root.snapshot;
    let url = '';
    let label: Observable<string> | null;
    const breadcrumbs: Breadcrumb[] = [];

    while (route.firstChild != null) {
      route = route.firstChild;

      url += route.routeConfig?.path ? `/${this.createUrl(route)}` : '';
      label = null;
      const breadcrumbData = route.data['breadcrumb'];
      if (breadcrumbData != null) {
        if (typeof breadcrumbData === 'string') {
          label = this.navbarService.placeholders$.pipe(
            // TODO: Should be replaceAll but easier to upgrade Angular
            map(placeholders => breadcrumbData.replace(/{{(.*?)}}/g, (_, key) => placeholders[key] ?? ''))
          );
        }
      }

      if (!route.routeConfig?.path) {
        continue;
      }
      if (!label) {
        continue;
      }
      breadcrumbs.push({ label, url });
    }
    return breadcrumbs;
  }

  private createUrl(route: ActivatedRouteSnapshot) {
    return route.url.map(s => s.toString()).join('/');
  }
}
