import { Inject, Injectable, OnDestroy } from '@angular/core';
import { APP_CONFIG, AppConfig, asWebSocketUrl } from '../../../app-config';
import { filter, take } from 'rxjs/operators';
import { ConnectionErrorModalComponent } from '../components/connection-error-modal/connection-error-modal.component';
import { Observable, Subject } from 'rxjs';
import { WsResponse } from '../models/ws-response';
import { OverlayService } from '../../services/overlay.service';
import { Auth0Service } from '../../auth0/auth0.service';
import { WsCommand } from '../models/ws-command';
import { ConfigService } from '../../services/config.service';
import * as Sentry from "@sentry/angular";

@Injectable({
  providedIn: 'root',
})
export class WebSocketFactory {
  constructor(@Inject(APP_CONFIG) private appConfig: AppConfig) {}

  build(): WebSocket {
    return new WebSocket(asWebSocketUrl(this.appConfig.wsRoot) + '/ws/v1');
  }
}

@Injectable({
  providedIn: 'root'
})
export class WebSocketService implements OnDestroy {

  private destroy$ = new Subject();
  private socket: WebSocket;
  private responses$ = new Subject<WsResponse>();
  private queuedCommands: WsCommand[] = [];
  private authenticated = false;

  constructor(
    websocketFactory: WebSocketFactory,
    private overlayService: OverlayService,
    private config: ConfigService,
    private authService: Auth0Service
  ) {
    this.socket = websocketFactory.build();
    this.socket.onmessage = (msgEvt) => this.responses$.next(JSON.parse(msgEvt.data));
    // We don't listen to the onerror event as the onclose should always be called immediately afterwards
    this.socket.onclose = (ev) => {
      if (!ev.wasClean || (ev.code !== 1000 && ev.code !== 1001)) {
        this.overlayService.showOverlay(ConnectionErrorModalComponent);
        const websocketEvent = { type: ev.type, code: ev.code, reason: ev.reason, wasClean: ev.wasClean, isTrusted: ev.isTrusted, documentVisibilityState: document.visibilityState };
        Sentry.captureException(new Error('WebSocket closed unexpectedly.'), { contexts: { websocketEvent } })
      }
    };
    this.socket.onopen = () => this.authenticateSocket();
  }

  private authenticateSocket() {
    this.authService.getTokenSilently$()
      .pipe(take(1))
      .subscribe((token) => {
        this.socket.send(JSON.stringify({
          cmd: 'AUTHENTICATE',
          token,
          client: this.config.selectedClient,
        }));
        this.authenticated = true;
        this.queuedCommands.forEach(cmd => this.socket.send(JSON.stringify(cmd)));
        this.queuedCommands = [];
      });
  }

  send(command: WsCommand & any) {
    if (this.authenticated) {
      this.socket.send(JSON.stringify(command));
    } else {
      this.queuedCommands = [...this.queuedCommands, command];
    }
  }

  receive<T extends WsResponse>(response: string): Observable<T> {
    return this.responses$.pipe(filter(it => it.res === response)) as Observable<T>;
  }

  ngOnDestroy() {
    this.responses$.complete();
    this.destroy$.next(null);
    this.destroy$.complete();
  }

}
