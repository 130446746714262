import { aqlValueToString, isAqlNumericValue } from './aql-value';
import { AqlQuantity } from './aql-quantity';

export interface AqlPriority {
    selectedPriorities: number[];
    totalPriorities: number;
    offset?: AqlQuantity | null;
}
export function aqlPriorityToString(aqlPriority: AqlPriority): string {
  let str = `BY PRIORITY ([${aqlPriority.selectedPriorities.join(', ')}], ${aqlPriority.totalPriorities}`;
  if (aqlPriority.offset != null && !(isAqlNumericValue(aqlPriority.offset) && aqlPriority.offset.value === 0)) {
    str += `, ${aqlValueToString(aqlPriority.offset)})`;
  } else {
    str += ')';
  }
  return str;
}
