import { faBolt, faChartLine } from '@fortawesome/pro-regular-svg-icons';
import { Injectable } from '@angular/core';
import { IconObj } from '../../shared-module/entities/icon-obj';
import { icon } from '@fortawesome/fontawesome-svg-core';
import { Task } from '../models/task';
import { TaskStatus } from '../models/task-status';
import { ResultStringService } from '../../shared-module/services/result-string.service';

export interface ReportTaskContext {
  displayName: string;
  resultName?: string;
}
export interface WorkflowTaskContext {
  displayName: string;
}

@Injectable({
  providedIn: 'root',
})
export class TaskHelperService {
  constructor(private resultStringService: ResultStringService) {}

  getIconByTaskType(type: string): IconObj {
    switch (type) {
      case 'svc-report/execute-report':
        return { icon: icon(faChartLine), classes: 'icon-primary' };
      case 'svc-workflow/execute-workflow':
        return { icon: icon(faBolt), classes: 'icon-primary' };
      default:
        throw Error(`Couldn't get an icon from type provided: ${type}`);
    }
  }

  getDisplayAs(task: Task): string {
    switch (task.type) {
      case 'svc-report/execute-report':
        const rc: ReportTaskContext = task.context
        return rc.resultName ?? rc.displayName;
      case 'svc-workflow/execute-workflow':
        const wc: WorkflowTaskContext = task.context
        return wc.displayName;
      default:
        throw Error(`Couldn't get the 'display as' text from type provided: ${task.type}`);
    }
  }

  getResultActionTextFromTask({ status, result }: Task): string | null {
    return status === TaskStatus.ERROR ? this.resultStringService.RESULT_ACTION_ERROR_TEXT : this.resultStringService.getResultActionText(result);
  }

  getResultActionIconFromTask({ status, result }: Task): IconObj | null {
    return status === TaskStatus.ERROR ? this.resultStringService.getResultActionErrorIcon() : this.resultStringService.getResultActionIcon(result);
  }
}

