import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostBinding, Inject, Input, OnDestroy, OnInit, Optional, Self, ViewChild } from '@angular/core';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { fromEvent, Subject, takeWhile } from 'rxjs';
import { Option } from '../../entities/form-entities';
import { CdkConnectedOverlay, ConnectedOverlayPositionChange, ScrollStrategyOptions, ViewportRuler } from '@angular/cdk/overlay';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { filter, takeUntil } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { fieldDropDownPositions, getFirstScrollParent } from '../form-fields/field-helpers';

@Component({
  selector: 'app-select [options]',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectComponent implements ControlValueAccessor, OnInit, OnDestroy {
  private destroy$ = new Subject();
  options: Option[] | undefined;
  defaultOption: Option = { value: null, label: $localize`:@@select__default-option__label:Please select an option` };
  selectedOption: string = this.defaultOption.label;
  isDropdownVisible = false;
  dropdownOriginRect: DOMRect | undefined;
  dropdownPositions = fieldDropDownPositions(1);
  dropdownAltPosition = false;
  highlightedIndex = -1;
  faChevronDown = faChevronDown;
  isInline = false;
  protected formDisabled = false;

  @Input() outputDataType: 'option'|'string' = 'string';
  @Input() label: string | null = null;
  @Input() forceDisabled = false
  @Input('options') set _options(options: string[] | Option[]) {
    this.options = (options.length > 0 && typeof options[0] === 'string') ? (this.options = options.map(option => ({ value: option as string, label: option as string }))) : (options as Option[]);
  }
  @Input('defaultOption') set _defaultOption(option: string | Option | null) {
    if (option != null) {
      this.defaultOption = typeof option === 'string' ? { value: null, label: option } : option;
      this.selectedOption = typeof option === 'string' ? option : option.label;
    } else {
      this.defaultOption = { value: null, label: '' };
    }
  }
  @Input() isPill = false;
  @Input('isInline') set _isInline(bool: boolean) {
    this.isInline = bool;
    this.dropdownPositions = fieldDropDownPositions(-3);
  }

  @HostBinding('class.pill-select') get isPillSelect() {
    return this.isPill;
  }
  @HostBinding('class.inline-select') get isInlineSelect() {
    return this.isInline;
  }

  @ViewChild('buttonElement', { static: true }) buttonElement: ElementRef<HTMLButtonElement> | undefined;
  @ViewChild('dropDown', { static: true, read: CdkConnectedOverlay }) dropDown: CdkConnectedOverlay | undefined;

  constructor(
    @Self() @Optional() public control: NgControl,
    private viewportRuler: ViewportRuler,
    private cdr: ChangeDetectorRef,
    private elementRef: ElementRef,
    @Inject(DOCUMENT) private document: Document,
    public scrollStrategyOptions: ScrollStrategyOptions,
  ) {
    if (control) {
      this.control.valueAccessor = this;
    }
  }

  onChanged: (value: string) => void = () => {};
  onTouched: any = () => {};

  ngOnInit(): void {
    fromEvent(this.document, 'click')
      .pipe(
        filter(e => (e.target as HTMLElement).closest('app-select') == null),
        takeUntil(this.destroy$)
      )
      .subscribe(() => this.toggleDropdownList(false));

    // Handle keyboard events
    fromEvent<KeyboardEvent>(this.elementRef.nativeElement, 'keydown')
      .pipe(takeUntil(this.destroy$))
      .subscribe((event: KeyboardEvent) => {
        switch (event.key) {
          case 'ArrowDown':
            if (this.isDropdownVisible) {
              this.updateHighlightedIndex(1);
            } else {
              this.updateHighlightedIndex(this.highlightedIndex === -1 ? 2 : 1);
              this.options && this.select(this.options[this.highlightedIndex]);
              this.buttonElement?.nativeElement.focus();
            }
            event.preventDefault();
            break;
          case 'ArrowUp':
            if (this.isDropdownVisible) {
              this.updateHighlightedIndex(-1);
            } else {
              this.updateHighlightedIndex(this.highlightedIndex === -1 ? 1 : -1);
              this.options && this.select(this.options[this.highlightedIndex]);
              this.buttonElement?.nativeElement.focus();
            }
            event.preventDefault();
            break;
          case 'Escape':
            this.toggleDropdownList(false);
            break;
          case ' ':
          case 'Enter':
            if (this.isDropdownVisible && this.highlightedIndex >= 0) {
              this.selectAndCloseDropdown(this.highlightedIndex);
              event.preventDefault();
            } else {
              return;
            }
            break;
          default:
            return;
        }
        event.stopPropagation();
      });

    // If the viewport is resized then recalculate the size of the dropdown
    this.viewportRuler
      .change(250)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        if (this.isDropdownVisible) {
          this.dropdownOriginRect = this.buttonElement?.nativeElement.getBoundingClientRect();
          this.cdr.markForCheck();
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  writeValue(value: Option | string): void {
    let val: string;

    if (this.options) {
      if (value == null) {
        val = this.defaultOption ? this.defaultOption.label : this.options[0].label;
      } else if (typeof value === 'string') {
        const index = this.options.findIndex(option => option.value === value);
        this.highlightedIndex = index;
        val = index >= 0 ? this.options[index].label : value;
      } else {
        this.highlightedIndex = this.options.findIndex(option => option.value === value);
        val = value.label ?? value;
      }
      this.selectedOption = val;
      this.cdr.markForCheck();
    }

  }

  registerOnChange(fn: any): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  selectAndCloseDropdown(option: Option | number) {
    this.select(option);
    this.toggleDropdownList(false);
  }

  select(option: Option | number) {
    if (typeof option === 'number') {
      if (this.options) option = this.options[option];
    }
    if (typeof option === 'object') {
      this.selectedOption = option?.label;
      this.onChanged(this.outputDataType === 'string' ? option?.value : option);
    }
  }

  setHighlightedIndex(index: number): void {
    this.highlightedIndex = index;
    this.cdr.markForCheck();
  }

  private updateHighlightedIndex(adjustment: number) {
    this.highlightedIndex += adjustment;
    if (this.options) {
      if (this.highlightedIndex >= this.options.length) {
        this.highlightedIndex = 0;
      } else if (this.highlightedIndex < 0) {
        this.highlightedIndex = this.options.length - 1;
      }
    }
    this.cdr.markForCheck();
    this.scrollToHighlightedIndex();
  }

  private scrollToHighlightedIndex() {
    setTimeout(() => {
      const el = this.dropDown?.overlayRef?.overlayElement?.querySelector('.highlight');
      el && el.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
    });
  }

  toggleDropdownList(toggle: boolean | null = null) {
    this.dropdownOriginRect = this.buttonElement?.nativeElement.getBoundingClientRect();
    this.highlightedIndex = -1; // When the dropdown is toggled reset the highlighted index (cus it's a better experience)

    if (toggle == null) {
      this.isDropdownVisible = !this.isDropdownVisible;
    } else {
      this.isDropdownVisible = toggle;
    }

    if (this.isDropdownVisible) {
      const scrollParentElement = getFirstScrollParent(this.elementRef.nativeElement);
      if (scrollParentElement == null) return;
      fromEvent(scrollParentElement, 'scroll')
        .pipe(takeWhile(() => this.isDropdownVisible))
        .subscribe(() => {
          this.isDropdownVisible = false;
          this.cdr.markForCheck();
        });
    }

    this.onTouched();
    setTimeout(() => this.cdr.markForCheck());
  }

  setDisabledState(bool: boolean): void {
    this.formDisabled = bool;
    this.cdr.markForCheck();
  }

  positionChanged(positionChange: ConnectedOverlayPositionChange) {
    this.dropdownAltPosition = (positionChange.connectionPair.overlayY === 'bottom');
  }
}
