import { Injectable } from '@angular/core';
import { Validator } from '../models/validator';
import { AttributeValue } from '../models/attribute';
import { EnumValidatorService } from './enum-validator.service';
import { isEnumValidator } from '../models/validator/enum-validator';
import { isDateValidator } from '../models/validator/date-validator';
import { DateValidatorService } from './date-validator.service';
import { RegexValidatorService } from './regex-validator.service';
import { isRegexValidator } from '../models/validator/regex-validator';
import { isCurrencyValidator } from '../models/validator/currency-validator';
import { CurrencyValidatorService } from './currency-validator.service';
import { RequiredValidatorService } from './required-validator.service';
import { isRequiredValidator } from '../models/validator/required-validator';
import { isAttributeKeyValidator } from '../models/validator/attribute-key-validator';
import { AttributeKeyValidatorService } from './attribute-key-validator.service';
import { Schema, ValidatorSchema } from '../models/schema';
import { AbstractControl } from '@angular/forms';
import { isLengthValidator } from '../models/validator/length-validator';
import { LengthValidatorService } from './length-validator.service';
import { isRangeValidator } from '../models/validator/range-validator';
import { RangeValidatorService } from './range-validator.service';
import { isLocalDateTimeValidator } from '../models/validator/local-date-time-validator';
import { LocalDateTimeValidatorService } from './local-date-time-validator.service';
import { PercentageValidatorService } from './percentage-validator-service';
import { DateTimeValidatorService } from './date-time-validator-service';
import { isDateTimeValidator } from '../models/validator/date-time-validator';
import { isPercentageValidator } from '../models/validator/percentage-validator';
import { isEmailValidator } from '../models/validator/email-validator';
import { EmailValidatorService } from './email-validator.service';
import { PhoneNumberValidatorService } from './phone-number-validator.service';
import { isPhoneNumberValidator } from '../models/validator/phone-number-validator';
import { UrlValidatorService } from './url-validator.service';
import { isUrlValidator } from '../models/validator/url-validator';

export interface IValidatorService {
  validate(displayName: string, validator: Validator, value: AttributeValue): { [key: string]: any | null };
}

@Injectable({
  providedIn: 'root',
})
export class ValidatorService implements IValidatorService {
  constructor(
    private enumValidatorService: EnumValidatorService,
    private dateValidatorService: DateValidatorService,
    private regexValidatorService: RegexValidatorService,
    private currencyValidatorService: CurrencyValidatorService,
    private requiredValidatorService: RequiredValidatorService,
    private attributeKeyValidatorService: AttributeKeyValidatorService,
    private lengthValidatorService: LengthValidatorService,
    private rangeValidatorService: RangeValidatorService,
    private localDateTimeValidatorService: LocalDateTimeValidatorService,
    private percentageValidatorService: PercentageValidatorService,
    private dateTimeValidatorService: DateTimeValidatorService,
    private emailValidatorService: EmailValidatorService,
    private phoneNumberValidatorService: PhoneNumberValidatorService,
    private urlValidatorService: UrlValidatorService,
  ) {}

  // tslint:disable-next-line:max-line-length
  private static validateByType(displayName: string, validator: Validator, value: AttributeValue|AttributeValue[], validatorService: IValidatorService): { [key: string]: any | null } {
    // if value is an array, loop over the values and validate each, return the first error to break loop, else validate single value
    if (Array.isArray(value)) {
      for (const v of value) {
        const result = validatorService.validate(displayName, validator, v);
        if (Object.keys(result).length > 0) return result;
      }
      return {};
    }
    return validatorService.validate(displayName, validator, value);
  }

  buildAngularFormValidatorFromSchema(schema: ValidatorSchema) {
    return (control: AbstractControl): { [key: string]: any } => {
      return this.validateAll(schema.displayName, schema.validators, control.value);
    };
  }

  buildAngularFormValidatorFromValidator(schema: Schema, validator: Validator) {
    return (control: AbstractControl): { [key: string]: any } => {
      return this.validate(schema.displayName, validator, control.value);
    };
  }

  validate(displayName: string, validator: Validator, value: AttributeValue|AttributeValue[]): { [key: string]: any | null } {
    const validatorService = this.getValidatorService(validator);
    if (validatorService) return ValidatorService.validateByType(displayName, validator, value, validatorService);
    throw new Error(`Unknown Validator - do you need to implement a new validator?\n\t${JSON.stringify(validator)}`);
  }

  private getValidatorService(validator: Validator): IValidatorService | null {
    if (isEnumValidator(validator)) {
      return this.enumValidatorService;
    }
    if (isDateValidator(validator)) {
      return this.dateValidatorService;
    }
    if (isRegexValidator(validator)) {
      return this.regexValidatorService;
    }
    if (isCurrencyValidator(validator)) {
      return this.currencyValidatorService;
    }
    if (isRequiredValidator(validator)) {
      return this.requiredValidatorService;
    }
    if (isAttributeKeyValidator(validator)) {
      return this.attributeKeyValidatorService;
    }
    if (isLengthValidator(validator)) {
      return this.lengthValidatorService;
    }
    if (isRangeValidator(validator)) {
      return this.rangeValidatorService;
    }
    if (isLocalDateTimeValidator(validator)) {
      return this.localDateTimeValidatorService;
    }
    if (isPercentageValidator(validator)) {
      return this.percentageValidatorService;
    }
    if (isDateTimeValidator(validator)) {
      return this.dateTimeValidatorService;
    }
    if (isEmailValidator(validator)) {
      return this.emailValidatorService;
    }
    if (isPhoneNumberValidator(validator)) {
      return this.phoneNumberValidatorService;
    }
    if (isUrlValidator(validator)) {
      return this.urlValidatorService;
    }
    return null;
  }

  validateAll(displayName: string, validators: Validator[], value: AttributeValue|AttributeValue[]): { [key: string]: any | null } {
    return validators.reduce((acc, validator) => ({ ...acc, ...this.validate(displayName, validator, value) }), {});
  }
}
