import { Component } from '@angular/core';
import { Auth0Service } from '../../auth0/auth0.service';
import { ActivatedRoute } from '@angular/router';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-login-page',
  template: ''
})
export class LoginPageComponent {

  constructor(auth0: Auth0Service, activatedRoute: ActivatedRoute) {
    activatedRoute.queryParamMap.pipe(
      switchMap(params =>
        auth0.login('/', {connection: params.get('connection') ?? undefined})
      )
    ).subscribe();
  }

}
