import { Option } from '../shared-module/entities/form-entities';
import { EMPTY, from, Observable, of, throwError } from 'rxjs';
import { EntityShort } from '../models/entity';
import { ActivatedRoute, Router } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { asRestErrorsOrThrow, IRestError } from '../models/rest-response';

export interface GeneratedFileResponse {
  blob: Blob | null;
  filename: string;
}

export function downloadGeneratedFile(generatedFileResponse: GeneratedFileResponse) {
  const elem = window.document.createElement('a');
  if (generatedFileResponse.blob) {
    elem.href = window.URL.createObjectURL(generatedFileResponse.blob);
  }
  elem.download = generatedFileResponse.filename;
  document.body.appendChild(elem);
  elem.click();
  document.body.removeChild(elem);
}

export function getNewSortWeights(a: { sortWeight: number | null } | null, b: { sortWeight: number | null } | null, count: number): (null | number)[] {
  if ((a == null && b == null) || (a?.sortWeight == null && b?.sortWeight == null)) {
    return Array(count)
      .fill(null)
      .map((_, index) => 1000 * (index + 1));
  }
  if ((a == null || a.sortWeight == null) && b && b.sortWeight != null) {
    return Array(count)
      .fill(null)
      .map((_, index) => b.sortWeight! - 1000 * (index + 1));
  }
  if ((b == null || b.sortWeight == null) && a && a.sortWeight != null) {
    return Array(count)
      .fill(null)
      .map((_, index) => a.sortWeight! + 1000 * (index + 1));
  }
  return new Array(count).fill(null).map((_, index) => {
    const mod = (b!.sortWeight! - a!.sortWeight!) / (count + 1);
    return mod + mod * index + a!.sortWeight!;
  });
}

export function buildLocalSortAndFilterFunction(searchText: string, options: Option[]): Option[] {
  // We create a pair containing the real option and a lowercase version of it
  // All the comparisons and sorts use the lowercase options
  // But we return the real one
  const str = searchText.toLowerCase();
  return options
    .map(option => {
      return [option, { ...option, label: option.label.toLowerCase() }] as const;
    })
    .filter(([_, lowercaseOption]) => lowercaseOption.label.includes(str))
    .sort(([_1, a], [_2, b]) => {
      const aStartsWith = a.label.startsWith(str);
      const bStartsWith = b.label.startsWith(str);
      if (aStartsWith && !bStartsWith) return -1;
      if (bStartsWith && !aStartsWith) return 1;
      return a.label.localeCompare(b.label);
    })
    .map(([it, _]) => ({ label: it.label, value: it.value }));
}

export function stringToOptions(values: (string | Option)[] | null): Option[] {
  if (values == null) return [];
  return values.map(value => stringToOption(value));
}

export function stringToOption(value: string | Option): Option {
  return typeof value === 'string' ? { value, label: `${ value ?? '' }` } : value;
}

export function entityShortsToOptions(values: EntityShort[] | null | undefined): Option[] {
  if (values == null) return [];
  return values.map(value => entityShortToOption(value));
}

export function entityShortToOption(value: EntityShort): Option {
  return { value: value.id, label: value.displayAs };
}

export function buildLocalSortAndFilterFunction$(options: Option[]): ((searchText: string) => Observable<Option[]>) {
  return searchText => of(buildLocalSortAndFilterFunction(searchText, options));
}

export const getMedian = (cellValArr: number[]): number => {
  const half = Math.floor(cellValArr.length / 2);
  cellValArr.sort((a, b) => a - b);
  return cellValArr.length % 2
    ? cellValArr[half]
    : ((cellValArr[half - 1] as number) + (cellValArr[half] as number)) / 2.0;
};

export function getLeafRoute(route: ActivatedRoute): ActivatedRoute {
  while (route.firstChild != null) {
    route = route.firstChild;
  }
  return route;
}

export function redirectOn404Errors(errors: any, router: Router, errorCode: string) {
  const restErrors = asRestErrorsOrThrow(errors);
  const errorsAreRedirectErrors = (restErrors as IRestError[]).every(err => err.code === errorCode);
  // if all errors are redirect errors then nav to 404 page, we want to throw if any of the errors are not 404
  if (errorsAreRedirectErrors) {
    return from(router.navigateByUrl('404', { skipLocationChange: true }))
      .pipe(switchMap((bool) => bool ? EMPTY : throwError(() => errors)));
  } else {
    throw errors;
  }
}
