import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { registerLocaleData } from '@angular/common';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { APP_CONFIG } from './app-config';
import localeNl from '@angular/common/locales/nl';
import localeEnGb from '@angular/common/locales/en-GB';
import * as Sentry from '@sentry/angular';

registerLocaleData(localeNl, 'nl');
registerLocaleData(localeEnGb, 'en-GB');

fetch('assets/config.json')
  .then(response => response.json())
  .then(config => {
    if (environment.production) {
      enableProdMode();
      Sentry.init({
        enabled: config.environment != 'development' && !window.hasOwnProperty('Cypress'), // Disable Sentry for local development & Cypress tests
        dsn: "https://138c43ec24104bdf5de0c9bc51343c03@o4508217679806464.ingest.de.sentry.io/4508222588911696",
        integrations: [
          Sentry.browserTracingIntegration(),
          Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false,
          }),
        ],
        // Tracing
        tracesSampleRate: 1.0,
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [/^\/api/],
        // Session Replay
        replaysSessionSampleRate: 1.0,
        replaysOnErrorSampleRate: 1.0,
        // Tags
        environment: config.environment,
        release: environment?.version ?? undefined,
      });
    }

    platformBrowserDynamic([
      { provide: APP_CONFIG, useValue: config },
    ])
      .bootstrapModule(AppModule, { ngZoneEventCoalescing: true })
      .catch(err => console.error(err));
  });
