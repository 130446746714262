import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { formatDate } from '@angular/common';
import { DateTimeParsingService } from './date-time-parsing.service';
import { DateTimeExtractionService } from './date-time-extraction.service';
import { DateTime } from 'luxon';

@Injectable({
  providedIn: 'root',
})
export class DateTimeFormattingService {
  constructor(
    private dateTimeExtractionService: DateTimeExtractionService,
    private dateTimeParsingService: DateTimeParsingService,
    @Inject(LOCALE_ID) private currentLocale: string
  ) {}

  /**
   * Formats a date-type string to a human readable date string
   */
  formatDateForHumans(dateStr: string): string {
    if (this.isValidAndNotNullDateTime(dateStr)) {
      try {
        return formatDate(dateStr, 'shortDate', this.currentLocale);
      } catch {
        /* SWALLOW */
      }
    }
    return dateStr;
  }

  formatDateTimeForHumans(dateTimeStr: string): string {
    if (dateTimeStr == null) return '';
    if (this.isValidAndNotNullDateTime(dateTimeStr)) {
      try {
        return formatDate(dateTimeStr, 'short', this.currentLocale);
      } catch {
        // Swallow
      }
      if (
        DateTime.fromFormat(dateTimeStr, `yyyy-MM-dd'T'HH:mm:ss.SSSZZ`, { locale: this.currentLocale }).isValid ||
        DateTime.fromFormat(dateTimeStr, `yyyy-MM-dd'T'HH:mm:ss.SSS`).isValid
      ) {
        const { dateISO } = this.dateTimeExtractionService.extractDateAndTimeFromDateTimeISO(dateTimeStr);
        if (dateISO) return formatDate(dateISO, 'short', this.currentLocale);
      }
    }
    return dateTimeStr;
  }

  /**
   * Takes a data/dateTime ISO and returns if it's valid and not null
   */
  private isValidAndNotNullDateTime(dateTimeStr: string): boolean {
    if (dateTimeStr == null) return false;
    return DateTime.fromFormat(dateTimeStr, `yyyy-MM-dd'T'HH:mm:ss.SSS`).isValid ||
      DateTime.fromFormat(dateTimeStr, `yyyy-MM-dd'T'HH:mm:ss.SSSZZ`).isValid ||
      (new Date(dateTimeStr).toString() !== 'Invalid Date' && dateTimeStr !== '');
  }
}
