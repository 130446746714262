import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

export function getAndFocusFirstFocusableElement(element: HTMLElement) {
  const focusableField = getFirstFocusableElement(element);
  setTimeout(() => {
    if (!focusableField) return;
    if (focusableField instanceof HTMLInputElement || focusableField instanceof HTMLTextAreaElement) {
      focusableField.select();
    }
    focusableField.focus();
  });
}

export function getFirstFocusableElement(element: HTMLElement): HTMLElement | null {
  const focusable = element.querySelectorAll<HTMLElement>(
    'button:not([tabindex="-1"]):not([data-tabindex="-1"]):not([disabled]), input:not([tabindex="-1"]):not([data-tabindex="-1"]):not([disabled]), select:not([tabindex="-1"]):not([data-tabindex="-1"]):not([disabled]), textarea:not([tabindex="-1"]):not([data-tabindex="-1"]):not([disabled]), [tabindex]:not([tabindex="-1"]):not([data-tabindex="-1"]):not([disabled]), [data-tabindex]:not([data-tabIndex="-1"]):not([disabled])'
  );

  if (focusable && focusable.length > 0) {
    const priority = Array.from(focusable).find(el => el.dataset['tabindex'] === '1');
    return priority ? priority : focusable[0];
  }
  return null;
}

/**
 * Takes a snake case string and returns a title case string
 */
export function snakeToTitleCase(str: string): string {
  if (typeof str !== 'string') return str;
  return str
    .split(/_+/)
    .map(l => (l.length > 0 ? l[0].toUpperCase() + l.substring(1).toLowerCase() : ''))
    .join(' ');
}

/**
 * Returns true if the value is not null and casts it to the non null type.
 */
export function isNonNull<T>(value: T): value is NonNullable<T> {
  return value != null;
}

/**
 * Filters null (or undefined) values from an observable and returns a non-null typed Observable.
 */
export function filterNonNull<T>(): (source: Observable<T | null | undefined>) => Observable<T> {
  return (source: Observable<T | null | undefined>) => {
    return source.pipe(filter(it => it != null)) as Observable<T>
  }
}


/**
 * Converts the given CSS value from various units to pixels ("px").
 *
 * @param cssValue - The CSS value to be converted. It can include units like 'px', 'cm', 'rem', etc.
 * @param [target=document.body] - The target HTML element whose style will be computed for relative measurements (e.g. 'em').
 * If not provided, it defaults to the document body.
 *
 * @returns The converted pixel value if the unit exists in 'supportedUnits'; otherwise, returns the original value.
 *
 * @example
 * ```
 * toPx("2cm");    // Returns: 76
 * toPx("100vw");  // Returns: the width of the viewport in pixels
 * toPx("20unknown");  // Returns: "20unknown"
 * ```
 */
export function cssToPx(cssValue: string, target?: HTMLElement ): number {

  target = target || document.body;

  const supportedUnits: { [k: string]: (value: number) => number } = {

    // Absolute sizes
    px: (value: number) => value,
    cm: (value: number) => value * 38,
    mm: (value: number) => value * 3.8,
    q: (value: number) => value * 0.95,
    in: (value: number) => value * 96,
    pc: (value: number) => value * 16,
    pt: (value: number) => value * 1.333333,

    // Relative sizes
    rem: (value: number) => value * parseFloat( getComputedStyle( document.documentElement ).fontSize ),
    em: (value: number) => value * parseFloat( getComputedStyle(target!).fontSize ),
    vw: (value: number) => value / 100 * window.innerWidth,
    vh: (value: number) => value / 100 * window.innerHeight,

    // Times
    ms: (value: number) => value,
    s: (value: number) => value * 1000,

    // Angles
    deg: (value: number) => value,
    rad: (value: number) => value * ( 180 / Math.PI ),
    grad: (value: number) => value * ( 180 / 200 ),
    turn: (value: number) => value * 360

  };

  // Match positive and negative numbers including decimals with following unit
  const pattern = new RegExp( `^([\-\+]?(?:\\d+(?:\\.\\d+)?))(${ Object.keys(supportedUnits).join( '|' ) })$`, 'i' );

  // If is a match, return example: [ "-2.75rem", "-2.75", "rem" ]
  const matches = String.prototype.toString.apply(cssValue).trim().match(pattern);

  if (matches) {
    const value = Number(matches[1]);
    const unit = matches[2].toLocaleLowerCase();

    // Sanity check, make sure unit conversion function exists
    if ( unit in supportedUnits ) {
      return supportedUnits[unit]( value );
    }
  }

  throw new Error(`Unable to convert '${cssValue}' to px.`);

}


export function hideLoadingScreen(){
  const loading = document.getElementById('initial-loading-spinner') as HTMLDivElement;
  if (!loading) return;
  loading.classList.add('fade');
  setTimeout(() => loading.parentNode?.removeChild(loading), 300);
}

/**
 * @param a is a string iso date
 * @param b is a string iso date
 * @private
 */
export const sortByDate = (a: string | null, b: string | null) => {
  if (a == null) {
    return -1;
  }
  if (b == null) {
    return 1;
  }
  return -a.localeCompare(b);
};

export function isLowercase(str: string): boolean {
  return str.toLowerCase() === str;
}
export function isPropertyKey(key: string): boolean {
  return isUppercase(key.replace(/\[.+\]/g, ''));
}
export function isUppercase(str: string): boolean {
  return str.toUpperCase() === str;
}

export function commaSeparatedListToArr(str: string): string[] {
  return str.split(',').map((val: string) => val.trim());
}

export function valueToString(value: any): string {
  return typeof value !== 'string' ? JSON.stringify(value) : value;
}

export function valueToLowercaseString(value: any): string {
  return valueToString(value).toLowerCase()
}

export function padIndex(index: number, amount = 2): string {
  return String(index).padStart(amount, '0');
}
