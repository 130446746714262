import { ChangeDetectionStrategy, Component, Input, TemplateRef } from '@angular/core';
import { IRestError, isRestErrors } from '../../../models/rest-response';
import { Nullable } from '../../../models/typescript-types';
import { IconEnum } from '../icon/icon.component';

@Component({
  selector: 'app-modal-result',
  templateUrl: './modal-result.component.html',
  styleUrls: ['./modal-result.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalResultComponent {
  isRestErrors = isRestErrors;
  @Input() error: Nullable<IRestError[] | Error>;
  @Input() errorListTemplate: TemplateRef<any> | undefined;
  protected readonly IconEnum = IconEnum;
}
