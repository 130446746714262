<app-modal
  modalTitle="Connection Error!"
  i18n-modalTitle="@@connection-error-modal__title-text"
  confirmButtonText="Reload"
  i18n-confirmButtonText="@@connection-error-modal__confirm-button-text"
  overlayClass="error"
  [cancelButtonText]="null"
  (confirmEvent)="onRefresh()"
  appFocusFirstFocusableElement
>
  <h5 class="txt-grey-m" data-test="prompt-text" i18n="@@connection-error-modal__prompt-text">
    There was a connection issue, please check you are connected to the internet and then click reload. If this error persists, please contact support.
  </h5>
</app-modal>
