import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concat, of } from 'rxjs';
import { catchError, filter, flatMap, switchMap } from 'rxjs/operators';
import * as ConfigActions from './actions';
import { ConfigService } from '../../services/config.service';

@Injectable()
export class Effects {
  constructor(private actions$: Actions, private configService: ConfigService) {}

  loadEffect$ = createEffect(() => this.actions$.pipe(
    ofType(ConfigActions.LoadRequestAction),
    switchMap(() =>
      concat(
        of(ConfigActions.RequestSentAction()),
        this.configService
          .getConfig()
          .pipe(
            filter(it => it != null),
            flatMap(config => [ConfigActions.LoadSuccessfulAction({ config }), ConfigActions.RequestSuccessfulAction()]),
            catchError(error => of(ConfigActions.LoadFailedAction({ error }), ConfigActions.RequestFailedAction({ error })))
          )
      )
    )
  ));
}
