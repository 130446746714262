import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ActivityId, RestResponse, ResultWithTotal } from '../../models/rest-response';
import { APP_CONFIG, AppConfig } from '../../../app-config';
import { Observable } from 'rxjs';
import { Annotation, Comment } from '../models/comment';
import { extractRestValue } from '../../services/api-utils';
import { map } from 'rxjs/operators';
import { Subscription } from '../../notifications-module/types';

@Injectable({
  providedIn: 'root'
})
export class CommentHttpService {
  constructor(@Inject(APP_CONFIG) private config: AppConfig, private http: HttpClient) { }

  get({ entityTypes, relatedEntities, entityIds, limit = 25, offset = 0 }: { entityTypes?: string[], relatedEntities?: string[], entityIds?: string[], limit?: number, offset?: number }): Observable<ResultWithTotal<Comment>> {
    let params = new HttpParams();
    entityTypes?.forEach(entityType => params = params.append('entity-type', entityType));
    entityIds?.forEach(entityId => params = params.append('entity-id', entityId));
    relatedEntities?.forEach(relatedEntity => params = params.append('related-entity', relatedEntity));
    params = params.append('limit', limit);
    params = params.append('offset', offset);
    return this.http.get<RestResponse<ResultWithTotal<Comment>>>(`${this.config.apiRoot}/comment/v1/comment`, { params }).pipe(extractRestValue());
  }

  getCommentById(commentId: string): Observable<Comment> {
    return this.http.get<RestResponse<Comment>>(`${this.config.apiRoot}/comment/v1/comment/${commentId}`).pipe(extractRestValue());
  }

  createReply(commentId: string, reply: { text?: string, annotations?: Annotation[], resolution?: boolean }): Observable<string> {
    return this.http.post<RestResponse<{ id: string }>>(`${this.config.apiRoot}/comment/v1/comment/${commentId}/reply`, reply).pipe(
      extractRestValue(),
      map(it => it.id)
    );
  }

  patch(commentId: string, data: Partial<Comment>): Observable<ActivityId> {
    throw new Error("Not implemented.");
    // return this.http.patch<RestResponse<void>>(`${this.config.apiRoot}/comment/v1/comment/${commentId}`, data).pipe(extractActivityId());
  }

  subscribe(commentId: string): Observable<Subscription> {
    return this.http.post<RestResponse<string>>(`${this.config.apiRoot}/comment/v1/comment/${commentId}/subscription`, {})
      .pipe(
        extractRestValue(),
        map(subscriptionId => ({ id: subscriptionId, entityId: commentId, entityType: 'svc-comment/comment', relatedEntities: [] })) // map to subscription
      );
  }
}
