import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-progress-ring',
  templateUrl: './progress-ring.component.html',
  styleUrls: ['./progress-ring.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgressRingComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject();
  private size = 140;
  private radius: number;
  circumference: number;
  progress: number;
  percentage = 0;
  isSpinner = false;

  @Input() isPulse = false;
  @Input() showProgressValue = true;
  @Input() strokeWidth = 14;
  @Input() isComplete = false;

  @Input('percentage') set _percentage(percentage: number | null) {
    if (percentage == null || percentage < 0) {
      // if percentage is set to null (or negative) we set the progress ring as a spinner
      this.percentage = 0.15;
      this.isSpinner = true;
    } else {
      this.progress = this.getProgress(percentage);
      this.percentage = percentage;
      this.isSpinner = false;
    }
    this.cdr.markForCheck();
  }

  constructor(private cdr: ChangeDetectorRef) {
    this.progress = this.getProgress(this.percentage ?? 0);
    this.radius = this.size / 2 - this.strokeWidth / 2;
    this.circumference = this.radius * 2 * Math.PI;
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  private getProgress(val: number) {
    return this.circumference - val * this.circumference;
  }
}
