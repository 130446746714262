import { Injectable, Injector, OnDestroy } from '@angular/core';
import { ComponentPortal } from '@angular/cdk/portal';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { SidePanelPortalConfig } from '../models/side-panel-portal-config';
import { OVERLAY_DATA } from './overlay.service';
import { distinctUntilChanged, filter, map, startWith, takeUntil } from 'rxjs/operators';
import { KeyVal } from '../models/typescript-types';
import { ActivationStart, Router } from '@angular/router';
import { getLeafRoute } from './utils';

@Injectable({
  providedIn: 'root',
})
export class SidePanelService implements OnDestroy {
  private destroy$ = new Subject<null>();
  private portalSub$ = new BehaviorSubject<ComponentPortal<any> | null>(null);
  readonly portal$ = this.portalSub$.asObservable();
  private _isPanelOpen$ = new BehaviorSubject<boolean>(false);
  readonly isPanelOpen$ = this._isPanelOpen$.asObservable();
  private readonly _portalConfigs$ = new BehaviorSubject<Map<string, SidePanelPortalConfig> | null>(null);
  private readonly _currentPortalConfigKey$ = new BehaviorSubject<string | null>(null);
  readonly currentPortalConfigKey$ = this._currentPortalConfigKey$.asObservable();
  readonly portalConfigs$ = this._portalConfigs$.asObservable();
  readonly mainMinWidth$ = new BehaviorSubject<number | null>(null);
  readonly minWidthSub$ = new BehaviorSubject<string | null>('460px');
  readonly minWidth$ = this.minWidthSub$.asObservable();
  private readonly _sidePanelState$ = new BehaviorSubject<SidePanelState>(null);
  readonly sidePanelState$ = this._sidePanelState$.asObservable();

  constructor(router: Router) {
    combineLatest([this.portalConfigs$, this._currentPortalConfigKey$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([_, currentKey]) => {
        const config = currentKey ? this._portalConfigs$.value?.get(currentKey) : null;
        // clean up the current portal
        this.cleanUpPortal();
        // create a new portal or set to null
        if (config) {
          this.portalSub$.next(this.createComponentPortal(config));
        } else {
          this.portalSub$.next(null);
        }
      });

    // get the initial root so we can check if the component has changed in the router event
    const initialRoute = getLeafRoute(router.routerState.root);

    // Reset the panel when the page and the component changes
    router.events
      .pipe(
        filter(event => event instanceof ActivationStart),
        map(() => router.routerState.root),
        map(route => getLeafRoute(route)),
        startWith(initialRoute),
        distinctUntilChanged((a, b) => a.component === b.component),
      )
      .subscribe(() => this.resetPanel());
  }

  get isPanelOpen() {
    return this._isPanelOpen$.value;
  }

  get minWidth(): string | null {
    return this.minWidthSub$.value;
  }
  set minWidth(width: string | null) {
    this.minWidthSub$.next(width);
  }

  get sidePanelState(): SidePanelState {
    return this._sidePanelState$.value;
  }
  set sidePanelState(state: SidePanelState) {
    this._sidePanelState$.next(state);
  }

  init(mainMinWidth?: number, configs?: KeyVal<SidePanelPortalConfig>) {
    if (mainMinWidth) {
      this.mainMinWidth$.next(mainMinWidth);
    }
    this._portalConfigs$.next(configs ? new Map(Object.entries(configs)) : null);
  }

  togglePanel(bool?: boolean) {
    this._isPanelOpen$.next(bool ?? !this._isPanelOpen$.value);
  }

  togglePanelWith(configKey: string, bool?: boolean) {
    // if the panel has been opened without a config or the config has changed set the current config to the provided one and toggle the panel
    if (this._currentPortalConfigKey$.value == null || configKey !== this._currentPortalConfigKey$.value) {
      this.setCurrentPortalConfigKey(configKey);
      // toggle the panel if a bool param is set, else open the panel if it isn't already (we don't want to close the panel if the user has clicked another panel button)
      this._isPanelOpen$.next(bool != null ? bool : true);
    } else {
      // otherwise, toggle the panel
      this._isPanelOpen$.next(bool != null ? bool : !this._isPanelOpen$.value);
    }
    if (!this._isPanelOpen$.value) this.sidePanelState = null;
  }

  setCurrentPortalConfigKey(configKey: string | null) {
    this._currentPortalConfigKey$.next(configKey);
  }

  private createComponentPortal(config: SidePanelPortalConfig): ComponentPortal<any> {
    return new ComponentPortal(
      config.component,
      null,
      Injector.create({
        providers: [
          {
            provide: OVERLAY_DATA,
            useValue: config.overlayData,
          },
        ],
        parent: config.injector,
      })
    );
  }

  resetPanel() {
    this.cleanUpPortal();
    this._isPanelOpen$.next(false);
    this._portalConfigs$.next(null);
    this.portalSub$.next(null);
    this.setCurrentPortalConfigKey(null);
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  private cleanUpPortal() {
    const currentPortal = this.portalSub$.value;
    if (currentPortal == null) return;
    currentPortal.viewContainerRef?.clear();
    currentPortal.isAttached && currentPortal.detach();
  }
}

export type SidePanelState = 'history' | 'comments' | null;
