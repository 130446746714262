import { Component, forwardRef, HostListener, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as uuid from 'uuid';

@Component({
  selector: 'app-boolean-toggle [label]',
  templateUrl: './boolean-toggle.component.html',
  styleUrls: ['./boolean-toggle.component.sass'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BooleanToggleComponent),
      multi: true,
    },
  ],
})
export class BooleanToggleComponent implements ControlValueAccessor {
  @Input() id = `toggle-${uuid.v4()}`;
  @Input() label: string | undefined;
  @Input() labelTrue = 'on';
  @Input() labelFalse = 'off';
  @Input() isToggleText = true;
  private onChanged: ((x: boolean) => void) | undefined;
  private onTouched: (() => void) | undefined;
  disabled = false;
  value = true;

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      event.preventDefault();
      this.toggle();
    }
  }

  toggle() {
    this.value = !this.value;
    this.onChanged && this.onChanged(this.value);
  }

  writeValue(value: boolean): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
