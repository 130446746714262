import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { catchError, filter, map, take } from 'rxjs/operators';
import { OverlayService } from '../../services/overlay.service';
import { LanguageService } from '../../services/language.service';
import { DOCUMENT } from '@angular/common';
import { BehaviorSubject, of, ReplaySubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { NotificationsBridgeService } from '../../notifications-module/services/notifications-bridge.service';
import { ConfigService } from '../../services/config.service';
import { hideLoadingScreen } from '../../utils/helpers';
import { SidePanelService } from '../../services/side-panel.service';
import { ActivatedRoute } from '@angular/router';
import { ResultStringService } from '../../shared-module/services/result-string.service';

@Component({
  selector: 'app-wrapper-page',
  templateUrl: './wrapper-page.component.html',
  styleUrls: ['./wrapper-page.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WrapperPageComponent implements OnInit {
  loadingCountSub$ = new BehaviorSubject<number>(3); // Initial value should be the number of things to load
  isLoading$ = this.loadingCountSub$.asObservable().pipe(map(it => it > 0));
  logoSrc$ = new ReplaySubject<string>(1);

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private configService: ConfigService,
    private http: HttpClient,
    public overlayService: OverlayService,
    public languageService: LanguageService,
    private notificationsBridgeService: NotificationsBridgeService,
    public sidePanelService: SidePanelService,
    private activatedRoute: ActivatedRoute,
    private resultStringService: ResultStringService,
  ) {
    this.notificationsBridgeService.init();
  }

  ngOnInit(): void {
    this.languageService.determineSiteLanguage();
    const { client, entity, entityId, component } = this.activatedRoute.snapshot.params;
    const action = this.activatedRoute.snapshot.queryParams['action'];

    if (client) {
      this.configService.changeClient(client);
      this.resultStringService.handle(`${component}/${entity}/${entityId}${action ? '?action=' + action : '' }`, client).then(() => this.loadingCountSub$.next(this.loadingCountSub$.value - 1));
    } else {
      this.loadingCountSub$.next(this.loadingCountSub$.value - 1);
    }

    // Load the stylesheet for the selected client
    this.http.get(`assets/client-styles/${ this.configService.selectedClient }-styles.css`, { responseType: 'blob' })
      .pipe(catchError(() => of(null)))
      .subscribe((styleFile) => {
        styleFile && this.loadTheme(styleFile, this.configService.selectedClient);
        this.loadingCountSub$.next(this.loadingCountSub$.value - 1);
      });

    // Load the logo for the selected client
    this.http.get(`assets/client-logos/${ this.configService.selectedClient }-logo.svg`, { responseType: 'blob' })
      .pipe(catchError(() => of(null)))
      .subscribe(logoFile => {
        logoFile && this.setLogo(logoFile);
        this.loadingCountSub$.next(this.loadingCountSub$.value - 1);
      });

    // Once the loading count reaches 0 i.e. loading has finished, remove the initial-loading-spinner from the dom
    this.loadingCountSub$
      .pipe(
        filter(it => it === 0),
        take(1),
      )
      .subscribe(() => hideLoadingScreen());
  }

  private loadTheme(file: Blob, clientName: string) {
    const linkId = `${ clientName }-stylesheet`;
    const linkHref = file ? window.URL.createObjectURL(file) : '';
    const headEl = this.document.getElementsByTagName('head')[0];
    const existingLinkEl = this.document.getElementById(linkId) as HTMLLinkElement;

    // if the link exists already, do nothing, else if the client has custom branding load the stylesheet
    if (existingLinkEl) {
      existingLinkEl.href = linkHref;
    } else if (file) {
      const newLinkEl = this.document.createElement('link');
      newLinkEl.id = linkId;
      newLinkEl.rel = 'stylesheet';
      newLinkEl.href = linkHref;

      headEl.appendChild(newLinkEl);
    }
  }

  private setLogo(file: Blob) {
    const logoSrc = file ? window.URL.createObjectURL(file) : null;
    if (logoSrc != null) {
      this.logoSrc$.next(logoSrc);
    }
  }
}
