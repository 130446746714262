<div class="input-btn-group" [formGroup]="queryForm!">
  <input
    data-test="query-name-input"
    class="input --sm"
    [formControl]="name"
    placeholder="Rule Name"
    i18n-placeholder="@@save-query__query-name--placeholder"
    #nameInputRef
  />
  <div *ngIf="mainButton$ | async; let mainButton" data-test="save-query-template-container" class="btn-drop-container" (appClickOutside)="showDropList = false" appClickOutside>
    <!-- MAIN BUTTON -->
    <!-- LOADING -->
    <ng-container *ngIf="isUpdating$ | async; else mainBtn">
      <button
        title="Working..."
        i18n-title="@@save-query__save-query-btn--working"
        data-test="save-query-template-working-btn"
        class="'btn-main primary-btn btn--sm --solo"
        disabled
      >
        <fa-duotone-icon class="icon icon-light left" [icon]="loadingIcon" animation="spin"></fa-duotone-icon><span i18n="@@save-query__save-query-btn--working">Working</span>
      </button>
    </ng-container>
    <!-- MAIN -->
    <ng-template #mainBtn>
      <button
        [attr.title]="mainButton.text"
        [attr.data-test]="mainButton.testId"
        [ngClass]="mainButton.classes"
        [class.--solo]="queryForm?.invalid"
        [disabled]="mainButton.disabled || queryForm?.invalid"
        (click)="mainButton.callback()"
      >
        <fa-icon [ngClass]="mainButton.icon.classes" [icon]="mainButton.icon.icon"></fa-icon>{{ mainButton.text }}
      </button>
    </ng-template>

    <ng-container *ngIf="dropdownButton$ | async; let dropdownButton">
      <ng-container *ngIf="queryForm?.valid">
        <!-- CHEVRON DOWN BUTTON -->
        <button data-test="dropdown-button" class="primary-d-btn btn-drop btn--sm" (click)="showDropList = !showDropList" (appClickOutside)="showDropList = false">
          <fa-icon class="icon" [icon]="dropdownIcon"></fa-icon>
        </button>
        <!-- DROPDOWN BUTTON LIST -->
        <ul data-test="save-query-dropdown-list" class="drop-list" [class.visually-hidden]="!showDropList">
          <li>
            <button
              [attr.title]="dropdownButton.text"
              [attr.data-test]="dropdownButton.testId"
              [ngClass]="dropdownButton.classes"
              [disabled]="dropdownButton.disabled"
              (click)="dropdownButton.callback()"
            >
              <ng-container *ngIf="isUpdating$ | async; else dropdownButtonIcon">
                <fa-duotone-icon class="icon icon-light left" [icon]="loadingIcon" animation="spin"></fa-duotone-icon><span i18n="@@save-query__save-query-btn--working">Working</span>
              </ng-container>
              <ng-template #dropdownButtonIcon>
                <fa-icon [ngClass]="dropdownButton.icon.classes" [icon]="dropdownButton.icon.icon"></fa-icon>{{ dropdownButton.text }}
              </ng-template>
            </button>
          </li>
        </ul>
      </ng-container>
    </ng-container>
  </div>
</div>
