export enum AqlOperator {
  EQ = 'EQ',
  NEQ = 'NEQ',
  GT = 'GT',
  GTEQ = 'GTEQ',
  LT = 'LT',
  LTEQ = 'LTEQ',
  IN = 'IN',
  NIN = 'NIN',
  EXISTS = 'EXISTS',
  NEXISTS = 'NEXISTS',
  CONTAINS_ALL = 'CONTAINS_ALL',
  CONTAINS_ANY = 'CONTAINS_ANY',
  CONTAINS_NONE = 'CONTAINS_NONE',
}

export function aqlOperatorToString(aqlOperator: AqlOperator): string {
  switch (aqlOperator) {
    case AqlOperator.EQ: return '=';
    case AqlOperator.NEQ: return '!=';
    case AqlOperator.GT: return '>';
    case AqlOperator.GTEQ: return '>=';
    case AqlOperator.LT: return '<';
    case AqlOperator.LTEQ: return '<=';
    default: throw Error(`Unknown AQL Operator: '${JSON.stringify(aqlOperator)}'.`);
  }
}

export function getInverseOfAqlOperator(operator: AqlOperator): AqlOperator {
  switch (operator) {
    case AqlOperator.EQ:
      return AqlOperator.NEQ;
    case AqlOperator.NEQ:
      return AqlOperator.EQ;
    case AqlOperator.GT:
      return AqlOperator.LTEQ;
    case AqlOperator.LT:
      return AqlOperator.GTEQ;
    case AqlOperator.GTEQ:
      return AqlOperator.LT;
    case AqlOperator.LTEQ:
      return AqlOperator.GT;
    default:
      throw Error(`Unknown AQL Operator '${operator}' when trying to invert it.`);
  }
}
