import { ChangeDetectionStrategy, Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { icon, Icon } from '@fortawesome/fontawesome-svg-core';
import { takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Subject } from 'rxjs';
import { faBan } from '@fortawesome/pro-regular-svg-icons';

export interface IconSelectOption {
  value: string;
  label: string;
  icon: Icon;
}

@Component({
  selector: 'app-icon-select',
  templateUrl: './icon-select.component.html',
  styleUrls: ['./icon-select.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => IconSelectComponent),
    multi: true
  }]
})
export class IconSelectComponent implements ControlValueAccessor, OnInit, OnDestroy {
  @Input() identifier: string | undefined;
  @Input() classes = '';
  @Input() label: string | undefined;
  @Input() labelClasses: string | undefined;
  @Input('options') set _options(options: any[]) {
    this.options = options;
    this.icon$.next(this.getIcon());
  }
  options: IconSelectOption[] = [];
  selectedOption = new UntypedFormControl(null);
  icon$ = new BehaviorSubject<Icon>(icon(faBan));
  private destroy$ = new Subject<null>();

  onChanged: (option: string) => void = () => { };
  onTouched: any = () => { };

  ngOnInit(): void {
    this.selectedOption.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(v => {
      this.onChanged(v);
      this.icon$.next(this.getIcon());
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  writeValue(value: string): void {
    this.selectedOption.setValue(value, { onlySelf: true, emitEvent: false });
    this.icon$.next(this.getIcon());
  }

  registerOnChange(fn: any): void { this.onChanged = fn; }

  registerOnTouched(fn: any): void { this.onTouched = fn; }

  setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.selectedOption.disable({ onlySelf: true, emitEvent: false }) : this.selectedOption.enable({ onlySelf: true, emitEvent: false });
  }

  getSelectedOption() {
    return this.options.find(option => option.value === this.selectedOption.value);
  }

  getIcon(): Icon {
    if (this.options == null || this.selectedOption == null) { return icon(faBan); }
    const selectedOption = this.getSelectedOption();
    if (selectedOption != null) {
      return selectedOption.icon;
    } else {
     return icon(faBan);
    }
  }
}
