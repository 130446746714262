import { Component, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import { ConfigService } from '../../services/config.service';
import { DOCUMENT } from '@angular/common';
import * as SentryBrowser from '@sentry/browser';
import { BehaviorSubject } from 'rxjs';
import { faCloudExclamation } from '@fortawesome/pro-regular-svg-icons';
import { SENTRY } from '../../injection-tokens';

@Component({
  selector: 'app-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.sass'],
})
export class ErrorModalComponent {
  protected readonly errorIcon = faExclamationTriangle;
  protected readonly offlineIcon = faCloudExclamation;
  protected isOffline$ = new BehaviorSubject<boolean>(false);
  userFeedback = new FormControl<string>('', { nonNullable: true });
  window: Window | null;

  confirmButtonText$ = this.userFeedback.valueChanges.pipe(
    startWith(''),
    map((value) => {
      if (value.length === 0) {
        return $localize`:@@error-modal__confirmation-button__without-feedback:Refresh the Page`;
      } else {
        return $localize`:@@error-modal__confirmation-button__with-feedback:Submit and Refresh`;
      }
    }),
  );

  constructor(private configService: ConfigService, @Inject(DOCUMENT) private document: Document, @Inject(SENTRY) private sentry: typeof SentryBrowser) {
    this.window = this.document.defaultView;
    this.isOffline$.next(!this.window?.navigator.onLine);
  }

  onConfirm() {
    const user = this.configService.user;
    if (this.userFeedback.value.trim().length > 0) {
      const associatedEventId = this.sentry.lastEventId();
      this.sentry.captureMessage(this.userFeedback.value);
      this.sentry.captureFeedback({
        name: user.displayAs,
        email: user.email,
        message: this.userFeedback.value,
        associatedEventId: this.sentry.captureMessage(associatedEventId ?? 'User Feedback'),
      });
    }
    this.window?.location.reload();
  }
}

export interface ErrorModalConfig {
  error: any;
}
