<svg class="progress-ring" [class.spinner]="isSpinner" [class.queued]="isPulse" viewBox="0 0 140 140">
  <text *ngIf="showProgressValue" class="progress-ring-value" x="50%" y="50%" dominant-baseline="middle" text-anchor="middle">{{ percentage * 100 | number: '1.0-0' }}%</text>
  <circle
    class="progress-ring-circle--back"
    fill="transparent"
    stroke="var(--progress-ring-back-colour)"
    [attr.stroke-width]="strokeWidth"
    [attr.r]="'calc(50% - ' + strokeWidth / 2 + 'px)'"
    cx="50%"
    cy="50%"
  />
  <circle
    class="progress-ring-circle"
    data-test="progress-ring-circle"
    fill="transparent"
    [attr.stroke]="isComplete ? 'var(--progress-ring-front-colour--success)' : 'var(--progress-ring-front-colour)'"
    stroke-linejoin="round"
    stroke-linecap="round"
    [attr.stroke-width]="strokeWidth"
    [attr.r]="'calc(50% - ' + strokeWidth / 2 + 'px)'"
    cx="50%"
    cy="50%"
    [attr.stroke-dasharray]="circumference + ' ' + circumference"
    [attr.stroke-dashoffset]="progress"
    [attr.transform-origin]="50 + '% ' + 50 + '%'"
  />
</svg>
