import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  LOCALE_ID,
  OnDestroy,
  OnInit,
  Optional,
  Self,
  ViewChild,
} from '@angular/core';
import { CdkConnectedOverlay, ConnectedOverlayPositionChange, ScrollStrategyOptions } from '@angular/cdk/overlay';
import { fromEvent, Subject, takeWhile } from 'rxjs';
import { ControlValueAccessor, NgControl, FormControl } from '@angular/forms';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { v4 as uuid } from 'uuid';
import { DateTimeParsingService } from '../../../services/date-time/date-time-parsing.service';
import { DateTimeFormattingService } from '../../../services/date-time/date-time-formatting.service';
import { debounceTime, filter, map, takeUntil, tap } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { fieldDropDownPositions, getFirstScrollParent } from '../field-helpers';

@Component({
  selector: 'app-date-field [label]',
  templateUrl: './date-field.component.html',
  styleUrls: ['./date-field.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateFieldComponent implements ControlValueAccessor, OnInit, OnDestroy {
  readonly dateForm: FormControl<string>;

  @Input() label: string | undefined;
  @Input() labelClasses: string | undefined;
  @Input() placeholder: string | undefined;
  @Input() showErrorsAboveInput = true;
  @Input() inputTitle: string | undefined;

  private readonly destroy$ = new Subject();
  readonly uid = uuid();
  isDropdownVisible = false;
  dropDownIcon = faChevronDown;
  dropdownPositions = fieldDropDownPositions();
  dropdownAltPosition = false;
  constructor(
    @Self() @Optional() public control: NgControl,
    private dateTimeParsingService: DateTimeParsingService,
    private dateTimeFormattingService: DateTimeFormattingService,
    @Inject(LOCALE_ID) private currentLocale: string,
    private cdr: ChangeDetectorRef,
    private elRef: ElementRef,
    @Inject(DOCUMENT) private document: Document,
    public scrollStrategyOptions: ScrollStrategyOptions,
  ) {
    if (control) {
      control.valueAccessor = this;
    }

    this.dateForm = new FormControl<string>('', { nonNullable: true });
  }

  @ViewChild('inputElement', { static: true }) inputElRef: ElementRef<HTMLInputElement> | undefined;
  @ViewChild('toggleButtonElement', { static: true }) toggleButtonElRef: ElementRef<HTMLButtonElement> | undefined;
  @ViewChild('dropListContainer', { static: true }) dropListContainerElRef: ElementRef<HTMLDivElement> | undefined;
  @ViewChild('datePickerWrapper', { static: true }) datePickerWrapper: CdkConnectedOverlay | undefined;

  onChanged: (value: string | null, timeStr?: string) => void = () => {};
  onTouched: any = () => {};


  get overlayElement() {
    return this.datePickerWrapper?.overlayRef?.overlayElement;
  }

  get inputElement() {
    return this.inputElRef?.nativeElement;
  }

  ngOnInit(): void {
    this.handleDateFormValueChanges();
    this.handleInputValueChanges();
    this.handleClickOutsideEvents();
    this.handleTabAndEnterEvents();
    this.handleEscapeEvents();
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  registerOnChange(fn: any): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  /**
   * @param value should be a date ISO string
   */
  writeValue(value: string): void {
    // If empty return early. @e need to check for array as the value can change whilst this component is being switched out
    if (value == null || value === '' || Array.isArray(value)) return;
    // validate the value as a date
    if (new Date(value).toString() !== 'Invalid Date') this.updateFormValue(value);
    // update the input to show the value formatted for user readability
    this.updateInputValue(this.dateTimeFormattingService.formatDateForHumans(value));
  }

  onBlur(event: FocusEvent) {
    this.onTouched();
    const dataISO = this.getInputValueAsISOString();
    this.updateFormValue(dataISO);
    try {
      const formattedInput = this.inputElement && this.dateTimeParsingService.parseDate(this.inputElement.value);
      formattedInput && this.updateInputValue(this.dateTimeFormattingService.formatDateForHumans(formattedInput));
    } catch { /*Swallow*/ }

    // if we lose focus but are not within the dropdown hide the dropdown
    if (
      this.overlayElement?.contains((event.relatedTarget as HTMLElement)?.closest('.litepicker')) == null &&
      this.overlayElement?.contains(event.relatedTarget as HTMLElement) == null
    ) {
      this.hideDropdown();
    }
  }

  toggleDatepicker() {
    this.isDropdownVisible = !this.isDropdownVisible;

    if (this.isDropdownVisible) {
      const scrollParentElement = getFirstScrollParent(this.elRef.nativeElement);
      if (scrollParentElement == null) return;
      fromEvent(scrollParentElement, 'scroll')
        .pipe(takeWhile(() => this.isDropdownVisible))
        .subscribe(() => {
          this.isDropdownVisible = false;
          this.cdr.markForCheck();
        });
    }

    setTimeout(() => this.cdr.markForCheck());
  }

  private hideDropdown() {
    this.isDropdownVisible = false;
    this.cdr.markForCheck();
  }

  /**
   * listen to date value changes to format the date ISO to a human-readable string to set the input to
   */
  private handleDateFormValueChanges() {
    this.dateForm.valueChanges.subscribe(value => {
      this.onChanged(this.dateTimeParsingService.parseDate(value));
      this.updateInputValue(this.dateTimeFormattingService.formatDateForHumans(value));
      this.hideAndFocus();
      this.cdr.markForCheck();
    });
  }

  private handleInputValueChanges() {
    /**
     * When the input value changes, validate the value and set/reset any validator errors
     */
    this.inputElement && fromEvent<Event>(this.inputElement, 'input')
      .pipe(
        debounceTime(200),
        map(_ => this.getInputValueAsISOString()),
        takeUntil(this.destroy$)
      )
      .subscribe(dateISO => {
        this.onTouched();
        this.updateFormValue(dateISO);
        this.onChanged(dateISO);
      });
  }

  private updateFormValue(dateISO: string) {
    if (new Date(dateISO).toString() !== 'Invalid Date') {
      // If it's valid update the date form
      this.dateForm.setValue(dateISO, { emitEvent: false });
    } else {
      // If the user entered an invalid date pass null to the date form
      this.dateForm.setValue('', { emitEvent: false });
    }
    this.onChanged(dateISO);
    this.cdr.markForCheck();
  }

  private updateInputValue(dateStr: string) {
    if(this.inputElement) this.inputElement.value = dateStr;
  }

  private getInputValueAsISOString(): string {
    const value = this.inputElement?.value.trim();
    if (value === '') this.onChanged(null);

    return this.formatDateAsISOString(value as string) as string;
  }

  private formatDateAsISOString(dateStr: string): string | null {
    try {
      return this.dateTimeParsingService.parseDate(dateStr);
    } catch (e) {
      return dateStr;
    }
  }

  private handleClickOutsideEvents() {
    /*
     * Close the dropdown if the user clicks outside of the input, toggleButton and dropdown
     */
    fromEvent<MouseEvent>(this.document, 'mousedown')
      .pipe(
        map(e => e.target as HTMLElement),
        filter(
          htmlElement =>
            !this.inputElement?.contains(htmlElement) &&
            !this.toggleButtonElRef?.nativeElement.contains(htmlElement) &&
            !this.overlayElement?.contains(htmlElement.closest('.litepicker')) &&
            !this.overlayElement?.contains(htmlElement)
        ),
        takeUntil(this.destroy$)
      )
      .subscribe(_ => this.hideDropdown());
  }

  private handleTabAndEnterEvents() {
    /*
     * Listen to tab events on the toggle dropdown button and hides the dropdown datePicker
     */
    this.toggleButtonElRef && fromEvent<KeyboardEvent>(this.toggleButtonElRef.nativeElement, 'keydown')
      .pipe(
        filter(_ => this.isDropdownVisible),
        filter(event => event.key === 'Tab'),
        takeUntil(this.destroy$)
      )
      .subscribe(() => this.hideDropdown());

    // Listen to enter events on the input and format the date on enter key pressed
    if (this.inputElement) {
      fromEvent<KeyboardEvent>(this.inputElement, 'keydown')
        .pipe(
          debounceTime(200),
          filter(event => event.key === 'Enter'),
          takeUntil(this.destroy$)
        )
        .subscribe(() => {
          if (this.inputElement) {
            this.inputElement.value = this.dateTimeFormattingService.formatDateForHumans(this.dateForm.value) ?? this.inputElement.value;
            this.updateFormValue(this.getInputValueAsISOString());
          }
          this.hideDropdown();
        });
    }
  }

  private handleEscapeEvents() {
    fromEvent<KeyboardEvent>(this.elRef.nativeElement, 'keydown')
      .pipe(
        filter(event => event.key === 'Escape'),
        tap(event => {
          event.preventDefault();
          event.stopPropagation();
        }),
        takeUntil(this.destroy$)
      )
      .subscribe(_ => this.hideAndFocus());
  }

  onTabbedInDatepicker() {
    this.hideDropdown();
  }

  hideAndFocus() {
    this.hideDropdown();
    this.inputElement?.focus();
  }

  onOverlayKeydown(event: KeyboardEvent) {
    if (event.key === 'Escape') this.hideAndFocus();
  }

  positionChanged(positionChange: ConnectedOverlayPositionChange) {
    this.dropdownAltPosition = (positionChange.connectionPair.overlayY === 'bottom');
  }
}
