import { Observable, pipe } from 'rxjs';
import { ActivityId, IRestError, isRestResponse, RestResponse } from '../models/rest-response';
import { map } from 'rxjs/operators';

function extractSuccessfulResponse<T>(response: RestResponse<T>): T | undefined {
  return response.result;
}

function extractErrorResponse(response: RestResponse<any>): IRestError[] | undefined {
  return response.errors;
}

export function handleRestResponse(): <T>(source: Observable<RestResponse<T>>) => Observable<RestResponse<T>> {
  return <T>(source: Observable<RestResponse<T>>) => {
    return new Observable<RestResponse<T>>(subscriber => {
      return source.subscribe({
        next: value => {
          if (value == null) {
            subscriber.error(new Error('Server response was successful but empty!'));
          } else if (value.success) {
            // Check that the result is really a success - if not then convert it to a RestError
            subscriber.next(value);
          } else {
            console.error('Unexpected server response.', value);
            subscriber.error(extractErrorResponse(value));
          }
        },
        error: error => {
          // If it's a response from the server then error.error will be a rest response
          // We don't extract 500 errors as there's nothing for us to do about them, let Sentry deal with them
          if (error.status && error.status < 500 && error.error && isRestResponse(error.error)) {
            subscriber.error(extractErrorResponse(error.error));
          } else {
            subscriber.error(error);
          }
        },
        complete: () => subscriber.complete(),
      });
    });
  };
}

export function extractRestValue(): <T>(source: Observable<RestResponse<T>>) => Observable<T> {
  return pipe(handleRestResponse(), map(it => it.result as any));
}

export function extractActivityId(): <T>(source: Observable<RestResponse<T>>) => Observable<ActivityId> {
  return pipe(handleRestResponse(), map(it => it.activityId));
}

export function isR36Url(url: string, apiRoot: string): boolean {
  return url.startsWith(apiRoot);
}

export const DEBOUNCE_TIME = 60;
