import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[appClickOutside]'
})
export class ClickOutsideDirective {

  @Output() appClickOutside = new EventEmitter<EventTarget|null>();

  constructor(private element: ElementRef) {}

  @HostListener('document:click', ['$event']) onClickOutside(event: MouseEvent) {
    if (!this.element.nativeElement.contains(event.target)) { this.appClickOutside.emit(event.target); }
  }
}
