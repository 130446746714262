<app-field
  fieldClasses="boolean-field"
  [labelOptions]="{ text: label!, classes: labelClasses, for: uid }"
  [showErrorsAboveInput]="false"
  [showErrorsTooltip]="false"
  [control]="$any(control)"
  [attr.aria-controls]="uid"
  (click)="toggle()"
>
  <div class="icon-prefixed-input" [class.toggled]="control.value ?? ''" [class.focused]="focused">
    <i class="icon prefix clickable">
      <fa-icon *ngIf="control.value" [icon]="['fad', 'toggle-on']"></fa-icon>
      <fa-icon *ngIf="!control.value" [icon]="['fad', 'toggle-off']"></fa-icon>
    </i>
    <input
      type="checkbox"
      [attr.title]="inputTitle"
      [name]="uid"
      [id]="uid"
      [formControl]="$any(control)"
      [class.error]="control.invalid && (control.dirty || control.touched)"
      (focus)="focused = true"
      (blur)="focused = false"
    />
    <div class="input boolean-input"><span>{{ control.value ? labelTrue : labelFalse }}</span></div>
    <span
      *ngIf="control.invalid && (control.dirty || control.touched)"
      class="error"
      i18n="@@Validation-Please-enter-a-true-false-value"
    >
      Please enter a true/false value
    </span>
  </div>
</app-field>
