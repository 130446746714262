import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { APP_CONFIG, AppConfig } from '../../../app-config';
import { Observable } from 'rxjs';
import { RestResponse } from '../../models/rest-response';
import { extractRestValue } from '../../services/api-utils';
import { AqlQuery } from '../../aql-module/models/aql/aql-query';

@Injectable({
  providedIn: 'root',
})
export class AllocationService {
  constructor(private http: HttpClient, @Inject(APP_CONFIG) private config: AppConfig) {}

  /**
   * Runs an AQL query and returns the resulting allocation count or a list of errors.
   */
  public testAqlQuery(jobId: string, elementId: string, aqlQuery: AqlQuery): Observable<number> {
    return this.http.post<RestResponse<number>>(`${this.config.apiRoot}/allocation/v1/job/${jobId}/element/${elementId}/test-query`, aqlQuery).pipe(extractRestValue());
  }
}
