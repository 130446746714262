import { NgModule } from '@angular/core';
import { GetUserColourPipe } from './pipes/get-user-colour.pipe';
import { InitialedPipe } from './pipes/initialed.pipe';
import { UserIconsComponent } from './components/user-icons/user-icons.component';
import { GetUsernamePipe } from './pipes/get-username.pipe';
import { SharedModule } from '../shared-module/shared.module';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ReactiveFormsModule } from '@angular/forms';
import { QueryBuilderModule } from '../query-builder-module/query-builder.module';
import { CLIENT_KEYSTORE, USER_KEYSTORE } from './injection-tokens';
import { APP_CONFIG, AppConfig } from '../../app-config';
import { HttpClient } from '@angular/common/http';
import { KeyStoreService } from './services/key-store.service';
import { KeyStoreDataStore } from './services/key-store-data.store';

@NgModule({
  declarations: [GetUserColourPipe, InitialedPipe, GetUsernamePipe, UserIconsComponent],
  imports: [CommonModule, SharedModule, FontAwesomeModule, ReactiveFormsModule, QueryBuilderModule],
  exports: [GetUserColourPipe, InitialedPipe, UserIconsComponent, GetUsernamePipe],
  providers: [
    {
      provide: CLIENT_KEYSTORE,
      useFactory: (config: AppConfig, http: HttpClient) =>
        new KeyStoreService(config, 'config/v1/client-keystore', http, new KeyStoreDataStore()),
      deps: [APP_CONFIG, HttpClient]
    },
    {
      provide: USER_KEYSTORE,
      useFactory: (config: AppConfig, http: HttpClient) =>
        new KeyStoreService(config, 'user/v1/user-client-keystore', http, new KeyStoreDataStore()),
      deps: [APP_CONFIG, HttpClient]
    },
  ]
})
export class UserModule {}
