<nav aria-label="Breadcrumb" class="breadcrumb-nav">
  <ol>
    <li *ngFor="let route of (breadcrumbs$ | async); let last = last" [class.last]="last">
      <!-- disable link of last item -->
      <a class="link" *ngIf="!last" [routerLink]="[route.url]" title="{{route.label | async}}">
        {{ route.label | async }}
      </a>
      <fa-icon *ngIf="!last" class="chevron-right" [icon]="chevronRight"></fa-icon>
      <span class="breadcrumb-current" *ngIf="last">{{ route.label | async }}</span>
    </li>
  </ol>
</nav>
