import { ConnectedPosition } from '@angular/cdk/overlay';

export const fieldDropDownPositions: (offset?: number) => ConnectedPosition[] = (offset = 0) => [
  {
    originX: 'start',
    originY: 'bottom',
    overlayX: 'start',
    overlayY: 'top',
    offsetY: offset ? -offset : undefined,
  },
  {
    originX: 'start',
    originY: 'top',
    overlayX: 'start',
    overlayY: 'bottom',
    offsetY: offset ? offset : undefined,
  },
];

export const getFirstScrollParent = (element: HTMLElement | null): HTMLElement | null => {
  if (element == null) {
    return null;
  }

  if (element.scrollHeight > element.clientHeight) {
    return element;
  } else {
    return getFirstScrollParent(element.parentElement);
  }
};
