import { Component, ElementRef, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged, first, map, shareReplay, startWith, switchMap, take, takeUntil } from 'rxjs/operators';
import { FormValueToAqlQueryService } from '../../services/form-value-to-aql-query.service';
import { QueryKeyStoreService } from '../../services/query-key-store.service';
import { AqlQuery } from '../../../aql-module/models/aql/aql-query';
import { icon } from '@fortawesome/fontawesome-svg-core';
import { faChevronDown, faStar as faStarHollow } from '@fortawesome/pro-regular-svg-icons';
import { faSpinner } from '@fortawesome/pro-duotone-svg-icons';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import { faStar } from '@fortawesome/pro-solid-svg-icons';
import { AqlPriority } from '../../../aql-module/models/aql/aql-priority';
import { AqlQuantity } from '../../../aql-module/models/aql/aql-quantity';
import { AqlCriteria } from '../../../aql-module/models/aql/aql-criteria';
import { Nullable } from '../../../models/typescript-types';
import { AuthService } from '../../../auth-module/services/auth.service';
import { IconObj } from '../../../shared-module/entities/icon-obj';

interface ButtonObj {
  text: string;
  testId: string;
  classes: string;
  disabled?: boolean;
  callback: () => void;
  icon: IconObj;
}

@Component({
  selector: 'app-save-query-template[queryForm]',
  templateUrl: './save-query-template.component.html',
  styleUrl: './save-query-template.component.sass'
})
export class SaveQueryTemplateComponent implements OnInit, OnDestroy {
  @Input({ required: true }) queryForm: FormGroup<{
    priority: FormControl<AqlPriority | null>,
    quantity: FormControl<AqlQuantity | null>,
    where: FormControl<AqlCriteria | null>,
  }> | undefined;

  private readonly destroy$ = new Subject();
  protected readonly dropdownIcon = icon(faChevronDown);
  protected readonly loadingIcon = icon(faSpinner);
  protected showDropList = false;
  private readonly _isUpdating$ = new BehaviorSubject(false);
  protected readonly isUpdating$ = this._isUpdating$.asObservable().pipe(distinctUntilChanged());
  protected mainButton$: Observable<ButtonObj | null> | undefined;
  protected dropdownButton$: Observable<ButtonObj | null> | undefined;
  readonly name = new FormControl<string>('', { nonNullable: true });

  protected formValueAndStatusChanges$!: Observable<AqlQuery | null>;
  protected queryName$!: Observable<Nullable<string>>;
  protected personalQueryName$!: Observable<Nullable<string>>;
  protected sharedQueryName$!: Observable<Nullable<string>>;
  protected nameChanges$!: Observable<Nullable<string>>;

  private readonly triggerIsSavedCheck$ = new Subject<null>();
  private readonly hasUpdateSharedQueryTemplatePerm = this.authService.hasPermission('svc-user.keystore:sharedAllocationQueryTemplates:update');

  @ViewChild('nameInputRef') private nameInputRef: ElementRef | undefined;

  @HostListener('keyup', ['$event'])
  closeDropList(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      event.preventDefault();
      this.showDropList = false;
    }
  }

  constructor(
    private formValueToAqlQueryService: FormValueToAqlQueryService,
    private queryKeyStoreService: QueryKeyStoreService,
    private authService: AuthService,
  ) {}

  ngOnInit() {
    this.formValueAndStatusChanges$ = combineLatest([
      this.queryForm!.valueChanges.pipe(startWith(this.queryForm!.value)),
      this.queryForm!.statusChanges.pipe(startWith(this.queryForm!.status)),
    ]).pipe(
      debounceTime(0), // debounce gives the validation a tick to respond
      map(([query, status]) => {
        if (status !== 'VALID') return null;
        return this.formValueToAqlQueryService.queryFormToAql(query);
      }),
      shareReplay(1),
    );

    this.personalQueryName$ = this.formValueAndStatusChanges$.pipe(
      switchMap(query => query ? this.queryKeyStoreService.getPersonalQueryTemplateName(query) : of(null)),
      distinctUntilChanged(),
      shareReplay(1),
    );

    this.sharedQueryName$ = this.formValueAndStatusChanges$.pipe(
      switchMap(query => query ? this.queryKeyStoreService.getSharedQueryTemplateName(query) : of(null)),
      distinctUntilChanged(),
      shareReplay(1),
    )

    this.queryName$ = combineLatest([this.personalQueryName$, this.sharedQueryName$]).pipe(
      map(([personalQueryName, sharedQueryName]) => personalQueryName ? personalQueryName : sharedQueryName),
      distinctUntilChanged(),
      shareReplay(1),
    )

    // set the initial value for the name
    this.queryName$.pipe(takeUntil(this.destroy$)).subscribe(name => this.name.patchValue(name ?? ''));

    this.nameChanges$ = this.name.valueChanges.pipe(map(it => it.trim().toLowerCase()), startWith(''), shareReplay(1));

    this.mainButton$ = combineLatest([
      this.personalQueryName$,
      this.sharedQueryName$,
      this.nameChanges$,
    ])
      .pipe(
        // debounceTime(0),
        map(([personalQueryName, sharedQueryName, newName]) => {
          const isSolo = (!this.hasUpdateSharedQueryTemplatePerm && sharedQueryName == null);
          const mainPrimaryBtn = 'btn-main primary-btn btn--sm';
          const mainWarningBtn = 'btn-main warning-btn btn--sm';
          const personalIsMatching = personalQueryName?.trim().toLowerCase() === newName;
          const sharedIsMatching = sharedQueryName?.trim().toLowerCase() === newName;

          // personal and shared OR personal
          if (personalQueryName) {
            if (personalIsMatching) {
              // personalIsMatching = remove personal query
              return {
                text: $localize`:@@save-query__save-query-btn--remove:Remove Rule`,
                testId: 'save-query-template-remove-personal-query-btn',
                classes: isSolo ? `${mainPrimaryBtn} --solo` : mainPrimaryBtn,
                icon: {
                  icon: icon(faStar),
                  classes: 'icon icon-yellow left',
                },
                callback: this.removePersonalQueryTemplate,
              };
            } else {
              // personalNOTMatching = rename personal query
              return {
                text: $localize`:@@save-query__save-query-btn--rename:Rename Rule`,
                testId: 'save-query-template-rename-personal-query-btn',
                classes: isSolo ? `${mainWarningBtn} --solo` : mainWarningBtn,
                icon: {
                  icon: icon(faStar),
                  classes: 'icon icon-yellow left',
                },
                callback: this.renamePersonalQueryTemplate,
              };
            }
          } else if (sharedQueryName && this.hasUpdateSharedQueryTemplatePerm) {
            // shared and hasPermission
            if (sharedIsMatching) {
              // sharedIsMatching = remove shared rule
              return {
                text: $localize`:@@save-query__save-shared-query-btn--remove:Remove Shared Rule`,
                testId: 'save-query-template-remove-shared-query-btn',
                classes: isSolo ? `${mainPrimaryBtn} --solo` : mainPrimaryBtn,
                icon: {
                  icon: icon(faStar),
                  classes: 'icon icon-accent left',
                },
                callback: this.removeSharedQueryTemplate,
              };
            } else {
              // sharedNOTMatching = rename shared rule
              return {
                text: $localize`:@@save-query-template__rename-shared-query-btn--text:Rename Shared Rule`,
                testId: 'save-query-template-rename-shared-query-btn',
                classes: isSolo ? `${mainWarningBtn} --solo` : mainWarningBtn,
                icon: {
                  icon: icon(faStar),
                  classes: 'icon icon-accent left',
                },
                callback: this.renameSharedQueryTemplate,
              };
            }
          } else if (sharedQueryName) {
            // is shared rule
            return {
              text: $localize`:@@save-query__save-shared-query-btn--saved:Shared Rule`,
              testId: 'save-query-template-shared-query-btn',
              classes:  isSolo ? `${mainPrimaryBtn} --solo` : mainPrimaryBtn,
              disabled: true,
              icon: {
                icon: icon(faStar),
                classes: 'icon icon-accent left',
              },
              callback: () => {},
            };
          } else {
            return {
              text: $localize`:@@save-query__save-query-btn--save:Save Rule`,
              testId: 'save-query-template-save-personal-query-btn',
              classes: isSolo ? `${mainPrimaryBtn} --solo` : mainPrimaryBtn,
              disabled: false,
              icon: {
                icon: icon(faStarHollow),
                classes: 'icon icon-yellow left',
              },
              callback: this.savePersonalQueryTemplate,
            };
          }
        }),
      );

    this.dropdownButton$ = combineLatest([
      this.personalQueryName$,
      this.sharedQueryName$,
      this.nameChanges$,
    ])
      .pipe(
        // debounceTime(0),
        map(([personalQueryName, sharedQueryName, newName]) => {
          const isMatching = sharedQueryName?.trim().toLowerCase() === newName;

          // personal and shared and hasPermission
          if (personalQueryName && sharedQueryName && this.hasUpdateSharedQueryTemplatePerm) {
            if (isMatching) {
              // matching = remove shared rule
              return {
                text: $localize`:@@save-query-template__remove-shared-query-btn--text:Remove Shared Rule`,
                testId: 'save-query-template-remove-shared-query-btn',
                classes: 'primary-btn btn--sm',
                icon: {
                  icon: icon(faStar),
                  classes: 'icon icon-accent left',
                },
                callback: this.removeSharedQueryTemplate,
              };
            } else {
              // mismatch = rename shared rule
              return {
                text: $localize`:@@save-query-template__rename-shared-query-btn--text:Rename Shared Rule`,
                testId: 'save-query-template-rename-shared-query-btn',
                classes: 'warning-btn btn--sm',
                icon: {
                  icon: icon(faStar),
                  classes: 'icon icon-accent left',
                },
                callback: this.renameSharedQueryTemplate,
              };
            }
          } else if (personalQueryName && sharedQueryName) {
            // personal and shared and NO Permission = is shared rule
            return {
              text: $localize`:@@save-query__save-shared-query-btn--saved:Shared Rule`,
              testId: 'save-query-template-shared-query-btn',
              classes: 'primary-btn btn--sm',
              disabled: true,
              icon: {
                icon: icon(faStar),
                classes: 'icon icon-accent left',
              },
              callback: () => {},
            };
          } else if (sharedQueryName == null && this.hasUpdateSharedQueryTemplatePerm) {
            // if personal name and HAS permission = save shared rule
            return {
              text: $localize`:@@save-query__save-shared-query-btn--save:Save to Shared Rules`,
              testId: 'save-query-template-save-shared-query-btn',
              classes: 'primary-btn btn--sm',
              disabled: false,
              icon: {
                icon: icon(faStarHollow),
                classes: 'icon icon-accent left',
              },
              callback: this.saveSharedQueryTemplate,
            };
          } else if (personalQueryName == null && sharedQueryName) {
            // no personal name
            return {
              text: $localize`:@@save-query__save-query-btn--save:Save Rule`,
              testId: 'save-query-template-save-personal-query-btn',
              classes: 'primary-btn btn--sm',
              disabled: false,
              icon: {
                icon: icon(faStarHollow),
                classes: 'icon icon-yellow left',
              },
              callback: this.savePersonalQueryTemplate,
            };
          } else {
            // otherwise there is no permission or shared rule so don't show shared rule
            return null;
          }
        }),
      );
  }

  private readonly getFormNameOrFocusField = (): string | null => {
    // if query is invalid return early
    if (this.queryForm == null || this.queryForm.invalid) return null;

    // if name field is empty - focus the name field as the name is required to save the query
    const name = this.name.value.trim();
    if (name == null || name === '') {
      this.nameInputRef?.nativeElement.focus();
      return null;
    }

    return name;
  }

  private readonly updateQueryTemplate = (queryNameObs$: Observable<Nullable<string>>, callback: (oldName: Nullable<string>) => Observable<any>) => {
    // set favourite query icon to spinner
    this._isUpdating$.next(true);
    // Saved rule
    queryNameObs$
      .pipe(
        take(1),
        switchMap(oldName => callback(oldName))
      )
      .pipe(first())
      .subscribe(_ => {
        this._isUpdating$.next(false);
        this.triggerIsSavedCheck$.next(null);
      });
  }

  readonly savePersonalQueryTemplate = () => {
    const name = this.getFormNameOrFocusField();
    const query: AqlQuery = this.formValueToAqlQueryService.queryFormToAql(this.queryForm!.value);
    // if any of the params are nullish, return early
    if (name == null || query == null) return;
    this.updateQueryTemplate(this.personalQueryName$, () => this.queryKeyStoreService.savePersonalQueryTemplate(name, query))
  }

  readonly renamePersonalQueryTemplate = () => {
    const name = this.getFormNameOrFocusField();
    // if name is nullish return early
    if (name == null) return;
    this.updateQueryTemplate(this.personalQueryName$, (oldName: Nullable<string>) => this.queryKeyStoreService.renamePersonalQueryTemplate(oldName, name))
  }

  readonly removePersonalQueryTemplate = () => {
    this.updateQueryTemplate(this.personalQueryName$, (oldName: Nullable<string>) => this.queryKeyStoreService.removePersonalQueryTemplate(oldName))
  }

  readonly saveSharedQueryTemplate = () => {
    const name = this.getFormNameOrFocusField();
    const query: AqlQuery = this.formValueToAqlQueryService.queryFormToAql(this.queryForm!.value);
    // if any of the params are nullish, return early
    if (name == null || query == null) return;
    this.updateQueryTemplate(this.sharedQueryName$,() => this.queryKeyStoreService.saveSharedQueryTemplate(name, query))
  }

  readonly renameSharedQueryTemplate = () => {
    const name = this.getFormNameOrFocusField();
    // if name is nullish return early
    if (name == null) return;
    this.updateQueryTemplate(this.sharedQueryName$,(oldName: Nullable<string>) => this.queryKeyStoreService.renameSharedQueryTemplate(oldName, name))
  }

  readonly removeSharedQueryTemplate = () => {
    this.updateQueryTemplate(this.sharedQueryName$,(oldName: Nullable<string>) => this.queryKeyStoreService.removeSharedQueryTemplate(oldName))
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

}
