import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faXmarkLarge } from '@fortawesome/pro-regular-svg-icons';
import { Notification } from '../../types';

@Component({
  selector: 'app-notification-bar',
  templateUrl: './notification-bar.component.html',
  styleUrls: ['./notification-bar.component.sass'],
})
export class NotificationBarComponent {
  closeNotificationIcon = faXmarkLarge;
  @Input() notification: Notification | undefined;
  @Output() onClose = new EventEmitter<Notification>();
  @Output() onHandleAction = new EventEmitter<Notification>();
}
