import { Validator, ValidatorType } from '../validator';

export interface EnumValidator extends Validator {
  type: ValidatorType.ENUM;
  options: EnumValidatorOption[];
  caseSensitive: boolean;
  relaxed: boolean;
  skipValidation: boolean;
  defaultColour: string | null;
}

export function isEnumValidator(validator: Validator): validator is EnumValidator {
  return validator?.type === ValidatorType.ENUM;
}

export interface EnumValidatorOption {
  option: string;
  colour: string | null;
}
