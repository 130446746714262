import { IconSelectOption } from '../../shared-module/components/icon-select/icon-select.component';
import { AttributeType } from '../../attribute-module/models/attribute';
import { AqlOperator } from 'src/app/aql-module/models/aql/aql-operator';
import { AqlFunctionTypes, AqlMathOperators } from '../../aql-module/models/aql/aql-value';
import { Schema } from '../../attribute-module/models/schema';
import { hasDateValidator } from '../../attribute-module/models/validator/date-validator';
import {
  faCircle,
  faCircleDot,
  faEmptySet,
  faEquals,
  faGreaterThan,
  faGreaterThanEqual,
  faIntersection,
  faLessThan,
  faLessThanEqual,
  faNotEqual,
  faUnion
} from '@fortawesome/pro-regular-svg-icons';
import { icon } from '@fortawesome/fontawesome-svg-core';
import { Option } from '../../shared-module/entities/form-entities';
import { isArrayAttributeType } from '../../attribute-module/utils/attribute-type-utils';

const ICON_SELECTS = {
  EQ: {
    value: AqlOperator.EQ,
    label: $localize`:@@query-builder-modal__icon-select-text--equal:Equal`,
    icon: icon(faEquals)
  },
  GT: {
    value: AqlOperator.GT,
    label: $localize`:@@query-builder-modal__icon-select-text--greater-than:Greater than`,
    icon: icon(faGreaterThan),
  },
  GTEQ: {
    value: AqlOperator.GTEQ,
    label: $localize`:@@query-builder-modal__icon-select-text--greater-or-equal:Greater than or equal`,
    icon: icon(faGreaterThanEqual),
  },
  LT: {
    value: AqlOperator.LT,
    label: $localize`:@@query-builder-modal__icon-select-text--less-than:Less than`,
    icon: icon(faLessThan),
  },
  LTEQ: {
    value: AqlOperator.LTEQ,
    label: $localize`:@@query-builder-modal__icon-select-text--less-than-or-equal:Less than or equal`,
    icon: icon(faLessThanEqual),
    appliesTo: (schema: Schema) => (schema.type === AttributeType.NUMBER || hasDateValidator(schema)) ?? false
  },
  NEQ: {
    value: AqlOperator.NEQ,
    label: $localize`:@@query-builder-modal__icon-select-text--not-equal:Not equal`,
    icon: icon(faNotEqual),
  },
  IN: {
    value: AqlOperator.IN,
    label: $localize`:@@query-builder-modal__icon-select-text--one-of:One of`,
    icon: icon(faIntersection),
  },
  NIN: {
    value: AqlOperator.NIN,
    label: $localize`:@@query-builder-modal__icon-select-text--not-one-of:Not one of`,
    icon: icon(faEmptySet),
  },
  EXISTS: {
    value: AqlOperator.EXISTS,
    label: $localize`:@@query-builder-modal__icon-select-text--exists:Is not blank`,
    icon: icon(faCircleDot),
  },
  NEXISTS: {
    value: AqlOperator.NEXISTS,
    label: $localize`:@@query-builder-modal__icon-select-text--not-exists:Is blank`,
    icon: icon(faCircle),
  },
  EXISTS_ARRAY: {
    value: AqlOperator.EXISTS,
    label: $localize`:@@query-builder-modal__icon-select-text--not-empty:Is not empty`,
    icon: icon(faCircleDot),
  },
  NEXISTS_ARRAY: {
    value: AqlOperator.NEXISTS,
    label: $localize`:@@query-builder-modal__icon-select-text--empty:Is empty`,
    icon: icon(faCircle),
  },
  CONTAINS_ALL: {
    value: AqlOperator.CONTAINS_ALL,
    label: $localize`:@@query-builder-modal__icon-select-text--contains-all-of:Contains all of`,
    icon: icon(faUnion)
  },
  CONTAINS_NONE: {
    value: AqlOperator.CONTAINS_NONE,
    label: $localize`:@@query-builder-modal__icon-select-text--contains-none-of:Contains none of`,
    icon: icon(faEmptySet)
  },
  CONTAINS_ANY: {
    value: AqlOperator.CONTAINS_ANY,
    label: $localize`:@@query-builder-modal__icon-select-text--contains-any-of:Contains any of`,
    icon: icon(faIntersection)
  },
};

export function getAqlOperatorsForSchema(schema: Schema): AqlOperator[] {
  if (schema.type == AttributeType.NUMBER){ // Special case for dates
    return [AqlOperator.EQ, AqlOperator.NEQ, AqlOperator.GT, AqlOperator.GTEQ, AqlOperator.LT, AqlOperator.LTEQ, AqlOperator.EXISTS, AqlOperator.NEXISTS];
  }
  if (schema.type == AttributeType.STRING){
    if(hasDateValidator(schema)){
      return [AqlOperator.EQ, AqlOperator.NEQ, AqlOperator.GT, AqlOperator.GTEQ, AqlOperator.LT, AqlOperator.LTEQ, AqlOperator.IN, AqlOperator.NIN, AqlOperator.EXISTS, AqlOperator.NEXISTS];
    }
    return [AqlOperator.EQ, AqlOperator.NEQ, AqlOperator.IN, AqlOperator.NIN, AqlOperator.EXISTS, AqlOperator.NEXISTS];
  }
  if (schema.type == AttributeType.BOOLEAN){
    return [AqlOperator.EQ, AqlOperator.NEQ, AqlOperator.EXISTS, AqlOperator.NEXISTS];
  }
  if(isArrayAttributeType(schema.type)){
    return [AqlOperator.CONTAINS_ANY, AqlOperator.CONTAINS_ALL, AqlOperator.CONTAINS_NONE, AqlOperator.EXISTS, AqlOperator.NEXISTS];
  }
  throw Error(`Unknown type ${schema.type}.`);
}

export function getIconSelectsForSchema(schema: Schema): IconSelectOption[] {
  return getAqlOperatorsForSchema(schema).map(operator => {
    if(isArrayAttributeType(schema.type)) {
      if (operator === AqlOperator.EXISTS) return ICON_SELECTS['EXISTS_ARRAY'];
      if (operator === AqlOperator.NEXISTS) return ICON_SELECTS['NEXISTS_ARRAY'];
    }
    const option = ICON_SELECTS[operator];
    if (option == null) throw Error(`Unknown operator ${operator}.`);
    return option
  })
}

function getTranslationForMathOperator(operator: AqlMathOperators): string {
    switch(operator) {
      case 'multiply': return $localize`:@@query-builder-modal__pill-button-text--multiply:multiply`;
      case 'divide': return $localize`:@@query-builder-modal__pill-button-text--divide:divide`;
      case 'subtract': return $localize`:@@query-builder-modal__pill-button-text--subtract:subtract`;
      case 'add': return $localize`:@@query-builder-modal__pill-button-text--add:add`;
      default:
        throw Error(`Unknown math operator '${operator}'.`);
    }
}

function getTranslationForFunction(fn: AqlFunctionTypes): string {
    switch(fn) {
      case AqlFunctionTypes.GROUP: return $localize`:@@query-builder-modal__pill-button-text--group:group`;
      case AqlFunctionTypes.ROUNDDOWN: return $localize`:@@query-builder-modal__pill-button-text--rounddown:round down`;
      case AqlFunctionTypes.ROUNDUP: return $localize`:@@query-builder-modal__pill-button-text--roundup:round up`;
      case AqlFunctionTypes.ROUND: return $localize`:@@query-builder-modal__pill-button-text--round:round`;
      default:
        throw Error(`Unknown function '${fn}'.`);
    }
}

export const MATH_OPTIONS: Option[] = Object.values(AqlMathOperators).map(it => ({ value: it, label: getTranslationForMathOperator(it) }));
export const FUNCTION_OPTIONS: Option[] = Object.values(AqlFunctionTypes).map(it => ({ value: it, label: getTranslationForFunction(it) }));
