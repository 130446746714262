import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { FullscreenOverlayContainer, OverlayContainer, OverlayModule } from '@angular/cdk/overlay';
import { ReactiveFormsModule } from '@angular/forms';
import { AuthenticationInterceptorService } from './services/authentication-interceptor.service';
import { AddHeadersInterceptorService } from './services/add-headers-interceptor.service';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { AppFAModule } from './app-f-a.module';
import { SharedModule } from './shared-module/shared.module';
import { RootStoreModule } from './root-store';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { ErrorPageComponent } from './pages/error-page/error-page.component';
import { PortalModule } from '@angular/cdk/portal';
import { extModules } from '../build-specifics';
import { AuditModule } from './audit-module/audit.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { TasksModule } from './tasks-module/tasks.module';
import { A11yModule } from '@angular/cdk/a11y';
import { NotificationsModule } from './notifications-module/notifications.module';
import { ConfigService } from './services/config.service';
import { Observable } from 'rxjs';
import { Config } from './models/config';
import { AuthModule } from './auth-module/auth.module';
import { SidePanelComponent } from './components/side-panel/side-panel.component';
import { WrapperPageComponent } from './pages/wrapper-page/wrapper-page.component';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import * as Sentry from '@sentry/angular';
import { Router } from '@angular/router';
import { ErrorHandlerService } from './services/error-handler.service';
import { ErrorModalComponent } from './components/error-modal/error-modal.component';
import { SENTRY } from './injection-tokens';
import { MarkdownModule } from 'ngx-markdown';

@NgModule({
  declarations: [AppComponent, NavbarComponent, BreadcrumbComponent, SidebarComponent, ErrorPageComponent, SidePanelComponent, WrapperPageComponent, LoginPageComponent, ErrorModalComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    ReactiveFormsModule,
    AppRoutingModule,
    AppFAModule,
    OverlayModule,
    RootStoreModule,
    // Instrumentation must be imported after importing StoreModule
    extModules,
    PortalModule,
    AuditModule,
    ScrollingModule,
    TasksModule,
    A11yModule,
    NotificationsModule,
    AuthModule,
    MarkdownModule.forRoot(),
  ], providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticationInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AddHeadersInterceptorService,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: ErrorHandlerService,
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
      },
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (configService: ConfigService) => (): Observable<Config> => configService.initialise(),
      deps: [ConfigService],
      multi: true,
    },
    { provide: OverlayContainer, useClass: FullscreenOverlayContainer },
    provideHttpClient(withInterceptorsFromDi()),
    { provide: SENTRY, useValue: Sentry }
  ],
})
export class AppModule {
}
