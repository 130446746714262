import { Injectable } from '@angular/core';
import { IValidatorService } from './validator.service';
import { EnumValidator } from '../models/validator/enum-validator';
import { AttributeValue } from '../models/attribute';
import { SanitizeService } from './sanitize.service';
import { ValidatorType } from '../models/validator';

@Injectable({
  providedIn: 'root',
})
export class EnumValidatorService implements IValidatorService {
  constructor(private sanitizePunctuationService: SanitizeService) {}

  validate(displayName: string, validator: EnumValidator, value: AttributeValue): { [key: string]: any | null } {
    if (!value) return {}; // Short circuit if the value is missing

    let valueToProcess = value;
    if (typeof valueToProcess !== 'string') {
      valueToProcess = valueToProcess.toString();
    }

    if (validator.skipValidation) return {};

    const options = validator.options;
    let optionsList: string[];
    optionsList = options.map(it => it.option);

    if (validator.caseSensitive === false && validator.relaxed) {
      valueToProcess = this.sanitizePunctuationService.sanitize(valueToProcess.toLowerCase());
      optionsList = optionsList.map(it => this.sanitizePunctuationService.sanitize(it.toLowerCase()));
    } else if (validator.caseSensitive === false) {
      valueToProcess = valueToProcess.toLowerCase();
      optionsList = optionsList.map(it => it.toLowerCase());
    } else if (validator.relaxed) {
      valueToProcess = this.sanitizePunctuationService.sanitize(valueToProcess);
      optionsList = optionsList.map(it => this.sanitizePunctuationService.sanitize(it));
    }

    if (!optionsList.includes(valueToProcess)) {
      return { [ValidatorType.ENUM]: { value, message: $localize`:@@enum-validator__invalid:${displayName}:displayName: must be one the available options.` } };
    } else {
      return {};
    }
  }
}
