import { Injectable } from '@angular/core';
import { IValidatorService } from './validator.service';
import { AttributeValue } from '../models/attribute';
import { ValidatorType } from '../models/validator';
import { EmailValidator } from '../models/validator/email-validator';

@Injectable({
  providedIn: 'root'
})
export class EmailValidatorService implements IValidatorService {

  private static readonly EMAIL_REGEXP = /^(?=.{1,254}$)(?=.{1,64}@)[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

  validate(displayName: string, validator: EmailValidator, value: AttributeValue): { [p: string]: any } {
    if (!value) return {}; // Short circuit if the value is missing

    if (typeof value !== 'string') {
      return { [ValidatorType.EMAIL]: { value, message: $localize`:@@email-validator__invalid--not-a-string:${displayName}:displayName: must be a string.`}};
    }
    if (!EmailValidatorService.EMAIL_REGEXP.test(value)){
      return { [ValidatorType.EMAIL]: { value, message: $localize`:@@email-validator__invalid--not-an-email:${displayName}:displayName: must be a valid email address.`}};
    }

    return {};
  }
}
