import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { AnimationProp } from '@fortawesome/angular-fontawesome/shared/models/props.model';

export enum IconEnum {
  ARROW_DOWN = 'arrow-down',
  ARROW_LEFT = 'arrow-left',
  ARROW_LEFT_SQUARE = 'arrow-left-square',
  ARROW_RIGHT = 'arrow-right',
  ARROW_RIGHT_CIRCLE = 'arrow-right-circle',
  ARROW_RIGHT_SQUARE = 'arrow-right-square',
  ARROW_UP = 'arrow-up',
  CANCEL = 'cancel',
  CHEVRON_CIRCLE_DOWN = 'chevron-circle-down',
  CHEVRON_CIRCLE_UP = 'chevron-circle-up',
  CHEVRON_DOUBLE_LEFT = 'chevron-double-left',
  CHEVRON_DOUBLE_RIGHT = 'chevron-double-right',
  CHEVRON_DOWN = 'chevron-down',
  CHEVRON_LEFT = 'chevron-left',
  CHEVRON_RIGHT = 'chevron-right',
  CLONE = 'clone',
  COLUMNS = 'columns',
  COPY = 'copy',
  DANGER = 'danger',
  DELETE = 'delete',
  DOLLAR_SIGN = 'dollar-sign',
  DOLLY = 'dolly',
  DOWNLOAD = 'download',
  EDIT = 'edit',
  ELEMENT = 'ticket-alt',
  ELLIPSIS_H = 'ellipsis-h',
  FILE_IMPORT = 'file-import',
  FILE = 'file',
  FILE_UPLOAD = 'file-upload',
  FLUSH = 'flush',
  HIDE = 'hide',
  HISTORY = 'history',
  INDUSTRY = 'industry',
  JOB = 'suitcase',
  LOCK = 'lock',
  MINUS_CIRCLE = 'minus-circle',
  PASTE = 'paste',
  PEN = 'pen',
  PLUS = 'plus',
  PLUS_CIRCLE = 'plus-circle',
  PORTAL_EXIT = 'portal-exit',
  RANDOM = 'random',
  SAVE = 'save',
  SEARCH = 'search',
  SITE = 'store',
  SORT_DUO = 'sort',
  SORT_DUO_DOWN = 'sort-down',
  SORT_DUO_UP = 'sort-up',
  SPINNER = 'spinner',
  STAR = 'star',
  STAR_HOLLOW = 'star-hollow',
  SUCCESS = 'success',
  SYNC = 'sync',
  TIMES = 'times',
  UNDO = 'undo',
  UNKNOWN = 'question',
  USER_COG = 'user-cog',
  WARNING = 'warning',
}

@Component({
  selector: 'app-icon[icon]',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconComponent {

  private static readonly ICON_SETTINGS_MAP: { [key: string]: Partial<IconSettings> } = {
    [IconEnum.ARROW_RIGHT_CIRCLE]: { iconProp: ['far', 'arrow-alt-circle-right'], isDuo: false },
    [IconEnum.ARROW_DOWN]: { iconProp: ['far', 'arrow-down'], isDuo: false },
    [IconEnum.ARROW_LEFT]: { iconProp: ['far', 'arrow-left'], isDuo: false },
    [IconEnum.ARROW_RIGHT]: { iconProp: ['far', 'arrow-right'], isDuo: false },
    [IconEnum.ARROW_RIGHT_SQUARE]: { iconProp: ['fas', 'arrow-square-right'], isDuo: false },
    [IconEnum.ARROW_LEFT_SQUARE]: { iconProp: ['fas', 'arrow-square-left'], isDuo: false },
    [IconEnum.ARROW_UP]: { iconProp: ['far', 'arrow-up'], isDuo: false },
    [IconEnum.CANCEL]: { iconProp: ['fas', 'times-circle'], isDuo: false },
    [IconEnum.CHEVRON_CIRCLE_DOWN]: { iconProp: ['far', 'chevron-circle-down'], isDuo: false },
    [IconEnum.CHEVRON_CIRCLE_UP]: { iconProp: ['far', 'chevron-circle-up'], isDuo: false },
    [IconEnum.CHEVRON_DOUBLE_LEFT]: { iconProp: ['far', 'chevron-double-left'], isDuo: false },
    [IconEnum.CHEVRON_DOUBLE_RIGHT]: { iconProp: ['far', 'chevron-double-right'], isDuo: false },
    [IconEnum.CHEVRON_DOWN]: { iconProp: ['far', 'chevron-down'], isDuo: false },
    [IconEnum.CHEVRON_LEFT]: { iconProp: ['far', 'chevron-left'], isDuo: false },
    [IconEnum.CHEVRON_RIGHT]: { iconProp: ['far', 'chevron-right'], isDuo: false },
    [IconEnum.CLONE]: { iconProp: ['far', 'clone'], isDuo: false },
    [IconEnum.COPY]: { iconProp: ['far', 'copy'], isDuo: false },
    [IconEnum.COLUMNS]: { iconProp: ['far', 'bars'], isDuo: false, rotate: 90 },
    [IconEnum.DANGER]: { iconProp: ['fas', 'exclamation-triangle'], isDuo: false },
    [IconEnum.DELETE]: { iconProp: ['far', 'trash-alt'], isDuo: false },
    [IconEnum.DOLLY]: { iconProp: ['far', 'dolly'], isDuo: false },
    [IconEnum.DOWNLOAD]: { iconProp: ['fad', 'download'], isDuo: false },
    [IconEnum.EDIT]: { iconProp: ['far', 'edit'], isDuo: false },
    [IconEnum.ELLIPSIS_H]: { iconProp: ['far', 'ellipsis-h'], isDuo: false },
    [IconEnum.FLUSH]: { iconProp: ['fad', 'shredder'], isDuo: false },
    [IconEnum.FILE]: { iconProp: ['far', 'file'], isDuo: false },
    [IconEnum.FILE_UPLOAD]: { iconProp: ['far', 'file-upload'], isDuo: false },
    [IconEnum.FILE_IMPORT]: { iconProp: ['far', 'file-import'], isDuo: false },
    [IconEnum.HIDE]: { iconProp: ['far', 'eye-slash'], isDuo: false },
    [IconEnum.HISTORY]: { iconProp: ['far', 'history'], isDuo: false },
    [IconEnum.INDUSTRY]: { iconProp: ['far', 'industry-alt'], isDuo: false },
    [IconEnum.LOCK]: { iconProp: ['far', 'lock-alt'], isDuo: false },
    [IconEnum.MINUS_CIRCLE]: { iconProp: ['far', 'minus-circle'], isDuo: false },
    [IconEnum.PASTE]: { iconProp: ['far', 'paste'], isDuo: false },
    [IconEnum.PEN]: { iconProp: ['far', 'pen'], isDuo: false },
    [IconEnum.PLUS]: { iconProp: ['far', 'plus'], isDuo: false },
    [IconEnum.PLUS_CIRCLE]: { iconProp: ['far', 'plus-circle'], isDuo: false },
    [IconEnum.PORTAL_EXIT]: { iconProp: ['far', 'portal-exit'], isDuo: false },
    [IconEnum.RANDOM]: { iconProp: ['far', 'random'], isDuo: false },
    [IconEnum.SAVE]: { iconProp: ['far', 'save'], isDuo: false },
    [IconEnum.SEARCH]: { iconProp: ['far', 'search'], isDuo: false },
    [IconEnum.SORT_DUO]: { iconProp: ['far', 'sort'], isDuo: false },
    [IconEnum.SORT_DUO_UP]: { iconProp: ['fad', 'sort-up'], isDuo: true },
    [IconEnum.SORT_DUO_DOWN]: { iconProp: ['fad', 'sort-down'], isDuo: true },
    [IconEnum.SPINNER]: { iconProp: ['fad', 'spinner'], isDuo: true },
    [IconEnum.STAR]: { iconProp: ['fas', 'star'], isDuo: false },
    [IconEnum.STAR_HOLLOW]: { iconProp: ['far', 'star'], isDuo: false },
    [IconEnum.SITE]: { iconProp: ['far', 'store'], isDuo: false },
    [IconEnum.SYNC]: { iconProp: ['fad', 'sync-alt'], isDuo: true },
    [IconEnum.SUCCESS]: { iconProp: ['fas', 'check-circle'], isDuo: false },
    [IconEnum.USER_COG]: { iconProp: ['far', 'user-cog'], isDuo: false },
    [IconEnum.UNDO]: { iconProp: ['far', 'undo'], isDuo: false },
    [IconEnum.UNKNOWN]: { iconProp: ['far', 'question'], isDuo: false },
    [IconEnum.WARNING]: { iconProp: ['fas', 'exclamation-circle'], isDuo: false },
    [IconEnum.JOB]: { iconProp: ['fas', 'suitcase'], isDuo: false },
    [IconEnum.ELEMENT]: { iconProp: ['fas', 'ticket-alt'], isDuo: false },
    [IconEnum.DOLLAR_SIGN]: { iconProp: ['far', 'dollar-sign'], isDuo: false },
  };

  icon$ = new BehaviorSubject<IconSettings>({
    iconProp: ['fas', 'check-circle'],
    isDuo: false,
    rotate: undefined,
  });

  @Input() set animation(animation: AnimationProp) {
    this.update({ animation });
  }

  @Input() set rotate(rotate: 90 | 180 | 270 | 0 | undefined) {
    this.update({ rotate: rotate === 0 ? undefined : rotate });
  }

  @Input() set icon(icon: IconEnum | string) {
    if (icon === null) return;
    const settings = IconComponent.ICON_SETTINGS_MAP[icon];
    if (settings == null) throw Error(`Unknown IconEnum: '${ icon }'.`);
    this.update(settings);
  }

  private update(iconSettings: Partial<IconSettings>) {
    this.icon$.next({ ...this.icon$.value, ...iconSettings });
  }

}

interface IconSettings {
  iconProp: IconProp;
  isDuo: boolean;
  animation?: AnimationProp;
  rotate: undefined | 90 | 180 | 270;
}
