import { AqlBooleanValue, AqlNumericValue, AqlSimpleValue, AqlStringValue } from '../models/aql/aql-value';
import { AttributeType } from '../../attribute-module/models/attribute';
import { Injectable } from '@angular/core';
import { getNestedType, isArrayAttributeType } from '../../attribute-module/utils/attribute-type-utils';

type FormValue = string | number | boolean;

@Injectable( { providedIn: 'root' } )
export class AqlTypeConverterService {

  // Form Values to Form Values
  formValueToFormValue(type: AttributeType, value: FormValue | FormValue[]): FormValue | null  {
    if (type === AttributeType.STRING && typeof value === 'string') {
      return value;
    }
    if (type === AttributeType.NUMBER && typeof value === 'number') {
      return value;
    }
    if (type === AttributeType.BOOLEAN && typeof value === 'boolean') {
      return value;
    }
    return null;
  }

  formValueToFormValueArray(type: AttributeType, value: FormValue | FormValue[]): FormValue[] {
    if (Array.isArray(value)) {
      if(isArrayAttributeType(type)){
        type = getNestedType(type);
      }
      return value.map(it => this.formValueToFormValue(type, it)).filter(it => it != null) as FormValue[];
    }
    return [];
  }

  // AQL Values to Form Values
  aqlValueToFormValueArray(type: AttributeType, aqlValue: AqlSimpleValue[]) {
    if (Array.isArray(aqlValue)) {
      if(isArrayAttributeType(type)){
        type = getNestedType(type);
      }
      return aqlValue.map(it => {
        return this.aqlValueToFormValue(type, it);
      }).filter(it => it != null);
    }
    return [];
  }

  aqlValueToFormValue(type: AttributeType, aqlValue: AqlSimpleValue | null): FormValue | null {
    if (aqlValue === null || aqlValue.value === null) {
      return null;
    }
    return this.formValueToFormValue(type, aqlValue.value);
  }

  // Form values to AQL Values
  formValueToAqlSimpleValue(type: AttributeType, value: FormValue | FormValue[]): AqlSimpleValue | null {
    if (type === AttributeType.STRING && typeof value === 'string') {
      return { type: 'string', value } as AqlStringValue;
    }
    if (type === AttributeType.NUMBER && typeof value === 'number') {
      return { type: 'numeric', value } as AqlNumericValue;
    }
    if (type === AttributeType.BOOLEAN && typeof value === 'boolean') {
      return { type: 'boolean', value } as AqlBooleanValue;
    }
    return null;
  }

  formValueToAqlSimpleValueArray(type: AttributeType, value: FormValue | FormValue[]): AqlSimpleValue[] {
    if ( Array.isArray(value)) {
      if(isArrayAttributeType(type)){
        type = getNestedType(type);
      }
      return value.map(it => this.formValueToAqlSimpleValue(type, it)).filter(x => x != null) as AqlSimpleValue[];
    }
    return [];
  }
}
