import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { DateTime, FixedOffsetZone } from 'luxon';
import { Chrono, en, nl, ParsedComponents } from 'chrono-node';

export class DateFormatError extends Error {
  constructor(message: string | undefined) {
    super(message);
    this.name = 'DateFormatError';
  }
}

@Injectable({
  providedIn: 'root',
})
export class DateTimeParsingService {
  private chrono: Chrono;

  constructor(@Inject(LOCALE_ID) private locale: string) {
    switch (locale) {
      case 'nl':
        this.chrono = nl.casual;
        break;
      case 'en-GB':
        this.chrono = new Chrono(en.configuration.createCasualConfiguration(true));
        break;
      default:
        // Yank format
        this.chrono = en.casual;
    }
  }

  private static toDateTime(components: ParsedComponents): DateTime {
    return DateTime.fromObject({
      year: components.get('year') ?? undefined,
      month: components.get('month') ?? undefined,
      day: components.get('day') ?? undefined,
      hour: components.isCertain('hour') ?? undefined ? components.get('hour') ?? undefined : 0,
      minute: components.isCertain('minute') ?? undefined ? components.get('minute') ?? undefined : 0,
      second: components.isCertain('second') ?? undefined ? components.get('second') ?? undefined : 0,
      millisecond: components.isCertain('millisecond') ?? undefined ? components.get('millisecond') ?? undefined : 0,
    }, {
      zone: components.isCertain('timezoneOffset') ? FixedOffsetZone.instance(components.get('timezoneOffset') as number) : undefined
    });
  }

  /**
   * Accepts a datetime entered by a human and attempts to return it as an ISO8601 formatted string (without any timezone information).
   */
  parseLocalDateTime(value: string): string {
    if (!value) throw new DateFormatError(`Cannot parse local date/time of type: ${typeof value}.`);

    // If it's already in ISO format and is a local-date-time return it as is
    const parsedFromISO = DateTime.fromISO(value, { locale: this.locale });
    if (parsedFromISO.isValid) return parsedFromISO.toLocal().toISO({ includeOffset: false });

    const result = this.chronoParse(value);
    if (result.isCertain('timezoneOffset')) {
      throw new DateFormatError($localize`:@@datetime-validator__invalid--not-a-valid-datetime-has-timezone:Unable to parse '${value}:value:' as a local date/time (found a timezone).`);
    }

    const datetime = DateTimeParsingService.toDateTime(result).toISO({ includeOffset: false }); // Get the datetime
    // Check if the iso returned datetime contains a time
    if (!datetime || datetime.split('T').length <= 1) {
      throw new DateFormatError(`Unable to parse '${value}' as a date/time (uncertain time).`);
    }
    return datetime;
  }

  /**
   * Accepts a datetime entered by a human and attempts to return it as an ISO8601 formatted string with a timezone
   * Otherwise it will throw an error
   */
  parseDateTime(value: string): string {
    if (!value) throw new DateFormatError(`Cannot parse date/time of type: ${typeof value}.`);

    // If it's already in ISO format and is not a local-date-time return it as is
    const parsedFromISO = DateTime.fromISO(value, { locale: this.locale });
    if (parsedFromISO.isValid) return parsedFromISO.toISO();

    const result = this.chronoParse(value);
    const datetime = DateTimeParsingService.toDateTime(result).toISO(); // Get the datetime with timezone
    // Check if the iso returned datetime contains a time
    if (!datetime || datetime.split('T').length <= 1) {
      throw new DateFormatError(`Unable to parse '${value}' as a date/time (uncertain time).`);
    }
    return datetime;
  }

  /**
   * Accepts a date entered by a human and attempts to return it as an ISO8601 formatted date string.
   * Otherwise it will throw an error
   */
  parseDate(value: string): string {
    if (!value) throw new DateFormatError(`Cannot parse date of type: ${typeof value}.`);

    const result = this.chronoParse(value);
    if (!result || !result.isCertain('day')) {
      throw new DateFormatError(`Unable to parse '${value}' as a date (uncertain date).`);
    }

    const dateTime = DateTime.fromJSDate(result.date()).toISODate();
    if (!dateTime) {
      throw new DateFormatError(`Unable to format date to ISO format`);
    }

    return dateTime;
  }

  /**
   * Attempts to format a user entered date/time string
   * Otherwise it will throw an error
   */
  private chronoParse(value: string): ParsedComponents {
    const parsedResults = this.chrono.parse(value, undefined, { forwardDate: true });
    if (parsedResults.length !== 1) {
      throw new DateFormatError($localize`:@@date-time-parsing-service__invalid--unable-to-parse:'${value}:value:' is not a valid date/time.`);
    }

    const parsedResult = parsedResults[0];
    /* Chrono will accept a datetime value such as 18/13/22 11 PM as it ignores the invalid date and takes and formats the valid time.
       So will output today's date with time given. To stop this we check that the value returned from chrono has a date on it */
    if (parsedResult.start.isCertain('day')) {
      return parsedResult.start;
    }
    throw new DateFormatError($localize`:@@date-time-parsing-service__invalid--unable-to-format-correctly:Unable to format '${value}:value:' as a date/time. Check your date is entered correctly.`)
  }

  /**
   * Accepts a dateTime entered by a human and attempts to return it as an ISO8601 formatted string
   * Otherwise it will throw an error
   */
  parse(dateTime: string, hasTz: boolean): string {
    if (hasTz) {
      return this.parseDateTime(dateTime);
    }
    return this.parseLocalDateTime(dateTime);
  }
}
