import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AqlQuery } from '../../aql-module/models/aql/aql-query';
import { aqlQuantityToString } from '../../aql-module/models/aql/aql-quantity';

@Pipe({
  name: 'getQuantityText',
})
export class GetQuantityTextPipe implements PipeTransform {
  transform(aqlQuery: Observable<AqlQuery | null>): Observable<string> {
    return aqlQuery.pipe(
      map(value => {
        if (value == null) return '~';
        return aqlQuantityToString(value.quantity);
      })
    );
  }
}
