import { Injectable } from '@angular/core';
import { IValidatorService } from './validator.service';
import { AttributeValue } from '../models/attribute';
import { ValidatorType } from '../models/validator';
import { RangeValidator } from '../models/validator/range-validator';

@Injectable({
  providedIn: 'root',
})
export class RangeValidatorService implements IValidatorService {
  constructor() {}

  validate(displayName: string, validator: RangeValidator, value: AttributeValue): { [key: string]: any } {
    if (value == null) return {};

    if (typeof value !== 'number') {
      return { [ValidatorType.RANGE]: { value, message: $localize`:@@range-validator__invalid--not-a-number:${displayName}:displayName: must be a number.`}};
    }

    if ((validator.min && validator.max) && (value < validator.min || value > validator.max)) {
      // tslint:disable-next-line:max-line-length
      return { [ValidatorType.RANGE]: { value, message: $localize`:@@range-validator__invalid--min-max:${displayName}:displayName: must be at least ${validator.min}:min: and no more than ${validator.max}:max:.` } };
    } else if (validator.min && value < validator.min) {
      // tslint:disable-next-line:max-line-length
      return { [ValidatorType.RANGE]: { value, message: $localize`:@@range-validator__invalid--min:${displayName}:displayName: must be at least ${validator.min}:min:.` } };
    } else if (validator.max && value > validator.max) {
      // tslint:disable-next-line:max-line-length
      return { [ValidatorType.RANGE]: { value, message: $localize`:@@range-validator__invalid--max:${displayName}:displayName: must be ${validator.max}:max: or less.` } };
    }

    return {};
  }
}

