import { Injectable, InjectionToken, Injector } from '@angular/core';
import { GlobalPositionStrategy, Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, ComponentType } from '@angular/cdk/portal';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export const OVERLAY_DATA = new InjectionToken<any>('OVERLAY_DATA');
export const OVERLAY_CONTROL = new InjectionToken<any>('OVERLAY_CONTROL');
@Injectable({
  providedIn: 'root',
})
export class OverlayService {
  openModals$ = new BehaviorSubject(0);
  isShowing$: Observable<boolean> = this.openModals$.pipe(map(i => i > 0));

  constructor(private injector: Injector, private overlay: Overlay) {}

  showOverlay(component: ComponentType<any>, overlayData: any = null, overlayConfig: OverlayConfig | null = null, injector: Injector = this.injector): OverlayRef {
    const positionStrategy = new GlobalPositionStrategy().centerHorizontally().centerVertically();

    const ref = this.overlay.create({
      hasBackdrop: true,
      backdropClass: 'backdrop',
      positionStrategy,
    });

    const control = {
      close: () => this.close(ref),
      backgroundDismiss: overlayConfig && !overlayConfig.disableOverlayDismiss,
    } as OverlayControl;

    ref.backdropClick().subscribe(() => {
      if (!control.backgroundDismiss) {
        return;
      }
      this.close(ref);
    });

    ref.attach(new ComponentPortal(component, null, this.createInjector(ref, overlayData, control, injector)));

    document.addEventListener('scroll', () =>
      document.documentElement.style.setProperty('--scroll-y', `${window.scrollY}px`)
    );
    this.preventBackgroundInteraction();

    this.openModals$.next(this.openModals$.value + 1);
    return ref;
  }

  close(overlayRef: OverlayRef): void {
    this.enableBackgroundInteraction();
    this.openModals$.next(this.openModals$.value - 1);
    overlayRef.dispose();
  }

  private createInjector(overlayRef: OverlayRef, overlayData: any, control: OverlayControl, parent: Injector) {
    const providers = [
      { provide: OverlayRef, useValue: overlayRef },
      { provide: OVERLAY_DATA, useValue: overlayData },
      { provide: OVERLAY_CONTROL, useValue: control },
    ];
    return Injector.create({ providers, parent });
  }

  private enableBackgroundInteraction() {
    const body = document.body;
    const scrollY = body.style.top;
    body.style.position = '';
    body.style.top = '';
    window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
  }

  private preventBackgroundInteraction() {
    const scrollY = document.documentElement.style.getPropertyValue('--scroll-y');
    const body = document.body;
    body.style.position = 'fixed';
    body.style.top = `-${scrollY}`;
  }
}

export interface OverlayConfig {
  disableOverlayDismiss: boolean;
}

export interface OverlayControl {
  close: () => void;
  backgroundDismiss: boolean;
}
