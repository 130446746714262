import { ConnectedPosition } from '@angular/cdk/overlay';

export type TooltipDirection = 'to-top'|'to-bottom'|'to-left'|'to-right';

export const createConnectedPosition = (direction: TooltipDirection = 'to-top', offset: number = 8): ConnectedPosition => {
  switch (direction) {
    case 'to-top':
      return {
        originX: 'center',
        originY: 'top',
        overlayX: 'center',
        overlayY: 'bottom',
        offsetY: -offset,
      };
    case 'to-bottom':
      return {
        originX: 'center',
        originY: 'bottom',
        overlayX: 'center',
        overlayY: 'top',
        offsetY: offset,
      };
    case 'to-right':
      return {
        originX: 'start',
        originY: 'center',
        overlayX: 'end',
        overlayY: 'center',
        offsetX: -offset,
      };
    case 'to-left':
      return {
        originX: 'end',
        originY: 'center',
        overlayX: 'start',
        overlayY: 'center',
        offsetX: offset,
      };
  }
};

