import { AfterViewInit, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { hideLoadingScreen } from '../../utils/helpers';
import { faPersonFromPortal } from '@fortawesome/pro-regular-svg-icons';
import { Auth0Service } from '../../auth0/auth0.service';
import { entityShortsToOptions } from '../../services/utils';
import { ConfigService } from '../../services/config.service';
import { FormControl, Validators } from '@angular/forms';
import { Option } from 'src/app/shared-module/entities/form-entities';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.sass'],
})
export class ErrorPageComponent implements AfterViewInit {
  availableClients: Option<any>[] | undefined;
  clientsForm: FormControl<string> | undefined;
  constructor(route: ActivatedRoute, public auth0Service: Auth0Service, public configService: ConfigService) {
    this.errorCode = route.snapshot.data['errorCode'];
    this.configService.config$.pipe().subscribe(config => {
      this.availableClients = entityShortsToOptions(config.user.clients);
      this.clientsForm = new FormControl<string>(this.configService.selectedClient, { ...Validators.required, nonNullable: true });
      this.clientsForm?.valueChanges.subscribe((selectedClient: string) => this.configService.changeClient(selectedClient));
    });
  }

  errorCode: number;

  protected readonly faPersonFromPortal = faPersonFromPortal;

  ngAfterViewInit(): void {
    hideLoadingScreen();
  }
}
