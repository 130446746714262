import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AqlQuery, aqlQueryToString } from '../../aql-module/models/aql/aql-query';

@Pipe({
  name: 'getQueryText'
})
export class GetQueryTextPipe implements PipeTransform {

  transform(aqlQuery: Observable<AqlQuery | null>): Observable<string> {
    return aqlQuery.pipe(
      map(value => {
        if(value == null) return '~';
        return aqlQueryToString(value);
      })
    );
  }

}
