import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { APP_CONFIG, AppConfig } from '../../app-config';
import { RestResponse } from '../models/rest-response';
import { BehaviorSubject, EMPTY, from, NEVER, Observable, of, switchMap } from 'rxjs';
import { extractRestValue } from './api-utils';
import { catchError, filter, map, take, tap } from 'rxjs/operators';
import { Config } from '../models/config';
import { isNonNull } from '../utils/helpers';
import { User } from '../user-module/models/user';
import { FileConfig } from '../models/file-config';
import { Router } from '@angular/router';
import { WorkflowConfig } from '../models/workflow-config';
import * as Sentry from '@sentry/angular';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  private _config$ = new BehaviorSubject<Config | null>(null);
  readonly config$: Observable<Config> = this._config$.asObservable().pipe(filter(isNonNull));
  private readonly _selectedClient$ = new BehaviorSubject<string | null>(null);
  readonly selectedClient$ = this._selectedClient$.asObservable().pipe(filter(isNonNull));

  constructor(private http: HttpClient, @Inject(APP_CONFIG) private CONFIG: AppConfig, private router: Router) {}

  initialise(): Observable<Config> {
    // Load the previously selected client
    this.selectedClient = localStorage.getItem('selectedClient');
    // if not ask the server who our default client should be.
    // we pipe off of the behaviour subject as we need null values when they exist which are filtered out of the asObservable
    return this._selectedClient$.pipe(
      take(1),
      switchMap(selectedClient => {
        if (selectedClient == null) {
          return this.http.get<RestResponse<{ id: string, displayAs: string }>>(`${ this.CONFIG.apiRoot }/config/v1/default-client`).pipe(
            extractRestValue(),
            map(it => it.id),
            tap(it => this.selectedClient = it),
          );
        } else {
          return of(selectedClient);
        }
      }),
      switchMap(() => this.http.get<RestResponse<Config>>(`${ this.CONFIG.apiRoot }/config/v1/config`)),
      extractRestValue(),
      catchError((e) => {
        if (Array.isArray(e) && e.length > 0 && e[0].code === 'svc-user.get-default-client.not-found') {
          return from(this.router.navigate(['/forbidden'], { skipLocationChange: true })).pipe(switchMap(() => EMPTY));
        }
        if (Array.isArray(e) && e.length > 0 && e[0].code === 'api-gateway.generic.client-not-found') {
          // If this call fails then clear the client (in case it's because the client doesn't exist (or the user doesn't have access))
          this.changeClient(null);
          // No point continuing, we've reloaded the page
          return NEVER;
        }
        throw e;
      }),

      tap(config => {
        this._config$.next(config);
        // If the user's selected client isn't valid then pick a new one
        if (config.user.clients?.find(it => it.id === this.selectedClient) == null) {
          this.changeClient(config.user.clients![0].id);
        }

        // Add user details to Sentry
        Sentry.setUser({
          id: config.user.id,
          username: config.user.displayAs,
          email: config.user.email,
          ip_address: '{{auto}}',
        });
        Sentry.setTags({
          client: this.selectedClient,
        });
      }),
    );
  }

  get user(): User {
    if (!this._config$.value?.user) throw new Error(`'Couldn't get the user from the provided config.`);
    return this._config$.value!.user!;
  }

  set user(user: User) {
    this._config$.value!.user = user;
  }

  get fileConfig(): FileConfig {
    return this._config$.value!.svcFileConfig;
  }

  get workflowConfig(): WorkflowConfig {
    return this._config$.value!.workflowConfig;
  }

  get selectedClient(): string {
    return this._selectedClient$.value!;
  }

  private set selectedClient(id: string | null) {
    if (id != null) {
      localStorage.setItem('selectedClient', id);
    } else {
      localStorage.removeItem('selectedClient');
    }
    this._selectedClient$.next(id);
  }

  public hasFeature(feature: string): boolean {
    return this._config$.value!.features!.includes(feature);
  }

  changeClient(value: string | null) {
    if (value !== this.selectedClient) {
      this.selectedClient = value;
      this.reload();
    }
  }

  private reload() {
    location.reload();
  }
}

