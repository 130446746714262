import { Injectable } from '@angular/core';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class SanitizeService {
  private readonly REGEX_UNSAFE_CHARS = new RegExp('[^a-zA-Z0-9\\s]+', 'g');
  private readonly REGEX_WHITESPACE = new RegExp('[\\s]+', 'g');
  private readonly REGEX_VALID_KEY = new RegExp('(?=.*[a-z_])^[a-z0-9_]*[a-z0-9]\$', 'g');

  constructor() { }

  sanitize(rawStr: string): string {
    const normalizedStr = rawStr.normalize('NFKD');
    return normalizedStr
      .replace(this.REGEX_UNSAFE_CHARS, '')
      .trim()
      .replace(this.REGEX_WHITESPACE, ' ');
  }

  asAttributeKey(rawStr: string): string|null {
    const key = _.trim(
      rawStr
        .toLowerCase()
        .normalize('NFKD')
        .replace(this.REGEX_UNSAFE_CHARS, '')
        .replace(this.REGEX_WHITESPACE, '_'),
      '_'
    );

    if(this.isAttributeKey(key)){
      return key;
    }

    // Not valid :-(
    return null;
  }

  isAttributeKey(rawStr: string): boolean {
    return rawStr.match(this.REGEX_VALID_KEY) != null;
  }

}
