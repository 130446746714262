import { Component } from '@angular/core';

@Component({
  selector: 'app-connection-error-modal',
  templateUrl: './connection-error-modal.component.html',
  styleUrls: ['./connection-error-modal.component.sass']
})
export class ConnectionErrorModalComponent {

  constructor() { }

  onRefresh() {
    window.location.reload();
  }

}
