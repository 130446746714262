import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Portal } from '@angular/cdk/portal';
import { Subject } from 'rxjs';
import { ActivationStart, Router } from '@angular/router';
import { distinctUntilChanged, filter, map, startWith, takeUntil } from 'rxjs/operators';
import { NavbarService } from '../../services/navbar.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { getLeafRoute } from '../../services/utils';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavbarComponent implements OnDestroy, OnInit {
  navbarPortal: Portal<any> | null = null;
  destroy$ = new Subject();
  logoSrc: string | SafeResourceUrl = 'assets/client-logos/rule-logo-dark.svg';
  @ViewChild('logoImgEl', { static: true }) logoImgEl: ElementRef<HTMLImageElement> | undefined;

  @Input('logoSrc')
  set _logoSrc(value: string | null) {
    if (value) this.logoSrc = this.domSanitizer.bypassSecurityTrustResourceUrl(value);
  }

  constructor(private router: Router, private cdr: ChangeDetectorRef, private navbarService: NavbarService, private domSanitizer: DomSanitizer) {
  }

  ngOnInit(): void {
    // get the initial root so we can check if the component has changed in the router event
    const initialRoute = getLeafRoute(this.router.routerState.root);

    // Reset the panel when the page and the component changes
    this.router.events
      .pipe(
        filter(event => event instanceof ActivationStart),
        map(() => this.router.routerState.root),
        map(route => getLeafRoute(route)),
        startWith(initialRoute),
        distinctUntilChanged((a, b) => a.component === b.component),
        takeUntil(this.destroy$),
      )
      .subscribe(_ => {
        this.navbarPortal = null;
        this.cdr.markForCheck();
      });

    this.navbarService.navbarPortal$.subscribe(portal => {
      this.navbarPortal = portal;
      this.cdr.markForCheck();
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
