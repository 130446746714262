<div role="alertdialog" class="dialogue" data-test="modal" cdkTrapFocus>
  <app-card class="modal" [ngClass]="[overlayClass$ | async, size]">
    <header>
      <div class="modal-titles" data-test="modal-header-title-container" [attr.title]="(subtitle$ | async) ? (title$ | async) + ' | ' + (subtitle$ | async) : (title$ | async)">
        <div class="title" data-test="modal-header-title">{{ title$ | async }}</div>
        <ng-container *ngIf="subtitle$ | async">
          <div class="title-divider" data-test="modal-header-divider"></div>
          <div class="subtitle" data-test="modal-header-subtitle">{{ subtitle$ | async }}</div>
        </ng-container>
      </div>
      <div class="close" *ngIf="cancelButtonText$ | async">
        <button type="button" data-test="modal-close-button" class="link-alt" (click)="cancelAction()">
          <ng-container i18n="@@button-close">Close</ng-container>
          <app-icon class="modal-close-icon right" [icon]="IconEnum.CANCEL"></app-icon>
        </button>
      </div>
    </header>

    <div class="modal-body" [ngClass]="'padding-' + padding" cdkScrollable>
      <ng-content></ng-content>
    </div>

    <footer>
      <ng-container>
        <button type="button" id="cancelButton" data-test="modal-cancel-button" class="link-alt" *ngIf="cancelButtonText$ | async" (click)="cancelAction()">
          {{ (cancelButtonText$ | async) || 'cancel' }}
        </button>
        <button type="button" id="confirmButton" data-test="modal-confirm-button" data-tabindex="1" class="modal-btn" (click)="confirmAction()" [disabled]="disableConfirmButton$ | async">
          {{ (confirmButtonText$ | async) || 'confirm' }}
        </button>
      </ng-container>
    </footer>
  </app-card>
</div>
