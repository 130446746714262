import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { DateTimeParsingService } from './date-time-parsing.service';
import { formatDate } from '@angular/common';
import { TimeParsingService } from './time-parsing.service';

@Injectable({
  providedIn: 'root'
})
export class DateTimeExtractionService {
  constructor(
    private dateTimeParsingService: DateTimeParsingService,
    @Inject(LOCALE_ID) private currentLocale: string,
    private timeParsingService: TimeParsingService,
  ) { }

  /**
   * Extract the date and time from the users input and return it in iso format
   * @param dateTimeStr The date time to be extracted from
   * @param hasTz Whether the value is with a timezone or not
   */
  extractDateAndTimeFromUserInput(dateTimeStr: string, hasTz: boolean): { dateISO: string; timeISO: string } {
    if (dateTimeStr == null || dateTimeStr === '') return { dateISO: '', timeISO: '' };
    const dateTimeISO = this.dateTimeParsingService.parse(dateTimeStr, hasTz);
    return this.extractDateAndTimeFromDateTimeISO(dateTimeISO);
  }

  /**
   * Takes a datetime ISO string and returns an object containing the date and time iso strings extracted from the datetime string given.
   */
  extractDateAndTimeFromDateTimeISO(dateTimeISO: string): { dateISO: string; timeISO: string } {
    if (dateTimeISO == null || dateTimeISO === '') return { dateISO: '', timeISO: '' };
    return { dateISO: this.extractDateFromDateTimeISO(dateTimeISO), timeISO: this.extractTimeFromDateTimeISO(dateTimeISO) };
  }

  /**
   * Takes a datetime iso and extracts and returns just the date from it ignoring the time
   */
  extractDateFromDateTimeISO(dateTimeISO: string): string {
    return this.dateTimeParsingService.parseDate(formatDate(dateTimeISO, 'shortDate', this.currentLocale));
  }

  /**
   * Takes a datetime iso and extracts and returns just the time from it ignoring the date
   */
  extractTimeFromDateTimeISO(dateTimeISO: string): string {
    return this.timeParsingService.parse(formatDate(dateTimeISO, 'HH:mm', this.currentLocale)).timeISO;
  }
}
