<label *ngIf="label" [for]="buttonElement" (click)="toggleDropdownList(true)">{{ label }}</label>

<button
  type="button"
  class="btn select-btn"
  data-test="select-button"
  [class.open]="isDropdownVisible"
  [class.dropdown-top]="dropdownAltPosition"
  [disabled]="forceDisabled || formDisabled"
  (click)="toggleDropdownList()"
  cdkOverlayOrigin
  #dropdownOrigin="cdkOverlayOrigin"
  #buttonElement
  (blur)="toggleDropdownList(false);"
>
  <span class="btn-text" data-test="select-button-text">{{ selectedOption }}</span>
  <span class="select-dropdown-icon"><fa-icon class="icon icon-clickable" [icon]="faChevronDown" [rotate]="(isDropdownVisible ? 180 : null)!"></fa-icon></span>
</button>

<ng-template
  #dropDown="cdkConnectedOverlay"
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="dropdownOrigin"
  [cdkConnectedOverlayOpen]="isDropdownVisible"
  [cdkConnectedOverlayFlexibleDimensions]="true"
  [cdkConnectedOverlayPositions]="dropdownPositions"
  [cdkConnectedOverlayViewportMargin]="15"
  [cdkConnectedOverlayWidth]="dropdownOriginRect?.width!"
  [cdkConnectedOverlayScrollStrategy]="scrollStrategyOptions.close()"
  (positionChange)="positionChanged($event)"
>
  <div class="dropdown" [class.pill-dropdown]="isPill" [class.inline-dropdown]="isInline" data-test="select-dropdown" [class.top]="dropdownAltPosition">
    <ul class="options" role="listbox">
      <li *ngIf="defaultOption != null" class="option default-option" tabindex="-1">{{ defaultOption.label }}</li>
      <li
        *ngFor="let option of options; let i = index"
        class="option"
        [attr.data-test]="'option(' + option.label + ')'"
        (mousemove)="setHighlightedIndex(i)"
        [class.highlight]="highlightedIndex === i"
        [title]="option.label"
        (mousedown)="$event.preventDefault()"
        (click)="selectAndCloseDropdown(option)"
      >
        {{ option.label }}
      </li>
    </ul>
  </div>
</ng-template>
