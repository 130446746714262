import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Auth0Guard } from './auth0/auth0.guard';
import { ErrorPageComponent } from './pages/error-page/error-page.component';
import { PermissionGuard } from './auth-module/services/permission-guard.service';
import { SETTINGS_PERMISSIONS } from './auth-module/permissions';
import { WrapperPageComponent } from './pages/wrapper-page/wrapper-page.component';
import { LoginPageComponent } from './pages/login-page/login-page.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: '/jobs' },
  { path: 'login', component: LoginPageComponent },
  {
    path: 'go/:client/:component/:entity/:entityId',
    component: WrapperPageComponent,
    canActivate: [Auth0Guard],
  },
  {
    path: '',
    component: WrapperPageComponent,
    canActivate: [Auth0Guard],
    children: [
      {
        path: 'jobs',
        title: 'Jobs',
        data: { breadcrumb: $localize`:@@breadcrumb-my-jobs:My Jobs` },
        loadChildren: () => import('./allocation-module/allocation.module').then(m => m.AllocationModule),
      },
      {
        path: 'sites',
        title: 'Sites',
        data: { breadcrumb: $localize`:@@breadcrumb-site-db:Site Database` },
        loadChildren: () => import('./site-db-module/site-db.module').then(m => m.SiteDbModule),
      },
      {
        path: 'settings',
        title: 'Settings',
        data: { breadcrumb: $localize`:@@breadcrumb-settings:Settings`, hasAnyPermissions: SETTINGS_PERMISSIONS },
        loadChildren: () => import('./settings-module/settings.module').then(m => m.SettingsModule),
        canActivate: [PermissionGuard],
      },
      {
        path: 'user-profile',
        title: 'User Profile',
        data: { breadcrumb: $localize`:@@breadcrumb-user-profile:User Profile` },
        loadChildren: () => import('./user-profile-module/user-profile.module').then(m => m.UserProfileModule),
      },
      {
        path: 'admin',
        title: 'Admin',
        data: { breadcrumb: 'Admin' },
        loadChildren: () => import('./admin-module/admin.module').then(m => m.AdminModule),
      },
    ],
  },
  { path: 'forbidden', data: { errorCode: 403 }, component: ErrorPageComponent },
  { path: '**', data: { errorCode: 404 }, component: ErrorPageComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { paramsInheritanceStrategy: 'always' })],
  providers: [PermissionGuard],
  exports: [RouterModule],
})

export class AppRoutingModule { }
