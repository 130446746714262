import { NgModule } from '@angular/core';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  faArrowSquareLeft,
  faArrowSquareRight,
  faCheckCircle,
  faExclamationCircle,
  faExclamationTriangle,
  faStar,
  faSuitcase, faTicketAlt,
  faTimesCircle,
} from '@fortawesome/pro-solid-svg-icons';
import {
  faArrowAltCircleRight,
  faArrowDown,
  faArrowUp,
  faArrowLeft,
  faArrowRight,
  faBan,
  faBars,
  faCheck,
  faChevronCircleDown,
  faChevronCircleUp,
  faChevronDoubleLeft,
  faChevronDoubleRight,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faClone,
  faCopy,
  faDivide,
  faDolly,
  faEdit,
  faEllipsisH,
  faEmptySet,
  faEquals,
  faEye,
  faEyeSlash,
  faFile,
  faFileExport,
  faFileUpload,
  faFilter,
  faGreaterThan,
  faGreaterThanEqual,
  faIndustryAlt,
  faIntersection,
  faLessThan,
  faLessThanEqual,
  faLockAlt,
  faMinus,
  faMinusCircle,
  faNotEqual,
  faPaste,
  faPlus,
  faPlusCircle,
  faPortalExit,
  faRandom,
  faSave,
  faSearch,
  faStar as faStarHollow,
  faStore,
  faThumbtack,
  faTimes,
  faTrashAlt,
  faUserCog,
  faFileImport,
  faUndo,
  faPen,
  faHistory,
  faQuestion, faDollarSign,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faCheckSquare,
  faDownload,
  faFileCheck,
  faShredder,
  faSort,
  faSortDown,
  faSortUp,
  faSpinner,
  faSquare,
  faSyncAlt,
  faToggleOff,
  faToggleOn,
  faUpload,
} from '@fortawesome/pro-duotone-svg-icons';

@NgModule({
    declarations: [],
    imports: [FontAwesomeModule],
    exports: [FontAwesomeModule]
})
export class AppFAModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(
      faArrowUp,
      faArrowDown,
      faArrowLeft,
      faArrowRight,
      faArrowAltCircleRight,
      faArrowSquareLeft,
      faArrowSquareRight,
      faBan,
      faBars,
      faCheck,
      faCheckCircle,
      faCheckSquare,
      faChevronCircleDown,
      faChevronCircleUp,
      faChevronDoubleLeft,
      faChevronDoubleRight,
      faChevronDown,
      faChevronLeft,
      faChevronRight,
      faChevronUp,
      faClone,
      faCopy,
      faDivide,
      faDolly,
      faDownload,
      faEdit,
      faEllipsisH,
      faEmptySet,
      faEquals,
      faExclamationCircle,
      faExclamationTriangle,
      faEye,
      faEyeSlash,
      faFile,
      faFileCheck,
      faFileExport,
      faFileUpload,
      faFileImport,
      faFilter,
      faGreaterThan,
      faGreaterThanEqual,
      faHistory,
      faIndustryAlt,
      faIntersection,
      faLessThan,
      faLessThanEqual,
      faLockAlt,
      faMinus,
      faMinusCircle,
      faNotEqual,
      faPaste,
      faPen,
      faPlus,
      faPlusCircle,
      faPortalExit,
      faRandom,
      faSave,
      faSearch,
      faShredder,
      faSort,
      faSortDown,
      faSortUp,
      faSpinner,
      faSquare,
      faStar,
      faStarHollow,
      faStore,
      faSyncAlt,
      faThumbtack,
      faTimes,
      faTimesCircle,
      faToggleOff,
      faToggleOn,
      faTrashAlt,
      faUndo,
      faUpload,
      faUserCog,
      faQuestion,
      faSuitcase,
      faTicketAlt,
      faDollarSign,
    );
  }
}
