import { Pipe, PipeTransform } from '@angular/core';
import { icon } from '@fortawesome/fontawesome-svg-core';
import { faBolt, faChartLine } from '@fortawesome/pro-regular-svg-icons';
import { IconObj } from '../../shared-module/entities/icon-obj';
import { TaskHelperService } from '../services/task-helper.service';

@Pipe({
  name: 'getTaskTypeIcon'
})
export class GetTaskTypeIconPipe implements PipeTransform {

  constructor(private taskHelperService: TaskHelperService) {}

  transform(type: string): IconObj {
    return this.taskHelperService.getIconByTaskType(type)
  }

}
