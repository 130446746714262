import { Component, Input } from '@angular/core';
import { Task } from '../../models/task';

@Component({
  selector: 'app-current-tasks',
  templateUrl: './current-tasks.component.html',
  styleUrls: ['./current-tasks.component.sass'],
})
export class CurrentTasksComponent {
  @Input() tasks: Task[] = [];

  constructor() {}
}
