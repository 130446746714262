import { Inject } from '@angular/core';
import { merge } from 'lodash';
import { Observable, OperatorFunction, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { RestResponse } from '../../models/rest-response';
import { APP_CONFIG, AppConfig } from '../../../app-config';
import { KeyStoreData } from '../models/key-store-data';
import { concatMap, first, map, pluck, share } from 'rxjs/operators';
import { bufferDebounceTime } from '../../shared-module/rxjs';
import { DEBOUNCE_TIME, extractRestValue } from '../../services/api-utils';
import { KeyStoreDataStore } from './key-store-data.store';
import * as uuid from 'uuid';

export class KeyStoreService {
  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private endPoint: string,
    private http: HttpClient,
    private dataKeyStore: KeyStoreDataStore,
  ) {
    this.patchRequests = this.patchQueue.pipe(
      bufferDebounceTime(DEBOUNCE_TIME),
      map(requests =>
        requests.reduce(
          (acc, { requestId, request }) => {
            return {
              request: merge(acc.request, request),
              requestIds: [...acc.requestIds, requestId],
            };
          },
          { request: {}, requestIds: [] as string[] }
        )
      ),
      concatMap(({ request, requestIds }) => this.http.patch<RestResponse<KeyStoreData>>(`${ this.config.apiRoot }/${this.endPoint}`, request)
        .pipe(
          extractRestValue(),
          map(response => ({ response, requestIds }))
        )
      ) as OperatorFunction<any, any>,
      share()
    );

    this.http
      .get<RestResponse<KeyStoreData>>(`${this.config.apiRoot}/${this.endPoint}`)
      .pipe(extractRestValue())
      .subscribe(data => this.dataKeyStore.setState(data));
  }

  private patchQueue = new Subject<{ requestId: string; request: KeyStoreData }>();
  private patchRequests: Observable<{ requestIds: string[]; response: KeyStoreData }>;

  getDataByKey$<T>(key: string): Observable<T | null> {
    return this.dataKeyStore.getDataByKey$<T>(key);
  }

  getDataByKey<T>(key: string): T | null {
    return this.dataKeyStore.getDataByKey<T>(key);
  }

  updateDataByKey$<T>(key: string, data: T): Observable<T> {
    // We patch the state first so that the data is available to use before the server is updated
    this.dataKeyStore.updateDataByKey({ key, data });
    const requestId = uuid.v4();
    return new Observable(subscriber => {
      this.patchRequests.pipe(first(it => it.requestIds.includes(requestId))).subscribe(subscriber);
      this.patchQueue.next({ requestId, request: { [key]: data } });
    }).pipe(
      map(({ response }: any) => response),
      pluck(key)
    );
  }
}
