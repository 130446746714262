import { NgModule } from '@angular/core';
import { SharedModule } from '../shared-module/shared.module';
import { QueryBuilderModalComponent } from './components/query-builder-modal/query-builder-modal.component';
import { GetQueryTextPipe } from './pipes/get-query-text.pipe';
import { GetQueryFromFormControlPipe } from './pipes/get-query-from-form-control.pipe';
import { GetQuantityTextPipe } from './pipes/get-quantity-text.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IsRoundingFunctionPipe } from './pipes/is-rounding-function.pipe';
import { AqlModule } from '../aql-module/aql.module';
import { ForecastPipe } from './pipes/forecast.pipe';
import { AqlToStringPipe } from './pipes/aql-to-string.pipe';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SaveQueryTemplateComponent } from './components/save-query-template/save-query-template.component';
import { AuthModule } from '../auth-module/auth.module';
import { QueryTemplateBoxComponent } from './components/query-template-box/query-template-box.component';

@NgModule({
  declarations: [
    QueryBuilderModalComponent,
    ForecastPipe,
    GetQueryTextPipe,
    GetQueryFromFormControlPipe,
    GetQuantityTextPipe,
    IsRoundingFunctionPipe,
    AqlToStringPipe,
    SaveQueryTemplateComponent,
    QueryTemplateBoxComponent,
  ],
  imports: [SharedModule, ReactiveFormsModule, FormsModule, AqlModule, FontAwesomeModule, AuthModule],
  exports: [AqlToStringPipe, QueryTemplateBoxComponent],
})
export class QueryBuilderModule {}
