export type AttributeScalar = string | number | boolean;
export type AttributeArray = string[] | number[] | boolean[];
export type AttributeValue = AttributeScalar | AttributeArray;

export enum AttributeType {
  STRING = 'STRING',
  STRING_ARRAY = 'STRING_ARRAY',
  NUMBER = 'NUMBER',
  NUMBER_ARRAY = 'NUMBER_ARRAY',
  BOOLEAN = 'BOOLEAN',
  BOOLEAN_ARRAY = 'BOOLEAN_ARRAY',
}

export interface AttributeMap {
  [key: string]: AttributeValue;
}
