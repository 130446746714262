import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardComponent } from './components/card/card.component';
import { ModalComponent } from './components/modal/modal.component';
import { DragNDropDirective } from './directives/drag-n-drop.directive';
import { DragNDropComponent } from './components/drag-n-drop/drag-n-drop.component';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { NoDataComponent } from './components/no-data/no-data.component';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { IconComponent } from './components/icon/icon.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { AppFAModule } from '../app-f-a.module';
import { RouterModule } from '@angular/router';
import { MultiSelectDatalistComponent } from './components/form-fields/multi-select-datalist/multi-select-datalist.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BooleanFieldComponent } from './components/form-fields/boolean-field/boolean-field.component';
import { NumberFieldComponent } from './components/form-fields/number-field/number-field.component';
import { FilterFieldComponent } from './components/form-fields/filter-field/filter-field.component';
import { TooltipDirective } from './directives/tooltip.directive';
import { InputErrorsComponent } from './components/input-errors/input-errors.component';
import { ProgressModalComponent } from './components/progress-modal/progress-modal.component';
import { TableComponent } from './components/table/table.component';
import { TruncatePipe } from './pipes/truncate.pipe';
import { ParseJsonPipe } from './pipes/parse-json.pipe';
import { EmptyTableListComponent } from './components/empty-table-list/empty-table-list.component';
import { A11yModule } from '@angular/cdk/a11y';
import { IconSelectComponent } from './components/icon-select/icon-select.component';
import { ErrorListingComponent } from './components/error-listing/error-listing.component';
import { TooltipComponent } from './components/tooltip/tooltip.component';
import { TextFieldComponent } from './components/form-fields/text-field/text-field.component';
import { RadioFieldComponent } from './components/form-fields/radio-field/radio-field.component';
import { FieldComponent } from './components/form-fields/field/field.component';
import { ErrorBarComponent } from './components/error-bar/error-bar.component';
import { ModalResultComponent } from './components/modal-result/modal-result.component';
import { BooleanToggleComponent } from './components/form-fields/boolean-toggle/boolean-toggle.component';
import { DateRangeFieldComponent } from './components/form-fields/date-range-field/date-range-field.component';
import { PaginationBarComponent } from './components/pagination-bar/pagination-bar.component';
import { DateRangePickerComponent } from './components/date-range-picker/date-range-picker.component';
import { DateTimeFieldComponent } from './components/form-fields/date-time-field/date-time-field.component';
import { SelectComponent } from './components/select/select.component';
import { ProgressRingComponent } from './components/progress-ring/progress-ring.component';
import { BurgerMenuComponent } from './components/burger-menu/burger-menu.component';
import { TimePickerComponent } from './components/time-picker/time-picker.component';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { DateFieldComponent } from './components/form-fields/date-field/date-field.component';
import { ReversePipe } from './pipes/reverse.pipe';
import { ToRelativeDateTimePipe } from './pipes/to-relative-date-time.pipe';
import { FocusFirstFocusableElementDirective } from './directives/focus-first-focusable-element.directive';
import { SubmitOnEnterDirective } from './directives/submit-on-enter.directive';
import { MultiSelectWidgetComponent } from './components/form-fields/multi-select-widget/multi-select-widget.component';
import { Icon2Component } from './components/icon2/icon2.component';
import { HasFeaturePipe } from './pipes/has-feature.pipe';
import { IsSavedQueryPipe } from './pipes/is-saved-query.pipe';
import { DiffNowPipe } from './pipes/diff-now.pipe';
import { DaysAgoPipe } from './pipes/days-ago.pipe';
import { AutofocusDirective } from './directives/autofocus.directive';
import { ScrollIntoViewDirective } from './directives/scroll-into-view.directive';
import { AllowPopupModalComponent } from '../allocation-module/components/allow-popup-modal/allow-popup-modal.component';

@NgModule({
  declarations: [
    BooleanFieldComponent,
    BooleanToggleComponent,
    CardComponent,
    ClickOutsideDirective,
    DateTimeFieldComponent,
    DateRangeFieldComponent,
    DateRangePickerComponent,
    DragNDropComponent,
    DragNDropDirective,
    EmptyTableListComponent,
    ErrorBarComponent,
    ErrorListingComponent,
    FieldComponent,
    FilterFieldComponent,
    IconComponent,
    IconSelectComponent,
    InputErrorsComponent,
    LoadingSpinnerComponent,
    ModalComponent,
    ModalResultComponent,
    MultiSelectDatalistComponent,
    NoDataComponent,
    NumberFieldComponent,
    PaginationBarComponent,
    ParseJsonPipe,
    ProgressModalComponent,
    RadioFieldComponent,
    SelectComponent,
    TableComponent,
    TextFieldComponent,
    TooltipComponent,
    TooltipDirective,
    TruncatePipe,
    BurgerMenuComponent,
    ProgressRingComponent,
    TimePickerComponent,
    DatePickerComponent,
    DateFieldComponent,
    ReversePipe,
    ToRelativeDateTimePipe,
    FocusFirstFocusableElementDirective,
    SubmitOnEnterDirective,
    MultiSelectWidgetComponent,
    Icon2Component,
    HasFeaturePipe,
    IsSavedQueryPipe,
    DiffNowPipe,
    DaysAgoPipe,
    AutofocusDirective,
    ScrollIntoViewDirective,
    AllowPopupModalComponent,
  ],
  imports: [A11yModule, AppFAModule, CommonModule, FormsModule, OverlayModule, ReactiveFormsModule, RouterModule],
  exports: [
    CommonModule,
    BooleanFieldComponent,
    BooleanToggleComponent,
    CardComponent,
    ClickOutsideDirective,
    DateTimeFieldComponent,
    DateRangeFieldComponent,
    DateRangePickerComponent,
    DragNDropComponent,
    DragNDropDirective,
    EmptyTableListComponent,
    ErrorBarComponent,
    FilterFieldComponent,
    IconComponent,
    IconSelectComponent,
    InputErrorsComponent,
    LoadingSpinnerComponent,
    ModalComponent,
    ModalResultComponent,
    MultiSelectDatalistComponent,
    NoDataComponent,
    NumberFieldComponent,
    PaginationBarComponent,
    ParseJsonPipe,
    RadioFieldComponent,
    TimePickerComponent,
    TableComponent,
    TextFieldComponent,
    TooltipDirective,
    TruncatePipe,
    SelectComponent,
    DatePickerComponent,
    BurgerMenuComponent,
    ProgressRingComponent,
    DateFieldComponent,
    ReversePipe,
    ToRelativeDateTimePipe,
    FocusFirstFocusableElementDirective,
    SubmitOnEnterDirective,
    MultiSelectWidgetComponent,
    Icon2Component,
    HasFeaturePipe,
    IsSavedQueryPipe,
    DiffNowPipe,
    DaysAgoPipe,
    AutofocusDirective,
    ScrollIntoViewDirective,
    AllowPopupModalComponent,
  ],
})
export class SharedModule {}
