import { Injectable } from '@angular/core';
import { Permission, PermissionService } from './permission.service';
import { ConfigService } from '../../services/config.service';
import { defaultIfEmpty, every, filter, map, mergeMap, switchMap, take } from 'rxjs/operators';
import { BehaviorSubject, from, Observable, of } from 'rxjs';
import { isNonNull } from '../../utils/helpers';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private userPermissionSub$ = new BehaviorSubject<Map<string, Permission[]> | null>(null);
  private isSuperuserSub$ = new BehaviorSubject<boolean | null>(null);

  get userPermissions(): Map<string, Permission[]> {
    return this.userPermissionSub$.value ?? new Map();
  }

  constructor(configService: ConfigService, private permissionService: PermissionService) {
    configService.config$
      .pipe(
        take(1),
      )
      .subscribe(({ user }) => {
        this.isSuperuserSub$.next(user.isSuperUser === true);
        this.userPermissionSub$.next(
          (user.permissions ?? []).reduce((userPermissionsMap, permissionString) => {
            const [service, permission] = this.parsePermission(permissionString);
            const existingPermissions = userPermissionsMap.get(service) ?? [];
            userPermissionsMap.set(service, [...existingPermissions, permission]);
            return userPermissionsMap;
          }, new Map<string, Permission[]>())
        );
      });
  }

  hasAllPermissions(...vararg: string[]): boolean {
    return vararg.every(permissionString => this.hasPermission(permissionString));
  }

  hasAnyPermissions(...vararg: string[]): boolean {
    return vararg.some(permissionString => this.hasPermission(permissionString));
  }

  hasPermission(permissionString: string): boolean {
    // No need to check permissions if the user is a superuser
    if (this.isSuperuserSub$.value === true) return true;

    const [service, permission] = this.parsePermission(permissionString);
    const existingPermissions = this.userPermissions.get(service) ?? [];
    return existingPermissions.some(existingPermission => this.permissionService.implies(existingPermission, permission));
  }

  isSuperuser(): boolean {
    return this.isSuperuserSub$.value === true;
  }

  hasAllPermissions$(...vararg: string[]): Observable<boolean> {
    return from(vararg).pipe(
      mergeMap(permissionString => this.hasPermission$(permissionString)),
      every(it => it)
    );
  }

  hasAnyPermissions$(...vararg: string[]): Observable<boolean> {
    return from(vararg).pipe(
      mergeMap(permissionString => this.hasPermission$(permissionString)),
      filter(it => it),
      take(1),
      defaultIfEmpty(false)
    );
  }

  hasPermission$(permissionString: string): Observable<boolean> {
    return this.isSuperuserSub$.pipe(
      filter(isNonNull),
      take(1),
      switchMap(isSuperUser => {
        // No need to check permissions if the user is a superuser
        if(isSuperUser) return of(true);

        const [service, permission] = this.parsePermission(permissionString);
        return this.userPermissionSub$.pipe(
          filter(isNonNull),
          take(1),
          map(userPermissions => (userPermissions!.get(service) ?? []).some(existingPermission => this.permissionService.implies(existingPermission, permission)))
        );
      })
    );
  }

  isSuperuser$(): Observable<boolean> {
    return this.isSuperuserSub$.pipe(filter(isNonNull));
  }

  private parsePermission(permString: string): [string, Permission] {
    const [service, permission] = permString.split('.');
    return [service, this.permissionService.resolvePermission(permission)];
  }
}
