import { ChangeDetectionStrategy, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { faCircleXmark } from '@fortawesome/pro-regular-svg-icons';
import { NotificationService } from '../../services/notification.service';
import { animationFrameScheduler, BehaviorSubject, delay, EMPTY, fromEvent, merge, mergeMap, NEVER, of, Subject, switchMap } from 'rxjs';
import { Notification } from '../../types';
import { distinctUntilChanged, map, shareReplay, startWith, takeUntil, tap } from 'rxjs/operators';

type NotificationAndId = Notification & { id: number };

@Component({
  selector: 'app-notification-overlay',
  templateUrl: './notification-overlay.component.html',
  styleUrls: ['./notification-overlay.component.sass'],
  animations: [
    trigger('openCloseOverlay', [
      state(
        'open',
        style({
          opacity: 1,
          transform: 'translateY(0%)',
        })
      ),
      state(
        'closed',
        style({
          opacity: 0,
          transform: 'translateY(100%)',
        })
      ),
      transition('closed => open', [animate('0.5s ease-in')]),
      transition('open => closed', [animate('0.2s ease-out')]),
    ]),
    trigger('notification', [
      transition(':enter', [style({ height: 0, opacity: 0 }), animate('0.15s ease-in', style({ height: '2.5em', opacity: 1 }))]),
      transition(':leave', [animate('0.1s ease-out', style({ height: 0, opacity: 0 }))]),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationOverlayComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject();
  readonly closeIcon = faCircleXmark;
  readonly notifications$ = new BehaviorSubject<Map<number, (NotificationAndId)>>(new Map());
  private currentId = 0;

  @ViewChild('overlay') overlayRef: ElementRef | undefined;

  constructor(private notificationsService: NotificationService, private elRef: ElementRef) {}

  ngOnInit(): void {
    const hover$ = merge(fromEvent<MouseEvent>(this.elRef.nativeElement, 'mouseover'), fromEvent<MouseEvent>(this.elRef.nativeElement, 'mouseleave')).pipe(
      takeUntil(this.destroy$),
      map(e => e.type === 'mouseover'),
      startWith(false),
      distinctUntilChanged(),
      shareReplay(1)
    );

    this.notificationsService.notifications$
      .pipe(
        map(notification => ({ ...notification, id: this.currentId++ })),
        tap(notification => this.notifications$.next(new Map(this.notifications$.getValue()).set(notification.id, notification))),
        mergeMap(notification => {
          let i = 0;
          if (notification.autoDismiss) {
            return hover$.pipe(switchMap(isHover => (isHover ? NEVER : of(notification).pipe(delay(i++ === 0 && !isHover ? 5000 : 2500, animationFrameScheduler)))));
          } else {
            return EMPTY;
          }
        }),
        takeUntil(this.destroy$)
      )
      .subscribe(notification => this.onClose(notification));
  }

  onHandleAction(notification: NotificationAndId) {
    let actionResult = notification.action?.fn();

    // If the function returns a boolean, wrap it in a promise
    if(typeof actionResult === 'boolean') {
      actionResult = Promise.resolve(actionResult);
    }
    // If action result is a promise, only close the notification if it is true
    if(actionResult) {
      actionResult.then((it) => { if (it) this.onClose(notification) });
    } else {
      this.onClose(notification);
    }
  }

  onClose(notification: NotificationAndId) {
    const m = new Map(this.notifications$.getValue());
    m.delete(notification.id);
    this.notifications$.next(m);
    notification.closeAction && notification.closeAction();
  }

  closeAllNotifications() {
    Array.from(this.notifications$.getValue().values()).forEach(n => n.closeAction && n.closeAction());
    this.notifications$.next(new Map());
  }

  trackByKey(index: number, { id }: NotificationAndId) {
    return id;
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
