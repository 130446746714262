<!--  Response feedback block  -->
<div *ngIf="error == null; else errorTpl">
  <app-icon class="icon-super-xl icon-success" data-test="modal-success-icon" [icon]="IconEnum.SUCCESS" ></app-icon>
  <h5 class="title txt-grey-m" data-test="modal-success-text" i18n="@@Text-Success">Success!</h5>
</div>

<ng-template #errorTpl>
  <app-icon class="icon-super-xl icon-danger" data-test="modal-error-icon" [icon]="IconEnum.DANGER" ></app-icon>

  <ng-container *ngIf="isRestErrors(error) && error.length > 0; else genericError">
    <h5 *ngIf="error.length === 1" class="title txt-grey-m" data-test="modal-error-text">{{ error[0].message }}</h5>
    <app-error-bar [errors]="$any(error)" [errorListTemplate]="errorListTemplate"></app-error-bar>
  </ng-container>

  <ng-template #genericError>
    <h5 class="title txt-grey-m" data-test="modal-error-text" i18n="@@Text-500-Sorry-an-unknown-error-occurred">Sorry, an unknown error occurred.</h5>
  </ng-template>
</ng-template>
