import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TimeParsingService {
  constructor() {}

  parseHourAndMinute(hourStr: string, minuteStr: string, isPm?: boolean): { timeISO: string; hour24: string; hour12: string; minute: string; isPM: boolean } {
    const timeStr = `${hourStr}:${minuteStr}`;
    return this.parse(timeStr, isPm);
  }

  parse(timeStr: string, isPm?: boolean): { timeISO: string; hour24: string; hour12: string; minute: string; isPM: boolean } {
    const [hour, minute] = this.splitTime(timeStr);
    const isPM = isPm ?? this.isPM(hour);
    const parsedHour12 = !isNaN(hour) ? this.parseHour12(hour, isPM) : this.parseHour12(0, isPM);
    const parsedHour24 = !isNaN(hour) ? this.parseHour24(hour, isPM) : this.parseHour24(0, isPM);
    const parsedMinute = !isNaN(minute) ? this.parseMinute(minute) : this.parseMinute(0);
    return { timeISO: `${parsedHour24}:${parsedMinute}`, hour24: parsedHour24, hour12: parsedHour12, minute: parsedMinute, isPM };
  }

  parseHour12(hour: number, isPm: boolean): string {
    return (isPm && hour > 12 ? hour - 12 : hour).toString().padStart(2, '0');
  }

  parseHour24(hour: number, isPm: boolean): string {
    return this.parseHour24AsNumber(hour, isPm)
      .toString()
      .padStart(2, '0');
  }

  parseHour24AsNumber(hour: number, isPm: boolean): number {
    if (isPm && hour < 12) {
      return hour + 12;
    } else if (!isPm && hour > 12) {
      return hour - 12;
    } else if (!isPm && hour === 12) {
      return 0;
    } else {
      return hour;
    }
  }

  parseMinute(minute: number): string {
    return minute.toString().padStart(2, '0');
  }

  splitTime(timeStr: string): TimeTuple {
    return (timeStr.split(':') as [string, string]).map((it: string) => Number(it)) as TimeTuple;
  }

  isPM(hour: number | string): boolean {
    return Number(hour) >= 12;
  }
}

export type Hour = number;
export type Minute = number;
export type TimeTuple = [Hour, Minute];
