import { Injectable } from '@angular/core';
import { IValidatorService } from './validator.service';
import { AttributeValue } from '../models/attribute';
import { ValidatorType } from '../models/validator';
import { UrlValidator } from '../models/validator/url-validator';

@Injectable({
  providedIn: 'root'
})
export class UrlValidatorService implements IValidatorService {

  private static readonly MARKDOWN_REGEXP = /^\[([^\]]*)]\(([^)]*)\)$/;

  static extractUrl(str: string): string {
    const match = UrlValidatorService.MARKDOWN_REGEXP.exec(str);
    if (match) {
      return match[2];
    }
    return str;
  }

  static extractDisplayText(str: string): string {
    const match = UrlValidatorService.MARKDOWN_REGEXP.exec(str);
    if (match) {
      return match[1];
    }
    return str
  }

  static isUrl(str: string): boolean {
    try {
      new URL(UrlValidatorService.extractUrl(str))
      return true;
    } catch (e: unknown) {
      return false;
    }
  }

  validate(displayName: string, validator: UrlValidator, value: AttributeValue): { [p: string]: any } {
    if (!value) return {}; // Short circuit if the value is missing

    if (typeof value !== 'string') {
      return { [ValidatorType.URL]: { value, message: $localize`:@@url-validator__invalid--not-a-string:${displayName}:displayName: must be a string.`}};
    }

    if(!UrlValidatorService.isUrl(value)){
      return { [ValidatorType.URL]: { value, message: $localize`:@@url-validator__invalid--not-a-url:${displayName}:displayName: must be a valid URL or Markdown link.`}};
    }

    return {};
  }
}
