import { Inject, Injectable, LOCALE_ID, OnDestroy } from '@angular/core';
import { BehaviorSubject, from, NEVER, Observable, Subject, throwError } from 'rxjs';
import { catchError, concatMap, filter, map, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { APP_CONFIG, AppConfig } from '../../app-config';
import { HttpClient } from '@angular/common/http';
import { Auth0Client, AuthorizationParams, createAuth0Client, TokenEndpointResponse } from '@auth0/auth0-spa-js';
import { NotificationService } from '../notifications-module/services/notification.service';
import { ConfigService } from '../services/config.service';

@Injectable({
  providedIn: 'root',
})
export class Auth0Service implements OnDestroy {
  private callbackUrl = `${ window.location.origin }/${ this.locale === 'en-US' ? '' : `${ this.locale }/` }callback`;
  private destroy$ = new Subject();
  private auth0Client$ = from(createAuth0Client({
    domain: this.config.auth0.domain,
    clientId: this.config.auth0.clientId,
    authorizationParams: {
      redirect_uri: this.callbackUrl,
      audience: 'https://mercury.r36.dev',
    },
    useRefreshTokens: true,
    cacheLocation: (window.hasOwnProperty('Cypress') ? 'localstorage' : 'memory'),
  }))
    .pipe(shareReplay(1));

  // set to true once handleAuthCallback has completed
  private initializedSubject = new BehaviorSubject<boolean>(false);

  // emits once handleCallback has completed
  private initialized$ = this.initializedSubject.pipe(
    filter(initialized => initialized), // only when initialized is true
    take(1), // only emit a single event
  );

  isAuthenticated$ = this.initialized$.pipe(
    concatMap(() => this.auth0Client$),
    concatMap((client: Auth0Client) => from(client.isAuthenticated())),
  );

  handleRedirectCallback$ = this.auth0Client$
    .pipe(
      concatMap((client: Auth0Client) => from(client.handleRedirectCallback())
        .pipe(
          catchError(error => {
            if ((error.message as string).toLowerCase() === 'invalid state') {
              window.location.replace('/');
              return NEVER;
            }
            return throwError(() => error);
          })
        )));

  constructor(
    private router: Router,
    @Inject(APP_CONFIG) private config: AppConfig,
    private configService: ConfigService,
    @Inject(LOCALE_ID) private locale: string,
    private http: HttpClient,
    private notificationService: NotificationService,
  ) {
    // Handle redirect from Auth0 login
    this.handleAuthCallback();
  }

  getUser$(): Observable<any> {
    return this.isAuthenticated$.pipe(
      filter(isAuthenticated => isAuthenticated),
      concatMap(() => this.auth0Client$),
      concatMap((client: Auth0Client) => from(client.getUser())),
    );
  }

  // When calling, options can be passed if desired
  // https://auth0.github.io/auth0-spa-js/classes/auth0client.html#gettokensilently
  getTokenSilently$(options?: any): Observable<Pick<TokenEndpointResponse, 'id_token' | 'access_token' | 'expires_in' | 'scope'>> {
    return this.isAuthenticated$.pipe(
      filter(isAuthenticated => isAuthenticated),
      concatMap(() => this.auth0Client$),
      concatMap((client: Auth0Client) => from(client.getTokenSilently(options))),
    );
  }

  login(redirectPath: string, authorizationParams: Partial<AuthorizationParams> = {}): Observable<void> {
    return this.auth0Client$.pipe(
      concatMap((client: Auth0Client) =>
        client.loginWithRedirect({
          authorizationParams: {
            redirect_uri: this.callbackUrl,
            audience: 'https://mercury.r36.dev',
            ...authorizationParams,
          },
          appState: { target: redirectPath },
        }),
      ),
    );
  }

  handleAuthCallback(): Observable<{ loggedIn: boolean; targetUrl: string }> | void {
    const params = window.location.search;
    if (!(params.includes('code=') && params.includes('state='))) {
      // user has not been redirected from login, nothing to do
      this.initializedSubject.next(true);
      return;
    }

    // if the user has been redirected, handle the redirect
    this.handleRedirectCallback$
      .pipe(
        tap(() => this.initializedSubject.next(true)),
        concatMap(cbRes => this.isAuthenticated$.pipe(map(loggedIn => ({
          loggedIn,
          targetUrl: cbRes.appState && cbRes.appState.target ? cbRes.appState.target : '/',
        })))),
        switchMap(result => this.router.navigateByUrl(result.targetUrl)),
        take(1),
      )
      .subscribe();
  }

  logout() {
    // Ensure Auth0 client instance exists
    this.auth0Client$.pipe(take(1)).subscribe((client: Auth0Client) => {
      // Call method to log out
      client.logout({
        clientId: this.config.auth0.clientId,
        logoutParams: {
          returnTo: window.location.origin,
        },
      });
    });
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  sendPasswordResetEmail() {
    this.http
      .post(
        `https://${ this.config.auth0.domain }/dbconnections/change_password`,
        {
          client_id: this.config.auth0.clientId,
          email: this.configService.user.email,
          connection: 'Username-Password-Authentication',
        },
        {
          headers: { 'content-type': 'application/json' },
          responseType: 'text',
        },
      )
      .subscribe({
        complete: () => this.notificationService.showNotificationText($localize`:@@auth-service__reset-password--success:An email has been sent for you to reset your password.`),
        error: errors =>
          this.notificationService.showErrorNotification(
            $localize`:@@auth-service__reset-password--error:An error occurred when trying to reset your password, please try again or contact your administrator.`,
            errors,
          ),
      });
  }

  isR36User(): Observable<boolean> {
    return this.getUser$().pipe(map(currUser => currUser.email.endsWith('@routethirtysix.com')));
  }
}
