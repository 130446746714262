import { Injectable } from '@angular/core';

export type Permission = string[][];

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  private readonly WILDCARD_TOKEN = '*';
  private readonly ANY_TOKEN = '!';
  private readonly PART_DIVIDER_TOKEN = ':';
  private readonly SUBPART_DIVIDER_TOKEN = ',';

  constructor() {}

  resolvePermission(permission: string): Permission {
    return permission
      .trim()
      .split(this.PART_DIVIDER_TOKEN)
      .map(part => part.split(this.SUBPART_DIVIDER_TOKEN).map(subPart => subPart.trim()));
  }

  /**
   * @description Returns <code>true</code> if this subjectPermission implies all the functionality and/or resource access described by
   * the specified <code>Permission</code> argument, <code>false</code> otherwise
   */
  implies(subjectPermission: Permission, permission: Permission) {
    if (subjectPermission.length === 0) return false;

    for (let i = 0, len = subjectPermission.length; i < len; i++) {
      const userPart = subjectPermission[i];
      if (i < permission.length) {
        const wildcardPart = permission[i];
        if (!this.containsWildCardToken(userPart) && !this.containsAll(userPart, wildcardPart) && !this.containsAnyToken(wildcardPart)) {
          return false;
        }
      } else {
        if (!this.containsWildCardToken(userPart) && !this.containsAnyToken(permission[permission.length - 1])) {
          return false;
        }
      }
    }
    return true;
  }

  private containsWildCardToken(part: string[]): boolean {
    return part.indexOf(this.WILDCARD_TOKEN) > -1;
  }

  private containsAnyToken(part: string[]): boolean {
    return part.indexOf(this.ANY_TOKEN) > -1;
  }

  /**
   * @description Returns <code>true</code> if all wildcard parts are contained in user's parts, <code>false</code> otherwise.
   */
  private containsAll(userPart: string[], wildcardPart: string[]): boolean {
    return wildcardPart.every(wPart => userPart.includes(wPart));
  }
}
