import { Component, Inject } from '@angular/core';
import { OVERLAY_CONTROL, OverlayControl } from '../../../services/overlay.service';

@Component({
  selector: 'app-allow-popup-modal',
  templateUrl: './allow-popup-modal.component.html',
  styleUrl: './allow-popup-modal.component.sass'
})
export class AllowPopupModalComponent {
  constructor(@Inject(OVERLAY_CONTROL) protected overlayCtrl: OverlayControl) {
  }
}
