import { Component, ElementRef, Input, OnInit, Self, ViewChild } from '@angular/core';
import * as uuid from 'uuid';
import { ControlValueAccessor, NgControl, ValidationErrors } from '@angular/forms';

@Component({
  selector: '[formControl] app-number-field, [label] [formControlName] app-number-field',
  templateUrl: './number-field.component.html',
  styleUrls: ['../input-field.sass']
})
export class NumberFieldComponent implements ControlValueAccessor, OnInit {
  @Input() label: string | undefined;
  @Input() labelClasses: string | undefined;
  @Input() min = '';
  @Input() max = '';
  @Input() placeholder = $localize`:@@default-input-placeholder:Please enter a value`;
  @Input() showErrorsAboveInput = true;
  @Input() inputTitle: string | undefined;

  @ViewChild('inputElement', { static: true }) input: ElementRef<HTMLInputElement> | undefined;

  uid = uuid.v4();

  private onChanged: (value: number | null) => void = () => {};
  private onTouched: any = () => {};

  constructor(@Self() public control: NgControl) {
    this.control.valueAccessor = this;
  }

  ngOnInit(): void {
    const control = this.control.control;
    const validators = control?.validator ? [control.validator, () => this.validate()] : () => this.validate();
    control?.setValidators(validators);
    control?.updateValueAndValidity({ onlySelf: true, emitEvent: false });
  }

  writeValue(value: number | null): void {
    if (this.input) {
      if (value == null) {
        this.input.nativeElement.value = '';
      } else {
        this.input.nativeElement.valueAsNumber = value;
      }
    }
  }

  onInputValueChanged(event: Event) {
    const value = (event.target as HTMLInputElement).valueAsNumber;
    if(Number.isNaN(value)){
      this.onChanged(null);
    } else {
      this.onChanged(value);
    }
  }

  onInputTouched() {
    this.onTouched();
  }

  registerOnChange(fn: any): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (this.input) this.input.nativeElement.disabled = isDisabled;
  }

  validate(): ValidationErrors | null {
    return this.input?.nativeElement.validity.valid
      ? null
      : { ['number']: { message: $localize`:@@dynamic-form__validator--number:${this.label?.replace(/[:|*]/g, '') ?? ''}:displayName: must be a number.` } };
  }


}
