<app-modal
  modalTitle="Error:"
  i18n-modalTitle="@@report-error-modal__modal-title"
  [subtitle]="subtitle"
  confirmButtonText="Close"
  i18n-confirmButtonText="@@report-error-modal__confirm-button-text"
  [cancelButtonText]="null"
  overlayClass="error"
  (confirmEvent)="onConfirm()"
  appFocusFirstFocusableElement
>
  <app-modal-result [error]="errors"></app-modal-result>
</app-modal>
