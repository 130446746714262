import { Injectable } from '@angular/core';
import { IValidatorService } from './validator.service';
import { AttributeValue } from '../models/attribute';
import { RequiredValidator } from '../models/validator/required-validator';
import { ValidatorType } from '../models/validator';

@Injectable({
  providedIn: 'root',
})
export class RequiredValidatorService implements IValidatorService {
  validate(displayName: string, validator: RequiredValidator, value: AttributeValue | null | undefined): { [key: string]: any | null } {
    if (value === null || value === undefined || value === '' || (typeof value === 'string' && value.trim() === '')) {
      return {[ValidatorType.REQUIRED]: {value, message: $localize`:@@required-validator__invalid:${displayName}:displayName: is required.`}};
    }

    return {};
  }
}
