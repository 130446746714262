import { Config } from '../../models/config/config';

export interface State {
  config: Config | null;
  outstandingRequests: number;
}

export const initialState: State = {
  config: null,
  outstandingRequests: 0,
};
