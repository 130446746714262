import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { FormValueToCriteriaService } from '../../aql-module/services/form-value-to-criteria.service';
import { AqlQuery } from 'src/app/aql-module/models/aql/aql-query';
import { positiveIntOrNull } from '../../aql-module/utils/form-validators';
import { AqlQuantity } from 'src/app/aql-module/models/aql/aql-quantity';
import { AqlFunctionValue, AqlValue, AqlFunction, AqlFunctionTypes } from '../../aql-module/models/aql/aql-value';
import { AqlPriority } from '../../aql-module/models/aql/aql-priority';

@Injectable()
export class FormValueToAqlQueryService implements OnDestroy {
  private destroy$ = new Subject();

  constructor(private formValueToCriteria: FormValueToCriteriaService) {}

  queryFormToAql(queryFormVal: any): AqlQuery {
    return {
      quantity: this.quantityFormToAql(queryFormVal.quantity),
      where: this.formValueToCriteria.criteriaFormToAql(queryFormVal.where),
      whereFixture: null,
      priority: this.priorityFormToAql(queryFormVal.priority),
      isAnyFixtureExists: false,
    };
  }

  quantityFormToAql(quantityFormVal: any): AqlQuantity {
    const qtyFormValAsNum = positiveIntOrNull(quantityFormVal);
    if (qtyFormValAsNum) {
      // It's a number
      return { type: 'numeric', value: qtyFormValAsNum };
    }
    if (typeof quantityFormVal === 'string') {
      // It's a field
      return { type: 'field', field: quantityFormVal };
    }
    if (quantityFormVal.fnType === 'group' && quantityFormVal.values.length === 1) {
      if (quantityFormVal.values[0] == null) return { type: 'numeric', value: quantityFormVal.values[0] };
      // It's a group of 1 value then remove the group
      return this.quantityFormToAql(quantityFormVal.values[0]);
    }

    let aqlQuantity: AqlQuantity = quantityFormVal.values.reduce((accumulator: AqlValue, current: any, i: number) => {
      if (accumulator == null) {
        return this.quantityFormToAql(current);
      } else {
        const functionType = quantityFormVal.operators[i - 1];
        return {
          type: 'function',
          function: { type: functionType, left: accumulator, right: this.quantityFormToAql(current) } as AqlFunction,
        } as AqlFunctionValue;
      }
    }, null);

    // If the function is group and we're not the root group then wrap the criteria in a group
    if (quantityFormVal.fnType === 'group') {
      aqlQuantity = { type: 'function', function: { type: AqlFunctionTypes.GROUP, value: aqlQuantity } };
    }

    // If the function is a rounding function then wrap the criteria in a rounding function
    if (quantityFormVal.fnType === AqlFunctionTypes.ROUND || quantityFormVal.fnType === AqlFunctionTypes.ROUNDUP || quantityFormVal.fnType === AqlFunctionTypes.ROUNDDOWN) {
      aqlQuantity = { type: 'function', function: { type: quantityFormVal.fnType, value: aqlQuantity, roundTo: quantityFormVal.fnParam1 } };
    }

    return aqlQuantity;
  }

  priorityFormToAql(priorityFormVal: AqlPriority | null): AqlPriority | null {
    if (priorityFormVal == null) {
      return null;
    }

    return {
      totalPriorities: priorityFormVal.totalPriorities,
      selectedPriorities: priorityFormVal.selectedPriorities.map((it: number, i: number) => (it ? i + 1 : null)).filter((it: any) => it != null).map(it => it as number),
      offset: priorityFormVal.offset ? this.quantityFormToAql(priorityFormVal.offset) : undefined,
    };
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
