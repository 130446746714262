import { Component, Input, OnInit } from '@angular/core';
import { Task, TaskId } from '../../models/task';
import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { ResultStringService } from '../../../shared-module/services/result-string.service';
import { TasksService } from '../../services/tasks.service';
import { TaskStatus } from '../../models/task-status';

@Component({
  selector: 'app-task [task]',
  templateUrl: './task.component.html',
  styleUrls: ['./task.component.sass'],
})
export class TaskComponent implements OnInit {
  removeIcon = faTrashAlt;
  taskStatus = TaskStatus;
  deletingTask = false;

  @Input() task!: Task;

  constructor(private resultStringService: ResultStringService, private tasksService: TasksService) {}

  ngOnInit(): void {}

  triggerTaskAction(task: Task) {
    this.tasksService.triggerTaskActionAndAcknowledge(task);
  }

  removeTask(taskId: TaskId) {
    this.deletingTask = true;
    this.tasksService.deleteTask(taskId).subscribe({ complete: () => { this.deletingTask = false; } });
  }
}
