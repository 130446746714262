import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { Chrono, en, nl, ParsedComponents } from 'chrono-node';
import { DateTime } from 'luxon';

/**
 * @Deprecated - refactor to use two fields for date ranges and the DateTimeParserService
 */
@Injectable({
  providedIn: 'root',
})
export class HumanDateParserService {
  private casual: Chrono;
  private strict: Chrono;

  constructor(@Inject(LOCALE_ID) private locale: string) {
    switch (locale) {
      case 'nl':
        this.casual = nl.casual;
        this.strict = nl.strict;
        break;
      case 'en-GB':
        this.casual = new Chrono(en.configuration.createCasualConfiguration(true));
        this.strict = new Chrono(en.configuration.createConfiguration(true, true));
        break;
      default:
        // Yank format
        this.casual = en.casual;
        this.strict = en.strict;
    }
  }

  private static toDateTime(components: ParsedComponents, isStart: boolean): DateTime {
    return DateTime.fromObject({
      year: components.get('year') ?? undefined,
      month: components.get('month') ?? undefined,
      day: (components.isCertain('day') ?? undefined) || (!(components.isCertain('month') ?? undefined)) ? components.get('day') ?? undefined : isStart ? 1 : HumanDateParserService.getLastDayOfMonth(components) ?? undefined,
      hour: components.isCertain('hour') ?? undefined ? components.get('hour') ?? undefined : isStart ? 0 : 23,
      minute: components.isCertain('minute') ?? undefined ? components.get('minute') ?? undefined : isStart ? 0 : 59,
      second: components.isCertain('second') ?? undefined ? components.get('second') ?? undefined : isStart ? 0 : 59,
      millisecond: isStart ? 0 : 999,
    });
  }

  private static getLastDayOfMonth(components: ParsedComponents): number | undefined {
    return DateTime.fromObject({
      year: components.get('year') ?? undefined,
      month: components.get('month') ?? undefined,
    }).daysInMonth;
  }

  // Provides the current date/time - overridden in the tests
  private static referenceDate() {
    return new Date();
  }

  /**
   * Attempts to parse a time range in the past from a string of text.
   * Returns the parsed range as an array of 2 Luxon DateTimes or null if a range cannot be parsed.
   *
   * @param input A string of text to try and extract a time range from
   */
  parseHistoricRange(input: string): [DateTime, DateTime] | null {
    const refDate = HumanDateParserService.referenceDate();
    const result = this.casual.parse(input, refDate, { forwardDate: false });
    if (result.length === 0) return null;

    const firstMatch = result[0];
    const startDate = HumanDateParserService.toDateTime(firstMatch.start, true);
    let endDate;
    if (firstMatch.end != null) {
      endDate = HumanDateParserService.toDateTime(firstMatch.end, false);
    } else {
      // If this is a strict match then use the start date as the end date (otherwise assume it's relative to now)
      const strictResult = this.strict.parse(input, refDate, { forwardDate: false });
      if (strictResult.length === 0 && (firstMatch.start.isCertain('day') || !firstMatch.start.isCertain('month'))) {
        // Doesn't match in strict mode and we're sure about the day - probably relative to now
        endDate = DateTime.fromJSDate(refDate);
      } else {
        // Matches in strict mode - user has provided an exact time
        endDate = HumanDateParserService.toDateTime(firstMatch.start, false);
      }
    }
    return [startDate, endDate];
  }
}
