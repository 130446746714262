import { AqlQuantity } from './aql-quantity';

export type AqlValue = AqlFieldValue | AqlFunctionValue | AqlSimpleValue

export function isAqlValue(x: any): x is AqlValue {
  return isAqlFieldValue(x) || isAqlFunctionValue(x) || isAqlSimpleValue(x);
}

export function aqlValueToString(aqlValue: AqlValue | null): string {
  if (aqlValue == null) {
    return '';
  } else if (isAqlFieldValue(aqlValue)) {
    return aqlFieldValueToString(aqlValue);
  } else if (isAqlStringValue(aqlValue)) {
    return aqlStringValueToString(aqlValue);
  } else if (isAqlNumericValue(aqlValue)) {
    return aqlNumericValueToString(aqlValue);
  } else if (isAqlBooleanValue(aqlValue)) {
    return aqlBooleanValueToString(aqlValue);
  } else if (isAqlFunctionValue(aqlValue)) {
    return aqlFunctionValueToString(aqlValue);
  } else {
    throw Error(`Unknown aql value: '${JSON.stringify(aqlValue)}'.`);
  }
}

export type AqlSimpleValue = AqlNumericValue | AqlStringValue | AqlBooleanValue;

export function isAqlSimpleValue(x: any): x is AqlSimpleValue {
  return isAqlNumericValue(x) || isAqlStringValue(x) || isAqlBooleanValue(x);
}

export interface AqlNumericValue {
  type: 'numeric';
  value: number | null;
}

export function isAqlNumericValue(x: any): x is AqlNumericValue {
  return x && x.type === 'numeric';
}

export function aqlNumericValueToString(aqlNumericValue: AqlNumericValue): string {
  return aqlNumericValue.value?.toString() ?? '';
}

export interface AqlFieldValue {
  type: 'field';
  field: string;
}

export function isAqlFieldValue(x: any): x is AqlFieldValue {
  return x && x.type === 'field';
}

export function aqlFieldValueToString(aqlFieldValue: AqlFieldValue): string {
  if(/^\d+$/.test(aqlFieldValue.field)){
    return `\`${aqlFieldValue.field?.toString()}\``;
  }
  return aqlFieldValue.field?.toString();
}

export interface AqlStringValue {
  type: 'string';
  value: string;
}

export function isAqlStringValue(x: any): x is AqlStringValue {
  return x && x.type === 'string';
}

export function aqlStringValueToString(aqlStringValue: AqlStringValue): string {
  return `"${aqlStringValue.value.replaceAll('"', '\\"')}"`;
}

export interface AqlBooleanValue {
  type: 'boolean';
  value: boolean;
}

export function isAqlBooleanValue(x: any): x is AqlBooleanValue {
  return x && x.type === 'boolean';
}

export function aqlBooleanValueToString(aqlBooleanValue: AqlBooleanValue): string {
  if (aqlBooleanValue.value === true) {
    return 'TRUE';
  }
  return 'FALSE';
}

export interface AqlFunctionValue {
  type: 'function';
  function: AqlFunction;
}

export function isAqlFunctionValue(x: any): x is AqlFunctionValue {
  return x && x.type === 'function';
}

export function aqlFunctionValueToString(aqlFunctionValue: AqlFunctionValue): string {
  return aqlFunctionToString(aqlFunctionValue.function);
}

export type AqlFunction = AqlRoundingFunction | AqlMathOperatorFunction | AqlGroupFunction;
export enum AqlFunctionTypes {
  GROUP = 'group',
  ROUND = 'round',
  ROUNDUP = 'roundup',
  ROUNDDOWN = 'rounddown'
}

export function isAqlFunction(obj: any): obj is AqlFunction {
  return obj && isAqlRoundingFunction(obj) || isAqlMathOperatorFunction(obj) || isAqlGroupFunction(obj);
}

export function aqlFunctionToString(aqlFunction: AqlFunction): string {
  if (isAqlMathOperatorFunction(aqlFunction)) {
    return aqlMathOperatorFunctionToString(aqlFunction);
  } else if (isAqlRoundingFunction(aqlFunction)) {
    return aqlRoundingFunctionToString(aqlFunction);
  } else if (isAqlGroupFunction(aqlFunction)) {
    return aqlGroupFunctionToString(aqlFunction);
  } else {
    throw Error(`Unknown function: '${JSON.stringify(aqlFunction)}'.`);
  }
}

export type AqlRoundingFunction = AqlRoundFunction | AqlRoundUpFunction | AqlRoundDownFunction;
export type AqlRoundingFunctionType = AqlFunctionTypes.ROUNDDOWN | AqlFunctionTypes.ROUNDUP | AqlFunctionTypes.ROUND;

export function isAqlRoundingFunction(obj: any): obj is AqlRoundingFunction {
  return obj && (isAqlRoundFunction(obj) || isAqlRoundUpFunction(obj) || isAqlRoundDownFunction(obj));
}

export function isAqlRoundingFunctionType(obj: any): obj is AqlRoundingFunctionType {
  return obj && (obj === AqlFunctionTypes.ROUND || obj === AqlFunctionTypes.ROUNDUP || obj === AqlFunctionTypes.ROUNDDOWN);
}

export function aqlRoundingFunctionToString(aqlRoundingFunction: AqlRoundingFunction): string {
  if (isAqlRoundDownFunction(aqlRoundingFunction)) {
    return aqlRoundDownFunctionToString(aqlRoundingFunction);
  } else if (isAqlRoundFunction(aqlRoundingFunction)) {
    return aqlRoundFunctionToString(aqlRoundingFunction);
  } else if (isAqlRoundUpFunction(aqlRoundingFunction)) {
    return aqlRoundUpFunctionToString(aqlRoundingFunction);
  } else {
    throw Error(`Unknown rounding function: '${JSON.stringify(aqlRoundingFunction)}'.`);
  }
}

export type AqlMathOperatorFunction = AqlMultiplyFunction | AqlDivideFunction | AqlSubtractFunction | AqlAddFunction;

export function isAqlMathOperatorFunction(obj: any): obj is AqlMathOperatorFunction {
  return obj && isAqlMultiplyFunction(obj) || isAqlDivideFunction(obj) || isAqlSubtractFunction(obj) || isAqlAddFunction(obj);
}

export function aqlMathOperatorFunctionToString(aqlMathOperatorFunction: AqlMathOperatorFunction): string {
  if (isAqlMultiplyFunction(aqlMathOperatorFunction)) {
    return aqlMultiplyFunctionToString(aqlMathOperatorFunction);
  } else if (isAqlDivideFunction(aqlMathOperatorFunction)) {
    return aqlDivideFunctionToString(aqlMathOperatorFunction);
  } else if (isAqlSubtractFunction(aqlMathOperatorFunction)) {
    return aqlSubtractFunctionToString(aqlMathOperatorFunction);
  } else if (isAqlAddFunction(aqlMathOperatorFunction)) {
    return aqlAddFunctionToString(aqlMathOperatorFunction);
  } else {
    throw Error(`Unknown math operator function: '${JSON.stringify(aqlMathOperatorFunction)}'.`);
  }
}

export interface AqlGroupFunction {
  type: AqlFunctionTypes.GROUP;
  value: AqlQuantity | null;
}
export function isAqlGroupFunction(obj: any): obj is AqlGroupFunction {
  return obj && obj.type === AqlFunctionTypes.GROUP;
}
export function aqlGroupFunctionToString(aqlGroup: AqlGroupFunction): string {
  return `(${aqlValueToString(aqlGroup.value)})`;
}

export enum AqlMathOperators {
  MULTIPLY = 'multiply',
  DIVIDE = 'divide',
  SUBTRACT = 'subtract',
  ADD = 'add'
}

export interface AqlRoundFunction {
  type: AqlFunctionTypes.ROUND;
  value: AqlQuantity | null;
  roundTo: number;
}

export function isAqlRoundFunction(obj: any): obj is AqlRoundFunction {
  return obj && obj.type === AqlFunctionTypes.ROUND;
}

export function aqlRoundFunctionToString(aqlRoundFunction: AqlRoundFunction) {
  return aqlRoundFunction.roundTo === 1 || aqlRoundFunction.roundTo == null
    ? `ROUND(${aqlValueToString(aqlRoundFunction.value)})`
    : `ROUND(${aqlValueToString(aqlRoundFunction.value)}, ${aqlRoundFunction.roundTo})`;
}

export interface AqlRoundUpFunction {
  type: AqlFunctionTypes.ROUNDUP;
  value: AqlQuantity | null;
  roundTo: number;
}

export function isAqlRoundUpFunction(obj: any): obj is AqlRoundUpFunction {
  return obj && obj.type === AqlFunctionTypes.ROUNDUP;
}

export function aqlRoundUpFunctionToString(aqlRoundUpFunction: AqlRoundUpFunction) {
  return aqlRoundUpFunction.roundTo === 1 || aqlRoundUpFunction.roundTo == null
    ? `ROUNDUP(${aqlValueToString(aqlRoundUpFunction.value)})`
    : `ROUNDUP(${aqlValueToString(aqlRoundUpFunction.value)}, ${aqlRoundUpFunction.roundTo})`;
}

export interface AqlRoundDownFunction {
  type: AqlFunctionTypes.ROUNDDOWN;
  value: AqlQuantity | null;
  roundTo: number;
}

export function isAqlRoundDownFunction(obj: any): obj is AqlRoundDownFunction {
  return obj && obj.type === AqlFunctionTypes.ROUNDDOWN;
}

export function aqlRoundDownFunctionToString(aqlRoundDownFunction: AqlRoundDownFunction) {
  return aqlRoundDownFunction.roundTo === 1 || aqlRoundDownFunction.roundTo == null
    ? `ROUNDDOWN(${aqlValueToString(aqlRoundDownFunction.value)})`
    : `ROUNDDOWN(${aqlValueToString(aqlRoundDownFunction.value)}, ${aqlRoundDownFunction.roundTo})`;
}

export interface AqlMultiplyFunction {
  type: AqlMathOperators.MULTIPLY;
  left: AqlQuantity;
  right: AqlQuantity;
}

export function isAqlMultiplyFunction(obj: any): obj is AqlMultiplyFunction {
  return obj && obj.type === AqlMathOperators.MULTIPLY;
}

export function aqlMultiplyFunctionToString(aqlMultiplyFunction: AqlMultiplyFunction): string {
  return `${aqlValueToString(aqlMultiplyFunction.left)} * ${aqlValueToString(aqlMultiplyFunction.right)}`;
}

export interface AqlDivideFunction {
  type: AqlMathOperators.DIVIDE;
  left: AqlQuantity;
  right: AqlQuantity;
}

export function isAqlDivideFunction(obj: any): obj is AqlDivideFunction {
  return obj && obj.type === AqlMathOperators.DIVIDE;
}

export function aqlDivideFunctionToString(aqlDivideFunction: AqlDivideFunction): string {
  return `${aqlValueToString(aqlDivideFunction.left)} / ${aqlValueToString(aqlDivideFunction.right)}`;
}

export interface AqlSubtractFunction {
  type: AqlMathOperators.SUBTRACT;
  left: AqlQuantity;
  right: AqlQuantity;
}

export function isAqlSubtractFunction(obj: any): obj is AqlSubtractFunction {
  return obj && obj.type === AqlMathOperators.SUBTRACT;
}

export function aqlSubtractFunctionToString(aqlSubtractFunction: AqlSubtractFunction): string {
  return `${aqlValueToString(aqlSubtractFunction.left)} - ${aqlValueToString(aqlSubtractFunction.right)}`;
}

export interface AqlAddFunction {
  type: AqlMathOperators.ADD;
  left: AqlQuantity;
  right: AqlQuantity;
}

export function isAqlAddFunction(obj: any): obj is AqlAddFunction {
  return obj && obj.type === AqlMathOperators.ADD;
}

export function aqlAddFunctionToString(aqlAddFunction: AqlAddFunction): string {
  return `${aqlValueToString(aqlAddFunction.left)} + ${aqlValueToString(aqlAddFunction.right)}`;
}
