<ul *ngIf="formErrors" class="form-errors" data-test="form-field-error-list">
  <li *ngFor="let error of (formErrors | keyvalue)" [attr.data-test]="'form-field-validation-error(' + error.key + ')'">
    <!-- If it's one of our validators then it'll provide us with the error message to display -->
    <ng-container *ngIf="error.value.message; else regularValidatorErrors">{{ error.value.message }}</ng-container>

    <ng-template #regularValidatorErrors>
      <ng-container [ngSwitch]="error.key">
        <ng-container *ngSwitchCase="'required'" i18n="@@error-listing__validation-error--required">
          This field is required
        </ng-container>
        <ng-container *ngSwitchCase="'min'" i18n="@@error-listing__validation-error--min">
          This field has a minimum value of '{{ error.value.min }}'
        </ng-container>
        <ng-container *ngSwitchCase="'email'" i18n="@@error-listing__validation-error--email">
          This field must be a valid email address
        </ng-container>
      </ng-container>
    </ng-template>
  </li>
</ul>
