import { Validator, ValidatorType } from '../validator';

export interface RangeValidator extends Validator {
  type: ValidatorType.RANGE;
  min: number | null;
  max: number | null;
}

export function isRangeValidator(validator: Validator): validator is RangeValidator {
  return validator.type === ValidatorType.RANGE;
}
