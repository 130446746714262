import { AqlCriteria } from '../models/aql/aql-criteria';
import { AqlOperator } from '../models/aql/aql-operator';
import { AqlTypeConverterService } from './aql-type-converter.service';
import { Schema } from '../../attribute-module/models/schema';
import { Inject, Injectable, InjectionToken, OnDestroy } from '@angular/core';
import { filter, takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { KeyVal } from '../../models/typescript-types';
import { AqlSimpleValue } from '../models/aql/aql-value';
import { isArrayAttributeType } from '../../attribute-module/utils/attribute-type-utils';

export const FORM_VALUE_SCHEMA_IT = new InjectionToken<Observable<KeyVal<Schema>>>('FormValueToAqlQueryServiceSchema');

@Injectable()
export class FormValueToCriteriaService implements OnDestroy {
  private schema: { [p: string]: Schema; } | undefined;
  private destroy$ = new Subject();

  constructor(
    private aqlTypeConverterService: AqlTypeConverterService,
    @Inject(FORM_VALUE_SCHEMA_IT) schema$: Observable<KeyVal<Schema>>,
  ) {
    schema$
      .pipe(
        filter(config => config != null),
        takeUntil(this.destroy$)
      )
      .subscribe(schema => (this.schema = schema));
  }

  criteriaFormToAql(criteriaFormVal: any): AqlCriteria | null {
    if (criteriaFormVal == null || this.schema == null) {
      return null;
    }
    let not = criteriaFormVal.not;

    // If it's a group of 1 then we can remove the group
    while (criteriaFormVal.type !== 'operator' && criteriaFormVal.criteria.length === 1) {
      criteriaFormVal = criteriaFormVal.criteria[0];

      // Remember to flip the not if required
      if (criteriaFormVal.not) {
        not = !not;
      }
    }

    let result: AqlCriteria;
    if (criteriaFormVal.type === 'operator') {
      const type = this.schema[criteriaFormVal.left]?.type;

      if (type == null) {
        throw Error('Null field type when converting from Rule Builder form to AQL.');
      }

      if (criteriaFormVal.operator === AqlOperator.IN || criteriaFormVal.operator === AqlOperator.NIN) {
        result = {
          type: criteriaFormVal.operator === AqlOperator.IN ? 'in' : 'nin',
          left: { type: 'field', field: criteriaFormVal.left },
          right: this.aqlTypeConverterService.formValueToAqlSimpleValueArray(type, criteriaFormVal.right),
        };
      } else if (criteriaFormVal.operator === AqlOperator.EXISTS || criteriaFormVal.operator === AqlOperator.NEXISTS) {
        result = {
          type: criteriaFormVal.operator === AqlOperator.EXISTS ? 'exists' : 'nexists',
          field: {type: 'field', field: criteriaFormVal.left},
        };
      } else if (criteriaFormVal.operator === AqlOperator.CONTAINS_NONE || criteriaFormVal.operator === AqlOperator.CONTAINS_ANY || criteriaFormVal.operator === AqlOperator.CONTAINS_ALL) {
        result = {
          type: 'contains',
          left: { type: 'field', field: criteriaFormVal.left },
          quantifier: criteriaFormVal.operator === AqlOperator.CONTAINS_NONE ? 'NONE'
            : criteriaFormVal.operator === AqlOperator.CONTAINS_ANY ? 'ANY'
            : 'ALL',
          right: this.aqlTypeConverterService.formValueToAqlSimpleValueArray(type, criteriaFormVal.right),
        }
      } else {
        result = {
          type: 'operator',
          left: { type: 'field', field: criteriaFormVal.left },
          right: this.aqlTypeConverterService.formValueToAqlSimpleValue(type, criteriaFormVal.right),
          operator: criteriaFormVal.operator,
        };
      }
    } else {
      // It is a group
      result = {
        type: criteriaFormVal.type,
        criteria: criteriaFormVal.criteria.map((it: any) => this.criteriaFormToAql(it)),
      };
    }

    if (not) {
      result = { type: 'not', criteria: result };
    }
    return result;
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
