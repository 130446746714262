import { Component, Input } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { Nullable } from '../../../models/typescript-types';

@Component({
  selector: 'app-error-listing',
  templateUrl: './error-listing.component.html',
  styleUrls: ['./error-listing.component.sass'],
})
export class ErrorListingComponent {
  @Input() formErrors: Nullable<ValidationErrors>; // type is ValidationErrors - we don't type because keyvalue pipe requires it as an iterable
}
