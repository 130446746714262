import { Component, Input } from '@angular/core';
import { Nullable } from '../../../models/typescript-types';
import { IconEnum } from '../icon/icon.component';

@Component({
  selector: 'app-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.sass'],
})
export class LoadingSpinnerComponent {
  loadingText: string | null = $localize`:@@Text-loading:Loading...`;
  iconClasses: string = 'icon-warning icon-xl';
  loadingTextClasses: string = 'txt-grey-m';
  @Input('setLoadingText') set setLoadingText(text: Nullable<string>) {
    if (text) this.loadingText = text;
  }
  @Input() disableLoadingText = false;
  @Input('iconClasses') set setIconClasses(classes: string) {
    if (classes) this.iconClasses = classes;
  }
  @Input('loadingTextClasses') set setLoadingTextClasses(classes: string) {
    if (classes) this.loadingTextClasses = classes;
  }
  protected readonly IconEnum = IconEnum;
}
