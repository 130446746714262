import { initialState } from './state';
import { createReducer, on } from '@ngrx/store';
import { LoadSuccessfulAction, RequestFailedAction, RequestSentAction, RequestSuccessfulAction } from './actions';


export const Reducer = createReducer(
  initialState,

  on(RequestSentAction, state => ({...state, outstandingRequests: state.outstandingRequests + 1})),
  on(RequestFailedAction, (state, action) => ({...state, outstandingRequests: state.outstandingRequests - 1, error: action.error})),
  on(RequestSuccessfulAction, (state) => ({...state, outstandingRequests: state.outstandingRequests - 1 })),

  on(LoadSuccessfulAction, (state, action) => ({...state, config: {...action.config}}))

);
