import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { APP_CONFIG, AppConfig } from '../../../app-config';
import { Observable } from 'rxjs';
import { Config } from '../models/config/config';
import { extractRestValue } from '../../services/api-utils';
import { RestResponse } from '../../models/rest-response';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {

  constructor(private http: HttpClient, @Inject(APP_CONFIG) private config: AppConfig) {}

  getConfig(): Observable<Config> {
    return this.http.get<RestResponse<Config>>(`${this.config.apiRoot}/allocation/v1/config`).pipe(extractRestValue());
  }
}
