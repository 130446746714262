import { Pipe, PipeTransform } from '@angular/core';
import { AqlQuery, aqlQueryToString } from '../../aql-module/models/aql/aql-query';

@Pipe({
  name: 'aqlToString'
})
export class AqlToStringPipe implements PipeTransform {

  transform(aql: AqlQuery): string {
    return aqlQueryToString(aql);
  }

}
