import { AfterViewInit, Component, ElementRef, HostBinding, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { filter, map, takeUntil } from 'rxjs/operators';
import { delay, Observable, Subject } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { Location } from '@angular/common';
import { LanguageService } from '../../services/language.service';
import { Option } from '../../shared-module/entities/form-entities';
import { FormControl, Validators } from '@angular/forms';
import { faChevronDoubleRight, faPersonFromPortal, faShuffle, faStore, faSync, faTasks } from '@fortawesome/pro-regular-svg-icons';
import { ConnectedPosition } from '@angular/cdk/overlay';
import { TasksService } from '../../tasks-module/services/tasks.service';
import { faCog } from '@fortawesome/pro-solid-svg-icons';
import { SETTINGS_PERMISSIONS } from '../../auth-module/permissions';
import { Auth0Service } from '../../auth0/auth0.service';
import { Task } from '../../tasks-module/models/task';
import { createConnectedPosition, TooltipDirection } from '../../shared-module/services/tooltip-helpers';
import { User } from '../../user-module/models/user';
import { ConfigService } from '../../services/config.service';
import { entityShortsToOptions } from '../../services/utils';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.sass'],
})
export class SidebarComponent implements OnInit, AfterViewInit, OnDestroy {
  private destroy$ = new Subject();
  readonly settingsPagePermissions = SETTINGS_PERMISSIONS;
  protected readonly tooltipPosition: { direction: TooltipDirection, connectedPosition?: ConnectedPosition } = { direction: 'to-left', connectedPosition: createConnectedPosition('to-left', 20) };
  availableClients: Option[];
  selectedClient: Option;
  user: User;

  currentRoute: string | undefined;
  titles = {
    allocations: {
      withPermission: $localize`:@@sidebar__nav-button--campaigns:Campaigns`,
      withoutPermission: $localize`:@@sidebar-component_allocation-without-permission:You don't have access to the Campaigns Module`,
    },
    siteDB: {
      withPermission: $localize`:@@Button-Store-Database:Site Database`,
      withoutPermission: $localize`:@@sidebar-component_site-db-without-permission:You don't have access to the Site Database Module`,
    },
    userSettings: $localize`:@@sidebar-component_user-profile:User Profile`,
    logOut: $localize`:@@sidebar-component_logout:Logout`,
  };
  iconClasses = {
    enabled: 'sidebar-icon',
    disabled: 'sidebar-icon disabled',
  };
  cogIcon = faCog;
  faPersonFromPortal = faPersonFromPortal;
  faChevronDoubleRight = faChevronDoubleRight;
  faShuffle = faShuffle;
  tasksIcon = faTasks;
  faStore = faStore;
  syncIcon = faSync;
  availableLocales: Option[] = [
    { value: 'en-GB', label: 'English' },
    { value: 'nl', label: 'Nederlands' },
  ];
  clientsForm = new FormControl<string>(this.config.selectedClient, {...Validators.required, nonNullable: true});
  localesForm = new FormControl<string>(this.languageService.getCurrentLocale(), {...Validators.required, nonNullable: true});
  isCurrentTasksVisible = false;
  currentTasksPositions: ConnectedPosition[] = [
    {
      originX: 'end',
      originY: 'bottom',
      overlayX: 'start',
      overlayY: 'bottom',
    },
  ];
  tasks$: Observable<Task[]>;
  unreadTasksCount$: Observable<number>;
  tasksRunning$: Observable<boolean>;
  cdkOffsetY$: Observable<number>;
  overlayOriginEl: HTMLElement | null = null;

  @ViewChild('currentTasks', { read: ElementRef }) currentTasksElRef: ElementRef | undefined;

  constructor(
    public auth0Service: Auth0Service,
    @Inject(Location) private location: Location,
    private router: Router,
    private languageService: LanguageService,
    private tasksService: TasksService,
    private config: ConfigService,
  ) {
    this.user = config.user;
    this.availableClients = entityShortsToOptions(this.user.clients);
    const selectedClient = this.availableClients.find(it => it.value === this.config.selectedClient);
    if(selectedClient == null) throw new Error(`Selected client is not available for this user (selected client is '${this.config.selectedClient}').`);
    this.selectedClient = selectedClient;

    // get the currently active module
    this.currentRoute = this.getCurrentLocation();
    router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(_ => this.currentRoute = this.getCurrentLocation());


    this.tasks$ = this.tasksService.tasks$.pipe(
      // map the tasks to show all unread tasks plus unread tasks if there are less than 10 tasks
      map(tasks => {
        // if there are more than 10 tasks we need to filter them
        if (tasks.length > 10) {
          const unreadTasks = tasks.filter(task => !task.acknowledged);
          const diff = 10 - unreadTasks.length;
          // if we have less than 10 unread tasks, get the diff amount of read tasks then sort by createdAt
          if (diff > 0) {
            return [...unreadTasks, ...tasks.filter(task => task.acknowledged).slice(0, diff)].sort((a, b) => b.createdAt.localeCompare(a.createdAt));
          } else {
            return unreadTasks;
          }
        }
        // else return as is
        return tasks;
      }),
      takeUntil(this.destroy$)
    );
    this.unreadTasksCount$ = this.tasksService.unreadTasksCount$.pipe(takeUntil(this.destroy$));
    this.tasksRunning$ = this.tasksService.tasksRunning$.pipe(takeUntil(this.destroy$));
    this.cdkOffsetY$ = this.tasks$.pipe(
      map(tasks => (tasks.length > 1 ? 25 : 0)),
      takeUntil(this.destroy$)
    );
  }

  @HostBinding('class.collapsed') isSidebarCollapsed = true;

  private getCurrentLocation(): string {
    return this.location.path().split('/')[1];
  }

  ngOnInit() {
    this.clientsForm.valueChanges.subscribe((selectedClient: string) => this.config.changeClient(selectedClient));
    this.localesForm.valueChanges.subscribe(locale => this.languageService.changeLanguage(locale));
  }

  ngAfterViewInit() {
    // if the tasks change we update the arrow position on the overlay
    this.tasks$.pipe(delay(0), takeUntil(this.destroy$)).subscribe(_ => this.onOverlayPositionChange());
  }

  toggleCollapseSidebar() {
    this.isSidebarCollapsed = !this.isSidebarCollapsed;
    // we need to set the root variable current-sidebar width to the expanded or collapsed sized depending
    document.documentElement.style.setProperty('--current-sidebar-width', `var(--${this.isSidebarCollapsed ? 'collapsed-sidebar-width' : 'sidebar-width'})`);
  }

  goToAllocations() {
    this.router.navigate(['/jobs']);
  }

  goToStoreDB() {
    this.router.navigate(['/sites']);
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  toggleCurrentTasks(el: HTMLElement | null, bool?: boolean) {
    this.overlayOriginEl = el;
    this.isCurrentTasksVisible = bool ?? !this.isCurrentTasksVisible;
  }

  /**
   * Sets the top and offset top on the overlay arrow indicator
   */
  onOverlayPositionChange() {
    if (!this.currentTasksElRef || !this.overlayOriginEl) return;
    const tasksButtonBoundingRect = this.overlayOriginEl.getBoundingClientRect();
    const currentTasksBoundingRect = this.currentTasksElRef.nativeElement.getBoundingClientRect();
    const currentTasksEl = this.currentTasksElRef.nativeElement;

    currentTasksEl.style.setProperty('--top-before', `${tasksButtonBoundingRect.top - currentTasksBoundingRect.top + tasksButtonBoundingRect.height / 2 - 7}px`);
    currentTasksEl.style.setProperty('--top-before-offset', `${0}px`);
    currentTasksEl.style.setProperty('--top-after', `${tasksButtonBoundingRect.top - currentTasksBoundingRect.top + tasksButtonBoundingRect.height / 2 - 6}px`);
    currentTasksEl.style.setProperty('--top-after-offset', `${0}px`);
  }
}
