import { isValidatorSchema, Schema } from './schema';

export interface Validator {
  type: ValidatorType;
}

export enum ValidatorType {
  ENUM = 'enum',
  CURRENCY = 'currency',
  DATE = 'date',
  REGEX = 'regex',
  ATTRIBUTE_KEY = 'attribute-key',
  REQUIRED = 'required',
  LENGTH = 'length',
  RANGE = 'range',
  LOCAL_DATE_TIME = 'local-date-time',
  PERCENTAGE = 'percentage',
  DATE_TIME = 'date-time',
  EMAIL = 'email',
  URL = 'url',
  PHONE_NUMBER = 'phone-number',
}

export function getFirstValidator(schema: Schema) {
  return schema && isValidatorSchema(schema) && schema.validators[0] ? schema.validators[0] : null;
}
