import { KeyVal } from './typescript-types';

export type ActivityId = string | undefined;

export interface RestResponse<T> {
  success: boolean;
  result?: T;
  errors?: IRestError[];
  activityId?: ActivityId;
}
export function isRestResponse(x: any): x is RestResponse<any> {
  if (x != null) {
    if (x.success === true) {
      return x.result !== undefined;
    } else if (x.success === false) {
      return x.errors !== undefined;
    }
  }
  return false;
}

export interface IRestError {
  code: string;
  message: string;
  context?: KeyVal<any> | null;
}

export interface ResultWithTotal<T> {
  total: number;
  result: T[];
}

export function isRestError(x: any): x is IRestError {
  return x.message !== undefined && x.code !== undefined;
}

export function isRestErrors(x: any): x is IRestError[] {
  return Array.isArray(x) && ((x.length > 0 && isRestError(x[0])) || x.length === 0);
}

export function asRestErrorsOrThrow(error: Error | IRestError | IRestError[]): IRestError[] {
  if (Array.isArray(error)) {
    return error;
  }
  if (isRestError(error)) {
    return [error];
  }
  throw error;
}
