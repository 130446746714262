import { AttributeType } from '../../attribute-module/models/attribute';
import { AbstractControl, ValidatorFn } from '@angular/forms';
import { Schema } from '../../attribute-module/models/schema';
import { fieldValidator, positiveIntOrNull } from '../../aql-module/utils/form-validators';

export function positiveNumberOrFieldValidator(siteSchema: { [p: string]: Schema }): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (positiveIntOrNull(control.value) != null) {
      return null;
    }
    return fieldValidator(siteSchema, [AttributeType.NUMBER, AttributeType.BOOLEAN])(control);
  };
}

export function isOneOfCheckboxesChecked(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null  => {
    if (control.value != null) {
      return control.value.some((value: any) => value) ? null : { 'query-builder__no-selected-checkbox': { value: control.value, message: $localize`:@@query-builder-modal__validation-error--no-selected-checkbox:Please select at least one checkbox` } };
    }
    else return null;
  };
}
