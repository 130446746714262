<div class="section module-section">
  <!--  TODO: add click events to module section links -->
  <ng-container *ngIf="isSidebarCollapsed; else expandedModuleSection">
    <div class="icon-group" [class.active]="currentRoute === 'jobs'">
      <button
        type="button"
        class="icon-btn"
        data-test="allocations-btn-collapsed"
        [disabled]="!('svc-allocation.!' | hasPermission)"
        (click)="goToAllocations()"
        [appTooltip]="('svc-allocation.!' | hasPermission) ? titles.allocations.withPermission : titles.allocations.withoutPermission"
        [position]="tooltipPosition"
      >
        <fa-icon [class]="('svc-allocation.!' | hasPermission) ? iconClasses.enabled : iconClasses.disabled" [class.icon-clickable]="'svc-allocation.!' | hasPermission" [icon]="faShuffle"></fa-icon>
      </button>
    </div>
    <div class="icon-group" [class.active]="currentRoute === 'sites'">
      <button
        type="button"
        class="js-sites-btn js-collapsed icon-btn"
        [disabled]="!(('svc-site-db.!' | hasPermission) && ('site-db' | hasFeature))"
        (click)="goToStoreDB()"
        [appTooltip]="(('svc-site-db.!' | hasPermission) && ('site-db' | hasFeature)) ? titles.siteDB.withPermission : titles.siteDB.withoutPermission"
        [position]="tooltipPosition"
      >
        <fa-icon [class]="(('svc-site-db.!' | hasPermission) && ('site-db' | hasFeature)) ? iconClasses.enabled : iconClasses.disabled" [icon]="faStore"></fa-icon>
      </button>
    </div>
  </ng-container>

  <ng-template #expandedModuleSection>
    <button
      type="button"
      class="sidebar-link"
      data-test="allocations-btn-expanded"
      [class.is-active]="currentRoute === 'jobs'"
      [disabled]="!('svc-allocation.!' | hasPermission)"
      (click)="goToAllocations()"
      [appTooltip]="('svc-allocation.!' | hasPermission) ? titles.allocations.withPermission : titles.allocations.withoutPermission"
      [position]="tooltipPosition"
    >
      <fa-icon class="icon left" [ngClass]="('svc-allocation.!' | hasPermission) ? iconClasses.enabled : iconClasses.disabled" [icon]="faShuffle"></fa-icon>
      <ng-container i18n="@@sidebar__nav-button--campaigns">Campaigns</ng-container>
    </button>
    <button
      type="button"
      class="js-sites-btn js-expanded sidebar-link"
      [class.is-active]="currentRoute === 'sites'"
      [disabled]="!(('svc-site-db.!' | hasPermission) && ('site-db' | hasFeature))"
      (click)="goToStoreDB()"
      [appTooltip]="(('svc-site-db.!' | hasPermission) && ('site-db' | hasFeature)) ? titles.siteDB.withPermission : titles.siteDB.withoutPermission"
      [position]="tooltipPosition"
    >
      <fa-icon class="left" [ngClass]="(('svc-site-db.!' | hasPermission) && ('site-db' | hasFeature)) ? iconClasses.enabled : iconClasses.disabled" [icon]="faStore"></fa-icon>
      <ng-container i18n="@@Button-Store-Database">Site Database</ng-container>
    </button>
  </ng-template>
</div>

<div class="section user-profile" data-test="user-profile-section">
  <button
    type="button"
    class="user-profile-btn"
    data-test="user-profile-button"
    [routerLink]="['/user-profile']"
    appTooltip="User Profile"
    i18n-appTooltip="@@sidebar__user-profile-button"
    [position]="tooltipPosition"
  >
    <div class="profile-image-container">
      <img data-test="profile-image" [src]="user.profilePicture" [alt]="user.displayAs + '\'s profile picture'" />
      <fa-icon class="icon" [icon]="cogIcon"></fa-icon>
    </div>
    <div class="profile-name" *ngIf="!isSidebarCollapsed">{{ user.displayAs }}</div>
  </button>
</div>

<div class="section sidebar-buttons">
  <ng-container *ngIf="isSidebarCollapsed; else expandedSidebarButtons">
    <button
      *ngIf="settingsPagePermissions | hasAnyPermissions"
      type="button"
      class="icon-btn"
      [ngClass]="iconClasses.enabled"
      data-test="settings-button-collapsed"
      [routerLink]="['/settings']"
      appTooltip="Go to settings"
      i18n-appTooltip="@@sidebar__go-to-settings-button--title"
      [position]="tooltipPosition"
    >
      <fa-icon class="icon" [icon]="cogIcon"></fa-icon>
    </button>

    <div class="tasks-btn-container" cdkOverlayOrigin #currentTasksOrigin="cdkOverlayOrigin" #openTasksButton>
      <button
        type="button"
        class="icon-btn {{ iconClasses.enabled }}"
        data-test="tasks-btn-collapsed"
        (click)="toggleCurrentTasks(openTasksButton)"
        appTooltip="Show Recent Tasks"
        i18n-appTooltip="@@sidebar__tasks-button--title"
        [position]="tooltipPosition"
      >
        <i *ngIf="(unreadTasksCount$ | async) ?? 0 > 0" class="unread-tasks-indicator">{{ unreadTasksCount$ | async }}</i>
        <i *ngIf="tasksRunning$ | async" class="tasks-running-indicator">
          <fa-icon class="icon" [icon]="syncIcon" animation="spin-pulse"></fa-icon>
        </i>
        <fa-icon class="sidebar-icon" [icon]="tasksIcon"></fa-icon>
      </button>
    </div>

    <div
      title="Selected Client: {{ selectedClient.label }}"
      i18n-title="@@sidebar-selected-client"
      class="client-abbr"
      data-test="client-abbr"
    >
      {{ selectedClient.label | slice: 0:2 | uppercase }}
    </div>
    <button
      type="button"
      class="js-logout-btn js-collapsed icon-btn {{ iconClasses.enabled }}"
      (click)="auth0Service.logout()"
      appTooltip="Log Out"
      i18n-appTooltip="@@sidebar__logout-button--title"
      [position]="tooltipPosition"
    >
      <fa-icon class="sidebar-icon" [icon]="faPersonFromPortal"></fa-icon>
    </button>
  </ng-container>

  <ng-template #expandedSidebarButtons>
    <button
      *ngIf="settingsPagePermissions | hasAnyPermissions"
      type="button"
      class="link-rel sidebar-link"
      data-test="settings-button-expanded"
      [routerLink]="['/settings']"
      appTooltip="Go to settings"
      i18n-appTooltip="@@sidebar__go-to-settings-button--title"
      [position]="tooltipPosition"
    >
      <fa-icon class="icon left" [ngClass]="iconClasses.enabled" [icon]="cogIcon"></fa-icon>
      <ng-container i18n="@@sidebar__go-to-settings-button">Settings</ng-container>
    </button>

    <div class="tasks-btn-container" cdkOverlayOrigin #currentTasksOrigin="cdkOverlayOrigin" #openTasksButton>
      <button
        type="button"
        class="link-rel sidebar-link"
        data-test="tasks-btn-expanded"
        (click)="toggleCurrentTasks(openTasksButton)"
        appTooltip="Show Recent Tasks"
        i18n-appTooltip="@@sidebar__tasks-button--title"
        [position]="tooltipPosition"
      >
        <i *ngIf="(unreadTasksCount$ | async) ?? 0 > 0" class="unread-tasks-indicator">{{ unreadTasksCount$ | async }}</i>
        <i *ngIf="tasksRunning$ | async" class="tasks-running-indicator">
          <fa-icon class="icon" [icon]="syncIcon" animation="spin-pulse"></fa-icon>
        </i>
        <fa-icon class="icon left" [ngClass]="iconClasses.enabled" [icon]="tasksIcon"></fa-icon>
        <ng-container i18n="@@sidebar__expanded-tasks-button--text">Tasks</ng-container>
      </button>
    </div>

    <app-select
      *ngIf="(availableClients?.length ?? 0) > 1"
      data-test="select-client"
      [options]="availableClients"
      defaultOption="Please select a client"
      i18n-defaultOption="@@sidebar__select__default-option--client"
      [formControl]="clientsForm"
      appTooltip="Change Client"
      i18n-appTooltip="@@sidebar__select__title--client"
      [position]="tooltipPosition"
    ></app-select>

    <app-select
      data-test="select-language"
      [options]="availableLocales"
      defaultOption="Please select a language"
      i18n-defaultOption="@@sidebar__select__default-option--language"
      [formControl]="localesForm"
      appTooltip="Change Language"
      i18n-appTooltip="@@sidebar__select__title--language"
      [position]="tooltipPosition"
    ></app-select>

    <button
      type="button"
      class="js-logout-btn js-expanded btn left"
      (click)="auth0Service.logout()"
      appTooltip="Log Out"
      i18n-appTooltip="@@sidebar__logout-button--title"
      [position]="tooltipPosition"
    >
      <ng-container i18n="@@Button-Log-Out">Log Out</ng-container>
      <fa-icon class="icon right" [ngClass]="iconClasses.enabled + ' icon-secondary'" [icon]="faPersonFromPortal"></fa-icon>
    </button>
  </ng-template>

  <ng-template
    #currentTasksCollapsed="cdkConnectedOverlay"
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="overlayOriginEl!"
    [cdkConnectedOverlayOpen]="isCurrentTasksVisible"
    [cdkConnectedOverlayFlexibleDimensions]="true"
    [cdkConnectedOverlayPositions]="currentTasksPositions"
    [cdkConnectedOverlayOffsetX]="isSidebarCollapsed ? 0 : 16"
    [cdkConnectedOverlayOffsetY]="(cdkOffsetY$ | async) ?? 0"
    (positionChange)="onOverlayPositionChange()"
  >
    <app-current-tasks
      [tasks]="(tasks$ | async)!"
      #currentTasks
      cdkTrapFocus
      cdkTrapFocusAutoCapture
      (appClickOutside)="toggleCurrentTasks(null, false)"
      appClickOutside
    ></app-current-tasks>
  </ng-template>
</div>

<div class="sidebar-control">
  <ng-container *ngIf="isSidebarCollapsed; else expandedSidebarCtrl">
    <button
      type="button"
      class="collapse-btn js-expand-sidebar-btn"
      (click)="toggleCollapseSidebar()"
      appTooltip="Expand Sidebar"
      i18n-appTooltip="@@sidebar-expand-title"
      [position]="tooltipPosition"
    >
      <fa-icon class="icon icon-m" [icon]="faChevronDoubleRight"></fa-icon>
    </button>
  </ng-container>
  <ng-template #expandedSidebarCtrl>
    <ng-container i18n="@@Sk-collapse">collapse</ng-container>
    <button
      type="button"
      class="collapse-btn js-collapse-sidebar-btn"
      (click)="toggleCollapseSidebar()"
      appTooltip="Collapse Sidebar"
      i18n-appTooltip="@@sidebar-collapse-title"
      [position]="tooltipPosition"
    >
      <fa-icon class="icon icon-m" [icon]="faChevronDoubleRight" [rotate]="180"></fa-icon>
    </button>
  </ng-template>
</div>
