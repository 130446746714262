import { NgModule } from '@angular/core';
import { FilterComponent } from './components/filter/filter.component';
import { SharedModule } from '../shared-module/shared.module';
import { ReactiveFormsModule } from '@angular/forms';
import { GetOptionsFunctionPipe } from './pipes/get-options-function.pipe';
import { IsBlankTypeOperatorPipe } from './pipes/is-blank-type-operator.pipe';

@NgModule({
  declarations: [FilterComponent, GetOptionsFunctionPipe, IsBlankTypeOperatorPipe],
  imports: [SharedModule, ReactiveFormsModule],
  exports: [FilterComponent],
})
export class AqlModule {}
