import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State } from './state';
import { JobTemplate } from '../../models/job/job-listing';
import { JobImporterConfig } from '../../models/job/job';
import { ClientSchemaElement } from '../../models/config/config';
import { DefinedAttributeSchema } from '../../../attribute-module/models/schema';
import { isNonNull } from '../../../utils/helpers';

export const selectConfigState = createFeatureSelector<State>('allocation-config');

export const selectConfigImporters = createSelector(selectConfigState, state => state.config?.importers ?? []);
export const selectConfigImportersForJobTemplate = createSelector(selectConfigImporters, (importers: JobImporterConfig[], {templateName}: { templateName: string; }) =>
  importers.filter(it => it.jobTemplates?.includes(templateName)));
export const selectConfigHasImportersForTemplate = createSelector(selectConfigImportersForJobTemplate, importers => importers.length > 0);
export const selectConfigFeatures = createSelector(selectConfigState, state => state.config?.features);
export const selectConfigFeaturesPricing = createSelector(selectConfigFeatures, features => features?.includes('pricing'));
export const selectConfigFeaturesCodeGeneration = createSelector(selectConfigFeatures, features => features?.includes('code-generation'));

export const selectConfigElementSchemaArray = createSelector(selectConfigState, state => state.config?.elementSchema ?? []);

export const selectConfigElementSchemaArrayForJobTemplate = createSelector(selectConfigElementSchemaArray, (schemas: ClientSchemaElement[], {templateName}: { templateName: string; }) =>
  schemas.filter(it => it.jobTemplates?.includes(templateName)));

export const selectConfigSiteSchemaArray = createSelector(selectConfigState, state => state.config?.siteSchema ?? []);

export const selectConfigJobListingKeys = createSelector(selectConfigState, state => state.config?.jobListingKeys ?? []);

export const selectConfigJobTemplatesArray = createSelector(selectConfigState, state => state.config?.jobTemplates ?? []);
export const selectConfigJobTemplatesArraySorted = createSelector(selectConfigJobTemplatesArray, jobTemplates => {
  if (!jobTemplates) return;
  const ret: JobTemplate[] = [];
  const defaultTemplate = jobTemplates.find(it => it.name === 'default');
  const alphabetizedTemplates = jobTemplates.filter(template => template.name !== 'default').sort((a, b) => a.displayName.localeCompare(b.displayName));
  if (defaultTemplate) ret.push(defaultTemplate);
  return [...ret, ...alphabetizedTemplates];
});
export const selectConfigJobTemplate = createSelector(selectConfigJobTemplatesArray, (templates: JobTemplate[], props: { templateName: string; }) =>
  (templates.find(it => it.name === props.templateName) as JobTemplate));
export const selectConfigJobTemplateSchemaArray = createSelector(selectConfigJobTemplate, template => template?.schema ?? []);
export const selectConfigJobTemplateEditableSchemaArray = createSelector(selectConfigJobTemplateSchemaArray, schema => schema.filter(it => !it.readOnly));

export const selectConfigJobTemplateElementKeys = (templateName: string) =>
  createSelector(selectConfigJobTemplatesArray, templates => (templates.find(it => it.name === templateName) as JobTemplate).elementKey ?? []);

export const selectConfigDefaultCurrency = createSelector(selectConfigState, state => state.config?.defaultCurrency ?? '');

/** Job Listing **/
export const selectConfigJobAllTemplatesSchema = createSelector(selectConfigJobTemplatesArray, (templates: JobTemplate[]) => templates
  .flatMap(it => it.schema)
  .reduce((acc, curr) => ({ ...acc, [curr.key]: curr }), {}) as Record<string, DefinedAttributeSchema>
);
export const selectConfigJobAllTemplatesSchemaListingsArray = createSelector(selectConfigJobAllTemplatesSchema, selectConfigJobListingKeys, (schema, listingKeys) =>
  listingKeys.map(listingKey => schema[listingKey]).filter(isNonNull)
);
