import { Validator, ValidatorType } from '../validator';

export interface RegexValidator extends Validator {
  type: ValidatorType.REGEX;
  regex: string;
  caseSensitive: boolean;
  errorMessage: string;
}

export function isRegexValidator(validator: Validator): validator is RegexValidator {
  return validator.type === ValidatorType.REGEX;
}
