<app-modal
  *ngIf="form"
  modalTitle="Allocation Rule Builder"
  i18n-modalTitle="@@query-builder-modal__title"
  confirmButtonText="Save"
  [subtitle]="element.displayAs"
  [overlayClass]="this.form!.valid ? 'standard' : 'error'"
  padding="small"
  size="size-90"
  [disableConfirmButton]="this.form!.invalid"
  i18n-confirmButtonText="@@query-builder-modal__confirm-button-text"
  cancelButtonText="Cancel"
  i18n-cancelButtonText="@@query-builder-modal__cancel-button-text"
  (confirmEvent)="onSaveClicked()"
  (cancelEvent)="onClose()"
  #modalComponent
>
<!--  [focusFormInput]="false"-->
  <div class="content">
    <div
      *ngFor="let queryCtrl of form.controls; let queryNo = index; let first = first; let last = last"
      id="aql-query-{{ queryNo }}"
      [attr.data-test]="'aql-query(' + queryNo + ')'"
      class="aql-query"
      [class.new]="last && (newQueryAdded$ | async)"
      [class.deleting]="(queryRemoved$ | async) === queryNo"
    >
      <div class="query-header" data-test="query-header">
        <app-save-query-template [queryForm]="$any(queryCtrl)"></app-save-query-template>

        <div *ngIf="queryCtrl && ($any(queryCtrl) | getQueryFromFormControl | forecast | async); let forecastValue" class="forecast-allocation" data-test="forecast-allocation">
          <fa-icon
            *ngIf="forecastValue && forecastValue === '0'"
            [icon]="warningIcon"
            class="icon icon-warning forecast-warning-icon"
            data-test="forecast-warning-icon"
            [appTooltip]="forecastAllocationTextTranslation"
          ></fa-icon>
          <ng-container i18n="@@query-builder-modal__forecast-allocation-text">
            Forecast Allocation: <span [class.warning-emphasize]="forecastValue === '0'">{{ forecastValue }}</span>
          </ng-container>
        </div>
      </div>

      <div class="query-box" data-test="query-box" #queryBox>
        <div class="toolbar" data-test="toolbar" [class.collapsed]="!queryCtrl.get('show')?.value" [class.invalid]="queryCtrl.invalid" (click)="showQueryBox($any(queryCtrl))" tabindex="0">
          <div data-test="toolbar-quantity" appFocusFirstFocusableElement>
            <div
              *ngIf="
                queryCtrl.get('show')?.value && !queryCtrl.get('showAdvancedQuantity')?.value && getBasicQuantity($any(queryCtrl.get('quantity')));
                let basicQtyCtrl;
                else: advancedQueryTxt
              "
              data-test="toolbar-basic-qty"
              class="input-group--inline"
            >
              <label [for]="'qty-input-' + queryNo" class="quantity-label" i18n="@@query-builder-modal__query__quantity-label">SEND:</label>
              <app-filter-field
                [id]="'qty-input-' + queryNo"
                [attr.data-test]="'qty-input(' + queryNo + ')'"
                [formControl]="basicQtyCtrl"
                [options]="(mergedSiteSchemaNumericAttributeKeys$ | async)!"
                [showErrorsAboveInput]="false"
                labelClasses="aql-label"
              ></app-filter-field>
            </div>
            <ng-template #advancedQueryTxt>
              <div class="toolbar-advanced-quantity" data-test="toolbar-advanced-quantity">
                <span class="quantity-label" i18n="@@query-builder-modal__query__quantity-label">SEND:</span>
                <ng-container *ngIf="queryCtrl.get('show')?.value; else queryToStringText"> {{ ($any(queryCtrl) | getQueryFromFormControl | getQuantityText | async) }}</ng-container>
                <ng-template #queryToStringText> {{ ($any(queryCtrl) | getQueryFromFormControl | getQueryText | async) ?? '' }}</ng-template>
              </div>
            </ng-template>
          </div>
          <div class="toolbar-icons" data-test="toolbar-icons">
            <fa-icon *ngIf="queryCtrl.invalid" class="icon icon-l" [icon]="warningIcon"></fa-icon>
            <button type="button" class="advanced-btn" data-test="toggle-advanced-button" (click)="toggleAdvanced($any(queryCtrl))">
              <ng-container i18n="@@query-builder-modal__query-toolbar_advanced-button-text">Advanced</ng-container>
              <fa-icon class="icon right" [icon]="chevronDownIcon" [rotate]="queryCtrl.get('showAdvancedQuantity')?.value ? 180 : undefined"></fa-icon>
            </button>
            <button type="button" class="toolbar-fill-btn" data-test="clone-query-button" (click)="cloneQuery($event, queryNo)" title="Copy Rule" [disabled]="form.invalid" i18n-title="@@query-builder-modal__button--copy-query">
              <fa-icon class="icon icon-l icon-light" [icon]="cloneIcon"></fa-icon>
            </button>
            <button type="button" class="toolbar-fill-btn" data-test="delete-query-button" (click)="deleteQuery($event, queryNo)" title="Delete Rule" i18n-title="@@query-builder-modal__button--delete-query">
              <fa-icon class="icon icon-l icon-light" [icon]="deleteIcon"></fa-icon>
            </button>
            <button type="button" class="toolbar-fill-btn" data-test="toggle-display-query-button" (click)="toggleDisplay($event, $any(queryCtrl))" title="Hide Rule" i18n-title="@@query-builder-modal__button--hide-query">
              <fa-icon class="icon icon-l icon-light" [icon]="chevronCircleUpIcon" [rotate]="queryCtrl.get('show')?.value ? undefined : 180"></fa-icon>
            </button>
          </div>
        </div>

        <div class="advanced-qty" data-test="advanced-qty" *ngIf="queryCtrl.get('show')?.value && queryCtrl.get('showAdvancedQuantity')?.value">
          <ng-container
            [ngTemplateOutlet]="advancedQuantity"
            [ngTemplateOutletContext]="{ qtyCtrl: queryCtrl.get('quantity'), allowDelete: countQuantityValues($any(queryCtrl.get('quantity'))) > 1, queryNo: 'root' }"
          ></ng-container>
        </div>

        <div class="query-body" data-test="query-body" *ngIf="queryCtrl.get('show')?.value">
          <div class="where" data-test="where" [attr.data-test]="'where(' + queryNo + ')'">
            <div class="query-summary" data-test="query-summary" i18n="@@query-builder-modal__query-where__query-summary">to all sites where:</div>
            <app-filter
              [criteriaCtrl]="$any(queryCtrl.get('where'))"
              [fields]="(mergedSiteSchemaAttributeKeys$ | async)!"
              [schema]="(mergedSiteSchema$ | async)!"
              [additionalRootButtonsRef]="additionalButtonsTemplate"
              (lastCriteriaDeleted)="onLastCriteriaDeleted(queryNo)"
              [readonlyRootCriteriaGroup]="hasAppendedCriteria"
            >
            </app-filter>

            <ng-template #additionalButtonsTemplate>
              <button *ngIf="!queryCtrl.get('priority')?.value" type="button" [attr.data-test]="'priority-button(' + queryNo + ')'" class="link" (click)="addPriority($any(queryCtrl))">
                <fa-icon class="icon filter-link-icon icon-m2 left" [icon]="plusCircleIcon"></fa-icon>
                <ng-container i18n="@@query-builder-modal__button--add-priority">Add Priority</ng-container>
              </button>
            </ng-template>
          </div>

          <div *ngIf="queryCtrl.get('priority')?.value" class="priority" data-test="priority">
            <div class="priority-header">
              <div i18n="@@query-builder-modal__priority-header-text">Priority</div>
              <button type="button" class="link link-light" [attr.data-test]="'delete-priority-button(' + queryNo + ')'" (click)="deletePriority($any(queryCtrl))">
                <fa-icon class="icon left" [icon]="deleteIcon"></fa-icon>
                <ng-container i18n="@@query-builder-modal__button--delete-priority">Delete Priority</ng-container>
              </button>
            </div>

            <div class="priority-body">
              <app-number-field
                [formControl]="$any(queryCtrl.get('priority.totalPriorities'))"
                label="How many?"
                min="2"
                max="10"
                [showErrorsAboveInput]="false"
                labelClasses="aql-label"
              ></app-number-field>

              <div class="input-group">
                <label class="aql-label" i18n="@@query-builder-modal__aql-priority-label">Priority:</label>
                <div class="priority-checkboxes" [class.error]="!queryCtrl.get('priority.selectedPriorities')?.valid">
                  <div class="priority-checkbox" *ngFor="let option of $any(queryCtrl.get('priority.selectedPriorities')).controls; let cbNo = index">
                    <input type="checkbox" [id]="'priority-cb-' + queryNo + '-' + cbNo" [formControl]="$any(option)" />
                    <label [for]="'priority-cb-' + queryNo + '-' + cbNo">{{ cbNo + 1 }}</label>
                  </div>
                </div>
              </div>

              <button
                *ngIf="!queryCtrl.get('priority.offset')?.value"
                type="button"
                (click)="addOffset($any(queryCtrl.get('priority')))"
                class="btn-add-offset link"
                data-test="add-offset-button"
              >
                <fa-icon class="icon filter-link-icon icon-m2 left" [icon]="plusCircleIcon"></fa-icon>
                <ng-container i18n="@@query-builder-modal__button--add-offset">Add Offset</ng-container>
              </button>
            </div>

            <div *ngIf="queryCtrl.get('priority.offset')?.value" class="priority-offset" data-test="priority-offset">
              <div class="offset-label" i18n="@@query-builder-modal__priority-offset__label">With Offset:</div>
              <div class="offset-body">
                <ng-container [ngTemplateOutlet]="advancedQuantity" [ngTemplateOutletContext]="{ qtyCtrl: queryCtrl.get('priority.offset'), allowDelete: true, queryNo }"></ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ng-container *ngIf="(showSavedQueries$ | async) === false && form?.controls; let controls">
      <div *ngIf="controls.length > 0; else noQuery" class="btn-group">
        <button type="button" class="link" data-test="add-query-button" [class.no-query-add-query-btn]="controls.length === 0" (click)="addQuery()">
          <fa-icon class="icon filter-link-icon icon-m2 left" [icon]="plusCircleIcon"></fa-icon>
          <ng-container i18n="@@query-builder-modal__button--new-query">New Rule</ng-container>
        </button>
        <button type="button" class="link" data-test="add-query-from-template-button" [class.no-query-add-query-btn]="controls.length === 0" (click)="newQueryFromTemplate()">
          <fa-icon class="icon filter-link-icon icon-m2 left" [icon]="plusCircleIcon"></fa-icon>
          <ng-container i18n="@@query-builder-modal__button--new-query-from-template">New Rule from Template</ng-container>
        </button>
      </div>
      <ng-template #noQuery>
        <div class="btn-group-no-query" data-test="no-query-btn-group" appFocusFirstFocusableElement>
          <button type="button" class="btn new-query-btn" data-test="add-query-button-no-queries" (click)="addQuery()">
            <fa-icon class="icon icon-super-xl" [icon]="plusCircleIcon"></fa-icon>
            <ng-container i18n="@@query-builder-modal__button--new-query">New Rule</ng-container>
          </button>
          <button type="button" class="btn new-query-btn" data-test="add-query-from-template-button-no-queries" [class.no-query-add-query-btn]="controls.length === 0" (click)="newQueryFromTemplate()">
            <fa-icon class="icon icon-super-xl" [icon]="plusCircleIcon"></fa-icon>
            <ng-container i18n="@@query-builder-modal__button--new-query-from-template">New Rule from Template</ng-container>
          </button>
        </div>
      </ng-template>
    </ng-container>
  </div>

  <app-query-template-box *ngIf="showSavedQueries$ | async" [class.solo]="form.controls.length === 0" (removedQueryTemplateBox)="showSavedQueries$.next(false)" (addedQueryFromTemplate)="addQueryFromTemplate($event)"></app-query-template-box>
</app-modal>

<ng-template #advancedQuantity let-qtyCtrl="qtyCtrl" let-allowDelete="allowDelete" let-queryNo="queryNo">
  <div class="advanced-qty-block" [attr.data-test]="'advanced-quantity-group(' + queryNo + ')'" [class.is-rounding]="qtyCtrl.get('fnType') | isRoundingFunction | async">
    <ng-container *ngFor="let valueCtrl of qtyCtrl.get('values').controls; let i = index; let last = last">
      <div *ngIf="isBasicQuantity(valueCtrl); else group" class="quantity-value" >
        <app-filter-field [formControl]="valueCtrl!" [options]="(mergedSiteSchemaNumericBooleanAttributeKeys$ | async)!" [showErrorsAboveInput]="false" labelClasses="aql-label" [attr.data-test]="'quantity-value(' + i + ')'"></app-filter-field>
        <button *ngIf="allowDelete" type="button" class="link delete-filter-button" [attr.data-test]="'delete-quantity-value-button(' + i + ')'" (click)="removeQuantity(qtyCtrl, i)">
          <fa-icon class="icon icon-m2" [icon]="deleteIcon"></fa-icon>
        </button>
      </div>

      <ng-template #group>
        <ng-container [ngTemplateOutlet]="advancedQuantity" [ngTemplateOutletContext]="{ qtyCtrl: valueCtrl, allowDelete: allowDelete, queryNo: i }"></ng-container>
      </ng-template>

      <app-select *ngIf="!last" class="math-operator-btn" [attr.data-test]="'quantity-operator-button(' + i + ')'" [isPill]="true" [options]="mathOptions" [defaultOption]="null" [formControl]="qtyCtrl.get('operators').controls[i]"></app-select>
    </ng-container>

    <div class="quantity-buttons" data-test="quantity-buttons">
      <button type="button" class="link add-filter-button" [attr.data-test]="'add-value-button(' + queryNo + ')'" (click)="addQuantity(qtyCtrl)">
        <fa-icon class="icon filter-link-icon icon-m2 left" [icon]="plusCircleIcon"></fa-icon>
        <ng-container i18n="@@query-builder-modal__button--add-value">Add Value</ng-container>
      </button>
      <button type="button" class="link add-filter-button" [attr.data-test]="'add-group-button(' + queryNo + ')'" (click)="addQuantityGroup(qtyCtrl)">
        <fa-icon class="icon filter-link-icon icon-m2 left" [icon]="plusCircleIcon"></fa-icon>
        <ng-container i18n="@@query-builder-modal__button--add-group">Add Group</ng-container>
      </button>
    </div>

    <app-select class="math-operator-btn" [attr.data-test]="'function-type-select(' + queryNo + ')'" [isPill]="true" [options]="functionOptions" [defaultOption]="null" [formControl]="qtyCtrl.get('fnType')"></app-select>

    <div *ngIf="qtyCtrl.get('fnType') | isRoundingFunction | async" class="quantity-fn-param" [attr.data-test]="'quantity-fn-param(' + queryNo + ')'" >
      <app-number-field label="to the nearest" i18n-label="@@query-builder-modal__button--to-the-nearest" [formControl]="qtyCtrl.get('fnParam1')" min="1"></app-number-field>
    </div>
  </div>
</ng-template>
