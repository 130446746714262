import { AttributeType } from '../models/attribute';

export function isArrayAttributeType(type: AttributeType) {
  return type === AttributeType.STRING_ARRAY || type === AttributeType.NUMBER_ARRAY || type === AttributeType.BOOLEAN_ARRAY;
}

export function getNestedType(type: AttributeType): AttributeType {
  switch (type) {
    case AttributeType.STRING_ARRAY:
      return AttributeType.STRING;
    case AttributeType.NUMBER_ARRAY:
      return AttributeType.NUMBER;
    case AttributeType.BOOLEAN_ARRAY:
      return AttributeType.BOOLEAN;
    default:
      throw new Error(`Type: ${type} is not an array type`);
  }
}
