import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { findKey, isEqual, mapValues, omit } from 'lodash';
import { KeyVal, Nullable } from '../../models/typescript-types';
import { KeyStoreService } from '../../user-module/services/key-store.service';
import { KeyStoreData } from '../../user-module/models/key-store-data';
import { AqlQuery } from '../../aql-module/models/aql/aql-query';

import { CLIENT_KEYSTORE, USER_KEYSTORE } from '../../user-module/injection-tokens';
import { QueryTemplateLibraryType } from '../types/query-template-library-type';

@Injectable({
  providedIn: 'root',
})
export class QueryKeyStoreService {
  private readonly queryTemplatesKey = 'allocationQueryTemplates';
  private readonly sharedQueryTemplatesKey = 'allocationSharedQueryTemplates';

  constructor(
    @Inject(USER_KEYSTORE) private userKeyStoreService: KeyStoreService,
    @Inject(CLIENT_KEYSTORE) private clientKeyStoreService: KeyStoreService,
  ) {}

  readonly selectAllocationSharedQueryTemplates$ = this.clientKeyStoreService.getDataByKey$<KeyVal<AqlQuery>>(this.sharedQueryTemplatesKey).pipe(
    map(sharedQueryTemplates => (sharedQueryTemplates == null) ? {} : sharedQueryTemplates),
  );

  readonly selectAllocationPersonalQueryTemplates$ = this.userKeyStoreService.getDataByKey$<KeyVal<AqlQuery | string>>(this.queryTemplatesKey).pipe(
    map(rawAllocationQueryTemplates => {
      if (rawAllocationQueryTemplates == null) return {};

      return mapValues(rawAllocationQueryTemplates, (val) => {
        // old data may have aqlQuery or string, so we need to check and parse it
        const x = typeof val === 'string' ? JSON.parse(val) : val;
        // we used to store name in the AQLQuery, so we omit it
        return omit(x, 'name') as AqlQuery;
      });
    }),
  );

  readonly selectAllocationSelectedQueryTemplateLibrary$ = this.userKeyStoreService.getDataByKey$<QueryTemplateLibraryType>('allocationSelectedQueryTemplateLibrary')
    .pipe(map(type => type == null ? 'personal' : type));

  getPersonalQueryTemplateName(query: AqlQuery): Observable<Nullable<string>> {
    return this.selectAllocationPersonalQueryTemplates$
      .pipe(map(templates => findKey(templates, (it) => isEqual(it, query))))
  }

  getSharedQueryTemplateName(query: AqlQuery): Observable<Nullable<string>> {
    return this.selectAllocationSharedQueryTemplates$
      .pipe(map(templates => findKey(templates, (it) => isEqual(it, query))))
  }

  savePersonalQueryTemplate(name: string, query: AqlQuery): Observable<KeyStoreData> {
    return this.userKeyStoreService.updateDataByKey$(this.queryTemplatesKey, { [name]: query });
  }

  renamePersonalQueryTemplate(oldName: Nullable<string>, newName: string): Observable<KeyStoreData> {
    if (oldName == null) return of({});
    return this.selectAllocationPersonalQueryTemplates$.pipe(
      take(1),
      map(templates => templates[oldName]),
      switchMap(template => this.userKeyStoreService.updateDataByKey$(this.queryTemplatesKey, { [newName]: template, [oldName]: null }))
    )
  }

  removePersonalQueryTemplate(name: Nullable<string>): Observable<KeyStoreData> {
    if (name == null) return of({});
    return this.userKeyStoreService.updateDataByKey$(this.queryTemplatesKey, { [name]: null });
  }

  saveSharedQueryTemplate(name: string, query: AqlQuery): Observable<KeyStoreData> {
    return this.clientKeyStoreService.updateDataByKey$(this.sharedQueryTemplatesKey, { [name]: query });
  }

  renameSharedQueryTemplate(oldName: Nullable<string>, newName: string): Observable<KeyStoreData> {
    if (oldName == null) return of({});
    return this.selectAllocationSharedQueryTemplates$.pipe(
      take(1),
      map(templates => templates[oldName]),
      switchMap(template => {
        return this.clientKeyStoreService.updateDataByKey$(this.sharedQueryTemplatesKey, { [newName]: template, [oldName]: null });
      }),
    )
  }

  removeSharedQueryTemplate(name: Nullable<string>): Observable<KeyStoreData> {
    if (name == null) return of({});
    return this.clientKeyStoreService.updateDataByKey$(this.sharedQueryTemplatesKey, { [name]: null });
  }

  setAllocationSelectedQueryTemplateLibrary(type: QueryTemplateLibraryType): Observable<QueryTemplateLibraryType> {
    return this.userKeyStoreService.updateDataByKey$('allocationSelectedQueryTemplateLibrary', type);
  }
}
