import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { APP_CONFIG, AppConfig } from '../../../app-config';
import { buffer, concat, delay, EMPTY, interval, Observable, ObservableInput, of, skipWhile, Subject } from 'rxjs';
import { merge, uniq } from 'lodash';
import { DEBOUNCE_TIME, extractActivityId, extractRestValue } from '../../services/api-utils';
import { ActivityId, RestResponse, ResultWithTotal } from '../../models/rest-response';
import { Element, ElementShort } from '../models/element/element';
import { concatMap, first, map, mergeMap, share, takeUntil, tap } from 'rxjs/operators';
import { bufferDebounceTime } from '../../shared-module/rxjs';
import { AttributeMap } from '../../attribute-module/models/attribute';
import * as uuid from 'uuid';
import { AqlQuery } from '../../aql-module/models/aql/aql-query';
import { FileIdActionResponse } from '../../models/typescript-types';
import { SearchOptions } from '../../search-module/interfaces/search-options';
import { aqlCriteriaToRqlString } from '../../aql-module/models/rql/aql-to-rql-string';

export type CreateElementRequest = { id?: string; aql?: AqlQuery[]; attributes: AttributeMap; sortWeight: number };
export type ParseAQLResponse = { errors: string[]; queries: AqlQuery[]; };
export type ElementSearchOptions = SearchOptions

@Injectable({
  providedIn: 'root',
})
export class ElementHttpService implements OnDestroy {
  private readonly destroy$ = new Subject();
  private patchQueue = new Subject<{ requestId: string; elementId: string; request: Partial<Element> }>();
  private patchRequests: Observable<{ activityId: string | undefined; requestIds: string[] }>;
  private deleteQueue = new Subject<string>();
  private deleteRequests: Observable<{ activityId: string | undefined; requestIds: string[] }>;
  private createQueue = new Subject<{ requestId: string; jobId: string; request: CreateElementRequest }>();
  private createRequests: Observable<{ activityId: string | undefined; requestIds: string[] }>;
  private aqlQueryQueue = new Subject<string>();
  private aqlQueryRequests: Observable<{ originalQuery: string | undefined; queries: AqlQuery[]; errors: string[] }[]>;
  private patchRequestsCount = 0;
  private readonly bufferInt$ = interval(DEBOUNCE_TIME).pipe(takeUntil(this.destroy$));

  constructor(private http: HttpClient, @Inject(APP_CONFIG) private config: AppConfig) {

    this.patchRequests = this.patchQueue.pipe(
      buffer(this.bufferInt$),
      map(requests => {
        return requests.reduce((agg, { requestId, elementId, request }) => {
          agg[elementId] = {
            request: merge(agg[elementId]?.request || {}, request),
            requestIds: [...(agg[elementId]?.requestIds || []), requestId],
          };
          return agg;
        }, {} as { [k: string]: { request: Partial<Element>; requestIds: string[] } });
      }),
      concatMap(elementMap => {
        const request = Object.entries(elementMap).map(([elementId, group]) => ({ ...group.request, id: elementId }));
        const requestIds = Object.values(elementMap).flatMap(it => it.requestIds);
        // increment the request count, so we can buffer other request till patches are finished
        this.patchRequestsCount++;
        return this.http.patch<RestResponse<null>>(`${this.config.apiRoot}/allocation/v1/element`, request).pipe(
          extractActivityId(),
          map(activityId => ({ activityId, requestIds })),
          // decrement request count to update buffer
          tap(() => this.patchRequestsCount--)
        ) as ObservableInput<{ activityId: string | undefined; requestIds: string[] }>;
      }),
      share()
    );

    this.createRequests = this.createQueue.pipe(
      buffer(this.bufferInt$.pipe(delay(0), skipWhile(() => this.patchRequestsCount > 0))),
      map(requests => {
        return requests.reduce((agg, { requestId, request, jobId }) => {
          agg[jobId] = {
            requests: [...new Map([...(agg[jobId]?.requests || []), request].map(item => [item.id, item])).values()],
            requestIds: [...(agg[jobId]?.requestIds || []), requestId],
          };
          return agg;
        }, {} as { [jobId: string]: { requests: CreateElementRequest[]; requestIds: string[] } });
      }),
      concatMap(
        jobIdMap =>
          concat(
            ...Object.entries(jobIdMap).map(([jobId, requests]) =>
              this.http.post<RestResponse<null>>(`${this.config.apiRoot}/allocation/v1/job/${jobId}/element`, requests.requests).pipe(
                extractActivityId(),
                map(activityId => ({ activityId, requestIds: requests.requestIds }))
              )
            )
          ) as ObservableInput<{ activityId: string | undefined; requestIds: string[] }>
      ),
      share()
    );

    this.deleteRequests = this.deleteQueue.pipe(
      bufferDebounceTime(DEBOUNCE_TIME),
      concatMap(requestIds => {
        return this.http
          .request<RestResponse<null>>('delete', `${this.config.apiRoot}/allocation/v1/element`, { body: requestIds })
          .pipe(
            extractActivityId(),
            map(activityId => ({ activityId, requestIds }))
          ) as ObservableInput<{ activityId: string | undefined; requestIds: string[] }>;
      }),
      share()
    );

    this.aqlQueryRequests = this.aqlQueryQueue.pipe(
      bufferDebounceTime(DEBOUNCE_TIME),
      map(aqlQueries => uniq(aqlQueries)),
      concatMap(aqlQueries => {
        return this.http.post<RestResponse<ParseAQLResponse[]>>(`${this.config.apiRoot}/v1/parse-aql-query`, aqlQueries).pipe(
          extractRestValue(),
          map(responseArray =>
            responseArray.map((result: { queries: any; errors: any }, i: number) => ({
              originalQuery: aqlQueries[i],
              queries: result.queries,
              errors: result.errors,
            }))
          )
        ) as ObservableInput<{ originalQuery: string | undefined; queries: AqlQuery[]; errors: string[] }[]>;
      }),
      share()
    );
  }

  private buildElementSearch(options: ElementSearchOptions): HttpParams {
    let params = new HttpParams();
    params = options.rql ? params.append('rql', aqlCriteriaToRqlString(options.rql)) : params;
    params = options.keyword ? params.append('keyword', options.keyword) : params;
    params = params.append('job-type', 'PRIMARY_JOB');
    params = params.append('job-type', 'PRODUCTION_JOB');
    params = params.append('job-type', 'DISPATCH_JOB');
    return params;
  }

  getElements(jobId: string, options: ElementSearchOptions = {}): Observable<ResultWithTotal<Element>> {
    const params = this.buildElementSearch(options);
    return this.http.get<RestResponse<ResultWithTotal<Element>>>(`${this.config.apiRoot}/allocation/v1/job/${jobId}/element`, { params }).pipe(extractRestValue());
  }

  getShortElements(jobId: string, options: ElementSearchOptions = {}): Observable<ResultWithTotal<ElementShort>> {
    const params = this.buildElementSearch(options).append('response', 'short');
    return this.http.get<RestResponse<ResultWithTotal<ElementShort>>>(`${this.config.apiRoot}/allocation/v1/job/${jobId}/element`, { params }).pipe(extractRestValue());
  }

  createElement(jobId: string, request: CreateElementRequest): Observable<string> {
    request = { id: request.id, aql: request.aql, attributes: request.attributes, sortWeight: request.sortWeight };
    const requestId = uuid.v4();
    return new Observable(subscriber => {
      this.createRequests
        .pipe(
          first(it => it.requestIds.includes(requestId)),
          map((it) => it.activityId)
        )
        .subscribe(subscriber);
      this.createQueue.next({ requestId, request, jobId });
    });
  }

  patchElement(elementId: string, toPatch: Partial<Element>): Observable<string> {
    // If the patch is empty do nothing
    if (Object.keys(toPatch).length === 0) {
      return EMPTY;
    }
    const requestId = uuid.v4();
    return new Observable(subscriber => {
      this.patchRequests
        .pipe(
          first(it => it.requestIds.includes(requestId)),
          map((it) => it.activityId)
        )
        .subscribe(subscriber);
      this.patchQueue.next({ requestId, elementId, request: toPatch });
    });
  }

  regenerateCodes(elementIds: string[]): Observable<ActivityId> {
    return this.http.post<RestResponse<void>>(`${this.config.apiRoot}/allocation/v1/element/regenerate-codes`, elementIds).pipe(extractActivityId());
  }

  parseAqlString(aqlString: string): Observable<AqlQuery[]> {
    return new Observable(subscriber => {
      this.aqlQueryRequests
        .pipe(
          mergeMap(it => of(it.find(response => response.originalQuery === aqlString))),
          first(it => it != null),
          map(it => it as { originalQuery: string; queries: AqlQuery[]; errors: string[] }),
          tap(it => {
            if (it?.errors?.length > 0) throw it.errors;
          }),
          map((it) => it.queries)
        )
        .subscribe(subscriber);
      this.aqlQueryQueue.next(aqlString);
    });
  }

  deleteElementById(elementId: string): Observable<string> {
    return new Observable(subscriber => {
      this.deleteRequests
        .pipe(
          first(it => it.requestIds.includes(elementId)),
          map((it) => it.activityId)
        )
        .subscribe(subscriber);
      this.deleteQueue.next(elementId);
    });
  }

  autoComplete(jobId: string, attr: string, search: string): Observable<string[]> {
    return this.http.get<RestResponse<string[]>>(`${this.config.apiRoot}/allocation/v1/job/${jobId}/element/auto-complete/${encodeURIComponent(attr)}/${encodeURIComponent(search)}`)
      .pipe(extractRestValue());
  }

  createFile(elementId: string, jobId: string, systemTags: string[], userTags: string[], fileId?: string, action?: string): Observable<FileIdActionResponse> {
    const body: { systemTags: string[]; userTags: string[]; id?: string; action?: string } = { systemTags, userTags };
    if (fileId) body['id'] = fileId;
    if (action) body['action'] = action;
    return this.http
      .post<RestResponse<FileIdActionResponse>>(`${this.config.apiRoot}/allocation/v1/job/${jobId}/element/${elementId}/file`, body)
      .pipe(extractRestValue());
  }

  getElementById(id: string): Observable<Element> {
    return this.http.get<RestResponse<Element>>(`${this.config.apiRoot}/allocation/v1/element/${id}`).pipe(extractRestValue());
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
