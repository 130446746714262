<div class="notification-bar" data-test="notification-bar" *ngIf="notification">
  <div class="icon-section"><fa-icon class="icon icon-m icon-white" data-test="notification-type-icon" [icon]="notification.icon.icon"></fa-icon></div>
  <div class="main-section" data-test="notification-main-section">
    <span *ngIf="notification.text" class="notification-text" data-test="notification-text">{{ notification.text }}</span>
    <button *ngIf="notification.action; let action" type="button" class="btn btn--sm action-button" data-test="notification-action-button" (click)="onHandleAction.emit(notification)">
      {{ action.text }}
      <ng-container *ngIf="action.icon"><fa-icon class="icon right" data-test="notification-action-button-icon" [icon]="action.icon.icon" [ngClass]="action.icon.classes ?? []"></fa-icon></ng-container>
    </button>
  </div>
  <button type="button" class="btn close-button" data-test="close-notification-button" (click)="onClose.emit(notification)"><fa-icon class="icon icon-m" [icon]="closeNotificationIcon"></fa-icon></button>
</div>
