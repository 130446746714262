import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG, AppConfig } from '../../../app-config';
import { Observable } from 'rxjs';
import { extractRestValue } from '../../services/api-utils';
import { RestResponse } from '../../models/rest-response';
import { Job } from '../models/job/job';
import { JobStore } from '../pages/job/job.store';
import { switchMap, take } from 'rxjs/operators';
import { AutoCompleteInterface } from '../../search-module/interfaces/auto-complete.interface';


@Injectable()
export class SiteService implements AutoCompleteInterface {

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private config: AppConfig,
    private jobStore: JobStore
  ) { }

  getSiteDBUpdatedAt(): Observable<SiteUpdatedAt> {
    return this.http.get<RestResponse<SiteUpdatedAt>>(`${this.config.apiRoot}/site-db/v1/site/updated-at`)
      .pipe(extractRestValue());
  }

  // TODO: Remove JobId
  importFromSiteDb(jobId: string): Observable<Job> {
    return this.http.post<RestResponse<Job>>(`${this.config.apiRoot}/allocation/v1/job/${jobId}/site/import-from-site-db`, null)
      .pipe(extractRestValue());
  }

  autoComplete(attr: string, search: string): Observable<string[]> {
    return this.jobStore.jobId$.pipe(
      switchMap(jobId => this.http.get<RestResponse<string[]>>(`${this.config.apiRoot}/allocation/v1/job/${jobId}/site/auto-complete/${encodeURIComponent(attr)}/${encodeURIComponent(search)}`)),
      extractRestValue(),
      take(1),
    );
  }

}

interface SiteUpdatedAt {
  result: string;
}
