import { AttributeType } from './attribute';
import { Validator } from './validator';

export interface Schema {
  key: string;
  displayName: string;
  type: AttributeType;
  sortWeight: number;
}

export interface ValidatorSchema extends Schema {
  validators: Validator[];
}

export function isValidatorSchema(schema: any): schema is ValidatorSchema {
  return (isSchema(schema) && schema.hasOwnProperty('validators'));
}


export interface DefinedAttributeSchema extends ValidatorSchema {
  readOnly: boolean;
}

export function isSchema(schema: Schema): schema is Schema {
  return (schema as Schema).key !== undefined
    && (schema as Schema).type !== undefined
    && (schema as Schema).sortWeight !== undefined
    && (schema as Schema).displayName !== undefined;
}

export function isDefinedAttributeSchema(schema: Schema): schema is DefinedAttributeSchema {
  return (schema as DefinedAttributeSchema).displayName !== undefined
    && (schema as DefinedAttributeSchema).readOnly !== undefined
    && (schema as DefinedAttributeSchema).validators !== undefined;
}
