<div *ngIf="!(isLoading$ | async)" class="body-wrapper" [class.blur]="overlayService.isShowing$ | async">
  <app-navbar [logoSrc]="logoSrc$ | async"></app-navbar>

  <main class="main" role="main">
    <app-sidebar></app-sidebar>
    <div class="page-wrapper" [class.side-panel-is-open]="sidePanelService.isPanelOpen$ | async"><router-outlet></router-outlet></div>
    <app-side-panel></app-side-panel>
  </main>
</div>

<app-notification-overlay></app-notification-overlay>
