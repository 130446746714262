import { Component, Input } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { Nullable } from '../../../models/typescript-types';

@Component({
  selector: 'app-input-errors',
  templateUrl: './input-errors.component.html',
  styleUrls: ['./input-errors.component.sass'],
})
export class InputErrorsComponent {
  @Input() errors: Nullable<ValidationErrors>;
}
