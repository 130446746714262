import { Pipe, PipeTransform } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map, startWith } from 'rxjs/operators';
import { AqlFunctionTypes, isAqlRoundingFunctionType } from '../../aql-module/models/aql/aql-value';

@Pipe({
  name: 'isRoundingFunction'
})
export class IsRoundingFunctionPipe implements PipeTransform {

  transform(formControl: UntypedFormControl): Observable<boolean> {
    return formControl.valueChanges.pipe(
      startWith(formControl.value),
      distinctUntilChanged(),
      map((fnType: AqlFunctionTypes) => isAqlRoundingFunctionType(fnType))
    );
  }

}
