<div class="icon-select" [ngClass]="classes">
  <label *ngIf="label" [ngClass]="labelClasses!" [for]="identifier!">{{ label }}:</label>
  <div class="input-group">
    <i class="icon prefix" [class.disabled]="selectedOption.disabled">
      <fa-icon [icon]="(icon$ | async)!"></fa-icon>
    </i>
    <select [id]="identifier" [formControl]="selectedOption">
      <option *ngFor="let option of options" [value]="option.value">{{ option.label }}</option>
    </select>
  </div>
</div>
