<app-modal
  modalTitle="Pop-up Blocker Detected"
  i18n-modalTitle="@@popup-blocker-modal__modal-title"
  confirmButtonText="Close"
  i18n-confirmButtonText="@@popup-blocker-modal__confirmation-button"
  [cancelButtonText]="null"
  overlayClass="standard"
  (confirmEvent)="overlayCtrl.close();"
  appFocusFirstFocusableElement
>
  <h5 class="txt-grey-m" data-test="prompt-text" i18n="@@popup-blocker-modal__prompt-text">Pop-up blocker detected, please allow pop-up windows for Rule and then try again.</h5>
</app-modal>
