import { Injectable } from '@angular/core';
import { IValidatorService } from './validator.service';
import { AttributeValue } from '../models/attribute';
import { CurrencyValidator } from '../models/validator/currency-validator';
import { ValidatorType } from '../models/validator';

@Injectable({
  providedIn: 'root',
})
export class CurrencyValidatorService implements IValidatorService {
  validate(displayName: string, validator: CurrencyValidator, value: AttributeValue | null): { [key: string]: any } {
    if (value == null || value === '') {
      return {};
    } // Short circuit if the value is missing

    if (isNaN(Number(value)) || typeof value !== 'number') {
      return { [ValidatorType.CURRENCY]: { value, message: $localize`:@@currency-validator__invalid--not-a-number:${displayName}:displayName: must be a number.` } };
    }

    return {};
  }
}
