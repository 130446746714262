import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';
import { ToRelativeOptions } from 'luxon/src/datetime';

@Pipe({
  name: 'toRelativeDateTime'
})
export class ToRelativeDateTimePipe implements PipeTransform {

  constructor(@Inject(LOCALE_ID) private locale: string) {}

  transform(date: string, options: ToRelativeOptions = {}): string {
    const dt = DateTime.fromISO(date);
    if (dt.diffNow('seconds').seconds > -60) return 'Just now';
    return dt.toRelative({ locale: this.locale, ...options }) as string;
  }

}
