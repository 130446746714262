import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { AuthService } from './auth.service';
import { combineLatest, Observable, of, switchMap } from 'rxjs';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

@Injectable()
export class PermissionGuard  {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean|UrlTree> {
    const hasAllPermissions = route.data['hasAllPermissions'];
    const hasAnyPermissions = route.data['hasAnyPermissions'];
    const isSuperuser = route.data['isSuperuser'];
    return combineLatest([
      isSuperuser ? this.authService.isSuperuser$() : of(true),
      hasAllPermissions ? this.authService.hasAllPermissions$(...hasAllPermissions) : of(true),
      hasAnyPermissions ? this.authService.hasAnyPermissions$(...hasAnyPermissions) : of(true),
    ]).pipe(
      map(([isSuper, hasAll, hasAny]) => isSuper && hasAll && hasAny),
      map(canActivate => canActivate ? true : this.router.parseUrl('/forbidden'))
    );
  }
}
