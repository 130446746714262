import { UserShort } from '../../user-module/models/user';
import { Task } from './task';

export interface ScheduledTask {
  scheduleId: string;
  context: any;
  cron: string;
  description: string;
  lastRunAt: string;
  nextRunAt: string;
  owner: UserShort;
  historicTasks: Task[];
  type: string;
  createdAt: string;
  updatedAt: string;
}
export function isScheduledTask(task: any): task is ScheduledTask {
  return (task.hasOwnProperty('scheduleId'));
}

