import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivityId, RestResponse } from '../../models/rest-response';
import { extractActivityId } from '../../services/api-utils';
import { APP_CONFIG, AppConfig } from '../../../app-config';
import { HttpClient } from '@angular/common/http';
import { TaskId } from '../models/task';

@Injectable({
  providedIn: 'root',
})
export class TasksHttpService {
  constructor(@Inject(APP_CONFIG) private config: AppConfig, private http: HttpClient) {}

  acknowledgeTasks(taskIds: TaskId[], acknowledged?: boolean): Observable<ActivityId> {
    const body: { taskIds: TaskId[]; acknowledged?: boolean } = { taskIds };
    if (acknowledged !== undefined) body.acknowledged = acknowledged;
    return this.http.post<RestResponse<ActivityId>>(`${this.config.apiRoot}/task/v1/acknowledge-tasks`, body).pipe(extractActivityId());
  }

  deleteTask(taskId: TaskId): Observable<ActivityId> {
    return this.http.delete<RestResponse<ActivityId>>(`${this.config.apiRoot}/task/v1/task/${taskId}`).pipe(extractActivityId());
  }
}
