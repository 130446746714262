<app-field
  [fieldClasses]="'droplist-field'"
  [labelOptions]="{ text: label, classes: labelClasses, for: uid }"
  [showErrorsAboveInput]="showErrorsAboveInput"
  [control]="control"
  [class.disabled]="control.disabled"
  [class.dropdown-top]="dropdownAltPosition"
>
  <div
    #inputContainer
    #dropdownOrigin="cdkOverlayOrigin"
    cdkOverlayOrigin
    class="droplist-input-container"
    [class.is-dropdown]="!control.disabled"
    [class.open]="isDropdownVisible"
    [class.error]="control.invalid && (control.dirty || control.touched)"
    (focusout)="onFocusOut($event)"
  >
    <input
      [id]="uid"
      type="text"
      [attr.title]="inputTitle"
      class="input"
      data-test="filter-field-input"
      [ngClass]="inputClasses ?? ''"
      [class.error]="control.invalid && (control.dirty || control.touched)"
      [placeholder]="placeholder"
      (input)="onInputChanged(inputElement.value)"
      autocomplete="false"
      #inputElement
    />
    <button *ngIf="!control.disabled" type="button" class="dropdown-toggle-btn" data-test="dropdown-arrow-btn" (click)="toggleDropdownBtnClicked($event)">
      <fa-icon class="icon icon-m icon-primary icon-clickable" [icon]="faChevronDown" [rotate]="(isDropdownVisible ? 180 : null)!"></fa-icon>
    </button>
  </div>
</app-field>

<ng-template
  #dropDown="cdkConnectedOverlay"
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="dropdownOrigin"
  [cdkConnectedOverlayOpen]="isDropdownVisible"
  [cdkConnectedOverlayFlexibleDimensions]="true"
  [cdkConnectedOverlayPositions]="dropdownPositions"
  [cdkConnectedOverlayViewportMargin]="15"
  [cdkConnectedOverlayWidth]="dropdownOriginRect?.width!"
  [cdkConnectedOverlayScrollStrategy]="scrollStrategyOptions.close()"
  (positionChange)="positionChanged($event)"
>
  <div class="dropdown" [class.top]="dropdownAltPosition" data-test="filter-dropdown">
    <app-loading-spinner *ngIf="isLoading$ | async; else options"></app-loading-spinner>

    <ng-template #options>
      <ul *ngIf="filteredOptions.length > 0; else noOptions" class="options" data-test="options" role="listbox">
        <li
          *ngFor="let option of filteredOptions; let i = index"
          class="option"
          (mousemove)="setHighlightedIndex(i)"
          [class.highlight]="highlightedIndex === i"
          [title]="option.label"
          (mousedown)="$event.preventDefault()"
          (click)="selectAndCloseDropdown($event, option)"
        >
          {{ option.label }}
        </li>
      </ul>
    </ng-template>

    <ng-template #noOptions>
      <div class="no-options" data-test="no-options" i18n="@@filter-field__no-matches-text">There are no matches.</div>
    </ng-template>
  </div>
</ng-template>
