import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Inject, Input, OnDestroy, OnInit, Optional, Self, ViewChild } from '@angular/core';
import { BehaviorSubject, combineLatest, fromEvent, merge, Observable, ReplaySubject, Subject } from 'rxjs';
import { ControlValueAccessor, FormControl, NgControl } from '@angular/forms';
import { debounceTime, filter, map, shareReplay, startWith, switchMap, take, takeUntil } from 'rxjs/operators';
import { CdkConnectedOverlay, ConnectedPosition, ScrollStrategyOptions, ViewportRuler } from '@angular/cdk/overlay';
import * as uuid from 'uuid';
import { faChevronDown, faCircleXmark } from '@fortawesome/pro-regular-svg-icons';
import { fieldDropDownPositions } from '../field-helpers';
import { Option, OptionProvider } from '../../../entities/form-entities';
import { Nullable } from '../../../../models/typescript-types';
import { buildLocalSortAndFilterFunction$, stringToOption, stringToOptions } from '../../../../services/utils';
import { DOCUMENT } from '@angular/common';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-multi-select-datalist [label] [options]',
  templateUrl: './multi-select-datalist.component.html',
  styleUrls: ['./multi-select-datalist.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('overlayAnimation', [
      state('void', style({ opacity: 0, transform: 'translateY(-30px)' })),
      state('*', style({ opacity: 1, transform: 'translateY(0px)' })),
      transition('void => *', animate('200ms ease-in-out')),
      transition('* => void', animate('0ms ease-in-out'))
    ])
  ]
})
export class MultiSelectDatalistComponent<T> implements ControlValueAccessor, OnInit, OnDestroy {
  readonly form = new FormControl<string>('', { nonNullable: true });
  readonly selectedValues$ = new BehaviorSubject<Set<any>>(new Set());
  private mappedOptions: Map<T, Option> = new Map();
  readonly selectedOptions$ = this.selectedValues$.pipe(map(values => Array.from(values).map(value => this.mappedOptions.get(value) ?? stringToOption(value))));

  private readonly onDestroy$ = new Subject();
  readonly cancelIcon = faCircleXmark;
  readonly faChevronDown = faChevronDown;
  readonly uid = uuid.v4();
  readonly PLACEHOLDER_OPEN_DEFAULT = $localize`:@@multi-select-datalist__default-input-placeholder--open:Type or select an option...`;
  readonly PLACEHOLDER_CLOSED_DEFAULT = $localize`:@@multi-select-datalist__default-input-placeholder--closed:Start typing to select an option...`;

  private readonly optionsProvider$ = new ReplaySubject<OptionProvider>(1);
  private readonly availableHighlightedIndex$ = new BehaviorSubject<number>(-1);
  private readonly selectedHighlightedIndex$ = new BehaviorSubject<number>(-1);
  readonly filteredOptions$: Observable<Option[]>;
  private readonly isAvailableDropdownVisibleSub$ = new BehaviorSubject<boolean>(false);
  private readonly isSelectedOptionsVisibleSub$ = new BehaviorSubject<boolean>(false);
  placeholder$: Observable<string> | undefined;
  isLoading$ = new BehaviorSubject(false);
  dropdownOriginRect: ClientRect | undefined;
  private readonly _dropdownPositions = fieldDropDownPositions(1);
  dropdownPositions = [this._dropdownPositions[0]];
  selectedOptionsDropdownPosition: ConnectedPosition[] = [this._dropdownPositions[1]];
  dropdownAltPosition = false;

  @Input() inputContainerClasses: string | undefined;
  @Input() inputClasses = '';
  @Input() label: string | undefined;
  @Input() labelClasses: string | undefined;
  @Input() inputTitle: string | undefined;

  @Input() set options(options: Nullable<string[] | Option<T>[] | OptionProvider<T>>) {
    if (options == null) return;
    if (Array.isArray(options)) {
      const mappedOptions = stringToOptions(options);
      this.mappedOptions = new Map(mappedOptions.map(o => [o.value, o]));
      this.optionsProvider$.next(buildLocalSortAndFilterFunction$(mappedOptions));
    } else {
      this.optionsProvider$.next(options);
    }
  }

  @ViewChild('inputElement', { static: true }) inputElementRef: ElementRef<HTMLInputElement> | undefined;
  @ViewChild('inputContainer', { static: true }) inputContainer: ElementRef<HTMLDivElement> | undefined;
  @ViewChild('dropDown', { static: true }) dropDown: CdkConnectedOverlay | undefined;
  @ViewChild('selectedOptionsDropdown', { static: true }) selectedOptionsDropdown: CdkConnectedOverlay | undefined;

  constructor(
    @Self() @Optional() public control: NgControl,
    private viewportRuler: ViewportRuler,
    private cdr: ChangeDetectorRef,
    public scrollStrategyOptions: ScrollStrategyOptions,
    private elRef: ElementRef,
    @Inject(DOCUMENT) private document: Document
  ) {
    if (this.control) {
      this.control.valueAccessor = this;
    }
    // when selectedOptions change we scroll the selected options to the end of the selected options container
    // so that options do not appear out of view to the user
    this.selectedValues$.pipe(takeUntil(this.onDestroy$)).subscribe(options => {
      if (this.isSelectedOptionsVisible) {
        setTimeout(() => {
          const el = this.selectedOptionsDropdown?.overlayRef?.overlayElement?.querySelector(`.selected-options`);
          el && el.scroll({ top: el.scrollHeight, behavior: 'smooth' });
          this.cdr.markForCheck();
        });
      }
      this.onChanged(Array.from(options));
    });

    // When the input or options provider change we update the filtered options
    this.filteredOptions$ = combineLatest([this.inputCtrl.valueChanges.pipe(debounceTime(250), startWith(this.inputCtrl.value)), this.optionsProvider$]).pipe(
      switchMap(([value, optionsProvider]) => optionsProvider(value)),
      takeUntil(this.onDestroy$),
      shareReplay(1)
    );

    // Reset the selected index if there are no options
    this.filteredOptions$.pipe(takeUntil(this.onDestroy$)).subscribe(filteredOptions => {
      if (filteredOptions.length === 0) this.resetHighlightedIndex();
    });

    // Open the dropdown when the input changes
    this.inputCtrl.valueChanges
      .pipe(debounceTime(250), filter(it => it.trim() !== ''), takeUntil(this.onDestroy$))
      .subscribe(() => this.toggleDropdownList(true));
  }

  get inputCtrl(): FormControl<string> {
    return this.form;
  }

  get selectedValues(): Set<T> {
    return this.selectedValues$.value;
  }

  set selectedValues(value: Set<T>) {
    this.selectedValues$.next(value);
  }

  get availableHighlightedIndex(): number {
    return this.availableHighlightedIndex$.value;
  }

  set availableHighlightedIndex(index: number) {
    this.availableHighlightedIndex$.next(index);
  }

  get selectedHighlightedIndex(): number {
    return this.selectedHighlightedIndex$.value;
  }

  set selectedHighlightedIndex(index: number) {
    this.selectedHighlightedIndex$.next(index);
  }

  get isAvailableDropdownVisible(): boolean {
    return this.isAvailableDropdownVisibleSub$.value;
  }

  set isAvailableDropdownVisible(bool: boolean) {
    this.isAvailableDropdownVisibleSub$.next(bool);
  }

  get isSelectedOptionsVisible(): boolean {
    return this.isSelectedOptionsVisibleSub$.value;
  }

  set isSelectedOptionsVisible(bool: boolean) {
    this.isSelectedOptionsVisibleSub$.next(bool);
  }

  onChanged: (value: T[]) => void = () => {};
  onTouched: any = () => {};

  ngOnInit(): void {
    // If the viewport is resized then recalculate the size of the dropdown
    this.viewportRuler
      .change(250)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        if (this.isAvailableDropdownVisible) {
          this.dropdownOriginRect = this.inputContainer?.nativeElement.getBoundingClientRect();
          this.cdr.markForCheck();
        }
      });

    // listen to updates to selected highlight index and scroll to the selected option
    this.selectedHighlightedIndex$
      .pipe(
        filter(index => index !== -1),
        takeUntil(this.onDestroy$)
      )
      .subscribe(() => this.scrollToSelectedHighlightedIndex());

    // listen to updates to highlight index and scrolls to the option
    this.availableHighlightedIndex$
      .pipe(
        filter(index => index !== -1),
        takeUntil(this.onDestroy$)
      )
      .subscribe(() => this.scrollToAvailableHighlightedIndex());

    // ensures that the correct placeholder is being shown when the dropdown is hidden/showing and the input is focused/not focused
    this.placeholder$ = merge(
      this.selectedValues$,
      fromEvent(this.inputElementRef!.nativeElement, 'focus'),
      fromEvent(this.inputElementRef!.nativeElement, 'blur'),
      this.isAvailableDropdownVisibleSub$
    ).pipe(
      startWith(null),
      debounceTime(150), // This prevents flashing of placeholder changes on open/close of dropdown and expression change errors in tests
      // Map to the correct text for the placeholder depending on whether the dropdown is showing/hiding, whether there are selectedOptions
      // and whether the input is focused or not as we want to show what the currently selected options are when the user is not interacting with the component
      switchMap(() => this.selectedOptions$),
      map(selectedOptions => {
        // if the options are visible (i.e when the dropdown is showing) then don't show the selected options string (as the user can see the options listed above)
        // but show the default open dropdown placeholder text.
        // If the input is focused (active element is input element) but the options are not showing (esc key has been pressed) then show the closed dropdown default text
        // If the input is not focused and there are selected options (which are not visible) then set the placeholder to a string representation of the selected options
        // Otherwise show the closed dropdown default text
        if (this.isAvailableDropdownVisible) {
          return this.PLACEHOLDER_OPEN_DEFAULT;
        } else if (this.document.activeElement === this.inputElementRef?.nativeElement) {
          return this.PLACEHOLDER_CLOSED_DEFAULT;
        } else if (selectedOptions.length > 0) {
          return selectedOptions.map(it => it.label).join(', ');
        } else {
          return this.PLACEHOLDER_CLOSED_DEFAULT;
        }
      }),
      takeUntil(this.onDestroy$)
    );

    this.isAvailableDropdownVisibleSub$.pipe(takeUntil(this.onDestroy$)).subscribe(isVisible => {
      if (isVisible) {
        this.dropdownOriginRect = this.inputContainer?.nativeElement.getBoundingClientRect();
        this.availableHighlightedIndex = 0; // When the dropdown is toggled reset the highlighted index (cus it's a better experience)
        this.resetSelectedHighlightedIndex(); // resets selected options selection
      } else {
        this.resetInput({ emitEvent: true }); // on close of the dropdown, clear the input
      }
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

  toggleOption(value: T | undefined) {
    if (value == null) return;

    if (this.isSelected(value)) {
      this.removeOption(value);
    } else {
      this.addOption(value);
    }
  }

  isSelected(value: T): boolean {
    return this.selectedValues.has(value);
  }

  addOption(value: T | T[]) {
    if (!Array.isArray(value)) {
      value = [value];
    }
    const updatedValues = new Set(this.selectedValues);
    value.forEach(v => updatedValues.add(v));
    this.selectedValues = updatedValues;
    this.resetSelectedHighlightedIndex();
    this.cdr.markForCheck();
  }

  private getHighlightedAvailableOption(): Observable<Option<T> | undefined> {
    return this.filteredOptions$.pipe(map(filteredOptions => filteredOptions[this.availableHighlightedIndex]));
  }

  removeOption(value: T) {
    const updatedValues = new Set(this.selectedValues);
    updatedValues.delete(value);
    this.selectedValues = updatedValues;
    this.cdr.markForCheck();
  }

  handleKeydownInInput($event: KeyboardEvent) {
    switch ($event.key) {
      case 'ArrowDown':
        if (!this.isAvailableDropdownVisible) {
          this.toggleDropdownList(true);
        }
        this.updateHighlightedAvailableIndex(1);
        $event.preventDefault();
        break;
      case 'ArrowUp':
        if (!this.isAvailableDropdownVisible) return;
        this.updateHighlightedAvailableIndex(-1);
        $event.preventDefault();
        break;
      case 'ArrowLeft':
        // if the input is blank then we cycle through the options
        // from right to left on keydown.arrowLeft by updating the selectedHighlightedIndex
        if (this.inputCtrl.value === '' && this.selectedValues.size > 0) {
          this.updateHighlightedSelectedIndex(-1);
          $event.preventDefault();
        }
        break;
      case 'ArrowRight':
        // if the input is blank then we cycle through the options
        // from left to right on keydown.arrowRight by updating the selectedHighlightedIndex
        if (this.inputCtrl.value === '' && this.selectedValues.size > 0) {
          this.updateHighlightedSelectedIndex(1);
          $event.preventDefault();
        }
        break;
      case 'Enter':
        if (!this.isAvailableDropdownVisible) return;
        // if the highlightedIndex is not -1 (nothing is highlighted) then toggle the highlighted option
        // else add the option which is typed into the input as a custom option
        if (this.availableHighlightedIndex !== -1) {
          this.getHighlightedAvailableOption()
            .pipe(take(1))
            .subscribe(value => this.toggleOption(value?.value));
          $event.preventDefault();
        }
        break;
      case 'Escape':
        // If there is a selected highlighted option then reset the selection
        // else hide the dropdown list
        if (this.selectedHighlightedIndex > -1) {
          this.resetSelectedHighlightedIndex();
        }
        if (this.isAvailableDropdownVisible) {
          this.toggleDropdownList(false, true);
          $event.preventDefault();
        }

        break;
      case 'Backspace':
        if (this.inputCtrl.value === '' && this.selectedValues.size > 0) {
          if (this.selectedHighlightedIndex !== -1) {
            this.removeOption(Array.from(this.selectedValues)[this.selectedHighlightedIndex]);
            if (this.selectedHighlightedIndex > 0) this.selectedHighlightedIndex--;
          } else {
            this.selectedHighlightedIndex = this.selectedValues.size - 1;
          }
          $event.preventDefault();
        }
        break;
      case 'Delete':
        if (this.inputCtrl.value === '' && this.selectedValues.size > 0) {
          if (this.selectedHighlightedIndex !== -1) {
            this.removeOption(Array.from(this.selectedValues)[this.selectedHighlightedIndex]);
            if (this.selectedHighlightedIndex >= this.selectedValues.size) this.selectedHighlightedIndex--;
          } else {
            this.selectedHighlightedIndex = 0;
          }
          $event.preventDefault();
        }
        break;
      case ',':
        // on comma, add the option which is typed into the input as a custom option
        this.updateFromCsv(this.inputCtrl.value);
        $event.preventDefault();
        break;
      default:
        return;
    }
  }

  private resetHighlightedIndex() {
    this.availableHighlightedIndex = -1;
  }

  private resetSelectedHighlightedIndex() {
    this.selectedHighlightedIndex = -1;
  }

  private updateHighlightedAvailableIndex(adjustment: number) {
    this.filteredOptions$.pipe(take(1)).subscribe(filteredOptions => {
      const currentIdx = this.availableHighlightedIndex$.value;
      const length = filteredOptions.length;
      let index = currentIdx === -1 ? (currentIdx + adjustment < 0 ? length - 1 : 0) : currentIdx + adjustment;
      index = index >= length ? 0 : index < 0 ? length - 1 : index;

      this.availableHighlightedIndex$.next(index);
    });
  }

  private updateHighlightedSelectedIndex(adjustment: number) {
    const currentIdx = this.selectedHighlightedIndex$.value;
    const length = this.selectedValues.size;
    let index = currentIdx === -1 ? (currentIdx + adjustment < 0 ? length - 1 : 0) : currentIdx + adjustment;
    index = index >= length ? 0 : index < 0 ? length - 1 : index;

    this.selectedHighlightedIndex$.next(index);
  }

  private scrollToAvailableHighlightedIndex() {
    setTimeout(() => {
      const el = this.dropDown?.overlayRef?.overlayElement?.querySelector('.highlight');
      el && el.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
      this.cdr.markForCheck();
    });
  }

  private scrollToSelectedHighlightedIndex() {
    setTimeout(() => {
      const el = this.selectedOptionsDropdown?.overlayRef?.overlayElement?.querySelector(`.selected-options .highlight`);
      el && el.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
      this.cdr.markForCheck();
    });
  }

  writeValue(options: T[]): void {
    this.selectedValues$.next(new Set(options));
  }

  toggleDropdownList(toggle: boolean, toggleSelected?: boolean) {
    this.isAvailableDropdownVisible = toggle; // toggles the dropdown
    this.isSelectedOptionsVisible = toggleSelected ?? toggle; // toggles the selected options
    this.cdr.markForCheck();
  }

  private resetInput(options: { onlySelf?: boolean; emitEvent?: boolean }) {
    this.inputCtrl.setValue('', options);
  }

  showDropdownAndFocusInput() {
    if (this.isAvailableDropdownVisible) {
      this.toggleDropdownList(false);
    } else {
      this.toggleDropdownList(true);
      this.inputElementRef?.nativeElement.focus();
    }
  }

  registerOnChange(fn: any): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.inputCtrl.disable();
      this.toggleDropdownList(false);
    } else {
      this.inputCtrl.enable();
    }
    this.cdr.markForCheck();
  }

  /**
   * Takes what is typed into the input and sets selected options from the parsed result
   */
  updateFromCsv(str: string | undefined) {
    // if(!this.allowFreetype) return;
    if (str == null || str.trim() === '') return;
    const values = str
      .split(/[\n\t,]/)
      .map(it => it.trim())
      .filter(it => it !== '');
    this.addOption((values as unknown) as T[]);
    this.resetInput({ onlySelf: true });
  }

  handleInputFocusEvent() {
    this.onTouched();
  }
}
