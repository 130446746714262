import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared-module/shared.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CurrentTasksComponent } from './components/current-tasks/current-tasks.component';
import { TaskComponent } from './components/task/task.component';
import { TaskErrorModalComponent } from './components/task-error-modal/task-error-modal.component';
import { HumanizeTaskTypePipe } from './pipes/hamanize-task-type.pipe';
import { GetResultActionTextPipe } from './pipes/get-result-action-text.pipe';
import { TaskStatusIconComponent } from './components/task-status-icon/task-status-icon.component';
import { HumanizeCronPipe } from './pipes/humanize-cron.pipe';
import { GetTaskTypeIconPipe } from './pipes/get-task-type-icon.pipe';
import { GetTaskDisplayAsPipe } from './pipes/get-task-display-as.pipe';
import { GetResultActionIconPipe } from './pipes/get-result-action-icon.pipe';

@NgModule({
  declarations: [
    CurrentTasksComponent,
    TaskComponent,
    TaskErrorModalComponent,
    HumanizeTaskTypePipe,
    GetResultActionTextPipe,
    TaskStatusIconComponent,
    HumanizeCronPipe,
    GetTaskTypeIconPipe,
    GetTaskDisplayAsPipe,
    GetResultActionIconPipe,
  ],
  imports: [CommonModule, SharedModule, FontAwesomeModule],
  exports: [
    CurrentTasksComponent,
    HumanizeTaskTypePipe,
    GetResultActionTextPipe,
    TaskStatusIconComponent,
    HumanizeCronPipe,
    GetTaskDisplayAsPipe,
    GetResultActionIconPipe,
  ],
})
export class TasksModule {}
