import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationBarComponent } from './components/notification-bar/notification-bar.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NotificationOverlayComponent } from './components/notification-overlay/notification-overlay.component';
import { SharedModule } from '../shared-module/shared.module';
import { NotificationErrorModalComponent } from './components/notification-error-modal/notification-error-modal.component';
import { SubscriptionButtonComponent } from './components/subscription-button/subscription-button.component';

@NgModule({
  declarations: [NotificationBarComponent, NotificationOverlayComponent, NotificationErrorModalComponent, SubscriptionButtonComponent],
  exports: [NotificationBarComponent, NotificationOverlayComponent, SubscriptionButtonComponent],
  imports: [CommonModule, FontAwesomeModule, SharedModule],
})
export class NotificationsModule {}
