import { NgModule } from '@angular/core';
import { AuditModalComponent } from './components/audit-modal/audit-modal.component';
import { AuditBarComponent } from './components/audit-bar/audit-bar.component';
import { SharedModule } from '../shared-module/shared.module';
import { ReactiveFormsModule } from '@angular/forms';
import { ActivityRecordsModalComponent } from './components/activity-records-modal/activity-records-modal.component';
import { AuditDetailComponent } from './components/audit-detail/audit-detail.component';
import { RevertActivityConfirmationModalComponent } from './components/revert-activity-confirmation-modal/revert-activity-confirmation-modal.component';
import { UserModule } from '../user-module/user.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  declarations: [AuditModalComponent, AuditBarComponent, ActivityRecordsModalComponent, AuditDetailComponent, RevertActivityConfirmationModalComponent],
  imports: [SharedModule, ReactiveFormsModule, UserModule, FontAwesomeModule],
})
export class AuditModule {}
