import { ChangeDetectionStrategy, Component, Input, TemplateRef } from '@angular/core';
import { IRestError, isRestError } from '../../../models/rest-response';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';

@Component({
  selector: 'app-error-bar',
  templateUrl: './error-bar.component.html',
  styleUrls: ['./error-bar.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ErrorBarComponent {
  @Input('errors') set errorsInput(errors: (IRestError|string)[]){
    if(errors.length > 0 && isRestError(errors[0])){
      this.errors = (errors as IRestError[]).map(it => it.message);
    } else {
      this.errors = errors as string[];
    }
  }
  @Input('showDropList') set showDropList(showDropList: boolean) {
    this.showErrors = showDropList;
  }
  @Input() errorListTemplate: TemplateRef<any> | undefined;
  showErrors = true;
  errors: string[] = [];
  readonly chevronDownIcon = faChevronDown;
  readonly dangerIcon = faExclamationTriangle;

  protected readonly faExclamationTriangle = faExclamationTriangle;

  toggle(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.showErrors = !this.showErrors;
  }
}
