import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { Portal } from '@angular/cdk/portal';

@Injectable({
  providedIn: 'root'
})
export class NavbarService {

  private _navbarPortal$ = new Subject<Portal<any>>();
  private _placeHolders$ = new BehaviorSubject<{ [k: string]: string }>({});

  constructor() { }

  readonly navbarPortal$ = this._navbarPortal$.asObservable();
  readonly placeholders$ = this._placeHolders$.asObservable();

  setNavbarPortal(portal: Portal<any>){
    this._navbarPortal$.next(portal);
  }

  setPlaceholder(key: string, value: string){
    this._placeHolders$.next({ ...this._placeHolders$.value, [key]: value });
  }

}
