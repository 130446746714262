import { ChangeDetectionStrategy, Component, EventEmitter, HostListener, Inject, Input, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { OVERLAY_CONTROL, OverlayControl } from 'src/app/services/overlay.service';
import { Nullable } from '../../../models/typescript-types';
import { IconEnum } from '../icon/icon.component';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalComponent {
  title$ = new BehaviorSubject<string>('');
  subtitle$ = new BehaviorSubject<Nullable<string>>('');
  overlayClass$ = new BehaviorSubject<Nullable<string>>('standard');
  confirmButtonText$ = new BehaviorSubject<Nullable<string>>($localize`:@@modal__confirm-button:Confirm`);
  cancelButtonText$ = new BehaviorSubject<Nullable<string>>($localize`:@@modal__cancel-button:Cancel`);
  disableConfirmButton$ = new BehaviorSubject<Nullable<boolean>>(false);

  @Input() padding: 'standard' | 'small' | 'none' = 'standard';
  @Input() size: 'auto' | 'size-90' | 'size-90x90' | 'size-90x90-full' = 'auto';

  @Input('modalTitle') set modalTitle(value: string) {
    this.title$.next(value);
  }

  @Input('subtitle') set subtitle(value: Nullable<string>) {
    this.subtitle$.next(value);
  }

  @Input('overlayClass') set overlayClass(value: Nullable<string>) {
    this.overlayClass$.next(value);
  }

  @Input('confirmButtonText') set confirmButtonText(value: Nullable<string>) {
    this.confirmButtonText$.next(value);
  }

  @Input('cancelButtonText') set cancelButtonText(value: Nullable<string>) {
    this.cancelButtonText$.next(value);
    this.overlayControl.backgroundDismiss = value != null;
  }

  @Input('disableConfirmButton') set disableConfirmButton(value: Nullable<boolean>) {
    this.disableConfirmButton$.next(value);
  }

  @Output() confirmEvent = new EventEmitter();
  @Output() cancelEvent = new EventEmitter();

  constructor(@Inject(OVERLAY_CONTROL) private overlayControl: OverlayControl) {}

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    if (event.key === 'Escape' && !event.defaultPrevented) {
      event.preventDefault();
      this.closeModal();
    }
  }

  closeModal(): void {
    this.overlayControl.close();
  }

  confirmAction(): void {
    this.confirmEvent.emit(null);
  }

  cancelAction(): void {
    this.cancelEvent.emit(null);
    this.closeModal();
  }

  protected readonly IconEnum = IconEnum;
}
