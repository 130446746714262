import { ComponentRef, Directive, ElementRef, HostListener, Input, OnDestroy, Injector, TemplateRef } from '@angular/core';
import { ConnectedPosition, Overlay, OverlayPositionBuilder, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';

import { TooltipComponent } from '../components/tooltip/tooltip.component';
import { Nullable } from '../../models/typescript-types';
import { OVERLAY_DATA } from '../../services/overlay.service';
import { createConnectedPosition, TooltipDirection } from '../services/tooltip-helpers';

@Directive({ selector: '[appTooltip]' })
export class TooltipDirective implements OnDestroy {
  @Input('appTooltip') valuesOrRef: string | string[] | TemplateRef<any> | undefined;
  @Input() position: { direction: TooltipDirection; connectedPosition?: ConnectedPosition } = {
    direction: 'to-top',
    connectedPosition: createConnectedPosition('to-top'),
  };

  private overlayRef: Nullable<OverlayRef>;
  private tooltipRef: Nullable<ComponentRef<TooltipComponent>>;

  constructor(private injector: Injector, private overlay: Overlay, private overlayPositionBuilder: OverlayPositionBuilder, private elementRef: ElementRef) {}

  @HostListener('mouseenter') showOnMouseEnter() {
    this.showTooltip();
  }

  @HostListener('focus') showOnFocus() {
    this.showTooltip();
  }

  @HostListener('mouseleave') hideOnMouseEnter() {
    this.hideTooltip();
  }

  @HostListener('blur') hideOnBlur() {
    this.hideTooltip();
  }

  private showTooltip() {
    // If we have already set the tooltip we don't need to do it again so return early
    if (this.tooltipRef) {
      return;
    }
    // Create the overlay and attach a tooltip component to it
    const positionStrategy = this.overlayPositionBuilder
      .flexibleConnectedTo(this.elementRef)
      .withPositions([this.position.connectedPosition ?? createConnectedPosition(this.position.direction)]);
    this.overlayRef = this.overlay.create({ positionStrategy });
    this.tooltipRef = this.overlayRef.attach(
      new ComponentPortal(
        TooltipComponent,
        null,
        Injector.create({
          providers: [
            {
              provide: OVERLAY_DATA,
              useValue: this.position.direction,
            },
          ],
          parent: this.injector,
        })
      )
    );
    // if valuesOrRef is a templateRef set the ref on the TooltipComponent input and attach portal to overlay
    if (this.valuesOrRef instanceof TemplateRef) {
      this.tooltipRef.instance.template = this.valuesOrRef;
    } else if (typeof this.valuesOrRef === 'string') {
      // if valuesOrRef is a single string value set the value on the TooltipComponent input and attach portal to overlay
      this.tooltipRef.instance.value = this.valuesOrRef;
    } else {
      // otherwise valuesOrRef is a string[] so set the values input on the TooltipComponent and attach portal to overlay
      this.tooltipRef.instance.values = this.valuesOrRef;
    }
  }

  private hideTooltip() {
    this.overlayRef && this.overlayRef.dispose();
    this.overlayRef = null;
    this.tooltipRef = null;
  }

  ngOnDestroy() {
    this.hideTooltip();
  }
}
