import { Schema } from '../../../attribute-module/models/schema';
import { AttributeMap } from '../../../attribute-module/models/attribute';
import { FileSummary } from '../../../asset-manager-module/models/file-summary';
import { Entity } from '../../../models/entity';
import { SiteJobItem } from './site-job-item';
import { ElementJobItem } from './element-job-item';
import { FixtureJobItem } from './fixture-job-item';

export interface Job extends Entity {
  createdAt: string;
  updatedAt: string;
  allocatedAt: string | null;
  sites: SiteJobItem;
  elements: ElementJobItem;
  fixtures: FixtureJobItem;
  attributes: AttributeMap;
  fileSummary: FileSummary;
  template: string;
  isArchived: boolean;
  allocateDemandUpdatedAt?: string;
}

export function isJob(job: any): job is Job {
  return job.hasOwnProperty('id')
    && job.hasOwnProperty('displayAs')
    && job.hasOwnProperty('createdAt')
    && job.hasOwnProperty('updatedAt')
    && job.hasOwnProperty('allocatedAt')
    && job.hasOwnProperty('sites')
    && job.hasOwnProperty('elements')
    && job.hasOwnProperty('fixtures')
    && job.hasOwnProperty('attributes')
    && job.hasOwnProperty('imports')
    && job.hasOwnProperty('template');
}

export interface JobItem {
  updatedAt?: string;
  schema: Schema[];
  count: number;
}

export interface JobImporterConfig {
  name: string;
  displayName: string;
  jobTemplates?: string[];
}

export enum JobAllocationStatus {
  OUT_OF_DATE = 'OUT_OF_DATE',
  UP_TO_DATE = 'UP_TO_DATE',
  NOT_ALLOCATED_NO_QUERIES = 'NOT_ALLOCATED_NO_QUERIES',
  NOT_ALLOCATED_HAS_DATA = 'NOT_ALLOCATED_HAS_DATA',
  MISSING_SITES_OR_ELEMENTS = 'MISSING_SITES_OR_ELEMENTS',
}

export enum JobPricingStatus {
  OUT_OF_DATE = 'OUT_OF_DATE',
  UP_TO_DATE = 'UP_TO_DATE',
  NOT_PROCESSED_NO_DATA = 'NOT_PROCESSED_NO_DATA',
  NOT_PROCESSED_HAS_DATA = 'NOT_PROCESSED_HAS_DATA',
  MISSING_ELEMENTS = 'MISSING_ELEMENTS',
}

export enum JobProperties {
  ID = 'ID',
  DISPLAY_AS = 'DISPLAY_AS',
  CREATED_AT = 'CREATED_AT',
  UPDATED_AT = 'UPDATED_AT',
  ALLOCATED_AT = 'ALLOCATED_AT',
  TEMPLATE = 'TEMPLATE',
  PRICED_AT = 'PRICED_AT',
  ALLOCATE_DEMAND_UPDATED_AT = 'ALLOCATE_DEMAND_UPDATED_AT',
  PRICE_DEMAND_UPDATED_AT = 'PRICE_DEMAND_UPDATED_AT',
  SITE_DB_IMPORTED_AT = 'SITE_DB_IMPORTED_AT',
}

export interface JobRenderStatus { SUCCESSFUL: number; IN_PROGRESS: number; FAILED: number; }
