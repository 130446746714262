<app-modal
  [confirmButtonText]="confirmButtonText$ | async"
  [cancelButtonText]="null"
  overlayClass="error"
  (confirmEvent)="onConfirm()"
>
  <ng-container *ngIf="!(isOffline$ | async); else offline">
    <fa-icon class="icon icon-danger icon-super-xxl" [icon]="errorIcon"></fa-icon>
    <h5 i18n="@@error-modal__header-text">Oops! Something went wrong</h5>
    <p class="txt-grey-m subtext" i18n="@@error-modal__sub-text">We've encountered a problem. To help us fix this, could you tell us what you were doing just before this happened.</p>

    <div class="input-group" appFocusFirstFocusableElement>
      <textarea [formControl]="userFeedback" name="answer" id="answer" cols="10" rows="6" i18n-placeholder="@@error-modal__textarea-placeholder" placeholder="Describe what you were doing here..."></textarea>
    </div>
  </ng-container>
  <ng-template #offline>
    <fa-icon class="icon icon-accent icon-super-xxl" [icon]="offlineIcon"></fa-icon>
    <h5 i18n="@@error-modal__header-text--offline">Oops, it appears you're offline</h5>
    <p class="txt-grey-m subtext" i18n="@@error-modal__sub-text--offline">Please check your internet connection and then refresh the page.</p>
  </ng-template>
</app-modal>

