import { Observable, OperatorFunction } from 'rxjs';
import { debounceTime, map, tap } from 'rxjs/operators';

let RXJSUtilsModule;

export function bufferDebounceTime<T>(time = 0): OperatorFunction<T, T[]> {
  return (source: Observable<T>) => {
    let bufferedValues: T[] = [];

    return source.pipe(
      tap(value => bufferedValues.push(value)),
      debounceTime(time),
      map(() => bufferedValues),
      tap(() => bufferedValues = []),
    );
  };
}

export function resizeObservable(element: Element): Observable<ResizeObserverEntry> {
  return new Observable(subscriber => {
    const ro = new ResizeObserver(entries => {
      subscriber.next(entries[0]);
    });

    ro.observe(element);
    return function unsubscribe() {
      ro.disconnect();
    }
  });
}

export default RXJSUtilsModule = {
  bufferDebounceTime,
  resizeObservable,
}
