import { Injectable } from '@angular/core';
import { IValidatorService } from './validator.service';
import { LengthValidator } from '../models/validator/length-validator';
import { AttributeValue } from '../models/attribute';
import { ValidatorType } from '../models/validator';

@Injectable({
  providedIn: 'root',
})
export class LengthValidatorService implements IValidatorService {
  constructor() {}

  validate(displayName: string, validator: LengthValidator, value: AttributeValue): { [key: string]: any } {
    if (!value) return {};

    if (typeof value !== 'string') value = value.toString();

    if ((validator.min && validator.max) && (value.length < validator.min || value.length > validator.max)) {
      // tslint:disable-next-line:max-line-length
      return { [ValidatorType.LENGTH]: { value, message: $localize`:@@length-validator__invalid--min-max:${displayName}:displayName: must be at least ${validator.min}:min: and no more than ${validator.max}:max: characters.` } };
    } else if (validator.min && value.length < validator.min) {
      // tslint:disable-next-line:max-line-length
      return { [ValidatorType.LENGTH]: { value, message: $localize`:@@length-validator__invalid--min:${displayName}:displayName: must be at least ${validator.min}:min: characters.` } };
    } else if (validator.max && value.length > validator.max) {
      // tslint:disable-next-line:max-line-length
      return { [ValidatorType.LENGTH]: { value, message: $localize`:@@length-validator__invalid--max:${displayName}:displayName: must be ${validator.max}:max: characters or less.` } };
    }

    return {};
  }
}

