import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Inject, LOCALE_ID, OnInit, Optional, Output, Self, ViewChild } from '@angular/core';
import { ILPConfiguration } from 'litepicker/dist/types/interfaces';
import Litepicker from 'litepicker';
import 'litepicker/dist/plugins/keyboardnav';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatePickerComponent implements OnInit, ControlValueAccessor {
  constructor(@Inject(LOCALE_ID) private currentLocale: string, @Self() @Optional() public control: NgControl, @Inject(DOCUMENT) private document: Document) {
    if (control) {
      this.control.valueAccessor = this;
    }
  }

  @ViewChild('litePicker', { read: ElementRef, static: true }) public litePickerEl: ElementRef<HTMLDivElement> | undefined;
  @Output() dateChangeEvent = new EventEmitter<null>();
  @Output() tabbed = new EventEmitter<null>();

  private datePicker: Litepicker | undefined;
  private litepickerDestroyed$ = new Subject();
  isSettingDateProgrammatically = false;
  initialDate: string | undefined;

  private static createLitepicker(options: ILPConfiguration): Litepicker {
    return new Litepicker(options);
  }

  onChanged: (value: string) => void = () => {};
  onTouched: (value: string) => void = () => {};

  ngOnInit(): void {
    this.datePicker = DatePickerComponent.createLitepicker({
      element: this.litePickerEl?.nativeElement as HTMLDivElement,
      inlineMode: true,
      lang: this.currentLocale,
      setup: picker => {
        picker.on('selected', () => {
          if (!this.isSettingDateProgrammatically) {
            this.dateChanged();
            this.dateChangeEvent.emit();
          }
        });
        picker.on('destroyed', () => this.litepickerDestroyed$.next(null));
      },
    });
    this.setupDate();
    this.datePicker.show();
  }

  dateChanged() {
    const newDate = this.datePicker?.getDate()?.format('YYYY-MM-DD');
    if (newDate && new Date(newDate).toString() !== 'Invalid Date') {
      this.onChanged(newDate);
    }
  }

  registerOnChange(fn: any): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(dateStr: string): void {
    this.initialDate = dateStr;
    this.setupDate();
  }

  private setupDate() {
    if (this.initialDate != null && this.datePicker) {
      this.isSettingDateProgrammatically = true;
      this.datePicker.setDate(this.initialDate);
      this.datePicker.gotoDate(this.initialDate);
      this.isSettingDateProgrammatically = false;
    }
  }
}
