import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { AqlOperator } from '../../models/aql/aql-operator';
import { CriteriaToFormConversionService } from '../../services/criteria-to-form-conversion.service';
import { AttributeType } from '../../../attribute-module/models/attribute';
import { IconSelectOption } from '../../../shared-module/components/icon-select/icon-select.component';
import { Schema } from '../../../attribute-module/models/schema';
import { getIconSelectsForSchema } from '../../../query-builder-module/utils/form-constants';
import { hasDateValidator } from '../../../attribute-module/models/validator/date-validator';
import { Option } from '../../../shared-module/entities/form-entities';
import { getNestedType, isArrayAttributeType } from '../../../attribute-module/utils/attribute-type-utils';

@Component({
  selector: 'app-filter[criteriaCtrl][additionalRootButtonsRef]',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterComponent {
  @Input() criteriaCtrl: UntypedFormGroup | undefined;
  @Input() fields: string[] | Option[] | undefined;
  @Input() schema: { [p: string]: Schema } | undefined;
  @Input() additionalRootButtonsRef: TemplateRef<any> | undefined;
  @Input() readonlyRootCriteriaGroup = false;

  @Output() lastCriteriaDeleted = new EventEmitter<string>();

  pillOptions: Option[] = [
    { value: 'and', label: $localize`:@@aql-query-label-AND:And` },
    { value: 'or', label: $localize`:@@aql-query-label-OR:Or` },
  ];

  constructor(private aqlToFormConversionService: CriteriaToFormConversionService) {}

  addOperatorCriteria(parentCriteria: AbstractControl) {
    if (parentCriteria.value == null) {
      // We don't have a valid parent group
      // we must be an empty where so replace it with a group of 1 operator
      if (this.schema) {
        (parentCriteria.parent as UntypedFormGroup).setControl(
          'where',
          this.aqlToFormConversionService.convertCriteriaToFromGroup(this.schema, {
            type: 'and',
            criteria: [{ type: 'operator', left: null, right: null, operator: AqlOperator.EQ }],
          })
        );
      }
    } else {
      // We have a valid parent which we can append to
      if (this.schema) {
        (parentCriteria.get('criteria') as UntypedFormArray).push(
          this.aqlToFormConversionService.convertCriteriaToFromGroup(this.schema, { type: 'operator', left: null, right: null, operator: AqlOperator.EQ })
        );
      }
    }
  }

  addGroupCriteria(parentCriteria: AbstractControl) {
    const newFormGroup = this.schema && this.aqlToFormConversionService.convertCriteriaToFromGroup(this.schema, {
      type: parentCriteria.value?.type === 'and' ? 'or' : 'and',
      criteria: [
        { type: 'operator', left: null, right: null, operator: AqlOperator.EQ },
        { type: 'operator', left: null, right: null, operator: AqlOperator.EQ },
      ],
    });
    if (parentCriteria.value == null) {
      // We don't have a valid parent group
      // we must be an empty where so replace it with a group
      (parentCriteria.parent as UntypedFormGroup).setControl('where', newFormGroup);
    } else {
      (parentCriteria.get('criteria') as UntypedFormArray).push(newFormGroup);
    }
  }

  isEmpty(ctrl: AbstractControl): boolean {
    return ctrl.value == null;
  }

  getWidgetForFieldAndOperator(field: string, operator: AqlOperator): 'multi-select' | 'string' | 'number' | 'boolean' | 'date' {
    const schema = this.schema?.[field];
    if (schema == null) {
      // The field will be disabled so it doesn't really matter what we display
      return 'string';
    }

    let type: AttributeType = schema.type;
    if(isArrayAttributeType(type)){
      return 'multi-select';
    }

    if (operator === AqlOperator.NIN || operator === AqlOperator.IN) {
      if (type === AttributeType.STRING) {
        return 'multi-select';
      } else {
        throw Error(`Unexpected (N)IN against non-string field '${JSON.stringify(field)}'.`);
      }
    }

    switch (type) {
      case AttributeType.BOOLEAN:
        return 'boolean';
      case AttributeType.NUMBER:
        return 'number';
      case AttributeType.STRING:
        // We check if the field has validation and we check the first validator within the validators array to see if it should be formatted as a date
        if (hasDateValidator(schema)) {
          return 'date';
        } else {
          return 'string';
        }
    }

    throw Error(`Unknown AttributeType '${JSON.stringify(type)}'.`);
  }

  getOperatorsForField(field: string): IconSelectOption[] {
    const schema = this.schema?.[field];
    if (schema == null) {
      // The user has entered a field which doesn't exist
      // This should mark the field as invalid and disable the operator and right
      return [];
    }
    return getIconSelectsForSchema(schema)
  }

  removeCriteria(criteriaCtrl: UntypedFormGroup, i: number) {
    const values = criteriaCtrl.get('criteria') as UntypedFormArray;
    values.removeAt(i);
    if (values.length === 0) {
      if (criteriaCtrl.parent instanceof UntypedFormArray) {
        // The parent is another criteria group
        // If the group is empty then we need to delete it
        this.removeCriteria(criteriaCtrl.parent.parent as UntypedFormGroup, criteriaCtrl.parent.controls.indexOf(criteriaCtrl));
      } else {
        this.lastCriteriaDeleted.emit();
      }
    }
  }
}
