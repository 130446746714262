<div *ngIf="(notifications$ | async); let notifications" class="overlay" data-test="overlay" [@openCloseOverlay]="notifications.size > 0 ? 'open' : 'closed'" #overlay>
  <button *ngIf="notifications.size > 1" type="button" class="btn btn--xs close-all-btn" data-test="close-all-notifications-button" (click)="closeAllNotifications()">
    <ng-container i18n="@@notification-overlay__close-all-notifications-button">Close All</ng-container><fa-icon class="icon right" [icon]="closeIcon"></fa-icon>
  </button>
  <ul *ngIf="notifications.size > 0" class="notification-list" data-test="notification-list">
    <li *ngFor="let notificationEntry of notifications.values() | reverse; trackBy: trackByKey" data-test="notification-list-item" @notification>
      <app-notification-bar
        [notification]="notificationEntry"
        [class.success]="notificationEntry.type === 'success'"
        [class.error]="notificationEntry.type === 'error'"
        (onHandleAction)="onHandleAction(notificationEntry)"
        (onClose)="onClose(notificationEntry)"
      ></app-notification-bar>
    </li>
  </ul>
</div>
