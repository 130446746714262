import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'reverse'
})
export class ReversePipe implements PipeTransform {

  transform<T>(value: Iterable<T>): Array<T> {
    return Array.from(value).reverse();
  }

}
