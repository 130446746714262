import { Pipe, PipeTransform } from '@angular/core';
import { Task } from '../models/task';
import { TaskHelperService } from '../services/task-helper.service';

@Pipe({
  name: 'getTaskDisplayAs'
})
export class GetTaskDisplayAsPipe implements PipeTransform {
  constructor(private taskHelperService: TaskHelperService) {}

  transform(task: Task): string {
    return this.taskHelperService.getDisplayAs(task);
  }

}
