import { Pipe, PipeTransform } from '@angular/core';
import { TaskHelperService } from '../services/task-helper.service';
import { IconObj } from '../../shared-module/entities/icon-obj';
import { Task } from '../models/task';

@Pipe({
  name: 'getResultActionIcon'
})
export class GetResultActionIconPipe implements PipeTransform {

  constructor(private taskHelperService: TaskHelperService) {}

  transform(task: Task): IconObj | null {
    return this.taskHelperService.getResultActionIconFromTask(task);
  }

}
