<ng-container [ngSwitch]="errorCode">
  <ng-container
    *ngSwitchCase="404"
  >
    <h2>{{errorCode}}</h2>
    <h1 i18n="@@Title-Page-Not-Found">Page Not Found</h1>
    <ng-container i18n="@@Text-404-Sorry-we-could-not-find-the-page-you-were">Sorry we could not find the page you were looking for</ng-container>
    <ng-template [ngTemplateOutlet]="brokenPageButtons"></ng-template>
  </ng-container>
  <ng-container
    *ngSwitchCase="403"
  >
    <h2>{{errorCode}}</h2>
    <h1 i18n="@@Text-403-Forbidden">Forbidden</h1>
    <ng-container i18n="@@Text-403-Sorry-you-dont-have-permission-to-access">Sorry you dont have permission to access this page</ng-container>
    <ng-template [ngTemplateOutlet]="brokenPageButtons"></ng-template>
  </ng-container>
  <ng-container
    *ngSwitchDefault
  >
    <h2>{{errorCode}}</h2>
    <h1 i18n="@@Text-500-Unknown-Error">Unknown Error</h1>
    <ng-container i18n="@@Text-500-Sorry-an-unknown-error-occurred">Sorry an unknown error occurred</ng-container>
    <ng-template [ngTemplateOutlet]="goHomeButton"></ng-template>
  </ng-container>
</ng-container>

<ng-template #goHomeButton>
  <a
    class="primary-btn btn--input-height go-home-btn"
    i18n="@@Button-Go-Home"
    data-test="go-home-button"
    [routerLink]="['/']"
    appTooltip="Go Home"
    i18n-appTooltip="@@Button-Go-Home"
  >
    Go Home
  </a>
</ng-template>

<ng-template #brokenPageButtons>
  <div class="cta-buttons">
    <ng-template [ngTemplateOutlet]="goHomeButton"></ng-template>

    <button
      type="button"
      class="btn primary-btn btn--input-height"
      data-test="logout-button"
      appTooltip="Log Out"
      i18n-appTooltip="@@sidebar__logout-button--title"
      (click)="auth0Service.logout()"
    >
      <fa-icon class="icon left" [icon]="faPersonFromPortal"></fa-icon>
      Log Out
    </button>

    <app-select
      *ngIf="(configService.config$ | async) && (availableClients ? availableClients.length > 1 : 0)"
      data-test="select-client"
      [options]="availableClients!"
      defaultOption="Please select a client"
      i18n-defaultOption="@@sidebar__select__default-option--client"
      [formControl]="clientsForm!"
      appTooltip="Change Client"
      i18n-appTooltip="@@sidebar__select__title--client"
    ></app-select>
  </div>
</ng-template>
