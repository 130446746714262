<div class="toolbar">
  <div *ngIf="queryTemplateLibraryType$ | async; let queryTemplatesType" class="tabs">
    <button type="button" data-test="query-template-box-saved-queries-tab" [ngClass]="queryTemplatesType === 'personal' ? 'query-toolbar-tab' : 'link-alt tab hover-to-fill'" i18n="@@query-template-box__saved-queries-button--text" (click)="showSavedQueries()">My Saved Rules</button>
    <button type="button" data-test="query-template-box-shared-queries-tab" [ngClass]="queryTemplatesType === 'shared' ? 'query-toolbar-tab' : 'link-alt tab hover-to-fill'" i18n="@@query-template-box__shared-queries-button--text" (click)="showSharedQueries()">Shared Rules</button>
  </div>

  <div *ngIf="!isManageQueriesCard" class="toolbar-icons" data-test="toolbar-icons">
    <button type="button" class="toolbar-fill-btn" data-test="remove-query-template-box-button" (click)="removeQueryTemplateBox()" title="Remove Rule Template Box" i18n-title="@@query-template-box__remove-query-box-btn--title">
      <fa-icon class="icon icon-l icon-light" [icon]="deleteIcon"></fa-icon>
    </button>
  </div>
</div>

<div class="query-body" data-test="query-template-body">
  <ng-container *ngIf="(queryTemplates$ | async); let queryTemplates; else noQueryTemplates">
    <div *ngFor="let queryTemplate of (queryTemplates | keyvalue)" class="query-template-bar-container" data-test="query-template-bar">
      <div class="query-template-bar">
        <div data-test="query-template-bar-name">{{ queryTemplate.key }}</div>

        <!-- LOAD Rule -->
        <button *ngIf="!isManageQueriesCard" data-test="query-template-bar-load-button" class="muted-btn btn--sm" (click)="loadQueryTemplate(queryTemplate.value)" appTooltip="Add Rule" i18n-appTooltip="@@query-template-box__load-query-template-btn--tooltip">
          <ng-container i18n="@@query-template-bar__load-query-btn--text">Add Rule</ng-container>
          <fa-icon class="right icon icon-primary" [icon]="nextIcon"></fa-icon>
        </button>

        <ng-container *ngIf="(queryTemplateLibraryType$ | async) === 'personal' || ('svc-user.keystore:sharedAllocationQueryTemplates:update' | hasPermission)">
          <!-- RENAME QUERY -->
          <div *ngIf="(showEditQueryTemplateForm$ | async) === queryTemplate.key; else renameQueryButton" class="input-btn-group" [attr.data-test]="'query-template-bar-rename-form-container(' + queryTemplate.key + ')'">
            <input type="text" [attr.id]="'query-template-bar-rename-form-input(' + queryTemplate.key + ')'" class="input input-muted --sm" [formControl]="currentlyEditingQueryName" #renameQueryInput />

            <button *ngIf="currentlyEditingQueryName.value === queryTemplate.key; else saveRenameQueryButton" type="button" class="muted-btn btn-sm" [attr.data-test]="'query-template-bar-cancel-rename-form-container(' + queryTemplate.key + ')'" (click)="cancelRenameQuery()">
              <fa-icon class="icon icon-danger" [icon]="cancelIcon"></fa-icon>
            </button>

            <ng-template #saveRenameQueryButton>
              <button type="button" class="muted-btn btn-sm" [attr.data-test]="'query-template-bar-rename-button(' + queryTemplate.key + ')'" (click)="renameQuery(queryTemplate.key)">
                <fa-duotone-icon *ngIf="(renamingQuery$ | async) === queryTemplate.key; else renameIcon" class="icon icon-warning" [icon]="workingIcon"></fa-duotone-icon>
                <ng-template #renameIcon><fa-icon class="icon icon-primary" [icon]="saveIcon"></fa-icon></ng-template>
              </button>
            </ng-template>
          </div>
          <ng-template #renameQueryButton>
            <button [attr.data-test]="'query-template-bar-rename-button(' + queryTemplate.key + ')'" class="muted-btn btn--sm" (click)="showEditQueryTemplateForm(queryTemplate.key)" appTooltip="Rename Query Template" i18n-appTooltip="@@query-template-box__rename-query-template-btn--tooltip">
              <ng-container i18n="@@query-template-box__rename-query-template-btn--text">Rename</ng-container>
              <fa-icon class="icon icon-warning right" [icon]="editIcon"></fa-icon>
            </button>
          </ng-template>

          <!-- DELETE QUERY -->
          <button *ngIf="(checkIfRemoveQueryTemplate$ | async) === queryTemplate.key; else beginRemoveButton" [attr.data-test]="'query-template-bar-delete-button(' + queryTemplate.key +')'" class="danger-btn btn--sm" (click)="removeQueryTemplate(queryTemplate.key)" appTooltip="Delete Query Template" i18n-appTooltip="@@query-template-box__remove-query-template-btn--tooltip">
            <ng-container i18n="@@query-template-box__remove-query-template-btn--text">Remove Query Template</ng-container>
            <fa-icon class="icon icon-light right" [icon]="deleteIcon"></fa-icon>
          </button>
          <ng-template #beginRemoveButton>
            <button *ngIf="'svc-user.keystore:sharedAllocationQueryTemplates:update' | hasPermission" [attr.data-test]="'query-template-bar-begin-delete-button(' + queryTemplate.key +')'" class="muted-btn btn--sm" (click)="beginRemoveQueryTemplate(queryTemplate.key)" appTooltip="Delete Query Template" i18n-appTooltip="@@query-template-box__begin-remove-query-template-btn--tooltip">
              <fa-icon class="icon icon-danger" [icon]="deleteIcon"></fa-icon>
            </button>
          </ng-template>
        </ng-container>
      </div>

      <div data-test="query-template-bar-description" class="description">
        {{ queryTemplate.value | aqlToString }}
      </div>
    </div>
  </ng-container>

  <ng-template #noQueryTemplates>
    <div data-test="query-template-box-no-queries" class="no-query-templates">
      <ng-container *ngIf="(queryTemplateLibraryType$ | async) === 'personal'; else noSharedQueryTemplates" i18n="@@query-template-box__no-saved-queries--text">There are currently no saved Rules</ng-container>
      <ng-template #noSharedQueryTemplates><span i18n="@@query-template-box__no-shared-queries--text">There are currently no shared Rules</span></ng-template>
    </div>
  </ng-template>

</div>
