import { Injectable, isDevMode } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  supportedLanguages = ['en-GB', 'nl'];
  defaultLanguage = 'en-GB';
  constructor() {}

  determineSiteLanguage(): void {
    this.changeLanguage(this.checkForLanguage());
  }

  determineBrowserLanguage(): string {
    if (typeof window === 'undefined' || typeof window.navigator === 'undefined') {
      return this.defaultLanguage;
    }
    const wn = window.navigator as any;
    let lang = wn.languages ? wn.languages[0] : this.defaultLanguage;
    lang = lang || wn.language || wn.browserLanguage || wn.userLanguage;
    return lang;
  }

  isLanguageSupported(language: string) {
    return language && this.supportedLanguages.includes(language);
  }

  checkForLanguage(): string {
    let siteLanguage = this.defaultLanguage;
    const previousSelectedLanguage = this.getCookie('rule_lang');
    if (!previousSelectedLanguage|| !this.isLanguageSupported(previousSelectedLanguage)) {
      const browserLanguage = this.determineBrowserLanguage();
      if(this.isLanguageSupported(browserLanguage)) {
        siteLanguage = browserLanguage;
      }
    } else {
      siteLanguage = previousSelectedLanguage;
    }
    return siteLanguage;
  }

  changeLanguage(language: string): void {
    this.setCookie(language);
    this.changeWindowPathname(language);
  }

  getCurrentLocale(): string {
    return this.getCookie('rule_lang') || this.defaultLanguage;
  }

  setCookie(languageCode: string): void {
    const now = new Date();
    now.setMonth( now.getMonth() + 3 );
    document.cookie = `rule_lang=${languageCode}; path=/; expires=${now.toUTCString()}; SameSite=Lax;`;
  }

  getCookie(name: string): string {
    return document.cookie.split('; ').reduce((result, cookies) => {
      const parts = cookies.split('=');
      return parts[0] === name ? decodeURIComponent(parts[1]) : result;
    }, '');
  }

  changeWindowPathname(languageCode: string): void {
    if(isDevMode()){
      return;
    }
    const path = window.location.pathname.split('/');
      if(path[1] !== languageCode) {
        path[1] = languageCode;
        window.location.pathname = path.join('/');
      }
  }
}
