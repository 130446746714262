import { Job, JobAllocationStatus, JobPricingStatus } from '../../models/job/job';
import { ComponentStore } from '@ngrx/component-store';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ConfigStoreSelectors } from '../../store/config-store';
import { Schema } from '../../../attribute-module/models/schema';
import { AttributeType } from '../../../attribute-module/models/attribute';
import { isEqual, sortBy } from 'lodash';
import { KeyVal } from '../../../models/typescript-types';
import { distinctUntilChanged, filter, map, takeUntil } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable()
export class JobStore extends ComponentStore<Job> {
  constructor(private store: Store) {
    super();
  }

  readonly job$ = this.select(state => state);
  readonly jobId$ = this.select(job => job.id);
  readonly displayAs$ = this.select(job => job.displayAs);
  readonly template$ = this.select(job => job.template);
  readonly renderStatus$ = this.select(job => job.elements?.renderStatus);
  readonly isRenderingJob$ = this.select(this.renderStatus$, renderStatus =>
    (renderStatus ? ((renderStatus.FAILED + renderStatus.SUCCESSFUL + renderStatus.IN_PROGRESS) > 0) : false));

  readonly allocationStatus$ = this.select(job => {
    if (job == null) {
      return null;
    } else if (job.sites == null || job.elements == null || job.sites.count == null || job.sites.count === 0 || job.elements.count == null || job.elements.count === 0) {
      return JobAllocationStatus.MISSING_SITES_OR_ELEMENTS;
    } else if (job.elements.allocateDemandUpdatedAt == null && job.allocatedAt == null) {
      return JobAllocationStatus.NOT_ALLOCATED_NO_QUERIES;
    } else if (job.allocateDemandUpdatedAt != null && job.allocatedAt == null) {
      return JobAllocationStatus.NOT_ALLOCATED_HAS_DATA;
    } else if (job.allocateDemandUpdatedAt == null) {
      return JobAllocationStatus.UP_TO_DATE;
    } else if (job.allocateDemandUpdatedAt && job.allocatedAt && job.allocatedAt < job.allocateDemandUpdatedAt) {
      return JobAllocationStatus.OUT_OF_DATE;
    } else {
      return JobAllocationStatus.UP_TO_DATE;
    }
  });

  readonly pricingStatus$ = this.select(job => {
    if (job == null) {
      return null;
    } else if (job.elements == null || job.elements.count == null || job.elements.count === 0) {
      return JobPricingStatus.MISSING_ELEMENTS;
    } else if (job.elements.priceDemandUpdatedAt == null && job.elements.pricedAt == null) {
      return JobPricingStatus.NOT_PROCESSED_NO_DATA;
    } else if (job.elements.priceDemandUpdatedAt != null && job.elements.pricedAt == null) {
      return JobPricingStatus.NOT_PROCESSED_HAS_DATA;
    } else if (job.elements.priceDemandUpdatedAt == null) {
      return JobPricingStatus.UP_TO_DATE;
    } else if (job.elements.pricedAt && job.elements.pricedAt < job.elements.priceDemandUpdatedAt) {
      return JobPricingStatus.OUT_OF_DATE;
    } else {
      return JobPricingStatus.UP_TO_DATE;
    }
  });

  readonly mergedElementSchema$: Observable<{ [k: string]: Schema }> = this.select(
    this.store.select(ConfigStoreSelectors.selectConfigElementSchemaArray),
    this.job$,
    (configSchema, job) => {
      if (configSchema == null || job == null) {
        return null;
      }

      // The server will respond with arrays - build a map so we can merge them without duplicates
      const jobSchemaMap = {} as { [key: string]: Schema };
      (job.elements?.schema ?? []).forEach(schema => {
        jobSchemaMap[schema.key] = schema;
      });

      // Filter so we're only getting elements relevant to our job template
      const clientSchemaMap = {} as { [key: string]: Schema };
      configSchema
        .filter(it => it.jobTemplates?.includes(job.template))
        .forEach(schema => {
          clientSchemaMap[schema.key] = schema;
        });

      // We must take the jobSchema first and then overwrite it with the client schema
      return { ...jobSchemaMap, ...clientSchemaMap };
    }
  ).pipe(distinctUntilChanged(isEqual));

  readonly mergedSiteSchema$: Observable<KeyVal<Schema>> = this.select(this.store.select(ConfigStoreSelectors.selectConfigSiteSchemaArray), this.job$, (configSchema, job) => {
    if (configSchema == null || job == null) {
      return null;
    }

    // The server will respond with arrays - build a map so we can merge them without duplicates
    const jobSchemaMap = {} as { [key: string]: Schema };
    (job.sites?.schema ?? []).forEach(schema => {
      jobSchemaMap[schema.key] = schema;
    });

    // Filter so we're only getting sites relevant to our job template
    const clientSchemaMap = {} as { [key: string]: Schema };
    configSchema
      .filter(it => it.jobTemplates?.includes(job.template))
      .forEach(schema => {
        clientSchemaMap[schema.key] = schema;
      });

    // We must take the jobSchema first and then overwrite it with the client schema
    return { ...jobSchemaMap, ...clientSchemaMap };
  }).pipe(distinctUntilChanged(isEqual));

  readonly sortMergedElementSchema$ = this.mergedElementSchema$.pipe(
    filter(config => config != null),
    map(schemas => sortBy(schemas, ['sortWeight'])),
    takeUntil(this.destroy$)
  );

  readonly mergedSiteSchemaArr$ = this.select(this.mergedSiteSchema$, schema => (schema ? Object.values(schema) : null));
  readonly mergedSiteSchemaAttributeKeys$ = this.select(this.mergedSiteSchemaArr$, schema => schema?.map(it => it.key));
  readonly mergedSiteSchemaNumericAttributeKeys$ = this.select(this.mergedSiteSchemaArr$, schema => schema?.filter(it => it.type === AttributeType.NUMBER).map(it => it.key));
  readonly mergedSiteSchemaNumericBooleanAttributeKeys$ = this.select(this.mergedSiteSchemaArr$, schema => schema?.filter(it => it.type === AttributeType.NUMBER || it.type === AttributeType.BOOLEAN).map(it => it.key));

  readonly update = this.updater((job, updatedJob: Job) => ({ ...updatedJob }));
}
