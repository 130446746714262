import { Validator, ValidatorType } from '../validator';

export interface LengthValidator extends Validator {
  type: ValidatorType.LENGTH;
  min: number | null;
  max: number | null;
}

export function isLengthValidator(validator: Validator): validator is LengthValidator {
  return validator.type === ValidatorType.LENGTH;
}
