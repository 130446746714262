<div [class]="fieldContainerClasses">

  <label *ngIf="labelOptions!.text" [ngClass]="labelOptions!['classes'] ?? ''" [for]="labelOptions!.for">{{ labelOptions!.text }}</label>

  <ng-container *ngIf="showErrorsAboveInput">
    <app-error-listing *ngIf="control!.invalid && (control!.dirty || control!.touched)" [formErrors]="control!.errors"></app-error-listing>
  </ng-container>

  <div class="input-icon-container">
    <ng-content></ng-content>

    <app-input-errors *ngIf="showErrorsTooltip && control!.invalid && (control!.dirty || control!.touched)" [errors]="control!.errors"></app-input-errors>
  </div>

</div>
