<app-field
  fieldClasses="number-field"
  [labelOptions]="{ text: label ?? '', classes: labelClasses, for: uid }"
  [showErrorsAboveInput]="showErrorsAboveInput"
  [control]="control"
>
  <input
    (input)="onInputValueChanged($event)"
    (blur)="onInputTouched()"
    [min]="min"
    [max]="max"
    [id]="uid"
    step="any"
    class="number-input"
    data-test="number-input"
    type="number"
    [class.error]="control.invalid && (control.dirty || control.touched)"
    [placeholder]="placeholder"
    [attr.title]="inputTitle"
    #inputElement
  />
</app-field>
