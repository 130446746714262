import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnDestroy, Output, QueryList, ViewChildren } from '@angular/core';
import { faChevronRight, faPen, faSave, faTrashAlt, faXmark } from '@fortawesome/pro-regular-svg-icons';
import { distinctUntilChanged, filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { KeyVal } from '../../../models/typescript-types';
import { AqlQuery } from '../../../aql-module/models/aql/aql-query';
import { BehaviorSubject, delay, EMPTY, fromEvent, Observable, Subject } from 'rxjs';
import { QueryKeyStoreService } from '../../services/query-key-store.service';
import { faSpinner } from '@fortawesome/pro-duotone-svg-icons';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-query-template-box',
  templateUrl: './query-template-box.component.html',
  styleUrl: './query-template-box.component.sass',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QueryTemplateBoxComponent implements AfterViewInit, OnDestroy {
  @Input() isManageQueriesCard = false;
  @Output() removedQueryTemplateBox = new EventEmitter<null>();
  @Output() addedQueryFromTemplate = new EventEmitter<AqlQuery>();

  private readonly destroy$ = new Subject();
  protected readonly queryTemplateLibraryType$ = this.queryKeyStoreService.selectAllocationSelectedQueryTemplateLibrary$;
  protected readonly deleteIcon = faTrashAlt;
  protected readonly editIcon = faPen;
  protected readonly saveIcon = faSave;
  protected readonly cancelIcon = faXmark;
  protected readonly workingIcon = faSpinner;
  protected readonly nextIcon = faChevronRight;
  protected queryTemplates$: Observable<KeyVal<AqlQuery> | null>;
  protected checkIfRemoveQueryTemplate$ = new BehaviorSubject<string | null>(null);
  protected showEditQueryTemplateForm$ = new BehaviorSubject<string | null>(null);
  protected renamingQuery$ = new BehaviorSubject<string | null>(null);
  protected currentlyEditingQueryName = new FormControl<string | null>(null);

  @ViewChildren('renameQueryInput') renameQueryInputChildren: QueryList<ElementRef> | undefined;

  constructor(private queryKeyStoreService: QueryKeyStoreService, private elRef: ElementRef) {
    this.queryTemplates$ = this.queryTemplateLibraryType$
      .pipe(
        switchMap(type => type === 'personal' ? this.queryKeyStoreService.selectAllocationPersonalQueryTemplates$ : this.queryKeyStoreService.selectAllocationSharedQueryTemplates$),
        // map to null if empty object, so we can check if exists in the template
        map(queries => Object.keys(queries).length > 0 ? queries : null),
      );
  }

  ngAfterViewInit() {
    if (!this.isManageQueriesCard) {
      requestAnimationFrame(() => {
        this.elRef?.nativeElement?.scrollIntoView({ behavior: 'smooth', block: 'start' });
      });
    }

    this.showEditQueryTemplateForm$
      .pipe(distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe(queryTemplateName => this.currentlyEditingQueryName.patchValue(queryTemplateName));
  }

  removeQueryTemplateBox() {
    this.removedQueryTemplateBox.emit(null);
  }

  loadQueryTemplate(query: AqlQuery) {
    this.addedQueryFromTemplate.emit(query)
  }

  beginRemoveQueryTemplate(queryTemplateName: string) {
    this.checkIfRemoveQueryTemplate$.next(queryTemplateName);
  }

  removeQueryTemplate(queryTemplateName: string) {
    this.checkIfRemoveQueryTemplate$.next(null);
    this.queryTemplateLibraryType$
      .pipe(
        switchMap(type => {
          if (type === 'personal') {
            return this.queryKeyStoreService.removePersonalQueryTemplate(queryTemplateName);
          } else {
            return this.queryKeyStoreService.removeSharedQueryTemplate(queryTemplateName);
          }
        }),
        take(1),
      )
      .subscribe();
  }

  showSavedQueries() {
    this.queryKeyStoreService.setAllocationSelectedQueryTemplateLibrary('personal').pipe(take(1)).subscribe();
  }

  showSharedQueries() {
    this.queryKeyStoreService.setAllocationSelectedQueryTemplateLibrary('shared').pipe(take(1)).subscribe();
  }

  showEditQueryTemplateForm(queryTemplateName: string) {
    this.showEditQueryTemplateForm$.next(queryTemplateName);
    setTimeout(() => {
      const matchingInputElement = Array.from(this.renameQueryInputChildren ?? [])
        .find(renameQueryInput => renameQueryInput.nativeElement.id === `query-template-bar-rename-form-input(${ queryTemplateName })`);

      if (matchingInputElement) {
        matchingInputElement.nativeElement.select();

        fromEvent<KeyboardEvent>(matchingInputElement.nativeElement, 'keyup')
          .pipe(
            filter(e => e.key === 'Enter'),
            take(1),
          )
          .subscribe(() => this.renameQuery(queryTemplateName));
      }
    }, 16);
  }

  renameQuery(queryTemplateName: string) {
    this.queryTemplateLibraryType$
      .pipe(
        tap(() => this.renamingQuery$.next(queryTemplateName)),
        delay(300),
        switchMap((type) => {
          const newName = this.currentlyEditingQueryName.value;
          if (newName == null || newName === queryTemplateName) return EMPTY;
          return (type === 'personal')
            ? this.queryKeyStoreService.renamePersonalQueryTemplate(queryTemplateName, newName)
            : this.queryKeyStoreService.renameSharedQueryTemplate(queryTemplateName, newName)
        }),
        take(1),
      )
      .subscribe({ complete: () => this.renamingQuery$.next(null) });
  }

  cancelRenameQuery() {
    this.showEditQueryTemplateForm$.next(null);
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
