import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';
import { getAndFocusFirstFocusableElement } from '../../utils/helpers';

@Directive({
  selector: '[appFocusFirstFocusableElement]',
})
export class FocusFirstFocusableElementDirective implements AfterViewInit {
  @Input() elementRef: ElementRef | undefined;

  constructor(private elRef: ElementRef) {}

  ngAfterViewInit() {
    getAndFocusFirstFocusableElement(this.elRef.nativeElement);
  }
}
