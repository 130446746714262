import { Component, Input } from '@angular/core';
import { TaskStatus } from '../../models/task-status';
import { faCircleCheck, faTriangleExclamation } from '@fortawesome/pro-regular-svg-icons';
import { isScheduledTask, ScheduledTask } from '../../models/scheduled-task';
import { Task } from '../../models/task';
import { faCircleExclamation } from '@fortawesome/pro-solid-svg-icons';

@Component({
  selector: 'app-task-status-icon [task]',
  templateUrl: './task-status-icon.component.html',
  styleUrls: ['./task-status-icon.component.sass']
})
export class TaskStatusIconComponent {
  taskStatus = {...TaskStatus, ...TaskStatusWithWarning };
  successIcon = faCircleCheck;
  warningIcon = faCircleExclamation;
  errorIcon = faCircleExclamation;
  dangerIcon = faTriangleExclamation;
  task!: TaskStatus | TaskStatusWithWarning;
  isMulti = false;

  @Input('task') set _task(task: Task | ScheduledTask) {
    this.task = this.getStatus(isScheduledTask(task) ? task.historicTasks.map(it => it.status) : task.status);
    this.isMulti = isScheduledTask(task);
  }

  constructor() { }

  private getStatus(status: TaskStatus | TaskStatus[]): TaskStatus | TaskStatusWithWarning {
    if (!Array.isArray(status)) return status;
    if (status.every(s => s === TaskStatus.COMPLETE)) return TaskStatus.COMPLETE;
    if (status.every(s => s === TaskStatus.ERROR)) return TaskStatus.ERROR;
    if (status.some(s => s === TaskStatus.ERROR)) return TaskStatusWithWarning.WARNING;
    if (status.some(s => s === TaskStatus.QUEUED)) return TaskStatus.QUEUED;
    return TaskStatus.RUNNING;
  }
}

enum TaskStatusWithWarning {
  WARNING = 'WARNING',
}
