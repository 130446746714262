import { Inject, Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, switchMap } from 'rxjs';
import { APP_CONFIG, AppConfig } from '../../app-config';
import { isR36Url } from './api-utils';
import { ConfigService } from './config.service';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AddHeadersInterceptorService implements HttpInterceptor {

  constructor(@Inject(APP_CONFIG) private config: AppConfig, private configService: ConfigService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // Don't intercept the default-client request as we need this to return when we have a null client.
    if (request.url.endsWith('api/config/v1/default-client')) return next.handle(request);

    return this.configService.selectedClient$.pipe(
      take(1),
      switchMap(selectedClient => {
        const updatedRequest = isR36Url(request.url, this.config.apiRoot) && request.headers.get('r36-client') == null
          ? request.clone({ headers: request.headers.set('r36-client', selectedClient) })
          : request;
        return next.handle(updatedRequest);
      })
    );
  }
}
