import { Injectable } from '@angular/core';
import { FileHttpService } from '../../asset-manager-module/services/file-http.service';
import { faCircleArrowRight, faDownload } from '@fortawesome/pro-regular-svg-icons';
import { icon } from '@fortawesome/fontawesome-svg-core';
import { IconObj } from '../entities/icon-obj';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { CommentHttpService } from '../../comment-module/services/comment-http.service';
import { KeyVal } from '../../models/typescript-types';
import { firstValueFrom, Observable, switchMap } from 'rxjs';
import { ElementHttpService } from '../../allocation-module/services/element-http.service';

@Injectable({
  providedIn: 'root',
})
export class ResultStringService {
  readonly RESULT_ACTION_ERROR_TEXT = $localize`:@@result-string__action--name--errors:See Errors`;

  constructor(
    private router: Router,
    private elementHttpService: ElementHttpService,
    private fileHttpService: FileHttpService,
    private commentHttpService: CommentHttpService,
  ) {}

  // splits result string into entity and value (where value is the rest of the string once entity is extracted)
  private static extractResultToComponents(result: string): { entity: string; value: string } {
    const parts = result.split('/');
    return {
      entity: `${ parts[0] }/${ parts[1] }`,
      value: parts.splice(2).reduce((prev, curr, index) => (index === 0 ? `${ curr }` : `${ prev }/${ curr }`), ''),
    };
  }

  handle(result: string | null, client: string): Promise<boolean> {
    if (result == null) return Promise.resolve(false);
    // get and remove the action from the result string as we don't want to persist it as an entity
    const actionIdx = result.indexOf('?');
    const action = actionIdx !== -1 ? result.substring(actionIdx + 8) : undefined; // 8 refers to ?action= in the string
    if (actionIdx !== -1) result = result.substring(0, actionIdx);

    const { entity, value } = ResultStringService.extractResultToComponents(result);
    switch (entity) {
      case 'svc-file/file': // TODO: this shouldn't have the version ID, to fix
        const [fileId, versionId] = value.split('/');
        return this.fileHttpService.downloadFile(fileId, versionId, client);
      case 'svc-allocation/job':
      case 'svc-site-db/site':
        return this.navigateByEntitiesArray([result]);
      case 'svc-comment/comment':
        return this.navigateByEntityObservable(this.commentHttpService.getCommentById(value), result, action);
      case 'svc-allocation/element':
        return this.navigateByEntityObservable(this.elementHttpService.getElementById(value), result);
      default:
        return this.router.navigate(['404'], { skipLocationChange: true });
    }
  }

  private navigateByEntityObservable (entity$: Observable<{ relatedEntities: string[] }>, result: string, action?: string): Promise<boolean> {
    return firstValueFrom(entity$.pipe(take(1), switchMap(({relatedEntities}) => this.navigateByEntitiesArray([...relatedEntities, result], action))));
  }

  private navigateByEntitiesArray(entities: string[], action?: string): Promise<boolean> {
    const entitiesMap: KeyVal<string> = entities.reduce((acc, curr) => {
      const { entity, value } = ResultStringService.extractResultToComponents(curr);
      return { ...acc, [entity]: value };
    }, {});

    const commands: string[] = [];
    const queryParams: KeyVal<string> = {};

    if (entitiesMap['svc-allocation/job']) commands.push('jobs', entitiesMap['svc-allocation/job']);
    if (entitiesMap['svc-allocation/element']) {
      commands.push('elements');
      queryParams['element-id'] = entitiesMap['svc-allocation/element'];
    }
    if (entitiesMap['svc-site-db/site']) {
      commands.push('sites');
      queryParams['site-id'] = entitiesMap['svc-site-db/site'];
    }
    if (entitiesMap['svc-comment/comment']) {
      queryParams['comment-id'] = entitiesMap['svc-comment/comment'];
    }
    if (entitiesMap['svc-file/file']) queryParams['file-id'] = entitiesMap['svc-file/file'];
    if (entitiesMap['svc-file/file-version']) queryParams['file-version-id'] = entitiesMap['svc-file/file-version'];

    if (action) queryParams['action'] = action;

    return this.router.navigate(commands, { queryParamsHandling: 'merge', queryParams });
  }

  getResultActionText(result: string | null): string | null {
    if (result == null) return null;
    const { entity } = ResultStringService.extractResultToComponents(result);
    switch (entity) {
      case 'svc-file/file':
        return $localize`:@@result-string__action--name--download:Download`;
      case 'svc-allocation/job':
        return $localize`:@@result-string__action--name--go-to-job:Go to Job`;
      case 'svc-site-db/site':
        return $localize`:@@result-string__action--name--go-to-site:Go to Site`;
      case 'svc-comment/comment':
        return $localize`:@@result-string__action--name--go-to-comment:Go to Comment`;
      default:
        throw Error(`Couldn't get action text from the result string provided: ${ result }`);
    }
  }

  getResultActionIcon(result: string | null): IconObj | null {
    if (result == null) return null;
    const { entity } = ResultStringService.extractResultToComponents(result);
    switch (entity) {
      case 'svc-file/file':
        return { icon: icon(faDownload), classes: 'icon-primary' };
      case 'svc-allocation/job':
      case 'svc-site-db/site':
      case 'svc-comment/comment':
        return { icon: icon(faCircleArrowRight), classes: 'icon-primary' };
      default:
        throw Error(`Couldn't get action icon from the result string provided: ${ result }`);
    }
  }

  getResultActionErrorIcon(): IconObj {
    return { icon: icon(faCircleArrowRight), classes: 'icon-danger' };
  }
}


