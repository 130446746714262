<ng-container *ngIf="criteriaCtrl && !isEmpty(criteriaCtrl); else noFilters" [ngTemplateOutlet]="criteria" [ngTemplateOutletContext]="{ criteriaCtrl: criteriaCtrl, root: true, index: 'root' }"></ng-container>

<ng-template #noFilters>
  <div class="no-filter-container">
    <div class="no-filters" i18n="@@filter-component__query-filter__no-filters" data-test="no-filters">There are currently no filters</div>
    <div class="criteria-buttons root" *ngIf="criteriaCtrl">
      <button type="button" class="link" data-test="add-filter-button" (click)="addOperatorCriteria(criteriaCtrl)">
        <app-icon class="filter-link-icon icon-m2 left" icon="plus-circle"></app-icon>
        <ng-container i18n="@@filter-component__button--add-filter">Add Filter</ng-container>
      </button>
      <button type="button" class="link" data-test="add-filter-group-button" (click)="addGroupCriteria(criteriaCtrl)">
        <app-icon class="filter-link-icon icon-m2 left" icon="plus-circle"></app-icon>
        <ng-container i18n="@@filter-component__button--add-filter-group">Add Filter Group</ng-container>
      </button>
      <ng-container *ngTemplateOutlet="additionalRootButtonsRef!"></ng-container>
    </div>
  </div>
</ng-template>

<ng-template #criteria let-criteriaCtrl="criteriaCtrl" let-root="root" let-index="index">
  <div
    class="group-criteria"
    [class.root]="root"
    [class.inverted]="criteriaCtrl.get('not').value"
    [class.readonly]="criteriaCtrl.disabled"
    [class.pad-bottom]="!root && criteriaCtrl.get('criteria').controls.length === 1"
    [attr.data-test]="'group-criteria(' + index + ')'"
  >
    <app-boolean-toggle label="Excludes:" data-test="excludes-filter-toggle" data-tabindex="-1" [formControl]="criteriaCtrl.get('not')"></app-boolean-toggle>

    <ng-container *ngFor="let nestedCriteriaCtrl of criteriaCtrl.get('criteria').controls; let isLast = last; let isFirst = first; let i = index">
      <div
        *ngIf="nestedCriteriaCtrl.get('type').value === 'operator'; else criteriaGroup"
        class="operator-criteria"
        [class.readonly]="root && isFirst && readonlyRootCriteriaGroup"
        [attr.data-test]="'operator-criteria(' + i + ')'"
      >
        <app-filter-field
          [options]="fields"
          [formControl]="nestedCriteriaCtrl.get('left')"
          [showErrorsAboveInput]="false"
          labelClasses="aql-label"
          data-test="operator-criteria-field"
        ></app-filter-field>

        <app-icon-select
          [options]="getOperatorsForField(nestedCriteriaCtrl.get('left').value)"
          [formControl]="nestedCriteriaCtrl.get('operator')"
          labelClasses="aql-label"
          data-test="operator-criteria-operator"
        ></app-icon-select>

        <ng-container [ngSwitch]="getWidgetForFieldAndOperator(nestedCriteriaCtrl.get('left').value, nestedCriteriaCtrl.get('operator').value)">
          <app-multi-select-datalist
            *ngSwitchCase="'multi-select'"
            [class.hide-in-place]="nestedCriteriaCtrl.get('operator').value | isBlankTypeOperator"
            data-test="operator-criteria-value-multi"
            [options]="(nestedCriteriaCtrl.get('left').value | getOptionsFunction:nestedCriteriaCtrl.get('left').valid)"
            [formControl]="nestedCriteriaCtrl.get('right')"
            labelClasses="aql-label"
            label=""
          ></app-multi-select-datalist>

          <app-number-field
            *ngSwitchCase="'number'"
            [class.hide-in-place]="nestedCriteriaCtrl.get('operator').value | isBlankTypeOperator"
            data-test="operator-criteria-value-number"
            [formControl]="nestedCriteriaCtrl.get('right')"
            [showErrorsAboveInput]="false"
            labelClasses="aql-label"
          ></app-number-field>

          <app-boolean-field
            *ngSwitchCase="'boolean'"
            [class.hide-in-place]="nestedCriteriaCtrl.get('operator').value | isBlankTypeOperator"
            data-test="operator-criteria-value-boolean"
            [formControl]="nestedCriteriaCtrl.get('right')"
            labelClasses="aql-label"
            label=""
          ></app-boolean-field>

          <app-date-field
            *ngSwitchCase="'date'"
            [class.hide-in-place]="nestedCriteriaCtrl.get('operator').value | isBlankTypeOperator"
            data-test="operator-criteria-value-date"
            [formControl]="nestedCriteriaCtrl.get('right')"
            [showErrorsAboveInput]="false"
            labelClasses="aql-label"
            placeholder="Please enter a valid date in the format: 'yyyy-mm-dd'"
            i18n-placeholder="@@date-field__placeholder--valid-date-format"
            label=""
          ></app-date-field>

          <app-filter-field
            *ngSwitchDefault
            [class.hide-in-place]="nestedCriteriaCtrl.get('operator').value | isBlankTypeOperator"
            data-test="operator-criteria-value-string"
            [formControl]="nestedCriteriaCtrl.get('right')"
            [options]="nestedCriteriaCtrl.get('left').value | getOptionsFunction:nestedCriteriaCtrl.get('left').valid"
            [showErrorsAboveInput]="false"
            labelClasses="aql-label"
          ></app-filter-field>
        </ng-container>

        <button *ngIf="!(isFirst && root && readonlyRootCriteriaGroup) && criteriaCtrl.enabled" type="button" class="link" data-test="operator-criteria-delete-button" (click)="removeCriteria(criteriaCtrl, i)">
          <app-icon class="icon-m2 icon-danger" icon="delete"></app-icon>
        </button>
      </div>
      <ng-template #criteriaGroup>
        <ng-container [ngTemplateOutlet]="criteria" [ngTemplateOutletContext]="{ criteriaCtrl: nestedCriteriaCtrl, root: false, index: i }"></ng-container>
      </ng-template>
      <app-select *ngIf="!isLast" class="math-operator-btn" data-test="and-or-select" [isPill]="true" [options]="pillOptions" [defaultOption]="null" [formControl]="criteriaCtrl.get('type')" [forceDisabled]="!isFirst"></app-select>
    </ng-container>

    <div *ngIf="criteriaCtrl.enabled || (root && criteriaCtrl.get('criteria').controls.length === 1)" class="criteria-buttons" [class.root]="root">
      <button type="button" [disabled]="root && readonlyRootCriteriaGroup" class="link" data-test="add-filter-button" (click)="addOperatorCriteria(criteriaCtrl)">
        <app-icon class="filter-link-icon icon-m2 left" icon="plus-circle"></app-icon>
        <ng-container i18n="@@filter-component__button--add-filter">Add Filter</ng-container>
      </button>
      <button
        type="button"
        [disabled]="root && readonlyRootCriteriaGroup && criteriaCtrl.get('criteria').controls.length !== 1"
        class="link"
        data-test="add-filter-group-button"
        (click)="addGroupCriteria(criteriaCtrl)"
      >
        <app-icon class="filter-link-icon icon-m2 left" icon="plus-circle"></app-icon>
        <ng-container i18n="@@filter-component__button--add-filter-group">Add Filter Group</ng-container>
      </button>
      <ng-container *ngIf="root">
        <ng-container *ngTemplateOutlet="additionalRootButtonsRef!"></ng-container>
      </ng-container>
    </div>
  </div>
</ng-template>
