import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import * as uuid from 'uuid';

@Component({
  selector: 'app-boolean-field [label]',
  templateUrl: './boolean-field.component.html',
  styleUrls: ['./boolean-field.component.sass'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BooleanFieldComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BooleanFieldComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input() labelClasses: string | undefined;
  @Input() label: string | undefined;
  @Input() labelTrue = $localize`:@@boolean-field__label--true:true`;
  @Input() labelFalse = $localize`:@@boolean-field__label--false:false`;
  @Input() inputTitle: string | undefined;
  uid = uuid.v4();
  control: UntypedFormControl;
  focused = false;
  private destroy$ = new Subject<null>();

  constructor(private cdr: ChangeDetectorRef) {
    this.control = new UntypedFormControl({ value: false, disabled: false }, { validators: [Validators.required, BooleanFieldComponent.isBooleanValidator] });
  }

  @HostBinding('class.disabled') get disabled() {
    return this.control.disabled;
  }

  static isBooleanValidator = (control: AbstractControl): { [p: string]: any } | null => {
    if (control.value !== null && typeof control.value === 'boolean') {
      return null;
    } else {
      return {
        'boolean-field-component__invalid-boolean': {
          value: control.value,
          message: $localize`:@@boolean-field__validation--invalid-boolean:'${control.value}:value:' is not a valid true/false value.`,
        },
      };
    }
  };

  onChanged: (value: boolean) => void = () => {};
  onTouched: any = () => {};

  ngOnInit(): void {
    this.control.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(v => {
      this.onChanged(v);
      this.cdr.markForCheck();
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  toggle(): void {
    if (this.control.disabled) {
      return;
    }
    this.control.setValue(!this.control.value);
  }

  writeValue(value: boolean): void {
    this.control.patchValue(value == null ? false : value, { emitEvent: false });
    setTimeout(() => this.onChanged(this.control.value));
  }

  registerOnChange(fn: any): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.control.disable({ onlySelf: true }) : this.control.enable({ onlySelf: true });
    this.cdr.markForCheck();
  }
}
