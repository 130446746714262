<div
  class="error-bar"
  data-test="error-bar"
  [class.errors-showing]="showErrors"
  (click)="toggle($event)"
>
  <h4 class="status-bar-title" data-test="status-bar-title" i18n="@@Text-Error-Overlay-An-error-has-occurred">An error has occurred</h4>

  <div class="error-icons">
    <fa-icon class="icon status-bar-icon" [icon]="dangerIcon" data-test="status-bar-error-icon"></fa-icon>
    <button
      type="button"
      class="icon-btn chevron"
      data-test="status-bar-dropdown-icon"
      (click)="toggle($event)"
    >
      <fa-icon [icon]="chevronDownIcon" class="icon status-bar-icon"></fa-icon>
    </button>
  </div>
</div>

<ng-container *ngIf="showErrors">
  <ng-container *ngTemplateOutlet="errorListTemplate ? errorListTemplate : defaultErrorList"></ng-container>
</ng-container>

<ng-template #defaultErrorList>
  <ul class="error-bar-drop-list">
    <li data-test="error-bar-error-list-item" *ngFor="let error of errors">{{ error }}</li>
  </ul>
</ng-template>
